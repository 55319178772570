import { OnInit, Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from '../../common/services/api-service.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss']
})
export class SearchJobComponent implements OnInit {


  modalRef: BsModalRef;
  constructor(
    private router: Router,
    private aroute: ActivatedRoute,
    private modalService: BsModalService,
    private apiServiceService: ApiServiceService,
    private fb: FormBuilder,
    private cookie:CookieService

  ) {
    this.getIndustry();
    this.getFeature();
  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
    // this.getIndustry();
    // this.getFeature();
  }

  keyskillList = null;
  locationList = null;
  experience = null;
  salaryType = null;
  ctcLakh = null;
  ctcThou = null;
  industry = null;
  funcArea = null;
  jobTypeFlag = null;
  employerType = null;


  ngOnInit() {

    this.initializeAdvanceSearchForm();
    // this.patchForm();


  }

  patchForm() {
    this.aroute.queryParams.subscribe(params => {
      if (params['q']) {
        let key = params['q'].replace(/_+/g, ' ');
        this.searchForm.patchValue({ keyskillList: key });
        this.keyskillList = key;
      }
      if (params['l']) {
        let loc = params['l'].replace(/_+/g, ' ');
        this.searchForm.patchValue({ locationList: loc })
        this.locationList = loc
      }
      if (params['e']) {
        let exp = params['e'];
        this.searchForm.patchValue({ experience: exp })
      }

      if (params['ind']) {
        let ind = params['ind'].replace(/_+/g, ' ');
        let data = this.industryList.find(item => item.categoryDesc === ind)

        // for (let item of this.industryList) {


        //   if (item.categoryDesc === "Account / Finance") {
        //     var indId = item.categoryId;
        //     console.log(indId)
        //   }
        //   else {
        //     console.log(false)
        //   }
        // }
        if (data)
          this.searchForm.patchValue({ industry: data.categoryId })

      }
      if (params['fun']) {
        let fa = params['fun'].replace(/_+/g, ' ');
        this.searchForm.patchValue({ funcArea: +fa })

      }
      if (params['jt']) {
        let jobType = params['jt'];
        this.searchForm.patchValue({ jobTypeFlag: jobType })
      }
      if (params['et']) {
        this.employerType = params['et'];
      }
    });
  }



  clickSearch() {

    var values = this.searchForm.value;
    let keyFilter = values.keyskillList.replace(/\s+/g, '_');
    let expLoc;
    if (values.locationList) {
      expLoc = values.locationList.replace(/\s+/g, '_');
    }
    this.keyskillList = keyFilter;
    this.locationList = expLoc;
    let indName = this.industryList.find(item => item.categoryId === values.industry)

    this.router.navigate(['/job-search'], {
      queryParams: {
        q: keyFilter, 'l': expLoc, 'e': values.experience,
        'ind': indName?indName.categoryDesc:null, 'fun': values.funcArea, 'jt': values.jobTypeFlag
      }
    });

    if (this.modalRef) {
      this.modalRef.hide();
    }
  }

  cancelForm() {
    // this.keyskillList = null;
    // this.locationList = null;
    // this.experience = null;
    // this.salaryType = null;
    // this.ctcLakh = null;
    // this.ctcThou = null;
    // this.industry = null;
    // this.funcArea = null;
    // this.jobTypeFlag = null;
    // this.employerType = null;
    this.modalRef.hide();
    let keyData = this.searchForm.value.keyskillList;
    this.searchForm.reset();
    // this.searchForm.patchValue({jobTypeFlag:'F'});
    this.locationList = null;
    this.searchForm.patchValue({ keyskillList: keyData });
    this.router.navigate(['/job-search']);

  }

  searchForm: FormGroup;
  initializeAdvanceSearchForm() {
    this.searchForm = this.fb.group({
      keyskillList: [null, Validators.required],
      locationList: [null],
      experience: [null],
      industry: [null],
      funcArea: [null],
      jobTypeFlag: [null],
    })
  }

  expYears = [
    // {
    //   display: "Select Experience Years",
    //   val: null
    // },
    {
      display: "1 Year",
      val: 1
    },
    {
      display: "2 Years",
      val: 2
    },
    {
      display: "3 Years",
      val: 3
    },
    {
      display: "4 Years",
      val: 4
    },
    {
      display: "5 Years",
      val: 5
    },
    {
      display: "6 Years",
      val: 6
    },
    {
      display: "7 Years",
      val: 7
    },
    {
      display: "8 Years",
      val: 8
    },
    {
      display: "9 Years",
      val: 9
    },
    {
      display: "10 Years",
      val: 10
    },
    {
      display: "11 Years",
      val: 11
    },
    {
      display: "12 Years",
      val: 12
    }
  ]

  erro = null;
  keyChange(e) {
    if (this.searchForm.value.keyskillList) {
      this.clickSearch();
    } else {
      this.erro = "Keyword is required."
    }
  }

  txtEnter() {
    this.erro = null;
  }

  industryList = [];
  getIndustry() {
    // let data = {
    //   type: "ind"
    // }
    this.apiServiceService.industriesList().subscribe(
      (response: any) => {
        this.industryList = response.ind;
        this.patchForm();
      })
  }

  featureList = [];
  getFeature() {
    // let data = {
    //   type: "func"
    // }
    this.apiServiceService.functionalAreasList().subscribe(
      (response: any) => {
        this.featureList = response.func;
      })
  }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }

}

<ng-template #template>
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title pull-left">Email Verification</h4>
    <span type="button" class="cursor pull-right" (click)=" closeModal()">
      <span aria-hidden="true">&times;</span>
    </span>
  </div>

  <div class="modal-body ">


    <div [ngClass]="{'visibilityClass': emailStep=='second'}">



      <div class="row">
        <div class="col-md-12">
          <p>Do you want to proceed to Email Verification ?</p>
        </div>

        <div class="col-md-12 mt-15">

          <div class="col-md-6 nopadding">

            <button class="btn-link" [routerLink]="[ '/jobseeker/change-email' ]" (click)="closeModal()">
              Change Email </button>
          </div>

          <div class="col-md-6 nopadding text-right">

            <button class="btn green-btn" style="margin-right: 15px;" (click)="proceedForVerification()">
              Confirm </button>

            <button class="btn cancel" (click)="closeModal()">
              Cancel </button>

          </div>




        </div>
      </div>
    </div>

    <div class="row" [ngClass]="{'visibilityClass': emailStep=='first'}">
      <div class="col-md-12">
        <div class="col-md-12  white-box">



          <div class="col-md-12 text-center">
            <img src="../../../assets/images/verifyemail.png" alt="">
          </div>
          <div class="col-md-12 text-center mt-30">

            <p style="font-size: 20px;">Please verify your email address</p>
            <p>We have sent you a verification email at <span class="fw-500">{{email|| 'null@gmail.com'}}</span> .
              Please
              verify your email address.</p>
          </div>

          <div class="col-md-12 mt-10 text-center ">
            <p><span id="timers" class="text-center"></span> </p>

            <!-- <span class="resend" (click)="resendCode()" *ngIf="!disableResend"> Resend
              Verification Email</span> -->
            <span class="resend" (click)="resendCode(); timer()"
              *ngIf="!disableResend">RESEND EMAIL VERIFICATION</span>
            <span class="resendDisable" *ngIf="disableResend" style="color:#808080">RESEND EMAIL VERIFICATION 
            </span>
            <!-- <button class="btn btn-green q-button" style="margin-left: 5px;" [routerLink]="[ '/employer/login' ]"> Go to
              login page</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
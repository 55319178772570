<app-spinner1 *ngIf="!clientObj"></app-spinner1>
<div class="container container-fixed-top" *ngIf="clientObj">
  <div class="col-md-12 tb-m-25">
    <div class="default-custom-banner" *ngIf="!clientObj.bannerImage">
      <p class="jobTitle">
        {{ clientObj.companyName }}
      </p>
    </div>

    <div class="row">
      <div class="col-md-12 " *ngIf="clientObj.bannerImage">
        <img
          class="imgbanner"
          src="{{ clientObj.bannerImage }}"
          alt=""
          width="100%"
        />
      </div>
    </div>

    <div class="col-sm-12 company-detail-main">
      <div class="col-sm-9 flex">
        <div style="margin-right:15px;">
          <img
            *ngIf="clientObj.image"
            src="{{ clientObj.image }}"
            class="companydetail-logo"
          />
          <label *ngIf="!clientObj.image" class="emptySSLogo">{{
            clientObj.companyName.split("")[0]
          }}</label>
        </div>

        <div class="company-name-section">
          <label class="company-name">{{ clientObj.companyName }}</label>
          <p *ngIf="clientObj.companyMoto">
            {{ clientObj.companyMoto }}
          </p>
        </div>
      </div>
      <!-- <div class="col-sm-3 followus-section">
          <p class="follow-text">( {{clientObj.follower || '0'}} ) Followers</p>
          <a href="" class="btn btn-info" ng-click="followCompany('Y')"
            *ngIf="clientObj.followed == 'N' && jsLoggedIn && !hideFollowBtn"> Follow us </a>
          <a class="btn btn-info" disabled *ngIf="hideFollowBtn"> Loading </a>
          <a class="btn btn-warning" *ngIf="clientObj.followed == 'Y' && jsLoggedIn && !hideFollowBtn"
            ng-click="followCompany('N')"> Unfollow </a>
        </div> -->
    </div>

    <div class="row">
      <div class="col-md-9">
        <!--What we offer-->
        <div class="box-view col-md-12">
          <p class="text-ellipsis jtitle ">
            About Us
          </p>
          <div class="jtbody" [innerHtml]="clientObj.companyDesc"></div>
        </div>
        <!--What we offer-->

        <!--Job Description Section-->
        <div class="box-view col-md-12" *ngFor="let item of jobSearchList">
          <div class="inner_box">
            <p>
              <a
                [routerLink]="[
                  '/job',
                  replaceSpace(item.title),
                  item.jobCreateId
                ]"
                class="green-orange-link inner-heading text-ellipsis"
                >{{ item.title }}</a
              >
            </p>
            <p>
              <i class="fa fa-map-marker btn-icon-10"></i
              ><span class="strong">Location : </span>{{ item.location }}
            </p>
            <div class="row">
              <div class="col-md-6">
                <p>
                  <i class="fa fa-hourglass-end btn-icon"></i
                  ><span class="strong">Job Type : </span>
                  <span *ngIf="item.jobTypeFlag == 'F'">Full Time</span>
                  <span *ngIf="item.jobTypeFlag == 'P'">Part Time</span>
                  <span *ngIf="item.jobTypeFlag == 'C'">Contract</span>
                </p>
              </div>
              <div class="col-md-6">
                <p>
                  <i class="fa fa-briefcase btn-icon-10"></i
                  ><span class="strong">Experience : </span>{{ item.expMin }} to
                  {{ item.expMax }} Years
                </p>
              </div>
            </div>
            <p>
              <i class="fa fa-lightbulb-o btn-icon-10"></i>
              <span class="strong">Key Skills : </span>
              <span
                *ngFor="let item of skillArray(item.keySkill)"
                class="skills"
                >{{ item || "Not Available" }}</span
              >
            </p>
            <div class="row footer">
              <div class="col-md-4 col-xs-4 foot-box">
                <i class="fa fa-thumbs-o-up btn-icon"></i>
                <!-- {{item.likes }} -->
              </div>
              <div class="col-md-4 col-xs-4 foot-box">
                <i class="fa fa-share-alt  btn-icon "></i>
              </div>
              <div class="col-md-4 col-xs-4 foot-box-right">
                <i class="fa fa-bookmark  btn-icon"></i>
                <!-- {{item.bookmarks}} -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Side Bar-->
      <div class="col-md-3 sidebar">
        <div class="box-view col-md-12">
          <div class="col-md-12 bar side-heading">
            <span>About Company</span>
          </div>

          <div class="col-md-12 bar">
            <p class="text-ellipsis">
              <span class="strong">Industry : </span>{{ clientObj.industries }}
            </p>
          </div>
          <div class="col-md-12 bar">
            <p class="text-ellipsis">
              <span class="strong">Ownership : </span>
              <span *ngIf="clientObj.companyType == 'PVT'">
                Private Company</span
              >
              <span *ngIf="clientObj.companyType == 'PUB'">
                Public Company</span
              >
              <span *ngIf="clientObj.companyType == 'GOV'">Government</span>
              <span *ngIf="clientObj.companyType == 'NGO'">NGO / INGO</span>
              <span *ngIf="clientObj.companyType == 'CNS'">Consultant</span>
            </p>
          </div>
          <div class="col-md-12 bar">
            <p class="text-ellipsis">
              <span class="strong">Address : </span>{{ clientObj.address }} ,
              {{ clientObj.location }}
            </p>
          </div>
          <div class="col-md-12 bar">
            <p class="text-ellipsis">
              <span class="strong">State : </span>{{ clientObj.stateName }}
            </p>
          </div>
          <div class="col-md-12 bar">
            <p class="text-ellipsis">
              <span class="strong">Country : </span>{{ clientObj.countryName }}
            </p>
          </div>
          <!-- <div class="col-md-12 bar">
            <p class="text-ellipsis"><span class="strong">Phone : </span>{{clientObj.landLine}}</p>
          </div> -->
          <a
            [href]="filterLink(clientObj.url)"
            target="_blank"
            *ngIf="clientObj.url"
          >
            <div class="col-md-12 bar green-link">
              <p class="text-ellipsis fw500">
                <i class="fa fa-laptop btn-icon" aria-hidden="true"></i
                >&nbsp;Visit Website
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="col-md-12 default-custom-banner">
    <p class="jobTitle" *ngIf="jobsObj.empDetails == 'Y'">
      {{jobsObj.companyName}}
    </p>

    <div *ngIf="jobsObj.empDetails == 'N'">
      <p class="f-alg f28 text-white" *ngIf="jobsObj.companyType == 'PVT'">Private Company</p>
      <p class="f-alg f28 text-white" *ngIf="jobsObj.companyType == 'PUB'">Public Company</p>
      <p class="f-alg f28 text-white" *ngIf="jobsObj.companyType == 'GOV'">Government Organization</p>
      <p class="f-alg f28 text-white" *ngIf="jobsObj.companyType == 'NGO'">NGO / INGO</p>
      <p class="f-alg f28 text-white" *ngIf="jobsObj.companyType == 'CNS'">Consultant</p>
    </div>
  </div> -->

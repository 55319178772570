import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/common/services/api-service.service";
import { Toast, ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-uefa",
  templateUrl: "./uefa.component.html",
  styleUrls: ["./uefa.component.scss"],
})
export class UefaComponent implements OnInit {
  constructor(
    private fbBuilder: FormBuilder,
    private apiServiceService: ApiServiceService,
    private toastr: ToastrService,
    private cookie: CookieService,
    private route: Router
  ) {}

  userCookie = {
    userId: null,
    userRole: null,
    tmpE: null,
    emailVerified: null,
  };
  getCookieStorage() {
    this.userCookie.userId = this.cookie.get("userId");
    this.userCookie.userRole = this.cookie.get("userRole");
    this.userCookie.tmpE = this.cookie.get("tmpE");
    this.userCookie.emailVerified = this.cookie.get("emailVerified");
    if (!this.userCookie.userId) {
      this.route.navigate(["/jobseeker/login"], {
        queryParams: { returnUrl: 'eurocup2024' },
      });
    }
    console.log(this.route.url);
    // debugger;
  }

  uefa: FormGroup;

  gameList = [];
  scoreA = [0, 0, 0, 0, 0];
  scoreB = [0, 0, 0, 0, 0];

  gameSpinner: boolean = true;
  ngOnInit(): void {
    this.getCookieStorage();
    this.gameSpinner = true;
    let obj = [];
    this.apiServiceService.getSchedule().subscribe((data: any[]) => {
      for (let d of data) {
        this.apiServiceService.checkMatch(d.id).subscribe((res: any) => {
          if (res.status == "SUCCESS") {
            obj.push(d);
          }
        });
      }
      console.log(obj);
      setTimeout(() => {
        this.gameList = obj;
        this.gameSpinner = false;
      }, 1500);
    });
  }

  spinner: boolean = false;
  submitForm() {
    this.spinner = true;

    let data = {
      predictions: [],
    };
    for (let i = 0; i <= this.gameList.length - 1; i++) {
      data.predictions.push({
        matchScheduleId: this.gameList[i].id,
        countryaScore: this.scoreA[i],
        countrybScore: this.scoreB[i],
        gameYear: "2024",
      });

      this.apiServiceService.submitScore(data.predictions).subscribe(
        (response) => {
          this.spinner = false;
          this.scoreA = this.scoreB = [];
          this.toastr.success("Successful", "Submitted Successfully.", {
            timeOut: 3000,
          });
          this.route.navigate([""]);
        },
        (error: any) => {
          this.toastr.error("Unsuccessful", error.error.errorMessage, {
            timeOut: 3000,
          });
        }
      );
    }
  }

  checkLoginStatus() {
    if (this.cookie.get("at")) return true;
    else return false;
  }
}

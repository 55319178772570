<div class="row">
    <div class="col-md-12">
        <div class="col-md-12 form-padding">
            <p class="main_heading">JOBSEEKER LOGIN</p>
            <p class="sub_heading">Already a registered member? Log in here </p>

            <div class="alert alert-danger text-center" role="alert" *ngIf="errorMsg">
                {{errorMsg}}
            </div>

            <form [formGroup]="loginForm">

                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" formControlName="username" placeholder="Email">
                </div>

                <div class="form-group" style="position: relative;">
                    <label for="password">Password</label>
                    <input type={{type}} class="form-control" id="password" formControlName="password"
                        placeholder="Password">
                    <span class="show-password" *ngIf="type=='text'" (click)="changeType()"><i class="fa fa-eye"
                            aria-hidden="true" style="font-size: 14px;"></i></span>
                    <span class="show-password" *ngIf="type=='password'" (click)="changeType()"><i
                            class="fa fa-eye-slash" aria-hidden="true" style="font-size: 14px;"></i></span>
                </div>

                <div class="form-group text-right">
                    <span for="password">
                        <a class="red-link cursor" (click)="forgetPassword()">
                            Forget password ?
                        </a>
                    </span>

                </div>

                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <button class="btn green-btn" (click)="login()" [disabled]="loginForm.invalid"
                                *ngIf="!logging" style="width: 150px;">Log
                                In</button>
                            <button class="btn green-btn" [disabled]="true" *ngIf="logging">Logging in ...</button>

                        </div>

                    </div>
                </div>
            </form>
            <br />
            <div class="col-md-12 text-center">
                <p>
                    Don't have any account yet?
                    <a class="green-orange-link noDecoration" style="white-space: pre;" (click)="createAccount()">Create a
                        new
                        account</a>
                    today !
                </p>
            </div>

        </div>
    </div>
</div>
<div class="container-fluid navbar-custom">
  <div class="container">
    <div class="grid-container">
      <div class="grid-item col-md-5" [routerLink]="['/jobseeker/dashboard']"
        [ngClass]="urlLastData == 'dashboard' ? 'active' : ''">
        <i class="fa fa-tachometer" aria-hidden="true"></i><span class="res-hide"> Dashboard</span>
      </div>

      <div class="grid-item col-md-5" [routerLink]="['/jobseeker/profile']" [ngClass]="urlLastData == 'profile' || getMatch() ? 'active' : ''">
        <i class="fa fa-user"></i><span class="res-hide"> Profile</span> 
      </div>
      <!-- <div
        class="grid-item col-md-5"
        [routerLink]="['/jobseeker/mail']"
        [ngClass]="urlLastData == 'mail' ? 'active' : ''"
      >
        <i class="fa fa-inbox" aria-hidden="true"></i><span class="res-hide"> Inbox</span> 
      </div> -->
      <!-- <div class="grid-item col-md-5" [routerLink]="['/jobseeker/preference']"
        [ngClass]="urlLastData == 'preference' ? 'active':''"> <i class="fa fa-cog" aria-hidden="true"></i> Setting
      </div>

      <a href="" [routerLink]="['/jobseeker/inbox']"><i class="fa fa-envelope-o"
        aria-hidden="true" style="margin-right:2px; font-size:14px; font-weight:bold;"></i> Inbox</a> -->

      <!-- <div class="grid-item col-md-5" [routerLink]="['/jobseeker/job-notifications']"
        [ngClass]="urlLastData == 'job-notifications' ? 'active':''"> <i class="fa fa-briefcase" aria-hidden="true"></i>
        Jobs For You</div> -->

      <div class="grid-item col-md-5" [routerLink]="['/jobseeker/application-status']"
        [ngClass]="urlLastData == 'application-status' ? 'active' : ''" *ngIf="!examFlag">
        <i class="fa fa-file-text-o" aria-hidden="true"></i><span class="res-hide"> Applied Jobs</span> 
      </div>

      <div class="grid-item col-md-5" [routerLink]="['/jobseeker/change-password']"
        [ngClass]="urlLastData == 'change-password' ||  urlLastData == 'change-email'||  urlLastData == 'change-mobileNumber'||  urlLastData == 'delete-account'? 'active' : ''">
        <i class="fa fa-cog" aria-hidden="true"></i><span class="res-hide"> Setting</span> 
      </div>

      <!-- <div class="grid-item col-md-5" [routerLink]="['/jobseeker/worldcup']"
      [ngClass]="">
     <span class="res-hide animate-charcter"> Worldcup</span> 
    </div> -->

      <div class="grid-item col-md-5" [routerLink]="['/examlist']" [ngClass]="urlLastData == 'examlist' ? 'active' : ''"
        *ngIf="examFlag">
        <i class="fa fa-cog" aria-hidden="true"></i> Exam List
      </div>
    </div>
  </div>
</div>
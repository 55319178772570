import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/common/services/api-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private apiService: ApiServiceService
  ) { }

  getYear;
  ngOnInit() {

    this.getYear = new Date().getFullYear()
    this.getIndustry();
    this.getFuncionalArea();
  }

  industryList: any;
  getIndustry() {
    this.apiService.getFooterIndustry().subscribe(
      (res: any) => {
        this.industryList = res.data;
      }
    )
  }


  functionalAreaList: any = [];
  getFuncionalArea() {
    this.apiService.getFooterFunctionalArea().subscribe(
      (res: any) => {
        this.functionalAreaList = res.data;
        this.functionalAreaList = this.functionalAreaList.slice(0, 6);
      }
    )
  }


  searchJobByIndustry(id) {
    var search = id;
    this.router.navigate(['/job-search'], {
      queryParams: {
        'ind': search, 'f': 'ind'
      }
    });
  }

  searchJobByFunctionalArea(id) {
    var search = id;
    this.router.navigate(['/job-search'], {
      queryParams: {
        'fun': search, 'f': 'fun'
      }
    });
  }




}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { RouterExtService } from 'src/app/common/services/router-ext.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { Uploadfile } from 'src/app/common/function/upload';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-job-apply',
  templateUrl: './job-apply.component.html',
  styleUrls: ['./job-apply.component.scss']
})
export class JobApplyComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  constructor(
    private restApiService: RestApiService,
    private routerExtService: RouterExtService,
    private uploadfile: Uploadfile,
    private router: Router,
    private cookie: CookieService,
    public rest: RestApiService,
    private modalService: BsModalService,
    private apiServiceService: ApiServiceService,
    private toastr: ToastrService




  ) {
  }

  responseJob: any;
  myProfile: any = '';
  jobInfo: any = '';
  validation: any = '';
  keySkills: any = [];

  ngOnInit(): void {
    if (this.cookie.get('userId'))
      this.userId = this.cookie.get('userId');

    this.responseJob = JSON.parse(localStorage.getItem('jobData'));
    this.jobInfo = this.responseJob.otherInfo;
    console.log(this.jobInfo)
    this.validation = this.responseJob.validation;
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match('/job/'))
      this.cookie.set('ajpu', previous, 365, '/');

  }

  goToJobDetail() {
    var previous = this.cookie.get('ajpu');
    this.router.navigate([previous])
  }


  splitData(data) {
    if (data)
      return data.split(',')
    else
      return null;
  }


  // Bookmark section

  bookmarkJobId: any;
  jobIndex: any;
  bookmarkJob(data) {

    this.jobInfo['jobBookmark'] = this.jobInfo['jobBookmark'] == 'Y' ? 'N' : 'Y'


    // if (data.bookmark == 'N')
    //   this.toastr.success('Successful', 'Added to Bookmark.');
    // else
    //   this.toastr.success('Successful', 'Removed from Bookmark.');

    this.apiServiceService.saveBookmark(data.jobCreateId).subscribe(
      (res: any) => {
      }
    );
  }

  setBookmarkId(id) {
    this.bookmarkJobId = id;
    this.rest.bookmarkJobId = id;
    this.rest.hideBookmarkModal = 0;
  }

  userId: any;
  showBookmark(data) {
    if (this.rest.hideBookmarkModal == 1) {
      this.modalRef.hide();
      this.userId = this.cookie.get('userId');
      // Write codes here
    }
    if (this.rest.hideBookmarkModal == 2) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['jobseeker/register'])
    }
    if (this.rest.hideBookmarkModal == 3) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['forget-password'])
    }

    if (this.rest.savedJobId != null && (this.rest.savedJobId == data.jobCreateId)) {

      if (!this.jobInfo.jobBookmark)
        this.jobInfo.jobBookmark = 'Y';



    }
  }

  closeModal() {
    this.modalRef.hide();
  }

  charFileName: any;
  charErrorMsg: string;
  charFile: any;
  charOriginalFileName: any;
  uploadCoverLetter(files: any) {

    if (files) {
      let extensionType = ['docx', 'pdf', 'doc'];
      this.charFile = this.uploadfile.fileUpload(files, extensionType, 3097152);
      this.charOriginalFileName = files[0].name;
      if (this.charFile.fileStatus == 1) {
        this.charFileName = files[0].name;
        this.uploadCover();
      }
      else if (this.charFile.fileStatus == 3) {
        this.charErrorMsg = "This file type is not supported.";
      }
      else {
        this.charErrorMsg = "File size should be less than 3 Mb.";
      }

      if (this.charErrorMsg) {
        setTimeout(() => {
          this.toastr.error('Unsuccessful', this.charErrorMsg, {
            timeOut: 4000
          });

          this.charErrorMsg = null

        }, 4000);
      }
    }
    else {
      this.charFile = {
        fileStatus: 0,
        sendFile: null
      };
      this.charFileName = null;
      this.charOriginalFileName = null;

    }
  }

  uploadCover() {
    if (this.charFile) {
      if (this.charFile.fileStatus == 1) {
        this.charFile.sendFile.append('jobCreateId', this.jobInfo.jobCreateId)
        this.apiServiceService.uploadCoverLetters(this.charFile.sendFile).subscribe(
          (response: any) => {

            if (response) {
              this.validation['coverLetter'] = true;
              this.validation['yourCoverLetter'] = response.originalFileName;
              this.validation['yourCoverLetterUrl'] = response.url;

              this.toastr.success("success", "Cover Letter Uploaded Successfully.");
            }



            this.applyJob(response);
          },
          (error: any) => {
            this.toastr.error('Unsuccessful', 'Cover Letter upload unsuccessful. ', {
              timeOut: 4000
            });
          }
        )
      }
    }
  }

  responseObj: any;
  applyJob(data) {
    let obj = {
      jobCreateId: this.jobInfo.jobCreateId,
      coverLetter: data.databaseFileName,
      coverLetterTitle: data.originalFileName,
    }

    this.apiServiceService.jobApply(obj).subscribe(
      (res: any) => {
        this.toastr.success('Successful', 'Job applied successfully.', { timeOut: 4000 });

        this.router.navigate(['/job/', this.replaceSpace(this.jobInfo.title), this.jobInfo.jobCreateId]);
      },
      (error: any) => {
        // this.responseObj = error.error;
        // this.cookie.set('jobData',error.error)
        localStorage.setItem('jobData', JSON.stringify(error.error));
        this.toastr.error('Unsuccessful', 'Job apply unsuccessful. ', {
          timeOut: 4000
        });


        this.router.navigate(['/job-apply'])
      }
    )
  }

  replaceSpace(value) {
    let str = value.replace(/\s+/g, "-");
    return str;
  }
}

import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(
    public meta: Meta, public title: Title,
  ) { }

  ngOnInit() {

    this.title.setTitle("About Us | Jobejee");
    this.meta.updateTag({ name: 'keywords', content: "jobs in Nepal, jobsite in Nepal, job vacancy in Nepal, employment, recent job in nepal, IT jobs in Nepal, job opportunities in Nepal" });
    this.meta.updateTag({ name: 'description', content: "Jobejee is the fastest growing job portal in Nepal. With Jobejee website and application, you can search and apply for jobs with optimal ease and also have a stress-free application period. Find your career opportunities at Jobejee! Search among thousands of jobs from top companies, industries and locations of your choice! Jobejee’s advanced features allows you to find your perfect job within minutes and apply for it with a single click. You are also able to easily assess your resume performance and track your applications." });
    this.meta.updateTag({ name: 'url', content: "https://www.jobejee.com/aboutus" });

    // <!-- Open Graph data -->
    this.meta.updateTag({ property: 'og:title', content: "About Us | Jobejee" });
    this.meta.updateTag({ property: 'og:site_name', content: "jobejee" });
    this.meta.updateTag({ property: 'og:type', content: "website" });
    this.meta.updateTag({ property: 'fb:app_id', content: "2458134394205611" });
    this.meta.updateTag({ property: 'og:url', content: "https://www.jobejee.com/aboutus" });
  }

}

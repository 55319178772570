<div class="container-fluid container-fixed-top container-fixed-top-nav">
  <div class="row">
    <app-admin-navbar></app-admin-navbar>
  </div>
  <div class="row" *ngIf="!loader">

    <div class="container">
      <div class="row">
        <div class="col-md-12 wt-box">

          <!--Top row -->
          <div class="row">
            <div class="col-md-12 text-right">
              <p class="addPayment"> <span (click)="openModal(addPayment)">+ Add Payment</span> </p>
            </div>
            <div class="col-md-3 col-sm-6 res-mb-20">
              <div>
                <input type="text" class="form-control searchbar" placeholder="Name of Employers/Bank Name"
                  (keyup)="search($event)">
                <i class="fa fa-search search-icon" aria-hidden="true"></i>
              </div>
            </div>

            <div class="col-md-9 col-sm-6 text-right">
              <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
                <button dropdownToggle type="button" class="btn custom-filter" style="outline: none;">
                  <i aria-hidden="true" class="fa fa-filter"></i> Filters
                </button>

                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu custom-dropdown" role="menu"
                  aria-labelledby="button-basic">
                  <span class="triangle"><i class="fa fa-caret-up" aria-hidden="true"></i></span>


                  <form [formGroup]="filterForm" (ngSubmit)="filter()">
                    <div class="col-md-12 form-group">
                      <label for="date">Date</label>
                      <input type="text" id="date" placeholder="Select Date" formControlName="date" class="form-control"
                        [bsConfig]="{ isAnimated: true ,dateInputFormat: 'MMMM Do YYYY',adaptivePosition: true  ,customTodayClass:'custom-today-class'}"
                        bsDaterangepicker placement="left">
                    </div>


                    <div class="col-md-12 form-group">
                      <label for="status">Status</label>
                      <select class="form-control" formControlName="status" id="status">
                        <option value="null" disabled>All</option>
                        <option value="approved">Approved</option>
                        <option value="unapproved">Not Approved</option>
                      </select>
                    </div>



                    <div class="col-md-12 text-right form-group">
                      <span class='cancel' (click)="dropdown.hide(); resetForm('filter')">Cancel</span>
                      <button class="btn green-btn" style="margin-left: 17px;" type="submit"
                        (click)="dropdown.hide();">Apply Filter</button>
                    </div>
                  </form>
                </ul>
              </div>
            </div>

            <div class="col-md-12 col-xs-12" *ngIf="billingData">
              <span class="details">Total : {{totalItems}}</span>
              <span class="details">Approved Payment : {{billingData.stat[0].paid}}</span>
              <span class="details">Pending Payment : {{billingData.stat[0].unpaid}}</span>
            </div>
          </div>

          <div class="row">
            <app-display-items [numberOfItems]="numberOfItems" [totalElements]="totalPages"
              (size)="onSizeChanged($event)"></app-display-items>
          </div>

          <!-- Table -->
          <div class="table-responsive col-md-12 nopadding">
            <table>
              <tr>
                <th>SN</th>
                <th>Employers</th>
                <th>Package</th>
                <th>Plan Type</th>
                <th>Amount</th>
                <th>Mode Of Payment</th>
                <th>Date</th>
                <th>Sold By</th>
                <th>Action</th>
              </tr>
              <ng-container *ngIf="billingList.length > 0">
                <tr *ngFor="let items of billingList; let i = index">
                  <td>{{(currentPage-1)*size+i + 1}}</td>
                  <td><a class="green-link"
                      [routerLink]="['/employer',items.companyName,items.empId]">{{items.companyName}}</a>
                    <i class="fa fa-info-circle res-display green-text-bold cursor" *ngIf="items.remarks"
                      aria-hidden="true" popover="{{items.remarks || 'N/A'}}" popoverTitle="Remarks"
                      [adaptivePosition]="true" placement="right" triggers="mouseenter:mouseleave"></i>
                  </td>
                  <td>{{items.packageType || 'N/A'}}</td>
                  <td>{{items.planType || 'N/A'}}</td>

                  <td>{{items.finalRate ? 'Rs. ' + (items.finalRate |number) + ' /-' : 'N/A'}}</td>
                  <td>{{items.payMedium || 'N/A'}}</td>
                  <td>{{(items.paidDate |date) || 'N/A'}}</td>
                  <td>{{items.soldBy || 'N/A'}}</td>
                  <td *ngIf="(i + 1 == billingList.length || i + 2 == billingList.length ); else drop">
                    <div class="btn-group" dropdown>
                      <span dropdownToggle class="action cursor" aria-controls="dropdown-disabled-menu">
                        <span
                          [ngClass]="items.payApproveStatus === 'Y' ? 'green-text' : 'red-text'">{{items.payApproveStatus == 'Y' ? 'Payment Approved' : 'Payment not Approved'}}</span><span
                          class="caret"></span>
                      </span>

                      <ul id="dropdown-disabled-menu" *dropdownMenu class="dropdown-menu action-menu"
                        role="menu" aria-labelledby="button-disabled-menu">
                        <li role="menuitem"><a class="dropdown-item cursor"
                            (click)="viewedItem = items ;openModal(view)">View</a></li>
                        <li role="menuitem"><a class="dropdown-item cursor"
                            (click)="openModal(template); remarksId = items.cartLogId; remarks=items.remarks">Add
                            Remarks</a></li>
                        <li role="menuitem"><a class="dropdown-item cursor"
                            (click)="openModal(alert); paymentApproveID = items.cartLogId"
                            *ngIf=" userRole=='an112' || userRole=='mr112'">{{items.payApproveStatus == 'Y' ? 'Payment not Approved' : 'Payment Approved'}}</a>
                        </li>
                        <!-- <li role="menuitem"><a class="dropdown-item">Cancel</a></li> -->
                      </ul>
                    </div>

                  </td>

                  <ng-template #drop>
                    <td>
                      <div class="btn-group" dropdown>
                        <span dropdownToggle class="action cursor" aria-controls="dropdown-disabled-menu">
                          <span
                            [ngClass]="items.payApproveStatus === 'Y' ? 'green-text' : 'red-text'">{{items.payApproveStatus == 'Y' ? 'Payment Approved' : 'Payment not Approved'}}</span><span
                            class="caret"></span>
                        </span>

                        <ul id="dropdown-disabled-menu" *dropdownMenu class="dropdown-menu action-menu" role="menu"
                          aria-labelledby="button-disabled-menu">
                          <li role="menuitem"><a class="dropdown-item cursor"
                              (click)="viewedItem = items ;openModal(view)">View</a></li>
                          <li role="menuitem"><a class="dropdown-item cursor"
                              (click)="openModal(template); remarksId = items.cartLogId; remarks=items.remarks">Add
                              Remarks</a></li>
                          <li role="menuitem"><a class="dropdown-item cursor"
                              (click)="openModal(alert); paymentApproveID = items.cartLogId"
                              *ngIf=" userRole=='an112' || userRole=='mr112'">{{items.payApproveStatus == 'Y' ? 'Payment not Approved' : 'Payment Approved'}}</a>
                          </li>
                          <!-- <li role="menuitem"><a class="dropdown-item">Cancel</a></li> -->
                        </ul>
                      </div>

                    </td>
                  </ng-template>
                </tr>
              </ng-container>


              <ng-container *ngIf="billingList.length==0">
                <tr class="text-center">
                  <td colspan="8">No Data Available</td>
                </tr>
              </ng-container>


            </table>
          </div>


          <!-- pagination -->
          <div class="col-md-12  text-center nopadding">
            <nav aria-label="Page navigation">
              <ul class="pagination" *ngIf="totalPages > 1">
                <li [ngClass]="{disabled: currentPage == 1}">
                  <a *ngIf="currentPage != 1; else disFirst" (click)="page = 0; changePage()">First</a>
                  <ng-template #disFirst>
                    <a>First</a>
                  </ng-template>
                </li>
                <li [ngClass]="{disabled: currentPage == 1}">
                  <a *ngIf="currentPage != 1; else disPrevious" (click)="page = (currentPage-2); changePage()"><i
                      class="fa fa-angle-double-left fa-lg side" aria-hidden="true"></i></a>
                  <ng-template #disPrevious>
                    <a><i class="fa fa-angle-double-left fa-lg side" aria-hidden="true" style="color: #999;"></i></a>
                  </ng-template>
                </li>
                <li [ngClass]="currentPage == x ? 'current' : ''" *ngFor="let x of totalPagination.pages">
                  <a (click)="page = (x-1); changePage()">{{x}}</a>
                </li>
                <li [ngClass]="{disabled: currentPage == totalPages}">
                  <a *ngIf="currentPage != totalPages; else disNext" (click)="page = (currentPage); changePage()"><i
                      class="fa fa-angle-double-right fa-lg side" aria-hidden="true"></i></a>
                  <ng-template #disNext>
                    <a><i class="fa fa-angle-double-right fa-lg side" aria-hidden="true" style="color: #999;"></i></a>
                  </ng-template>
                </li>
                <li [ngClass]="{disabled: currentPage == totalPages}">
                  <a *ngIf="currentPage != totalPages; else disLast"
                    (click)="page = (totalPages-1); changePage()">Last</a>
                  <ng-template #disLast>
                    <a>Last</a>
                  </ng-template>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid " style="margin-top: 200px; margin-bottom: 15px;" *ngIf="loader">
  <app-jobejee-loader></app-jobejee-loader>
</div>

<!--Modal-->
<ng-template #template>
  <!--Heading Section-->
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title text-left">Add Remarks</h4>
    <div class="x-close" (click)="modalRef.hide();remarksId = null; remarks = null;">
      <span>x</span>
    </div>
  </div>
  <!--Body Section-->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 form-group">
        <label for="remarks">Remarks</label>
        <textarea id="remarks" class="form-control custom-text-area" cols="10" rows="5" [(ngModel)]="remarks"
          placeholder="Add remarks here"></textarea>
      </div>
      <div class="col-md-12 form-group">
        <button class="btn green-btn margin-right-20" (click)="addRemarks();modalRef.hide()"
          [disabled]="!remarks">Save</button>
        <button class="btn" (click)="modalRef.hide(); remarksId = null; remarks = null;">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>




<!-- Add Payment -->
<ng-template #addPayment>
  <!--Heading Section-->
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title text-left">Add Payment</h4>
    <div class="x-close" (click)="modalRef.hide(); resetForm('payment')">
      <span>x</span>
    </div>
  </div>
  <!--Body Section-->
  <div class="modal-body">
    <div class="row">
      <form [formGroup]="paymentForm" (ngSubmit)="paymentSubmit()">
        <div class="col-md-12 form-group">
          <label for="compamnyName">Company Name <span class="red-text">*</span></label>
          <ngx-select-dropdown id="compamnyName" [config]="config1" [options]="companyList" formControlName="empId"
            [multiple]="false">
          </ngx-select-dropdown>
        </div>

        <div class="col-md-12 form-group">
          <label for="bankName">Bank Name <span class="red-text">*</span></label>
          <ngx-select-dropdown [config]="config2" [options]="bankList" formControlName="bankDetailId" [multiple]="false"
            (change)="changeValidators()">
          </ngx-select-dropdown>
        </div>

        <div class="col-md-12 form-group">
          <label for="planType">Plan Type <span class="red-text">*</span></label>
          <select name="" id="planType" class="form-control" formControlName="packageType" (change)="controlValidators()">
            <option value="null" disabled>Select Plan Type</option>
            <option value="jobPlan">Job Plan</option>
            <option value="resumeAccessPlan">Resume Plan</option>

          </select>
        </div>

        <div class="col-md-12 form-group" *ngIf="paymentForm.value.packageType=='jobPlan'">
          <label for="packageType">Job Package Type <span class="red-text">*</span></label>
          <ngx-select-dropdown [config]="config3" [options]="jobPlansList" formControlName="jobOfferedPlanId"
            [multiple]="false">
          </ngx-select-dropdown>
        </div>

        <div class="col-md-12 form-group" *ngIf="paymentForm.value.packageType=='resumeAccessPlan'">
          <label for="resumePackage">Resume Package Type <span class="red-text">*</span></label>

          <ngx-select-dropdown [config]="config4" [options]="resumePlanList" formControlName="resumeAccessPlanId"
            [multiple]="false">
          </ngx-select-dropdown>
        </div>

        <div class="col-md-12 form-group">
          <label for="paidDate">Paid Date <span class="red-text">*</span></label>
          <input type="text" id="paidDate" placeholder="Select Paid date" formControlName="paidDate" autocomplete="off"
            class="form-control"
            [bsConfig]="{ dateInputFormat: 'MMMM Do YYYY',adaptivePosition: true ,customTodayClass:'custom-today-class'  }"
            bsDatepicker placement="right">
          <p class="red-text" *ngIf="paymentForm.controls.paidDate.touched && paymentForm.controls.paidDate.invalid">
            This field is required.</p>
        </div>

        <div class="col-md-12 form-group" *ngIf="voucherValid">
          <label for="voucher">Upload Voucher <span class="red-text" *ngIf="voucherValid">*</span></label>
          <input type="file" id="voucher" #file accept=".doc,.docx,.rtf, .pdf" (click)="file.value = null" value=""
            (change)="uploadFile(file.files)">
        </div>

        <div class="col-md-12">
          <span class="invalid-feedback"> {{errorMsg}}</span>
        </div>

        <div class="col-md-12 form-group">
          <label for="soldBy">Sold By <span class="red-text">*</span></label>
          <select class="form-control" formControlName="soldBy" id="status">
            <option value="null" disabled>Select Sold By</option>
            <option [value]="item.email" *ngFor="let item of userList">{{item.email}}</option>

          </select>
          <!-- <input type="text" id="soldBy" placeholder="Enter Name" class="form-control" formControlName="soldBy"
            autocomplete="off"> -->
          <span class="red-text" *ngIf="paymentForm.controls.soldBy.touched && paymentForm.controls.soldBy.invalid ">
            <span *ngIf="paymentForm.controls.soldBy.errors.required">This feild is required</span>
          </span>
        </div>

        <div class="col-md-12 form-group">
          <label for="remarks">Remarks</label>
          <textarea id="remarks" class="form-control " style="height: 75px !important;" formControlName="remarks"
            placeholder="Add remarks here"></textarea>
        </div>

        <div class="col-md-12 form-group">
          <button class="btn green-btn margin-right-20" type="submit" [disabled]="paymentForm.invalid || processing "
            *ngIf="voucherValid">{{processing ? 'Processing...' : 'Save'}}</button>
          <button class="btn green-btn margin-right-20" type="submit" [disabled]="paymentForm.invalid || processing "
            *ngIf="!voucherValid">{{processing ? 'Processing...' : 'Save'}}</button>
          <button class="btn" (click)="modalRef.hide(); resetForm('payment')" type="button">Cancel</button>
        </div>
      </form>

    </div>
  </div>
</ng-template>



<!-- View -->
<ng-template #view data-backdrop="static" data-keyboard="false">
  <!--Heading Section-->
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title text-left">Complete Detail</h4>
    <div class="x-close" (click)="modalRef.hide()">
      <span>x</span>
    </div>
  </div>
  <!--Body Section-->
  <div class="modal-body view-box">
    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Employee Name</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.companyName || '-'}}</div>
    </div>


    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Package Type</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.packageType || '-'}}</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Package Amount</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">
        {{viewedItem.finalRate ? 'Rs. ' + (viewedItem.finalRate |number) + ' /-' : '-'}}
      </div>
    </div>


    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Payment Date</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.paidDate || '-'}}</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Payment Mode</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.payType || '-'}}</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Sold By</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.soldBy || '-'}}</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Voucher</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8"><a href="{{viewedItem.voucher}}" target="_blank" class="green-link"
          *ngIf="viewedItem.voucher !=null">{{(viewedItem.voucher | slice:0:40) + '...'}} <i class="fa fa-download"
            aria-hidden="true"></i></a>
        <span *ngIf="viewedItem.voucher ==null">-</span> </div>
    </div>


    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Invoice</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">-</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Approved By</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.payApproveBy || '-'}}</div>
    </div>

    <div class="row mb-20">
      <div class="col-md-3 col-sm-3 col-xs-3">Remarks</div>
      <div class="col-md-1 col-sm-1 col-xs-1 res-nopadding res-txt-center">:</div>
      <div class="col-md-8 col-sm-8 col-xs-8">{{viewedItem.remarks || '-'}}</div>
    </div>







  </div>

</ng-template>


<!-- status change -->
<ng-template #alert>
  <!--Heading Section-->
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title text-left">Alert Message</h4>
    <div class="x-close" (click)="modalRef.hide()">
      <span>x</span>
    </div>
  </div>
  <!--Body Section-->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 form-group">
        <p class="green-text">Are you sure you want to change status?</p>
        <p class="red-text">Once received it can't be cancelled</p>
      </div>
      <div class="col-md-12">
        <button class="btn green-btn margin-right-20" (click)="pyamentApprove();modalRef.hide()">Save</button>
        <button class="btn" (click)="modalRef.hide()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { EmployerApi } from "src/app/common/services/employerApi.service";
import { CookieService } from "ngx-cookie-service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Observable } from "rxjs/Observable";
import { Location } from "@angular/common";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/timer";
import "rxjs/add/observable/of";
import "rxjs/add/operator/mapTo";
import "rxjs/add/operator/switchMap";
import { RouterExtService } from "src/app/common/services/router-ext.service";
import { RestApiService } from "src/app/common/services/rest-api.service";

@Component({
  selector: "app-setting",
  templateUrl: "./setting.component.html",
  styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private empService: EmployerApi,
    public cookie: CookieService,
    private toastr: ToastrService,
    private router: Router,
    private location: Location,
    private routerExtService: RouterExtService,
    public rest: RestApiService
  ) {}

  tabSelected = "changePassword";
  phoneType: any = null;
  ngOnInit() {
    if (this.router.url.match("/employer")) this.phoneType = "Phone";
    else this.phoneType = "Mobile";
    this.getMailStatus();

    let href = this.router.url;
    if (href.match("/change-email")) {
      this.tabSelected = "changeEmail";
    } else if (href.match("/change-mobileNumber")) {
      this.tabSelected = "changeMobile";
    } else if (href.match("/delete-account")) {
      this.tabSelected = "deleteAccount";
      this.checkAccountDeletion();
    } else {
      this.tabSelected = "changePassword";
    }
    this.initializePasswordForm();
    this.initializeEmailForm();
    this.initializeMobileNumberForm();
    this.initializedeleteAccountForm();
    // this.timer()
  }

  verifiedMail: string = null;
  unVerifiedMail: string = null;
  mobileNo: any;
  getMailStatus() {
    this.empService.mailStatus().subscribe((res: any) => {
      this.mobileNo = res.mobileNo;
      this.mobileNumberForm.patchValue({ oldMobileNo: this.mobileNo });
      if (res.oldMail != null) this.verifiedMail = res.oldMail;
      if (res.newMail != null) this.unVerifiedMail = res.newMail;
    });
  }

  passwordForm: FormGroup;
  initializePasswordForm() {
    this.passwordForm = this.fb.group(
      {
        oldPassword: [null, [Validators.required]],
        newPassword: [null, [Validators.required, Validators.minLength(6)]],
        conformPassword: [null, [Validators.required, Validators.minLength(6)]],
      },
      { validator: this.matchingPasswords("newPassword", "conformPassword") }
    );
  }

  deleteAccountForm: FormGroup;
  initializedeleteAccountForm() {
    this.deleteAccountForm = this.fb.group({
      password: [null, [Validators.required]],
      remarks: [null, [Validators.required]],
    });
  }

  matchStatus: boolean = false;
  selectMatchingPassword() {
    let data = this.passwordForm.value;
    if (data.oldPassword && (data.newPassword || data.conformPassword)) {
      if (data.oldPassword == data.newPassword) {
        this.matchStatus = true;

        setTimeout(() => {
          this.passwordForm.patchValue({ newPassword: null });
          this.passwordForm.patchValue({ conformPassword: null });
          this.matchStatus = false;
        }, 1000);
      }
      if (data.oldPassword == data.conformPassword) {
        this.matchStatus = true;
        setTimeout(() => {
          this.passwordForm.patchValue({ newPassword: null });
          this.passwordForm.patchValue({ conformPassword: null });
          this.matchStatus = false;
        }, 1000);
      }
    } else this.matchStatus = false;
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value && confirmPassword.value) {
        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true,
          };
        }
      }
    };
  }

  changePassword() {
    this.empService.doPasswordChange(this.passwordForm.value).subscribe(
      (res: any) => {
        this.passwordForm.reset();
        this.cookie.deleteAll("/");
        this.toastr.success("Successful", "Password changed successfully.");
        this.goToLogin();
      },
      (err: any) => {
        this.passwordForm.reset();

        this.toastr.error("Unsuccessful", err.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }
  deletedData: any = null;
  remainingDays: any = 0;
  checkAccountDeletion() {
    this.empService.checkAccountDeletion().subscribe(
      (res: any) => {
        this.deletedData = res;
        if (this.deletedData.createdDate) {
          let currentDate: any = new Date();
          let deletedDate: any = new Date(this.deletedData.createdDate);
          let expireDate = deletedDate.getDate() + 7;
          this.remainingDays = expireDate - currentDate.getDate();
        }
      },
      (err: any) => {
        this.toastr.error("Unsuccessful", err.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }

  deleteAccount() {
    this.empService.deleteAccount(this.deleteAccountForm.value).subscribe(
      (res: any) => {
        this.checkAccountDeletion();
        this.deleteAccountForm.reset();
        this.toastr.success("Successful", "Delete account successfully.");
      },
      (err: any) => {
        this.deleteAccountForm.reset();

        this.toastr.error("Unsuccessful", err.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }
  cancelAccount() {
    this.empService.cancelAccount().subscribe(
      (res: any) => {
        this.checkAccountDeletion();
        this.toastr.success(
          "Successful",
          "Cancelled account deletion successfully."
        );
      },
      (err: any) => {
        this.toastr.error("Unsuccessful", err.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }

  goToLogin() {
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match("/jobseeker"))
      this.router.navigate(["/jobseeker/login"]);
    else this.router.navigate(["/employer/login"]);
  }

  emailForm: FormGroup;
  initializeEmailForm() {
    this.emailForm = this.fb.group(
      {
        // oldEmail: [null, [Validators.required, Validators.pattern('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$'), Validators.maxLength(50)]],
        newEmail: [
          null,
          [
            Validators.required,
            Validators.pattern(
              "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$"
            ),
            Validators.maxLength(50),
          ],
          this.checkEmail.bind(this),
        ],
        conformEmail: [
          null,
          [
            Validators.required,
            Validators.pattern(
              "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$"
            ),
            Validators.maxLength(50),
          ],
          this.checkEmail.bind(this),
        ],
        password: [null, [Validators.required]],
      },
      { validator: this.matchingEmail("newEmail", "conformEmail") }
    );
  }

  mobileNumberForm: FormGroup;
  initializeMobileNumberForm() {
    this.mobileNumberForm = this.fb.group({
      oldMobileNo: [
        this.mobileNo,
        [
          Validators.required,
          Validators.pattern("[0-9]*"),
          Validators.minLength(7),
          Validators.maxLength(10),
        ],
      ],
      newMobileNo: [
        null,
        [
          Validators.required,
          Validators.pattern("[0-9]*"),
          Validators.minLength(this.phoneType == "Mobile" ? 10 : 7),
          Validators.maxLength(10),
        ],
      ],
    });
  }

  checkEmail(control: FormControl) {
    return Observable.timer(1000).switchMap(() => {
      return this.empService
        .emailDuplicate(control.value)
        .mapTo(null)
        .catch((err) => Observable.of({ notAvailability: true }));
    });
  }

  matchingEmail(newEmail: string, confirmEmail: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let email = group.controls[newEmail];
      let cEmail = group.controls[confirmEmail];

      if (email.value && cEmail.value) {
        if (email.value !== cEmail.value) {
          return {
            mismatchedEmails: true,
          };
        }
      }
    };
  }

  errorMessageEmail: string = null;
  emailChangeStatus: boolean = false;
  changeEmail() {
    this.emailChangeStatus = true;
    this.empService.doEmailChange(this.emailForm.value).subscribe(
      (res: any) => {
        this.emailChangeStatus = false;
        this.getMailStatus();
        this.timer();
        // this.router.navigate(['']);
        // this.cookie.deleteAll();

        this.toastr.success(
          "Successful",
          "Verification email has been sent to your email.Please check your email."
        );
      },
      (error: any) => {
        // this.errorMessageEmail = error.error.error_description;
        this.emailChangeStatus = false;
        this.toastr.error("Unsuccessful", error.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }

  // TIMER
  min: number;
  sec: number;
  disableResend: boolean = false;
  timer() {
    this.min = 0;
    this.sec = 59;
    this.disableResend = true;
    document.getElementById("newTimers").style.visibility = "visible";

    document.getElementById("newTimers").innerHTML = this.min + ":" + this.sec;
    this.startTimer();
  }

  m: any;
  s: any;
  timeArray: any = [];
  startTimer() {
    if (document.getElementById("newTimers").innerHTML) {
      var presentTime = document.getElementById("newTimers").innerHTML;
      this.timeArray = presentTime.split(/[:]+/);
      this.m = this.timeArray[0];
      this.s = this.checkSecond(this.timeArray[1] - 1);
      if (this.s == 59) {
        this.m = this.m - 1;
      }
      document.getElementById("newTimers").innerHTML = this.m + ":" + this.s;

      if (this.m == 0 && this.s == 0) {
        this.disableResend = false;
        document.getElementById("newTimers").style.visibility = "hidden";
      } else {
        setTimeout(() => {
          this.startTimer();
        }, 1000);
      }
    }
  }

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) {
      sec = "0" + sec;
    }
    if (sec < 0) {
      sec = "59";
    }
    return sec;
  }

  getCodeAgain() {
    this.empService.resendCode().subscribe((res: any) => {
      this.toastr.success(
        "Successful",
        "Verificaiton link has been sent to you email. Please verigy your email."
      );
    });
  }

  tempMobile: any;
  changeMobileNo() {
    if (this.phoneType == "Mobile") {
      this.rest.mobileVerification = true;
      this.tempMobile = this.mobileNumberForm.value.newMobileNo;
    } else {
      this.empService
        .changeMobileNumber(this.mobileNumberForm.value.newMobileNo)
        .subscribe(
          (res: any) => {
            this.toastr.success("Successful", "Number changed successfully.");
            this.tempMobile = this.mobileNumberForm.value.newMobileNo;
            this.mobileNumberForm.reset();
            this.getMailStatus();
          },
          (error: any) => {
            this.toastr.error("Unsuccessful", error.error.errorMessage, {
              timeOut: 3000,
            });
            this.mobileNumberForm.reset();
          }
        );
    }
  }

  checkStatus() {
    if (
      this.rest.mobileVerification == false &&
      this.rest.mobileVerified == 1
    ) {
      this.mobileNumberForm.reset();
      this.getMailStatus();
      this.rest.mobileVerified = 0;
    }
  }

  phoneError: boolean = false;
  getErorMessage() {
    if (this.mobileNumberForm.valid) {
      if (
        this.mobileNumberForm.value.oldMobileNo ==
        this.mobileNumberForm.value.newMobileNo
      ) {
        this.phoneError = true;
        return "Choose different Mobile Number.";
      } else {
        this.phoneError = false;
      }
    }
  }

  changeEmailUrl() {
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match("/jobseeker"))
      this.location.go("/jobseeker/change-email");
    else this.location.go("/employer/change-email");
  }

  changeMobileUrl() {
    // this.location.go('/jobseeker/change-mobileNumber');
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match("/jobseeker"))
      this.location.go("/jobseeker/change-mobileNumber");
    else this.location.go("/employer/change-mobileNumber");
  }

  changePasswordUrl() {
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match("/jobseeker"))
      this.location.go("/jobseeker/change-password");
    else this.location.go("/employer/change-password");
  }
  deleteAccountUrl() {
    var previous = this.routerExtService.getPreviousUrl();
    if (previous.match("/jobseeker"))
      this.location.go("/jobseeker/delete-account");
    else this.location.go("/employer/delete-account");
  }
}

<div class="container-fluid container-fixed-top-nav">
  <!-- <div class="row">
        <app-employer-navbar></app-employer-navbar>
    </div> -->
  <div class="container">
    <div class="row">
      <div class="col-md-3 res-nopadding">
        <div class="list-group" id="list-tab" role="tablist">
          <img
            src="https://img.icons8.com/windows/20/ffffff/--checkmark-yes.png"
            style="visibility: hidden"
          />
          <a
            class="list-group-item list-group-item-action cursor"
            [ngClass]="{ active: tabSelected === 'changePassword' }"
            (click)="tabSelected = 'changePassword'; changePasswordUrl()"
            >Change Password</a
          >
          <a
            class="list-group-item list-group-item-action cursor"
            [ngClass]="{ active: tabSelected === 'changeEmail' }"
            (click)="tabSelected = 'changeEmail'; changeEmailUrl()"
            >Change Email Address</a
          >
          <a
            class="list-group-item list-group-item-action cursor"
            [ngClass]="{ active: tabSelected === 'changeMobile' }"
            (click)="tabSelected = 'changeMobile'; changeMobileUrl()"
            >Change {{ phoneType }} Number</a
          >
          <a
            class="list-group-item list-group-item-action cursor"
            [ngClass]="{ active: tabSelected === 'deleteAccount' }"
            (click)="
              tabSelected = 'deleteAccount';
              deleteAccountUrl();
              checkAccountDeletion()
            "
            >Delete Account</a
          >
        </div>
      </div>
      <div class="col-md-9 res-nopadding">
        <p style="visibility: hidden; display: none" *ngIf="checkStatus()">
          <span id="newTimers"></span>
        </p>
        <div class="col-md-12 wt-box" style="margin-bottom: 15px">
          <ng-container *ngIf="tabSelected === 'changePassword'">
            <div class="col-md-12 title-row">Change Password</div>
            <div class="col-md-12 content-box">
              <form [formGroup]="passwordForm">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label for="oldPassword">Current Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="oldPassword"
                      placeholder="Enter Current Password"
                      formControlName="oldPassword"
                      (change)="selectMatchingPassword()"
                    />
                  </div>
                  <div class="clearfix"></div>
                  <div class="col-md-6 form-group">
                    <label for="newPassword">New Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="newPassword"
                      placeholder="Enter New Password"
                      formControlName="newPassword"
                      (change)="selectMatchingPassword()"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        passwordForm.controls.newPassword.touched &&
                        passwordForm.controls.newPassword.invalid
                      "
                    >
                      <span
                        *ngIf="
                          passwordForm.controls.newPassword.errors.required
                        "
                        >Password is required.
                      </span>
                      <span
                        *ngIf="
                          passwordForm.controls.newPassword.errors.minlength
                        "
                        >Password should be minimum 6 characters long.
                      </span>
                      <span
                        *ngIf="
                          passwordForm.controls.newPassword.errors.pattern &&
                          !passwordForm.controls.newPassword.errors.minlength
                        "
                        class="mat-error"
                        >Passoword should contain at least one digit.
                      </span>
                    </div>

                    <div
                      class="invalid-feedback"
                      *ngIf="
                        matchStatus &&
                        passwordForm.value.newPassword &&
                        !(
                          passwordForm.controls.newPassword.touched &&
                          passwordForm.controls.newPassword.invalid
                        )
                      "
                    >
                      <span>New password is similar to current password.</span>
                    </div>
                  </div>

                  <div class="clearfix"></div>
                  <div class="col-md-6 form-group">
                    <label for="confirmPassword">Confirm Password</label>
                    <input
                      type="password"
                      class="form-control"
                      id="confirmPassword"
                      placeholder="Confirm Password"
                      formControlName="conformPassword"
                      (change)="selectMatchingPassword()"
                    />

                    <div
                      class="invalid-feedback"
                      *ngIf="
                        passwordForm.controls.conformPassword.touched &&
                        passwordForm.controls.conformPassword.invalid
                      "
                    >
                      <span
                        *ngIf="
                          passwordForm.controls.conformPassword.errors.required
                        "
                        >Password is required.
                      </span>
                      <span
                        *ngIf="
                          passwordForm.controls.conformPassword.errors.minlength
                        "
                        >Password should be minimum 6 characters long.
                      </span>
                      <span
                        *ngIf="
                          passwordForm.controls.conformPassword.errors
                            .pattern &&
                          !passwordForm.controls.conformPassword.errors
                            .minlength
                        "
                        class="mat-error"
                        >Passoword should contain at least one digit.
                      </span>
                    </div>

                    <div
                      class="invalid-feedback"
                      *ngIf="
                        matchStatus &&
                        passwordForm.value.conformPassword &&
                        !(
                          passwordForm.controls.conformPassword.touched &&
                          passwordForm.controls.conformPassword.invalid
                        )
                      "
                    >
                      <span>New password is similar to current password.</span>
                    </div>
                  </div>

                  <div
                    class="col-md-12 form-group"
                    *ngIf="
                      passwordForm.hasError('mismatchedPasswords') &&
                      passwordForm.controls.newPassword.valid &&
                      passwordForm.controls.conformPassword.valid
                    "
                    style="color: #dc3545"
                  >
                    Password doesn't match.
                  </div>

                  <div class="col-md-12 form-group">
                    <button
                      class="btn green-btn"
                      [disabled]="passwordForm.invalid || matchStatus"
                      (click)="changePassword()"
                    >
                      Apply Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>

          <!-- Email Change -->
          <ng-container *ngIf="tabSelected == 'changeEmail'">
            <div class="col-md-12 content-box">
              <form [formGroup]="emailForm" autocomplete="off">
                <div class="row">
                  <div class="col-md-12 alert">
                    <div>
                      <p>
                        Your current email address:
                        <b style="margin-left: 5px">{{ verifiedMail }}</b>
                        <span
                          class="verified"
                          *ngIf="
                            cookie.get('emailVerified') == 'Y' && verifiedMail
                          "
                        >
                          <i
                            class="fa fa-check-circle-o"
                            aria-hidden="true"
                          ></i>
                          Verified
                        </span>

                        <span
                          class="not-verified"
                          *ngIf="
                            cookie.get('emailVerified') == 'N' && verifiedMail
                          "
                        >
                          <i
                            class="fa fa-times-circle-o"
                            aria-hidden="true"
                          ></i>
                          Not Verified
                        </span>
                      </p>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="clearfix"></div>

                    <div class="col-md-12 form-group">
                      <label for="newEmail">New Email Address</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter new email address"
                        formControlName="newEmail"
                        autocomplete="off"
                      />

                      <div
                        class="invalid-feedback"
                        *ngIf="
                          emailForm.controls.newEmail.touched &&
                          emailForm.controls.newEmail.invalid
                        "
                      >
                        <span
                          *ngIf="emailForm.controls.newEmail.errors.required"
                        >
                          Email is required
                        </span>
                        <span
                          *ngIf="emailForm.controls.newEmail.errors.pattern"
                          class="mat-error"
                          >Invalid Email. Email format should be
                          <b> {{ "jobejee@gmail.com" }}</b>
                        </span>
                        <span
                          *ngIf="emailForm.controls.newEmail.errors.maxlength"
                          >Email Address should be maximum 50 characters</span
                        >
                        <span
                          *ngIf="
                            emailForm.get('newEmail').errors['notAvailability']
                          "
                          >Sorry! Email already exist.
                        </span>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <div class="col-md-12 form-group">
                      <label for="conformEmail">Confirm Email Address</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Confirm email address"
                        formControlName="conformEmail"
                        autocomplete="off"
                      />

                      <div
                        class="invalid-feedback"
                        *ngIf="
                          emailForm.controls.conformEmail.touched &&
                          emailForm.controls.conformEmail.invalid
                        "
                      >
                        <span
                          *ngIf="
                            emailForm.controls.conformEmail.errors.required
                          "
                        >
                          Email is required
                        </span>
                        <span
                          *ngIf="emailForm.controls.conformEmail.errors.pattern"
                          class="mat-error"
                          >Invalid Email. Email format should be
                          <b> {{ "jobejee@gmail.com" }}</b>
                        </span>
                        <span
                          *ngIf="
                            emailForm.controls.conformEmail.errors.maxlength
                          "
                          >Email Address should be maximum 50 characters</span
                        >
                        <span
                          *ngIf="
                            emailForm.get('conformEmail').errors[
                              'notAvailability'
                            ]
                          "
                          >Sorry! Email already exist.
                        </span>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <div
                      class="col-md-12 form-group"
                      *ngIf="
                        emailForm.hasError('mismatchedEmails') &&
                        emailForm.controls.newEmail.valid &&
                        emailForm.controls.conformEmail.valid
                      "
                    >
                      <div style="color: #dc3545">Email doesn't match.</div>
                    </div>

                    <!-- <div class="clearfix"></div> -->
                    <div class="col-md-12 form-group">
                      <label for="conformEmail">Password</label>
                      <input
                        type="password"
                        formControlName="password"
                        placeholder="Password"
                        class="form-control"
                        autocomplete="new-password"
                      />
                    </div>

                    <div class="col-md-12">
                      <button
                        class="btn green-btn"
                        (click)="changeEmail()"
                        [disabled]="emailForm.invalid || disableResend"
                        *ngIf="!emailChangeStatus"
                      >
                        Apply Changes
                      </button>
                      <button
                        class="btn green-btn"
                        [disabled]="true"
                        *ngIf="emailChangeStatus"
                      >
                        Sending Email Verification...
                      </button>
                    </div>
                  </div>

                  <div class="col-md-6 nopadding text-center">
                    <img src="../../../assets/images/email.png" alt="" />
                  </div>

                  <div
                    class="col-md-12 text-center"
                    style="margin-top: 15px"
                    *ngIf="unVerifiedMail"
                  >
                    <div>
                      <p>
                        Please verify by clicking link sent to your updated
                        email address
                        <b style="margin-left: 5px">{{ unVerifiedMail }}</b>
                        <span class="not-verified">
                          <i
                            class="fa fa-times-circle-o"
                            aria-hidden="true"
                          ></i>
                          Not Verified</span
                        >
                      </p>
                    </div>

                    <div class="text-center">
                      <p *ngIf="s > 0">
                        <span id="newTimers" class="text-center"></span>
                        {{ s }} sec
                      </p>

                      <button
                        class="btn btn-resend"
                        (click)="timer(); getCodeAgain()"
                        [disabled]="disableResend"
                      >
                        Resend Verification Link
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>

          <ng-container *ngIf="tabSelected == 'changeMobile'">
            <div class="col-md-12 title-row">Change {{ phoneType }} Number</div>

            <div class="col-md-12 content-box">
              <div class="col-md-6 nopadding">
                <form [formGroup]="mobileNumberForm">
                  <div class="form-group">
                    <label for=""
                      >Current {{ phoneType }} Number
                      <span class="text-validation">*</span></label
                    >
                    <div class="row">
                      <div
                        class="col-md-12"
                        [ngClass]="{
                          'invalid-feedback':
                            mobileNumberForm.get('oldMobileNo').touched &&
                            mobileNumberForm.get('oldMobileNo').errors
                        }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          minlength="7"
                          maxlength="10"
                          placeholder="Enter Current Mobile Number"
                          formControlName="oldMobileNo"
                          disabled
                          required
                        />

                        <div
                          *ngIf="
                            mobileNumberForm.get('oldMobileNo').touched &&
                            mobileNumberForm.get('oldMobileNo').invalid
                          "
                        >
                          <span
                            *ngIf="
                              mobileNumberForm.get('oldMobileNo').errors
                                .required
                            "
                            >{{ phoneType }} Number is required</span
                          >

                          <span
                            *ngIf="
                              mobileNumberForm.get('oldMobileNo').errors.pattern
                            "
                            >Invalid {{ phoneType }} Number</span
                          >
                          <span
                            *ngIf="
                              !mobileNumberForm.get('oldMobileNo').errors
                                .pattern &&
                              mobileNumberForm.get('oldMobileNo').errors
                                .minlength
                            "
                            >{{ phoneType }} Number should be minimum 7
                            digit.</span
                          >
                          <span
                            *ngIf="
                              !mobileNumberForm.get('oldMobileNo').errors
                                .minlength &&
                              mobileNumberForm.get('oldMobileNo').errors
                                .maxlength
                            "
                            >{{ phoneType }} Number should be maximum 10
                            digit.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for=""
                      >New {{ phoneType }} Number
                      <span class="text-validation">*</span></label
                    >
                    <div class="row">
                      <div
                        class="col-md-12"
                        [ngClass]="{
                          'invalid-feedback':
                            mobileNumberForm.get('newMobileNo').touched &&
                            mobileNumberForm.get('newMobileNo').errors
                        }"
                      >
                        <input
                          type="text"
                          class="form-control"
                          minlength="7"
                          maxlength="10"
                          placeholder="Enter New {{ phoneType }} Number"
                          formControlName="newMobileNo"
                          required
                        />

                        <div
                          *ngIf="
                            mobileNumberForm.get('newMobileNo').touched &&
                            mobileNumberForm.get('newMobileNo').invalid
                          "
                        >
                          <span
                            *ngIf="
                              mobileNumberForm.get('newMobileNo').errors
                                .required
                            "
                            >{{ phoneType }} Number is required</span
                          >

                          <span
                            *ngIf="
                              mobileNumberForm.get('newMobileNo').errors.pattern
                            "
                            >Invalid {{ phoneType }} Number</span
                          >
                          <span
                            *ngIf="
                              !mobileNumberForm.get('newMobileNo').errors
                                .pattern &&
                              mobileNumberForm.get('newMobileNo').errors
                                .minlength
                            "
                            >{{ phoneType }} Number should be minimum
                            {{ this.phoneType == "Mobile" ? 10 : 7 }}
                            digit.</span
                          >
                          <span
                            *ngIf="
                              !mobileNumberForm.get('newMobileNo').errors
                                .minlength &&
                              mobileNumberForm.get('newMobileNo').errors
                                .maxlength
                            "
                            >{{ phoneType }} Number should be maximum 10
                            digit.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 invalid-feedback">
                    <span>{{ getErorMessage() }}</span>
                  </div>

                  <div class="col-md-12 form-group nopadding">
                    <button
                      class="btn green-btn"
                      [disabled]="mobileNumberForm.invalid || phoneError"
                      (click)="changeMobileNo()"
                    >
                      Apply Changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              tabSelected === 'deleteAccount' &&
              deletedData &&
              !deletedData.status
            "
          >
            <div class="col-md-12 title-row">Delete Account</div>
            <div class="col-md-12 content-box">
              <div class="col-md-12 title-down">
                Are you sure! You want to delete your account.
              </div>
              <form [formGroup]="deleteAccountForm">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label for="password"
                      >Please enter your login Password to confirm</label
                    >
                    <input
                      type="password"
                      class="form-control"
                      id="password"
                      placeholder="Enter Password"
                      formControlName="password"
                    />
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        deleteAccountForm.controls.password.touched &&
                        deleteAccountForm.controls.password.invalid
                      "
                    >
                      <span
                        *ngIf="
                          deleteAccountForm.controls.password.errors.required
                        "
                        >Password is required.
                      </span>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="col-md-6 form-group">
                    <label for="remarks"
                      >Why you want to delete your account?</label
                    >
                    <textarea
                      class="form-control-textarea"
                      id="remarks"
                      rows="3"
                      placeholder="Remarks"
                      formControlName="remarks"
                    >
                    </textarea>
                    <div
                      class="invalid-feedback"
                      *ngIf="
                        deleteAccountForm.controls.remarks.touched &&
                        deleteAccountForm.controls.remarks.invalid
                      "
                    >
                      <span
                        *ngIf="
                          deleteAccountForm.controls.remarks.errors.required
                        "
                        >Reason is required.
                      </span>
                    </div>
                  </div>

                  <div class="col-md-12 form-group">
                    <button
                      class="btn green-btn"
                      [disabled]="deleteAccountForm.invalid"
                      (click)="deleteAccount()"
                    >
                      Delete Account
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>

          <ng-container
            *ngIf="
              tabSelected === 'deleteAccount' &&
              deletedData &&
              deletedData.status
            "
          >
            <div class="col-md-12 title-row">Delete Account</div>
            <div class="col-md-12 content-box">
              <div class="col-md-12">
                <p>
                  Your account deletion process has been started on
                  {{ deletedData.createdDate | date : "mediumDate" }}
                  {{ deletedData.createdDate | date : "shortTime" }}.
                  <br />
                  You have
                  <span *ngIf="remainingDays >= 0; else second">{{
                    remainingDays
                  }}</span>
                  <ng-template #second>0 </ng-template>
                  remaining days to cancel this process.
                </p>
              </div>
              <div class="col-md-12 form-group">
                <button class="btn green-btn" (click)="cancelAccount()">
                  Cancel Request
                </button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="rest.mobileVerification">
  <app-mobile-verification [mobileNo]="tempMobile"></app-mobile-verification>
</ng-container>

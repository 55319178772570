import { Component, OnInit, Input, TemplateRef } from '@angular/core';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { ApiServiceService } from '../../common/services/api-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-bookmark-job',
  templateUrl: './bookmark-job.component.html',
  styleUrls: ['./bookmark-job.component.scss']
})
export class BookmarkJobComponent implements OnInit {

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  modalRef: BsModalRef;
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  constructor(
    public rest: RestApiService,
    private modalService: BsModalService,
    private apiServiceService: ApiServiceService,
    private toastr: ToastrService,
    private restApiService: RestApiService,
    private router: Router,
    private route: ActivatedRoute,
    public meta: Meta, public title: Title,
    private cookieService: CookieService,
    private fb: FormBuilder,


  ) {
    this.rest.savedJobId = null;
  }

  bookmarkJobId: any;
  type: string = 'password';
  ngOnInit(): void {
    this.bookmarkJobId = this.rest.bookmarkJobId;
    this.initializeLoginForm();
  }

  changeType() {
    if (this.type == 'text')
      this.type = 'password';
    else
      this.type = 'text';
  }

  loginForm: FormGroup
  initializeLoginForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      grant_type: ['password']
    })
  }

  // errorMsg: any;
  // logging: boolean = false;
  // showModal: boolean = true;
  // login() {
  //   this.logging = true;
  //   const data = this.loginForm.value;
  //   const datas = 'grant_type=password&username=' + data.username + '&password=' + data.password;
  //   this.apiServiceService.doLogin(datas).subscribe(
  //     (res: any) => {
  //       this.cookieService.set('at', res.access_token, 365, '/');
  //       this.cookieService.set('rt', res.refresh_token, 365, '/');
  //       this.rest.hideBookmarkModal = 1;
  //       this.bookmarkJob();
  //       this.getUserInfo();
  //     },
  //     (error: any) => {
  //       this.errorMsg = error.error.error_description;
  //       this.loginForm.controls.password.reset();
  //       this.logging = false;
  //       setTimeout(() => {
  //         this.errorMsg = null;
  //       }, 2000);
  //     }
  //   )
  // }

  // loginStatus: boolean = false;
  // getUserInfo() {
  //   this.apiServiceService.userInfo().subscribe(
  //     (res: any) => {
  //       this.cookieService.set('userRole', res.role, 365, '/');
  //       this.cookieService.set('userId', res.userDetailId, 365, '/');
  //       this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
  //       this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');

  //       this.cookieService.set("username", res.firstName + ' ' + res.lastName, 365, '/');
  //       this.cookieService.set('tmpE', res.email, 365, '/');

  //       this.logging = false;
  //       this.loginStatus = true;

  //     }
  //   )
  // }


  errorMsg: any;
  logging: boolean = false;
  login() {
    this.logging = true;
    const data = this.loginForm.value;
    const datas = 'grant_type=password&username=' + data.username + '&password=' + data.password;
    this.apiServiceService.doLogin(datas).subscribe(
      (res: any) => {

        // this.cookieService.set('at', res.access_token, 365, '/');
        // this.cookieService.set('rt', res.refresh_token, 365, '/');
        // this.getUserInfo();
        this.cookieService.set('at', res.access_token, 365, '/');
        this.cookieService.set('rt', res.refresh_token, 365, '/');
        this.rest.hideBookmarkModal = 1;
        this.bookmarkJob();
        this.getUserInfo();
      },
      (error: any) => {
        this.errorMsg = error.error.error_description;
        this.loginForm.controls.password.reset();
        this.logging = false;
        setTimeout(() => {
          this.errorMsg = null;
        }, 2000);
      }
    )
  }

  loginStatus: boolean = false;
  getUserInfo() {
    this.apiServiceService.userInfo().subscribe(
      (res: any) => {

        this.cookieService.set('userRole', res.role, 365, '/');
        if (res.role == "E") {
          this.cookieService.set('uR', "f2c1de33", 365, '/');
          this.cookieService.set('empId', res.empDetId, 365, '/');
          this.cookieService.set('rap', res.resumeAccessPlan ? res.resumeAccessPlan : 'N', 365, '/');
          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('addInfoType', res.contactInfo, 365, '/');
          this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
          this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');
          this.cookieService.set('tmpE', res.email, 365, '/');
          this.cookieService.set('username', res.companyName, 365, '/');
          // if (res.emailVerified == 'N') {
          //   this.router.navigate(['/employer/employer-email-verification']);
          // }
          // else {
          //   if (res.contactInfo == 'Y')
          //     this.router.navigate(['/employer/dashboard'])

          //   if (res.contactInfo == 'N')
          //     this.router.navigate(['/employer/welcome'])
          // }
        }

        else if (res.role == "SE") {
          this.cookieService.set('ar', res.adminType, 365, '/');
          this.cookieService.set('uR', "f2c1da34", 365, '/');
          if (res.adminType == "SUPERVISOR") {
            this.cookieService.set('uTy', "sr112", 365, '/');
          } else if (res.adminType == "MANAGER") {
            this.cookieService.set('uTy', "mr112", 365, '/');
          }
          else {
            this.cookieService.set('uTy', "an112", 365, '/');
          }
          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('username', res.email, 365, '/');
          // this.router.navigate(['/admin/dashboard']);
        }

        else if (res.role == "JS") {
          this.cookieService.set('uR', "f2c1dj32", 365, '/');

          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
          this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');

          this.cookieService.set("username", res.firstName + ' ' + res.lastName, 365, '/');
          this.cookieService.set('tmpE', res.email, 365, '/');

          // this.router.navigate(['/jobseeker/dashboard']);
        }
        // this.cookieService.set('userRole', res.role, 365, '/');
        // if (res.role == "JS") {
        //   this.cookieService.set('uR', "f2c1dj32", 365, '/');
        // }

        // this.cookieService.set('userId', res.userDetailId, 365, '/');
        // this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
        // this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');

        // this.cookieService.set("username", res.firstName + ' ' + res.lastName, 365, '/');
        // this.cookieService.set('tmpE', res.email, 365, '/');

        // this.logging = false;
        // this.loginStatus = true;
        // this.router.navigate(['/jobseeker/dashboard']);
      }
    )
  }

  checkLoginStatus() {
    if (this.loginStatus)
      return true;
    else
      return false;
  }

  forgetPassword() {
    this.rest.hideBookmarkModal = 3;
  }

  createAccount() {
    this.rest.hideBookmarkModal = 2;
  }

  bookmarkJob() {

    this.rest.savedJobId = this.bookmarkJobId;
    this.apiServiceService.saveBookmark(this.bookmarkJobId).subscribe(
      (res: any) => {
        // this.toastr.success('Successful', 'Added to Bookmark.');

      }
    );
  }

}

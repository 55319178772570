import { OnInit, Component, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from '../../common/services/api-service.service';
import { FooterComponent } from 'src/app/includes/footer/footer.component';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { UniversalService } from 'src/app/common/services/universal.service';

@Component({
  selector: 'app-job-search',
  templateUrl: './job-search-list.component.html',
  styleUrls: ['./job-search.component.scss']
})

export class JobSearchComponent implements OnInit {
  isCollapsed = true;
  eduCollapsed = true;
  locationCollapsed = true;
  publicationCollapsed = true;
  comCollapsed = true;
  loader: boolean = true;

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  modalRef: BsModalRef;
  constructor(private modalService: BsModalService,
    private router: Router,
    private apiServiceService: ApiServiceService,
    private univeralFunctionService: UniversalService,
    private toastr: ToastrService,
    private cookie: CookieService,
    public rest: RestApiService,
    private aroute: ActivatedRoute) {
    this.rest.hideBookmarkModal = 0;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  keyskillList = null;
  locationList = null;
  experience = null;
  salaryType = null;
  ctcLakh = null;
  ctcThou = null;
  industry = null;
  funcArea = null;
  jobTypeFlag = null;
  employerType = null;
  pageNo = 1;
  offset: 15;

  indqs = null;
  funqs = null;
  locqs = null;

  userId: any = null;
  ngOnInit() {

    if (this.cookie.get('userId'))
      this.userId = this.cookie.get('userId');

    this.aroute.queryParams.subscribe(params => {

      // params['q'] ? this.keyskillList = params['q'].replace(/_+/g, ' '):null ;

      if (params['f']) {
        // console.log('vafasf')
      }
      else {
        params['q'] ? this.keyskillList = params['q'].replace(/_+/g, ' ') : null;
      }

      params['l'] ? this.locationList = params['l'].replace(/_+/g, ' ') : this.locationList = null
      params['e'] ? this.experience = params['e'] : this.experience = null
      params['ind'] ? this.industry = params['ind'].replace(/_+/g, ' ') : this.industry = null;
      params['fun'] ? this.funcArea = params['fun'].replace(/_+/g, ' ') : this.funcArea = null;
      params['jt'] ? this.jobTypeFlag = params['jt'] : this.jobTypeFlag = null;
      params['page'] ? this.page = params['page'] : this.page = 1;



      if (params['f']) {
        this.getList();
      }
      else {
        if (this.keyskillList) {
          this.getList();
        }
        else {
          this.router.navigate([''])
        }
      }
    });
  }


  totalData;
  jobSearchList: any = [];
  // hashKey;
  industryList: any = [];
  companyList: any = [];
  locList: any = [];
  page: any = 0;
  size: any = 10;
  jobResponse: any;
  getList() {


    let Obj = {
      funcArea: this.funcArea,
      industry: this.industry,
      jobType: this.jobTypeFlag,
      exp: this.experience,
      location: this.locationList,
      searchKey: this.keyskillList,

    }
    this.loader = true;

    this.apiServiceService.jobsearch(Obj, this.page - 1, this.size).subscribe(
      (response: any) => {
        this.jobSearchList = response.data;

        this.jobResponse = response;
        this.getPagination();
        // this.router.navigate([], { queryParams: { page: this.page + 1, size: this.size }, queryParamsHandling: 'merge' });
        // var data=this.getIndustryObject()
        if (response.industry && response.data)
          this.getIndustryObject(response.industry);
        else
          this.industryList = [];
        if (response.companyName && response.data)
          this.getCompanyObject(response.companyName);
        else
          this.companyList = [];
        if (response.location && response.data)
          this.getLocationObject(response.location);
        else
          this.locList = [];

        this.loader = false;
      }
    )
  }

  currentPage;
  totalPagination;
  totalPages;
  totalElements;
  getPagination() {
    this.totalPages = this.jobResponse.totalPages;
    this.totalElements = this.jobResponse.totalElements;
    this.currentPage = this.jobResponse.pageNumber + 1;
    this.totalPagination = this.univeralFunctionService.getPage(this.totalPages, this.currentPage);
  }

  getIndustryObject(data) {

    let value = Object.keys(data);
    let keys = Object.values(data);
    var arrayData = [];
    for (let i = 0; i < value.length; i++) {
      arrayData.push({ name: value[i], count: keys[i] })
    }
    this.industryList = arrayData;
  }

  getCompanyObject(data) {
    let value = Object.keys(data);
    let keys = Object.values(data);
    var arrayData = [];
    for (let i = 0; i < value.length; i++) {
      arrayData.push({ name: value[i], count: keys[i] })
    }
    this.companyList = arrayData;
  }

  getLocationObject(data) {
    let value = Object.keys(data);
    let keys = Object.values(data);
    var arrayData = [];
    for (let i = 0; i < value.length; i++) {
      arrayData.push({ name: value[i], count: keys[i] })
    }
    this.locList = arrayData;
  }

  pushIntoArray(value) {
    let splitData = value.split(",");
    return splitData;
  }

  replaceSpace(value) {
    let str = value.replace(/\s+/g, '-');
    str = str.replace(/\/+/g, '_');
    return str;
  }

  // filter
  industryObj = [];
  locationObj = [];
  publicationObj = [];
  companyObj = [];

  onChecked(obj: any, isChecked: boolean, type: string) {

    if (isChecked == true) {
      this.page=1
      if (type == 'industry') {
        this.industryObj.push(obj);
        // this.router.navigate([], {
        //   queryParams: {
        //     inds: obj
        //   },
        //   queryParamsHandling: 'merge'
        // })
      }
      else if (type == 'location') {
        this.locationObj.push(obj);
      }
      else if (type == 'publication') {
        this.publicationObj.push(obj);
      }
      else if (type == 'company') {
        this.companyObj.push(obj);
      }
    }
    else {
      if (type == 'industry') {
        let index = this.industryObj.indexOf(obj);
        if (index != -1) {
          this.industryObj.splice(index, 1);
        }
      }
      else if (type == 'location') {
        let index = this.locationObj.indexOf(obj);
        if (index != -1) {
          this.locationObj.splice(index, 1);
        }
      }
      else if (type == 'publication') {
        let index = this.publicationObj.indexOf(obj);
        if (index != -1) {
          this.publicationObj.splice(index, 1);
        }
      }
      else if (type == 'company') {
        let index = this.companyObj.indexOf(obj);
        if (index != -1) {
          this.companyObj.splice(index, 1);
        }
      }

      this.router.navigate(
      [],
      {
        // relativeTo: this.aroute,
        queryParams:  { page: 1, size: this.size },
        queryParamsHandling: 'merge'
      });
    }
    // this.page=1;

    // this.router.navigate(
    //   [],
    //   {
    //     relativeTo: this.aroute,
    //     queryParams:  { page: 1, size: this.size },
    //     queryParamsHandling: 'merge'
    //   });

    this.getFilterData();
  }

  quickFilter() {
    this.loader = true;
    let Obj: any = {
      offSet: 10,
      pageNo: 1
    };

    if (this.indqs) {
      Obj.industryId = this.indqs;
    }
    if (this.funqs) {
      Obj.funcAreaId = this.funqs;
    }
    if (this.locqs) {
      Obj.locationId = this.locqs;
    }
    this.apiServiceService.getQuickFilter(Obj).subscribe(
      (response: any) => {
        this.jobSearchList = response.jobSearchList;
        this.loader = false;
      }
    )
  }

  getFilterData() {

    let data: any = {
      // hashKey: this.hashKey
    }
    if (this.industryObj.length) {
      // data.industryList = this.industryObj;
      data.industries = this.industryObj;
    }
    if (this.locationObj.length) {
      data.locationList = this.locationObj
    }
    if (this.publicationObj.length) {
      data.publicationNameList = this.publicationObj
    }
    if (this.companyObj.length) {
      // data.companyNameList = this.companyObj
      data.compName=this.companyObj
    }

    let Obj = {
      funcArea: this.funcArea,
      industry: this.industry,
      jobType: this.jobTypeFlag,
      exp: this.experience,
      location: this.locationList,
      searchKey: this.keyskillList,

    }

    let finalObj = { ...Obj, ...data }
    this.apiServiceService.jobsearch(finalObj, this.page - 1, this.size).subscribe(
      (response: any) => {
        this.jobSearchList = response.data;
        this.jobResponse = response;
        this.getPagination();

        this.loader = false;
      })
  }

  changePage() {
    this.router.navigate([], { queryParams: { page: this.page + 1, size: this.size }, queryParamsHandling: 'merge' });
  }



  calculateDiff(data) {
    let date = new Date(data);
    let currentDate = new Date();
    let days = Math.round((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);
    if (days <= 0)
      return "Apply By Today"
    else
      return "Apply within " + days + " days from now"
    // return days;
  }

  // Bookmark section

  bookmarkJobId: any;
  jobIndex: any;
  bookmarkJob(data, index) {

    //find the index of object from array that you want to update
    const objIndex = index

    const updatedObj = { ...this.jobSearchList[objIndex], bookmark: data.bookmark == 'Y' ? 'N' : 'Y' };
    const updatedProjects = [
      ...this.jobSearchList.slice(0, objIndex),
      updatedObj,
      ...this.jobSearchList.slice(objIndex + 1),
    ];
    this.jobSearchList = updatedProjects;
    // if (data.bookmark == 'N')
    //   this.toastr.success('Successful', 'Added to Bookmark.');
    // else
    //   this.toastr.success('Successful', 'Removed from Bookmark.');

    this.apiServiceService.saveBookmark(data.job_create_id).subscribe(
      (res: any) => {
      }
    );
  }

  setBookmarkId(id) {
    this.bookmarkJobId = id;
    this.rest.bookmarkJobId = id;
    this.rest.hideBookmarkModal = 0;
  }



  closeModal() {
    this.modalRef.hide();
  }

  showBookmark(data, i) {
    if (this.rest.hideBookmarkModal == 1) {
      this.modalRef.hide();
      this.userId = this.cookie.get('userId');
      // Write codes here
    }
    if (this.rest.hideBookmarkModal == 2) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['jobseeker/register'])
    }
    if (this.rest.hideBookmarkModal == 3) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['forget-password'])
    }

    if (this.rest.savedJobId == data.job_create_id) {

      const objIndex = i

      const updatedObj = { ...this.jobSearchList[objIndex], bookmark: data.bookmark == 'Y' ? 'N' : 'Y' };
      const updatedProjects = [
        ...this.jobSearchList.slice(0, objIndex),
        updatedObj,
        ...this.jobSearchList.slice(objIndex + 1),
      ];
      this.jobSearchList = updatedProjects;
      this.rest.savedJobId = null;
      this.getList();

    }




  }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }
}



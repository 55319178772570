import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { RestApiService } from "./rest-api.service";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class EmployerApi {
  constructor(
    private restApiService: RestApiService,
    private httpClient: HttpClient,
    private cookieService: CookieService,
  ) {}

  doLogin(obj) {
    let url = "oauth/token";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  userInfo() {
    let url = "userInfo";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailDuplicate(email) {
    let url = "user/emailExist?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  phoneDuplicate(phone) {
    let url = "user/phoneExist?email=" + phone;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  companyDuplicate(company) {
    let url = "user/employer/exists?companyName=" + company;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return true;
      })
    );
  }

  industriesList() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["ind"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  publicationList() {
    let url = "newsPublication/getAll";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  educationCatList() {
    let url = "newspaperJob/eduCat";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  countryList() {
    let url = "location/getAllCityCountry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["ctry"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // locationSearch(obj) {
  //     let url = 'location/new/search';
  //     let getUrl = this.restApiService.modifyEmpTokenUrl(url);
  //     return this.httpClient.post(getUrl.url, obj).pipe(
  //         map(response => {
  //             return response;
  //         })
  //     );
  // }

  locationSearch(obj) {
    let url = "location/forJob";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  cityList() {
    let url = "location/getAllCityCountry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["city"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  functionalAreasList() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["func"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  registerEmployee(obj) {
    let url = "user/employer/register";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobDetails(id) {
    let url = "createJob/refillJobDetail/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getJobType() {
    let url = "jobType";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  educationLevel() {
    let url = "category/eduByType";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  createEmployeeJob(obj) {
    let url = "createJob/save";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadBanner(obj) {
    let url = "employer/simpleSite/uploadBanner";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  doPasswordChange(obj) {
    let url = "user/changePassword";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  checkAccountDeletion() {
    let url = "jsDelete";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  cancelAccount() {
    let url = "jsDelete/updateStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  deleteAccount(obj) {
    let url = "jsDelete";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  doEmailChange(obj) {
    let url = "user/changeEmail";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getJobDataList(id, jobType, page, size) {
    let url =
      "jobList/" + jobType + "/" + id + "?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getRemainingJobs(id) {
    let url = "jobList/credit/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getResumeStatus(id) {
    let url = "jobList/resumeAccess/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getJobViews(id) {
    let url = "jobList/views/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  mailStatus() {
    let url = "user/unverifiedMail";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  resendCode() {
    let url = "user/resendCode";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  registerCodeResend(email) {
    let url = "user/resendEmailVerification?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCountData(id) {
    let url = "jobList/count/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEmployerProfile(id) {
    let url = "user/employer/profile/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  verifyEmai(code) {
    let url = "user/verify/email/" + code;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, null).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  saveWelcomeData(obj) {
    let url = "user/employer/profile";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  forgetPassword(email: string) {
    let url = "user/forgotPassword?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  applicantsDetail(id, type, key, page, size) {
    var url;
    if (key)
      url =
        "applicants/" +
        id +
        "/" +
        type +
        "?searchKey=" +
        key +
        "&page=" +
        page +
        "&size=" +
        size;
    else
      url = "applicants/" + id + "/" + type + "?page=" + page + "&size=" + size;

    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  applicantsStats(id) {
    let url = "applicants/stats/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  applicantRemarks(id, remarks) {
    let url = "applicants/addRemarks/" + id + "?remarks=" + remarks;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  applicantStatus(id, status, remarks) {
    let url =
      "applicants/changeStatus?jobAppId=" +
      id +
      "&status=" +
      status +
      "&remarks=" +
      remarks;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  changeApplicantStatus(obj) {
    let url = "applicants/changeStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  profileData(id) {
    // let urole:any = localStorage.getItem('theme');
    let urole = this.cookieService.get('userRole')
    let url;
    if(urole == "SE" || urole == "E"){
      url = "admin/jobseeker/data/" + id;
    }
    else if(urole == ""){
      url = "admin/jobseeker/data/" + id;
    }
    else{
      url = "cv/data/" + id;
    }
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  saveBookmark(id) {
    let url = "jobReview/bookMark/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeMobileNumber(number) {
    let url = "jsInfo/changeNumber/" + number;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  downloadCv(id) {
    let url = "cv/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadProfilePic(obj) {
    let url = "user/employer/profile";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadCoverPic(obj) {
    let url = "employer/simpleSite/updateSimpleSite";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getResumeAccessPlan(obj, page, size) {
    let url = "resumeAccess?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getResumeHistory(obj, page, size) {
    let url = "resumeAccess/history?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getResumeFromLog(obj, page, size) {
    let url = "resumeAccess?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  checkDownloadLimit(id) {
    let url = "resumeAccess/dailyCvLimit/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  resumePurchaseDetails() {
    let url = "resumeAccess/purchaseDetails";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  topCompanies() {
    let url = "topCompanies/logos";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getTopCompanies(obj, page, size) {
    let url = "topCompanies/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  employerList() {
    let url = "topCompanies/employer";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getTestimonialList() {
    let url = "admin/testimonial/getAll";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addTestimonial(obj) {
    let url = "admin/testimonial";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadTestiProfilePic(obj) {
    let url = "admin/testimonial/uploadPic";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  changeTestimonialStatus(obj) {
    let url = "admin/testimonial/updateStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addTopCompanies(obj) {
    let url = "topCompanies";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEditedCompany(id) {
    let url = "topCompanies/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteTOpCompany(id) {
    let url = "topCompanies/delete/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  rejectedList(page, size, obj) {
    let url = "admin/job/applicantList?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeRemarksStatus(obj) {
    let url = "applicants/showRemarks";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addRemarks(obj) {
    let url = "applicants/systemRemarks";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEmployerList() {
    let url = "user/employer/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getJobs(id) {
    let url = "admin/job/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
}

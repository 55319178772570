
<div class="container" style="margin-top: 120px;">
    <div class="col-md-12">
        <div class="col-md-8 col-md-offset-2 white-box">
            <div class="header" *ngIf="!verifyStatus">
                <p style="color: #000; text-align: center; margin: 50px;">Verifying Email...
                </p>
            </div>

            <div class="header" *ngIf="verifyStatus && !errorMsg">
                <p style="color: #000; text-align: center; margin: 50px;">Your email has been successfully verified.
                </p>
            </div>

            <div class="header" *ngIf="verifyStatus && errorMsg">
                <p style="color: #000; text-align: center; margin: 50px;"> Sorry we couldn't verify your email at this
                    moment. Please try again later.
                </p>
            </div>
        </div>
    </div>
</div>

<!-- <app-employers-login></app-employers-login> -->
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Uploadfile } from 'src/app/common/function/upload';
import { ApiServiceService } from 'src/app/common/services/api-service.service';

@Component({
  selector: 'app-hipco',
  templateUrl: './hipco.component.html',
  styleUrls: ['./hipco.component.scss']
})
export class HipcoComponent implements OnInit {


  englishReq:string = "This field is required";
  bhojpuriReq = "ई क्षेत्र जरूरी बा";
  nepaliReq = "यो क्षेत्र आवश्यक छ";
  languageSelected = "English";
  myForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private apiServiceService: ApiServiceService,
    private toastr: ToastrService,
    private uploadfile: Uploadfile
  ) {
    
   }

  ngOnInit(): void {
    this.getStateList();
    this.setupForm();
  }

  SelectChange(e){
    if(e.target.name == "langauge change"){
      this.languageSelected = e.target.value;
    }
    else if(e.target.name == "motherTongue"){
      if(e.target.value == "Other"){
        if(!this.myForm.controls.otherMotherTongue){
          this.myForm.addControl('otherMotherTongue', this.fb.control(''));
        }
      }else{
        if(this.myForm.controls.otherMotherTongue){
          this.myForm.removeControl('otherMotherTongue'); 
        }
      }
    }

    else if(e.target.name == "isWorking"){
      if(e.target.value == "Y"){
        if(!this.myForm.controls.organizationName){
          this.myForm.addControl('organizationName', this.fb.control(''));
          this.myForm.addControl('organizationAddress', this.fb.control(''));
          this.myForm.addControl('position', this.fb.control(''));
          this.myForm.addControl('salary', this.fb.control(''));
        }
      }else{
        if(this.myForm.controls.organizationName){
          this.myForm.removeControl('organizationName'); 
          this.myForm.removeControl('organizationAddress'); 
          this.myForm.removeControl('position'); 
          this.myForm.removeControl('salary'); 
          this.checkboxValue = [];
        }
      }
    }
    else if(e.target.name == "ifDisease"){
      if(e.target.value == "Y"){
        if(!this.myForm.controls.disease){
          this.myForm.addControl('disease', this.fb.control(''));
        }
      }else{
        if(this.myForm.controls.disease){
          this.myForm.removeControl('disease'); 
        }
      }
    }

    else if(e.target.name == "ifMedication"){
      if(e.target.value == "Y"){
        if(!this.myForm.controls.medication){
          this.myForm.addControl('medication', this.fb.control(''));
        }
      }else{
        if(this.myForm.controls.medication){
          this.myForm.removeControl('medication'); 
        }
      }
    }
  }


  diseaseCheckbox = [
                      {english:"Diabetes", nepali:"मधुमेह", bhojpuri:"मधुमेह (चिनी)"},
                      {english:"High Blood Pressure (Hypertension)", nepali:"उच्च रक्तचाप", bhojpuri:"ब्लड प्रेषर (बिपी)"},
                      {english:"Kidney related problems", nepali:"मिर्गौला सम्बन्धी रोग", bhojpuri:"किड्नी के बेमारी"},
                      {english:"Epilepsy", nepali:"क्षयरोग", bhojpuri:"मिर्गी"},
                      {english:"Migraine", nepali:"माईग्रेन (टाउको दुख्ने रोग)", bhojpuri:"जोडसे मुडी दुखाई"},
                      {english:"Others", nepali:"अन्य", bhojpuri:"औरी बहुत्किसिम के रोग"},
                    ]



  setupForm(){
    this.myForm = this.fb.group({
      firstName:["",[Validators.required]],
      age:["",[]],
      maritalStatus:["",[]],
      permProvince: ["",[]],
      permDistrict: ["",[]],
      permMunicipality: ["",[]],
      permWard: ["",[]],
      permStreet: ["",[]],
      tempProvince: ["",[]],
      tempDistrict: ["",[]],
      tempMunicipality: ["",[]],
      tempWard: ["",[]],
      tempStreet: ["",[]],
      phone: ["",[]],
      mobile: ["",[]],
      emergencyNo: ["",[]],
      motherTongue: ["",[]],
      isWorking: ["Y",[]],
      organizationName: ["",[]],
      organizationAddress: ["",[]],
      position: ["",[]],
      salary: ["",[]],
      ctzNumber: ["",[Validators.required]],
      ctzIssuedDate: ["",[]],
      ctzIssuedDistrict: ["",[]],
      ctzOriginalFileName: [""],
      ctzDbFileName: [""],
      commitment: ["Y",[]],
      ifDisease: ["Y",[]],
      disease: ["",[]],
      ifMedication: ["Y",[]],
      medication: ["",[]],
    })
  }


  // formControlName="disease"

  checkboxValue:any = [];
  checkboxSelect(e){
     let checkboxData = e.target.name;
      let flag = this.checkboxValue.findIndex(element => element == checkboxData);
     if(flag == -1){
       this.checkboxValue.push(checkboxData);
       if(e.target.name == "Others"){
        this.myForm.addControl('diseaseOther', this.fb.control(''));
       }
     }
     else{
       this.checkboxValue.splice(flag, 1);
       if(e.target.name == "Others"){
        this.myForm.removeControl('diseaseOther'); 
       }
     }
    this.myForm.get("disease").setValue(this.checkboxValue.toString());
  }

  checkOthersData(){
    let flag = this.checkboxValue.findIndex(element => element == "Others"); 
    if(flag == -1){
      return false;
    }
    else{
      return true;
    }
  }

   registerFormControl() {
    return this.myForm.controls;
  }




  checkbox = false;
  checkboxAccept(){
    if(this.checkbox){
      this.checkbox = false;
    }
    else{
      this.checkbox = true;
    }
  }


  stateList: any = [];
  getStateList() {
    this.stateList = [];
      let obj = {
        parentId: 1
      }
      this.apiServiceService.locationSearch(obj).subscribe(
        (res: any) => {
           this.stateList = res;
          this.stateList.splice(this.stateList.indexOf({
            locationId: 395,
            locationName: "All Over Nepal",
            parentLocationId: 1
          }), 1);
        }
      )
  }

  citiList:any = [];
  getCity(id) {
    this.citiList = [];
    let obj = {
      parentId: id
    }

    this.apiServiceService.locationSearch(obj).subscribe(
      (res: any) => {
        this.citiList = res;
      }
    )
  }


  tempcitiList:any = [];
  gettempCity(id) {
    this.tempcitiList = [];
    let obj = {
      parentId: id
    }

    this.apiServiceService.locationSearch(obj).subscribe(
      (res: any) => {
        this.tempcitiList = res;
      }
    )
  }


  provienceChange(e){
    let location = e.target.value;
    let found = this.stateList.find(element => element.locationName == location);
    if(found.locationId){
      this.getCity(found.locationId);
    }
  }


  provienceTempChange(e){
    let location = e.target.value;
    let found = this.stateList.find(element => element.locationName == location);
    if(found.locationId){
      this.gettempCity(found.locationId);
    }
  }

  originalFileName: any;
  profileFileName: any;
  uploadStatus;
  fileObj1 = {
    fileStatus: 0,
    sendFile: null
  };
  errorMsg;
  imgURL1;
  uploadPhoto(files: any) {

    this.uploadStatus = true;
    if (files) {
      let extensionType = ['png', 'PNG', 'JPG', 'JPEG', 'jpg', 'jpeg', 'pdf', 'PDF', 'doc', 'DOC', 'docx'];
      this.fileObj1 = this.uploadfile.fileUpload(files, extensionType, 15097152);
      this.originalFileName = files[0].name;
      if (this.fileObj1.fileStatus == 1) {
        this.profileFileName = files[0].name;
        this.uploadStatus = false;
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
          this.imgURL1 = reader.result;
        }
        // this.uploadCv();
      }
      else if (this.fileObj1.fileStatus == 3) {
        this.errorMsg = "This file type is not supported. File Name shouldnt contain any space";
      }
      else {
        this.errorMsg = "File size should be less than 15 Mb.";
      }
      setTimeout(() => {
        this.errorMsg = null
      }, 4000);
    }
    else {
      this.imgURL1 = null;
      this.fileObj1 = {
        fileStatus: 0,
        sendFile: null
      };
      this.profileFileName = null;
      this.uploadStatus = false;
      this.originalFileName = null;

    }
  }

  
  saveData(){
    //this.uploadPhotoSave();
    console.log(this.myForm)
    console.log(this.myForm.controls.ctzNumber.status)
    if(this.myForm.controls.firstName.status == "INVALID"){
      this.showMissingToaster('Missing Field', "Please enter your Full Name");
    }
    else if(this.myForm.controls.ctzNumber.status == "INVALID"){
      this.showMissingToaster('Missing Field', "Please enter your Citizenship Number");
    }
    else if(!this.checkbox){
      this.showMissingToaster('Missing Checkbox', "Please accept the term and conditions");
    }
    else if (this.fileObj1.fileStatus == 0){
      this.showMissingToaster('Upload Missing', "Please upload both sides of your citizenship");
    }
    else if (this.fileObj1.fileStatus == 3){
      this.showMissingToaster('Upload Missing', "This file type is not supported. File Name shouldnt contain any space")
    }
    else if(this.myForm.controls.firstName.status == "VALID" && this.checkbox && this.myForm.controls.ctzNumber.status == "VALID" && this.fileObj1.fileStatus == 1){
      this.saveUpload();
    }

    }
  

  saveUpload(){
    if (this.fileObj1.fileStatus == 1) {
      this.apiServiceService.uploadPhoto(this.fileObj1.sendFile).subscribe(
        (response: any) => {
          this.myForm.get("ctzOriginalFileName").setValue(response.originalFileName);
          this.myForm.get("ctzDbFileName").setValue(response.databaseFileName);

          if(this.myForm.valid){
            this.apiServiceService.addDataHulas(this.myForm.value).subscribe(
              (res: any) => {
                this.toastr.success( 'Thankyou You have Successfully Added', 'Successful', { timeOut: 4000, positionClass:'toast-center-center' });
                setTimeout(()=>{
                 
                  window.open("https://www.jobejee.com/hulas-hipco-steel-fabrication", "_blank");
                  window.location.reload();
                }, 2000);
              },
              (err: any) => {
                this.toastr.error('Unsuccessful', 'Saving unsuccessful', {
                  timeOut: 2000,
                  positionClass:'toast-center-center'
                });
              }
              )
          }
        }
      )
    }
  }


  showMissingToaster(status, message){
    this.toastr.error(message, status, {
      timeOut: 3000,
      positionClass:'toast-center-center'
    });
  }



}

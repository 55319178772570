<div class="container-fluid container-fixed-top">


    <div class="container nopadding" style="margin-top:20px">
        <div class=" col-md-12 top-row ">
            <span class="orange-text">
                <a class="green-link noDecoration" [routerLink]="['/home']">
                    <i class="fa fa-tachometer" aria-hidden="true"></i>Home/
                </a>
                <a class="green-link noDecoration" (click)="goToJobDetail()">
                    Job Detail
                </a>
                <span class="orange-text"> /Job Apply</span>
            </span>
        </div>
    </div>


    <div class="container lg-view">





        <div class="row">
            <div class="col-md-12 wt-box">
                <div class="col-md-12 grey-row">
                    Please review your profile before applying for this job.
                </div>

                <div class="col-md-12">
                    <table>
                        <tr>
                            <th>Key Job Requirement</th>
                            <th>Your Profile</th>
                        </tr>

                        <!-- Image Box -->
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-4 col-sm-5">
                                        <div class="img-box">
                                            <!-- <img src="../../../assets/images/ncell50.png" alt=""> -->

                                            <!-- <span>{{jobInfo.image}}</span> -->
                                            <img src={{jobInfo.image}} alt="" *ngIf="jobInfo.image">
                                            <img src="../../../assets/images/default-comp.jpeg" alt=""
                                                *ngIf="!jobInfo.image">
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-sm-7 info-box">
                                        <p class="title green-orange-link cursor" (click)="goToJobDetail()">
                                            {{jobInfo.title || 'N/A'}}</p>
                                        <p>{{jobInfo.companyName || 'N/A'}}</p>
                                        <p>Vacancy : {{jobInfo.no_vac || 'N/A'}}</p>
                                        <p><i class="fa fa-map-marker" aria-hidden="true"></i>
                                            {{jobInfo.location || 'Not Available'}}
                                        </p>
                                        <p>Job Expiry Date : {{(jobInfo.job_expiry | date) || 'N/A'}}</p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-4 col-sm-5">
                                        <div class="img-box">
                                            <!-- <img src="../../../assets/images/ncell50.png" alt=""> -->
                                            <img src={{jobInfo.profile_pic}} alt="" *ngIf="jobInfo.profile_pic">
                                            <img src="../../../assets/images/prof.png" alt=""
                                                *ngIf="!jobInfo.profile_pic">
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-sm-7 info-box">
                                        <p class="title green-text">{{jobInfo.firstName || 'N/A'}}
                                            {{jobInfo.lastName || 'N/A'}}</p>
                                        <p>Age : {{validation.yourAge || 'N/A'}}</p>
                                        <p>Address : {{jobInfo.address || 'N/A'}}
                                        </p>
                                        <p>Contact : {{jobInfo.mobNo || 'N/A'}}</p>
                                        <p>Email : {{jobInfo.email}}</p>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Skills-->
                        <tr>
                            <td>
                                <p class="title orange-text"> Skills
                                    <span *ngIf="validation.skillMand=='Y'" class="mandatory">(Mandatory)</span>
                                </p>

                                <span class="keyskills-small"
                                    *ngFor="let item of splitData(validation.reqSkills)">{{item}}</span>

                            </td>
                            <td>
                                <p class="title">Your Skills</p>
                                <div class="row">
                                    <div class="col-md-11 col-sm-11 col-xs-11">
                                        <span class="keyskills-small"
                                            *ngFor="let item of splitData(validation.yourSkills)">{{item}}</span>
                                        <span *ngIf="!validation.yourSkills">Not Available</span>
                                    </div>

                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding" *ngIf="validation.skillMand=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.skills"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.skills"></i>

                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Education -->
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> Education Level
                                            <span *ngIf="validation.eduMand=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-2 title orange-text nopadding">:</div>
                                    <div class="col-md-5 col-sm-6 col-xs-5">{{jobInfo.reqEdu || 'Not Available'}}</div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Education Level</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        {{validation.yourEducation || 'Not Available'}}</div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding" *ngIf="validation.eduMand=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.education"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.education"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Experience-->
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> Experience Level
                                            <span *ngIf="validation.expMand=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-2 title orange-text nopadding">:</div>
                                    <div class="col-md-5 col-sm-6 col-xs-5">
                                        {{validation.reqExperienceMin}}-{{validation.reqExperienceMax}} Years</div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Experience</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        {{validation.yourExperience || 'Not Available'}} <span
                                            *ngIf="validation.yourExperience">Years</span></div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding" *ngIf="validation.expMand=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.experience"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.experience"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>


                        <!-- Age-->
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text">Preferred Age
                                            <span *ngIf="validation.ageBar=='Y'" class="mandatory">(
                                                Mandatory)</span>

                                        </p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-2 title orange-text nopadding">:</div>
                                    <div class="col-md-5 col-sm-6 col-xs-5">{{validation.reqAgeFrom || 'Not Available'}}
                                        <span *ngIf="validation.reqAgeFrom">-</span> {{validation.reqAgeTo}}

                                        <span *ngIf="validation.reqAgeFrom">Years</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Age</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">{{validation.yourAge || 'Not Available'}}
                                        <span *ngIf="validation.yourAge">Years</span>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding" *ngIf="validation.ageBar=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.age"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.age"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>


                        <!-- Gender-->
                        <tr>
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text">Preferred Gender
                                            <span *ngIf="validation.reqGender!='A'" class="mandatory">(Mandatory)</span>

                                        </p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-2 title orange-text nopadding">:</div>
                                    <div class="col-md-5 col-sm-6 col-xs-5">
                                        <span *ngIf="validation.reqGender=='M'">Male</span>
                                        <span *ngIf="validation.reqGender=='F'">Female</span>
                                        <span *ngIf="validation.reqGender=='A'">Any</span>
                                        <!-- <span *ngIf="validation.reqGender">Any</span> -->


                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Gender</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="validation.yourGender=='M'">Male</span>
                                        <span *ngIf="validation.yourGender=='F'">Female</span>
                                        <span *ngIf="validation.yourGender=='A'">Any</span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding">
                                        <ng-container *ngIf="validation.reqGender!='A'">
                                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                                *ngIf="!validation.gender"></i>
                                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                                *ngIf="validation.gender"></i>
                                        </ng-container>

                                    </div>
                                </div>
                            </td>
                        </tr>


                        <!-- Cover Letter -->
                        <tr *ngIf="validation.reqCoverLetter=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> Cover Letter
                                            <span *ngIf="validation.reqCoverLetter=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Cover Letter</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourCoverLetter" class="upload-btn">

                                            <label for="cc" class="upl-file" style="font-weight: normal;">
                                                <span class="cursor">Upload Cover Letter</span>
                                            </label>

                                            <input type="file" id="cc" class="form-control" #fileCc name="ccFile"
                                                accept=".pdf,.doc,.docx" (click)="fileCc.value = null" value=""
                                                (change)="uploadCoverLetter(fileCc.files)"
                                                style="display: none; visibility: hidden;">

                                        </span>



                                        <span *ngIf="validation.yourCoverLetter">
                                            <a [href]="validation.yourCoverLetterUrl " target="_blank"
                                                [title]="validation.yourCoverLetter">
                                                <span *ngIf="validation.yourCoverLetter.length>20">
                                                    {{(validation.yourCoverLetter | slice:0:20)+'...' }}



                                                </span>
                                                <span *ngIf="validation.yourCoverLetter.length<21">
                                                    {{validation.yourCoverLetter }}

                                                </span>
                                            </a>

                                            <span>
                                                <label for="ccEdit" class="upl-file" style="font-weight: normal;">
                                                    <span class="cursor" style="margin-left:5px;">
                                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                                    </span>
                                                </label>

                                                <input type="file" id="ccEdit" class="form-control" #fileCc
                                                    name="ccFile" accept=".pdf,.doc,.docx" (click)="fileCc.value = null"
                                                    value="" (change)="uploadCoverLetter(fileCc.files)"
                                                    style="display: none; visibility: hidden;">

                                            </span>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqCoverLetter=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.coverLetter"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.coverLetter"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- License -->
                        <tr *ngIf="validation.reqLicense=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> License
                                            <span *ngIf="validation.reqLicense=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your License</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourLicense">Not Available</span>
                                        <span *ngIf="validation.yourLicense">
                                            <a [href]="validation.yourLicenseUrl " target="_blank"
                                                [title]="validation.yourLicense">
                                                <span *ngIf="validation.yourLicense.length>20">
                                                    {{(validation.yourLicense | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourLicense.length<21">
                                                    {{validation.yourLicense }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqLicense=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.license"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.license"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Citizenship -->
                        <tr *ngIf="validation.reqCitizenship=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> Citizenship
                                            <span *ngIf="validation.reqCitizenship=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Citizenship</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourCitizenship">Not Available</span>
                                        <span *ngIf="validation.yourCitizenship">
                                            <a [href]="validation.yourCitizenshipUrl " target="_blank"
                                                [title]="validation.yourCitizenship">
                                                <span *ngIf="validation.yourCitizenship.length>20">
                                                    {{(validation.yourCitizenship | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourCitizenship.length<21">
                                                    {{validation.yourCitizenship }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqCitizenship=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.citizenship"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.citizenship"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- School -->
                        <tr *ngIf="validation.reqSlcTranscript=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> SLC/SEE Certificate
                                            <span *ngIf="validation.reqSlcTranscript=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your SLC/SEE Certificate</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourSlcTranscript">Not Available</span>
                                        <span *ngIf="validation.yourSlcTranscript">
                                            <a [href]="validation.yourSlcTranscriptUrl " target="_blank"
                                                [title]="validation.yourSlcTranscript">
                                                <span *ngIf="validation.yourSlcTranscript.length>20">
                                                    {{(validation.yourSlcTranscript | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourSlcTranscript.length<21">
                                                    {{validation.yourSlcTranscript }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqSlcTranscript=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.slc"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.slc"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- +2 Academic Certificate -->
                        <tr *ngIf="validation.reqPlusTwoTranscript=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> +2 Academic Certificate
                                            <span *ngIf="validation.reqPlusTwoTranscript=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your +2 Academic Certificate</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourPlusTwoTranscript">Not Available</span>
                                        <span *ngIf="validation.yourPlusTwoTranscript">
                                            <a [href]="validation.yourPlusTwoTranscriptUrl " target="_blank"
                                                [title]="validation.yourPlusTwoTranscript">
                                                <span *ngIf="validation.yourPlusTwoTranscript.length>20">
                                                    {{(validation.yourPlusTwoTranscript | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourPlusTwoTranscript.length<21">
                                                    {{validation.yourPlusTwoTranscript }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqPlusTwoTranscript=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.plusTwo"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.plusTwo"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Bachelor Transcript -->
                        <tr *ngIf="validation.reqBachelorsTranscript=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text"> Bachelor Transcript
                                            <span *ngIf="validation.reqBachelorsTranscript=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Bachelor Transcript</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourBachelorsTranscript">Not Available</span>
                                        <span *ngIf="validation.yourBachelorsTranscript">
                                            <a [href]="validation.yourBachelorsTranscriptUrl " target="_blank"
                                                [title]="validation.yourBachelorsTranscript">
                                                <span *ngIf="validation.yourBachelorsTranscript.length>20">
                                                    {{(validation.yourBachelorsTranscript | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourBachelorsTranscript.length<21">
                                                    {{validation.yourBachelorsTranscript }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqBachelorsTranscript=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.bachelors"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.bachelors"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- Master -->
                        <tr *ngIf="validation.reqMastersTranscript=='Y'">
                            <td>
                                <div class="row">
                                    <div class="col-md-6 col-sm-5 col-xs-5">
                                        <p class="title orange-text">Your Master Transcript
                                            <span *ngIf="validation.reqMastersTranscript=='Y'" class="mandatory">(
                                                Mandatory)</span>
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div class="row">
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <p class="title">Your Master Transcript</p>
                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 title nopadding">:</div>
                                    <div class="col-md-5 col-sm-5 col-xs-5">
                                        <span *ngIf="!validation.yourMastersTranscript">Not Available</span>
                                        <span *ngIf="validation.yourMastersTranscript">
                                            <a [href]="validation.yourMastersTranscriptUrl " target="_blank"
                                                [title]="validation.yourMastersTranscript">
                                                <span *ngIf="validation.yourMastersTranscript.length>20">
                                                    {{(validation.yourMastersTranscript | slice:0:20)+'...' }}

                                                </span>
                                                <span *ngIf="validation.yourMastersTranscript.length<21">
                                                    {{validation.yourMastersTranscript }}

                                                </span>
                                            </a>
                                        </span>

                                    </div>
                                    <div class="col-md-1 col-sm-1 col-xs-1 nopadding"
                                        *ngIf="validation.reqMastersTranscript=='Y'">
                                        <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                            *ngIf="!validation.masters"></i>
                                        <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                            *ngIf="validation.masters"></i>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="col-md-12 text-center">
                    <p class="title green-text">Please update <span class="orange-text">Your Profile</span> Before
                        Applying for this Job <button class="btn btn-orange" [routerLink]="[ '/jobseeker/profile' ]"
                            [queryParams]="{target: 'update'}">Update Profile</button></p>
                </div>

                <div class="co-md-12 text-center grey-text">

                    <span (click)="goToJobDetail()" class="cursor">
                        <i class="fa fa-eye" aria-hidden="true"></i> View Job Description
                    </span>

                    <span>
                        <ng-container *ngIf="jobInfo.jobBookmark=='Y';else noBookmark">
                            <span class="icontxt icon-gap" (click)="bookmarkJob(jobInfo)"> <i class="fa fa-bookmark"
                                    aria-hidden="true"></i> Bookmarked</span>
                        </ng-container>

                        <ng-template #noBookmark>
                            <span class="icontxt icon-gap" (click)="bookmarkJob(jobInfo)" *ngIf="userId"> <i
                                    class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark this job</span>
                            <span class="icontxt icon-gap"
                                (click)="openModal(bookmark); setBookmarkId(jobInfo.jobCreateId)" *ngIf="!userId">
                                <i class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark this job
                            </span>
                            <span [ngStyle]="showBookmark(jobInfo)" style="visibility:hidden;display: none">Hide</span>
                        </ng-template>
                    </span>
                </div>

            </div>
        </div>
    </div>


    <!-- For Mobile View -->

    <div class="row small-view">
        <div class="col-md-12 wt-box">
            <div class="col-md-12 grey-row">
                Please reivew your profile before applying for this job.
            </div>

            <div class="row">
                <div class="col-md-4 col-xs-4">
                    <div class="img-box">
                        <img src={{jobInfo.image}} alt="" *ngIf="jobInfo.image">
                        <img src="../../../assets/images/default-comp.jpeg" alt="" *ngIf="!jobInfo.image">
                    </div>
                </div>
                <div class="col-md-8 col-xs-8 info-box">
                    <p class="title">{{jobInfo.title || 'Not Available'}}</p>
                    <p>{{jobInfo.companyName || 'Not Available'}}</p>
                    <p>Vacancy : {{jobInfo.no_vac || 'Not Available'}}</p>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i> {{jobInfo.location || 'Not Available'}}
                    </p>
                    <p class="res-sm-txt">Job Expiry Date : {{(jobInfo.job_expiry|date) || 'Not Available'}}</p>
                </div>


                <!-- Key Skills -->
                <div class="col-xs-12 mt-15 form-group">

                    <p class="title orange-text"> Required Skills:
                        <span *ngIf="validation.skillMand=='Y'" class="mandatory">( Mandatory)</span>

                    <p>
                        <span>{{validation.reqSkills|titlecase}}</span>
                    </p>

                    <p class="title">Your Skills
                        <span *ngIf="validation.skillMand=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.skills"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.skills"></i>
                        </span>
                    </p>
                    <p *ngIf="validation.yourSkills">

                        <span class="keyskills-small" *ngFor="let item of splitData(validation.yourSkills)">{{item
                            }}</span>
                    </p>
                    <p *ngIf="!validation.yourSkills">
                        <span>Not Available</span>

                    </p>

                </div>



                <!--Education Level-->
                <div class="col-xs-12 form-group">
                    <p class="title orange-text">Required Education :
                        <span *ngIf="validation.eduMand=='Y'" class="mandatory">(Mandatory)</span>
                    </p>
                    <p>
                        {{jobInfo.reqEdu || 'Not Available'}}
                    </p>

                    <p class="title">Your Education Level :
                        <span *ngIf="validation.eduMand=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.education"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.education"></i>
                        </span>
                    </p>
                    <p>{{validation.yourEducation || 'Not Available'}}</p>

                </div>

                <!--Experience-->
                <div class="col-xs-12 form-group">
                    <p class="title orange-text">Required Experience :
                        <span *ngIf="validation.expMand=='Y'" class="mandatory">( Mandatory)</span>
                    </p>

                    <p>
                        {{validation.reqExperienceMin}}-{{validation.reqExperienceMax}} Years
                    </p>

                    <p class="title">Your Experience : {{validation.yourExperience || 'Not Available'}} <span
                            *ngIf="validation.yourExperience">Years</span>

                        <span *ngIf="validation.expMand=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.experience"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.experience"></i>
                        </span>
                    </p>

                </div>

                <!-- Age-->
                <div class="col-xs-12 form-group">

                    <p class="title orange-text">Preffered Age :
                        <span *ngIf="validation.ageBar=='Y'" class="mandatory">(Mandatory)</span>
                    </p>
                    <p>
                        {{validation.reqAgeFrom}} - {{validation.reqAgeTo}}
                        Years
                    </p>
                    <p class="title">Your Age : {{validation.yourAge || 'Not Available'}}
                        <span *ngIf="validation.ageBar=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.age"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.age"></i>
                        </span>
                    </p>

                </div>

                <!-- Gender-->
                <div class="col-xs-12 form-group">

                    <p class="title orange-text">Preffered Gender :
                        <span *ngIf="validation.reqGender!='A'" class="mandatory">(Mandatory)</span>
                    </p>

                    <p>
                        <span *ngIf="validation.reqGender=='M'">Male</span>
                        <span *ngIf="validation.reqGender=='F'">Female</span>
                        <span *ngIf="validation.reqGender=='A'">Any</span>
                    </p>

                    <p class="title">Your Gender :


                        <span *ngIf="validation.yourGender=='M'">Male</span>
                        <span *ngIf="validation.yourGender=='F'">Female</span>
                        <span *ngIf="validation.yourGender=='A'">Any</span>

                        <ng-container *ngIf="validation.reqGender!='A'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.gender"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.gender"></i>

                        </ng-container>
                    </p>




                </div>

                <!-- Cover Letter -->
                <div class="col-xs-12 form-group" *ngIf="validation.reqCoverLetter=='Y'">
                    <p class="title orange-text">Required Cover Letter :
                        <span *ngIf="validation.reqCoverLetter=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your Cover Letter :

                        <span *ngIf="!validation.yourCoverLetter">

                            <span *ngIf="!validation.yourCoverLetter" class="upload-btn">


                                <label for="cc" class="upl-file" style="font-weight: normal;">
                                    <span class="cursor">Upload Cover Letter</span>
                                </label>

                                <input type="file" id="cc" class="form-control" #fileCc name="ccFile"
                                    accept=".pdf,.doc,.docx" (click)="fileCc.value = null" value=""
                                    (change)="uploadCoverLetter(fileCc.files)"
                                    style="display: none; visibility: hidden;">

                            </span>
                        </span>
                        <span *ngIf="validation.yourCoverLetter">
                            <a [href]="validation.yourCoverLetterUrl " target="_blank"
                                [title]="validation.yourCoverLetter">


                                <span *ngIf="validation.yourCoverLetter">

                                    <span *ngIf="validation.yourCoverLetter.length>15">
                                        {{(validation.yourCoverLetter | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourCoverLetter.length<16">
                                        {{validation.yourCoverLetter }}

                                    </span>
                                </span>

                            </a>


                            <span>
                                <label for="ccEdit" class="upl-file" style="font-weight: normal;">
                                    <span class="cursor" style="margin-left:5px;">
                                        <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    </span>
                                </label>

                                <input type="file" id="ccEdit" class="form-control" #fileCc name="ccFile"
                                    accept=".pdf,.doc,.docx" (click)="fileCc.value = null" value=""
                                    (change)="uploadCoverLetter(fileCc.files)"
                                    style="display: none; visibility: hidden;">

                            </span>
                        </span>


                        <span *ngIf="validation.reqCoverLetter=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.coverLetter"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.coverLetter"></i>
                        </span>
                    </p>

                </div>

                <!--License-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqLicense=='Y'">
                    <p class="title orange-text">Required License :
                        <span *ngIf="validation.reqLicense=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your License :

                        <span *ngIf="!validation.yourLicense">Not Available</span>
                        <span><a [href]="validation.yourLicenseUrl " target="_blank" [title]="validation.yourLicense">

                                <span *ngIf="validation.yourLicense">

                                    <span *ngIf="validation.yourLicense.length>15">
                                        {{(validation.yourLicense | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourLicense.length<16">
                                        {{validation.yourLicense }}

                                    </span>
                                </span>



                            </a>
                        </span>


                        <span *ngIf="validation.reqLicense=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.license"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.license"></i>
                        </span>
                    </p>

                </div>


                <!--Citisenship-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqCitizenship=='Y'">
                    <p class="title orange-text">Required Citizenship :
                        <span *ngIf="validation.reqCitizenship=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your Citizenship :

                        <span *ngIf="!validation.yourCitizenship">Not Available</span>
                        <span><a [href]="validation.yourCitizenshipUrl " target="_blank"
                                [title]="validation.yourCitizenship">

                                <span *ngIf="validation.yourCitizenship">

                                    <span *ngIf="validation.yourCitizenship.length>15">
                                        {{(validation.yourCitizenship | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourCitizenship.length<16">
                                        {{validation.yourCitizenship }}

                                    </span>
                                </span>
                            </a>
                        </span>

                        <span *ngIf="validation.reqCitizenship=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.citizenship"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.citizenship"></i>
                        </span>
                    </p>

                </div>

                <!--School-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqSlcTranscript=='Y'">
                    <p class="title orange-text">Required SLC/SEE Certificate :
                        <span *ngIf="validation.reqSlcTranscript=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your SLC/SEE Certificate :

                        <span *ngIf="!validation.yourSlcTranscript">Not Available</span>
                        <span><a [href]="validation.yourSlcTranscriptUrl " target="_blank"
                                [title]="validation.yourSlcTranscript">

                                <span *ngIf="validation.yourSlcTranscript">

                                    <span *ngIf="validation.yourSlcTranscript.length>15">
                                        {{(validation.yourSlcTranscript | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourSlcTranscript.length<16">
                                        {{validation.yourSlcTranscript }}

                                    </span>
                                </span>
                            </a>
                        </span>


                        <span *ngIf="validation.reqSlcTranscript=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.slc"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.slc"></i>
                        </span>
                    </p>

                </div>

                <!--+2 Certificate-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqPlusTwoTranscript=='Y'">
                    <p class="title orange-text">Required +2 Academic Transcript :
                        <span *ngIf="validation.reqPlusTwoTranscript=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your +2 Academic Transcript :

                        <span *ngIf="!validation.yourPlusTwoTranscript">Not Available</span>
                        <span><a [href]="validation.yourPlusTwoTranscriptUrl " target="_blank"
                                [title]="validation.yourPlusTwoTranscript">

                                <span *ngIf="validation.yourPlusTwoTranscript">

                                    <span *ngIf="validation.yourPlusTwoTranscript.length>15">
                                        {{(validation.yourPlusTwoTranscript | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourPlusTwoTranscript.length<16">
                                        {{validation.yourPlusTwoTranscript }}

                                    </span>
                                </span>



                            </a>
                        </span>


                        <span *ngIf="validation.reqPlusTwoTranscript=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.plusTwo"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.plusTwo"></i>
                        </span>
                    </p>

                </div>

                <!--Bachelor Transcript-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqBachelorsTranscript=='Y'">
                    <p class="title orange-text">Required Bachelor Transcript :
                        <span *ngIf="validation.reqBachelorsTranscript=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your Citizenship :

                        <span *ngIf="!validation.yourBachelorsTranscript">Not Available</span>
                        <span><a [href]="validation.yourBachelorsTranscriptUrl " target="_blank"
                                [title]="validation.yourBachelorsTranscript">

                                <span *ngIf="validation.yourBachelorsTranscript">

                                    <span *ngIf="validation.yourBachelorsTranscript.length>15">
                                        {{(validation.yourBachelorsTranscript | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourBachelorsTranscript.length<16">
                                        {{validation.yourBachelorsTranscript }}

                                    </span>
                                </span>



                            </a>
                        </span>


                        <span *ngIf="validation.reqBachelorsTranscript=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.bachelors"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.bachelors"></i>
                        </span>
                    </p>

                </div>

                <!--Master-->
                <div class="col-xs-12 form-group" *ngIf="validation.reqMastersTranscript=='Y'">
                    <p class="title orange-text">Required Master Transcript :
                        <span *ngIf="validation.reqMastersTranscript=='Y'" class="mandatory">( Mandatory)</span>
                    </p>


                    <p class="title">Your Master Transcript :

                        <span *ngIf="!validation.yourMastersTranscript">Not Available</span>
                        <span><a [href]="validation.yourMastersTranscriptUrl " target="_blank"
                                [title]="validation.yourMastersTranscript">

                                <span *ngIf="validation.yourMastersTranscript">

                                    <span *ngIf="validation.yourMastersTranscript.length>15">
                                        {{(validation.yourMastersTranscript | slice:0:15)+'...' }}

                                    </span>

                                    <span *ngIf="validation.yourMastersTranscript.length<16">
                                        {{validation.yourMastersTranscript }}

                                    </span>
                                </span>



                            </a>
                        </span>


                        <span *ngIf="validation.reqMastersTranscript=='Y'">
                            <i class="fa fa-times-circle-o info-icons red-text" aria-hidden="true"
                                *ngIf="!validation.masters"></i>
                            <i class="fa fa-check-circle-o green-text info-icons" aria-hidden="true"
                                *ngIf="validation.masters"></i>
                        </span>
                    </p>

                </div>

                <div class="col-xs-12 form-group">
                    <p class="title green-text text-center">Please update <span class="orange-text">Your Profile</span>
                        Before
                        Applying for this Job
                    </p>

                    <p class="text-center"><button class="btn btn-orange" [routerLink]="[ '/jobseeker/profile' ]"
                            [queryParams]="{target: 'update'}">
                            Update Profile
                        </button></p>

                </div>

                <div class="col-xs-12 form-group">
                    <p class="text-center">
                        <span (click)="goToJobDetail()"><i class="fa fa-eye" aria-hidden="true"></i> View Job
                            Description</span>

                        <span style="margin-left: 15px;">
                            <ng-container *ngIf="jobInfo.jobBookmark=='Y';else noBookmark">
                                <span class="icontxt icon-gap" (click)="bookmarkJob(jobInfo)"> <i class="fa fa-bookmark"
                                        aria-hidden="true"></i> Bookmarked</span>
                            </ng-container>

                            <ng-template #noBookmark>
                                <span class="icontxt icon-gap" (click)="bookmarkJob(jobInfo)" *ngIf="userId"> <i
                                        class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark this job</span>
                                <span class="icontxt icon-gap"
                                    (click)="openModal(bookmark); setBookmarkId(jobInfo.jobCreateId)" *ngIf="!userId">
                                    <i class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark this job
                                </span>
                                <span [ngStyle]="showBookmark(jobInfo)"
                                    style="visibility:hidden;display: none">Hide</span>
                            </ng-template>
                        </span>
                    </p>

                </div>
            </div>

        </div>
    </div>
</div>


<!-- Modal -->
<ng-template #bookmark>
    <ng-container>
        <div class="modal-header" style="position: relative;">
            <p>Please Login to Continue</p>
            <!-- <p>HR OFFICER</p> -->
            <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <app-bookmark-job></app-bookmark-job>
            </div>
        </div>
    </ng-container>

</ng-template>
<!-- 
<div class="l-wrapper">


  <svg viewBox="0 0 120 120" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink">

    <symbol id="s--circle">
      <circle r="10" cx="20" cy="20"></circle>
    </symbol>

    <g class="g-circles g-circles--v4">
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
      <g class="g--circle">
        <use xlink:href="#s--circle" class="u--circle" />
      </g>
    </g>
  </svg>
</div> -->
<!-- <div class="spinner1">
</div> -->

<div id="loader-wrapper">
  <div id="loader"></div>
</div>

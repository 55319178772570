import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiServiceService } from '../../services/api-service.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private apiService: ApiServiceService,
    private toastr: ToastrService,
    private router : Router) { }

  token: string = '';
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.token = params['token'];
    })
    this.initilizeForm();
  }

  confirmForm: FormGroup;
  initilizeForm() {
    this.confirmForm = this.fb.group({
      newPassword: [null, [Validators.required, Validators.minLength(8)]],
      confirmPassword: [null, [Validators.required, Validators.minLength(8)]],
    },
      { validator: this.matchingPasswords("newPassword", "confirmPassword") }
    );
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: boolean } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value && confirmPassword.value) {

        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true
          };
        }
      }
    };
  }


  processing: boolean = false;
  onSubmit() {
    this.processing = true;
    if (this.confirmForm.valid) {
      const obj = {
        activationCode: this.token,
        newPassword: this.confirmForm.controls.newPassword.value,
        conformPassword: this.confirmForm.controls.confirmPassword.value
      }

      this.apiService.changePassword(obj).subscribe(
        (res: any) => {
          this.toastr.success('Successful', "Password changed successfully.");
          this.confirmForm.reset();
          this.processing = false;
          this.router.navigate(['/home']);
        },
        (error: any) => {
          this.toastr.error('Unsuccessful', error.error.errorMessage, {
            timeOut: 3000
          });
          this.confirmForm.reset();
          this.processing = false;
        }
      );
    }

  }
}

<div class="uefa-bg" [ngClass]="{'container-fixed-top ': checkLoginStatus()===false }">

    <div class="container">
        <div class="row">
            <div class="col-md-6 col-md-offset-3">
                <div class="row">
                    <div class="score-box col-md-12">
                        <div class="col-md-12 text-center">
                            <img src="/assets/images/football/logo_euro.png" alt="worldcup" style="width: 50%; margin-bottom: 20px;">
                            <p class="t2"> Predict the score and win exciting gift hampers</p>
                        </div>
                        <div class="clearfix"></div>

                        <div class="point row" *ngFor="let GameList of gameList; let i = index">
                        
                            <div class="col-md-5 col-xs-5 flex-box">
                                <div>
                                    <img [src]="GameList.countryAFlag" alt="{{GameList.countryAName}}" class="count">
                                    <span class="country-name">{{GameList.countryAName}}</span>
                                </div>
                                <input type="number" class="form-input " placeholder="" [(ngModel)]="scoreA[i]">
                            </div>

                            <div class="vs col-md-2 col-xs-1 text-center"> VS</div>

                            <div class="col-md-5 text-right col-xs-6 flex-box">
                                <div>
                                    <img [src]="GameList.countryBFlag" alt="{{GameList.countryBName}}" class="count">
                                    <span class="country-name">{{GameList.countryBName}}</span>
                                </div>
                          
                                <input type="number" class="form-input " placeholder="" [(ngModel)]="scoreB[i]">
                            </div>
                    
                           
                        </div>
                        <div *ngIf="gameList.length > 0 && !gameSpinner">
                            <button  (click)="submitForm()" class="btn-orange col-md-4  col-xs-offset-4">Predict Now</button>
                        </div>


                      

                        <ng-container *ngIf="gameList.length==0 && !gameSpinner">
                            <alert type="danger">
                                <p> <strong>Sorry !</strong> You can no longer predict for today's match.</p>
                                <span><small>Note*: Prediction can be made till one hour prior to the
                                        match.</small></span>
                            </alert>
                        </ng-container>

                        <ng-container *ngIf="gameSpinner" style="margin: 10px 0px;">

                            <app-football-spinner></app-football-spinner>
                            <!-- <div class="col-md-12 text-center" style="margin: 15px;">
                                <i class="fa fa-spinner fa-spin" aria-hidden="true" style="color: #00737e; font-size: 78px;"></i>
                            </div> -->
                        </ng-container>



                        <div class="col-md-12">
                            <div class="terms">
                                <p style="text-align: right;"> Terms And Conditions Applied</p>
                                <!-- <p>
                                    1. Must like Jobejee facebook page. <br>
                                    2. Must be a Nepali Citizen and details must match with your e-Sewa ID.<br>
                                    3. Must share the result on social media by tagging us with hashtags:

                                    <span style="white-space: pre;"> #JobejeeChampionship & #eurocup2021</span>
                                    <br>
                                    <br>
                                   <span style="white-space: pre;"> Note* : One Lucky winner will be selected randomly.</span>
                                </p> -->
                            </div>
                        </div>
                    </div>

                </div>



            </div>
        </div>
    </div>
    <div class="clearfix"></div>
</div>

<div class="clearfix"></div>
<app-footer></app-footer>
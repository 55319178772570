<!-- <div class="container-fluid container-fixed-top">
  <app-search-job></app-search-job>
</div> -->
<div class="container container-fixed-top">

  <div class="col-md-12 box">
    <div class="row">

      <!--Main heading-->
      <div class="col-md-12">
        <p class="title">About <span class="orange">Job</span> <span class="green">ejee</span> application</p>
      </div>

      <!--Image-->
      <div class="col-md-5 tb-30">
        <img src="../../../assets/images/responsive-web.png">
      </div>

      <!--Description-->
      <div class="col-md-7 tb-30">
        <p class="justify">Jobejee is the fastest growing job portal in Nepal. With Jobejee website and application,
          you can search and apply for jobs with optimal ease and also have a stress-free application period.
        </p>
        <p class="justify">Find your career opportunities at Jobejee! Search among thousands of jobs from top companies,
          industries
          and locations of your choice!
        </p>
        <p class="justify">Jobejee’s advanced features allows you to find your perfect job within minutes and apply for
          it with a
          single click. You are also able to easily assess your resume performance and track your applications.
        </p>
        <p>With Jobejee’s innovative features you can:</p>

        <!--List items-->
        <ul>
          <li>Find all your Newspaper Jobs in a single, searchable space.</li>
          <li>Receive relevant Job Recommendations. </li>
          <li>Create customized Job Alerts. </li>
          <li>Track your Application Status.</li>
          <li>Learn about your Applicant Ranking.</li>
        </ul>

        <p class="support">To learn more visit our FAQ section or email us at <a
            href="mailTo:support@jobejee.com">support@jobejee.com</a></p>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

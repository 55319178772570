import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  constructor(private router: Router, private route:ActivatedRoute) { }

  urlLastData: string;

  jsIdNumber;
  examFlag = false;
  ngOnInit() {
    let fullURl = this.router.url;
    let data = fullURl.split("/");
    this.urlLastData = data[data.length - 1];

    this.jsIdNumber = localStorage.getItem("jsInfoId");

    if (
      this.jsIdNumber == "9bfbd71d98fe82636e4b83d735e91d6f71f1896c" ||
      this.jsIdNumber == "e8fbbb9a83be16b643364992aa8b977539bfb317" ||
      this.jsIdNumber == "7fef636810b487a49c667c6ec8acc8048adb2e59" ||
      this.jsIdNumber == "f3da629736d9fb0077569adfc1ef24f6fce2f552" ||
      this.jsIdNumber == "4355b1cbc57661e0be10f9f04debcc8efdf89e86" ||
      this.jsIdNumber == "85ff10710e50484161e05b4fd9ffcf57793a9eec" ||
      this.jsIdNumber == "c4e78e970a1d488b27141ca30e6da0fabba7a9d3"
    ) {
      this.examFlag = true;
    }
  }

  profileUrl:any;
  getMatch() {

    let url = this.router.url;

    this.route.queryParams.subscribe(
      (res:any)=>{
        this.profileUrl=res['target']?res['target']:null;
      }
    )

    if (url.match(this.profileUrl))
      return true;
    else
      return false;
  }
}

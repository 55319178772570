import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "../../common/services/api-service.service";
import { RestApiService } from "../../common/services/rest-api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Toast, ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  constructor(
    private apiServiceService: ApiServiceService,
    private restApiService: RestApiService,
    private router: Router,
    private route: ActivatedRoute,
    public meta: Meta,
    public title: Title,
    private cookieService: CookieService,
    private fb: FormBuilder,
    private cookie: CookieService,
    private toastr: ToastrService
  ) {
    this.title.setTitle("Job Seeker Login | Jobejee");
    this.meta.updateTag({
      name: "keywords",
      content:
        "jobs in Nepal, jobsite in Nepal, job vacancy in Nepal, employment, recent job in nepal, IT jobs in Nepal, job opportunities in Nepal",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "Jobejee is the fastest growing job portal in Nepal. Find your career opportunities here! Search among thousands of jobs from top companies, industries and locations of your choice!",
    });
    this.meta.updateTag({
      name: "url",
      content: "https://www.jobejee.com/jobseeker/login",
    });
  }

  url: any;
  code: any;
  type: string = "password";
  ngOnInit() {
    this.url = this.router.url;
    if (this.url.match("js/verify-email")) {
      let data = this.url.split("=");
      this.code = data[1];
      this.verify();
    }

    this.initializeLoginForm();
  }

  changeType() {
    if (this.type == "text") this.type = "password";
    else this.type = "text";
  }

  verify() {
    this.apiServiceService.verifyEmai(this.code).subscribe(
      (res: any) => {
        this.cookie.set("emailVerified", "Y");
        this.toastr.success("Successful", "Email verified Successfully.");
        this.cookie.deleteAll("/");
      },
      (error: any) => {
        this.toastr.error("Unsuccessful", error.error.errorMessage, {
          timeOut: 3000,
        });
      }
    );
  }

  loginForm: FormGroup;
  initializeLoginForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      grant_type: ["password"],
    });
  }

  errorMsg: any;
  logging: boolean = false;
  login() {
    this.logging = true;
    const data = this.loginForm.value;
    const datas =
      "grant_type=password&username=" +
      data.username +
      "&password=" +
      data.password;
    this.apiServiceService.doLogin(datas).subscribe(
      (res: any) => {
        this.cookieService.set("at", res.access_token, 365, "/");
        this.cookieService.set("rt", res.refresh_token, 365, "/");
        this.getUserInfo();
      },
      (error: any) => {
        this.errorMsg = error.error.error_description;
        this.loginForm.controls.password.reset();
        this.logging = false;
        setTimeout(() => {
          this.errorMsg = null;
        }, 2000);
      }
    );
  }
  returnUrl: string;
  loginStatus: boolean = false;
  getUserInfo() {
    this.apiServiceService.userInfo().subscribe((res: any) => {
      this.returnUrl = this.route.snapshot.queryParams["returnUrl"];

      this.cookieService.set("userRole", res.role, 365, "/");
      if (res.role == "E") {
        this.cookieService.set("uR", "f2c1de33", 365, "/");
        this.cookieService.set("empId", res.empDetId, 365, "/");
        this.cookieService.set(
          "rap",
          res.resumeAccessPlan ? res.resumeAccessPlan : "N",
          365,
          "/"
        );
        this.cookieService.set("userId", res.userDetailId, 365, "/");
        this.cookieService.set("addInfoType", res.contactInfo, 365, "/");
        this.cookieService.set("emailVerified", res.emailVerified, 365, "/");
        this.cookieService.set(
          "mobileVerified",
          res.mobileVerified ? res.mobileVerified : "N",
          365,
          "/"
        );
        this.cookieService.set("tmpE", res.email, 365, "/");
        this.cookieService.set("username", res.companyName, 365, "/");
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
          return;
        }
        if (res.emailVerified == "N") {
          this.router.navigate(["/employer/employer-email-verification"]);
        } else {
          if (res.contactInfo == "Y")
            this.router.navigate(["/employer/dashboard"]);

          if (res.contactInfo == "N")
            this.router.navigate(["/employer/welcome"]);
        }
      } else if (res.role == "SE") {
        this.cookieService.set("ar", res.adminType, 365, "/");
        this.cookieService.set("uR", "f2c1da34", 365, "/");
        if (res.adminType == "SUPERVISOR") {
          this.cookieService.set("uTy", "sr112", 365, "/");
        } else if (res.adminType == "MANAGER") {
          this.cookieService.set("uTy", "mr112", 365, "/");
        } else {
          this.cookieService.set("uTy", "an112", 365, "/");
        }
        this.cookieService.set("userId", res.userDetailId, 365, "/");
        this.cookieService.set("username", res.email, 365, "/");
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
          return;
        }
        this.router.navigate(["/admin/dashboard"]);
      } else if (res.role == "JS") {
        this.cookieService.set("uR", "f2c1dj32", 365, "/");

        this.cookieService.set("userId", res.userDetailId, 365, "/");
        this.cookieService.set("emailVerified", res.emailVerified, 365, "/");
        this.cookieService.set(
          "mobileVerified",
          res.mobileVerified ? res.mobileVerified : "N",
          365,
          "/"
        );

        this.cookieService.set(
          "username",
          res.firstName + " " + res.lastName,
          365,
          "/"
        );
        this.cookieService.set("tmpE", res.email, 365, "/");
        if (this.returnUrl) {
          this.router.navigate([this.returnUrl]);
          return;
        }
        this.router.navigate(["/jobseeker/dashboard"]);
      }
      // this.cookieService.set('userRole', res.role, 365, '/');
      // if (res.role == "JS") {
      //   this.cookieService.set('uR', "f2c1dj32", 365, '/');
      // }

      // this.cookieService.set('userId', res.userDetailId, 365, '/');
      // this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
      // this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');

      // this.cookieService.set("username", res.firstName + ' ' + res.lastName, 365, '/');
      // this.cookieService.set('tmpE', res.email, 365, '/');

      // this.logging = false;
      // this.loginStatus = true;
      // this.router.navigate(['/jobseeker/dashboard']);
    });
  }

  checkLoginStatus() {
    if (this.cookie.get("at")) return true;
    else return false;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {

  constructor(private router: Router) { }

  urlLastData: string;
  examFlag: boolean;
  ngOnInit() {
    let fullURl = this.router.url.split("?");
    let data = fullURl[0].split("/");
    this.urlLastData = data[data.length - 1];
  }

  matchEmpUrl(){
    let url = this.router.url;
    if (url.match('/empDetail') || url.match('/admin/edit-basic') || url.match('/admin/edit-contact') )
      return true;
    else
      return false;
  }

  matchUrl() {
    let url = this.router.url;
    if (url.match('/job/edit') || url.match('/createJob') || url.match('/specific/jobcreate') || url.match('/manage/jobcreate') )
      return true;
    else
      return false;
  }

  getMatch(){
    let url = this.router.url;
    if (url.match('/newspaper-approval') )
      return true;
    else
      return false;
  }
}

<app-spinner1 *ngIf="loader"></app-spinner1>

<div class="container container-fixed-top" *ngIf="!loader" style="margin-bottom:25px;">
  <div class="row m25" *ngIf="jobsDetailObj">
    <div class="col-md-12 text-center">
      <alert type="success" class="calert" *ngIf="msg">
        {{ msg }}
      </alert>
    </div>

    <div class="col-md-12" *ngIf="jobsDetailObj.compBanner">
      <img src="{{ jobsDetailObj.compBanner }}" alt="jobsDetailObj.title" class="banner-img" />
    </div>

    <div class="col-md-12" *ngIf="!jobsDetailObj.compBanner">
      <div class="col-md-12 default-custom-banner">
        <p class="jobTitle" *ngIf="jobsDetailObj.empDetails == 'Y'">
          {{ jobsDetailObj.companyName }}
        </p>

        <div *ngIf="jobsDetailObj.empDetails == 'N'">
          <p class="f-alg f28 text-white" *ngIf="jobsDetailObj.companyType == 'PVT'">
            Private Company
          </p>
          <p class="f-alg f28 text-white" *ngIf="jobsDetailObj.companyType == 'PUB'">
            Public Company
          </p>
          <p class="f-alg f28 text-white" *ngIf="jobsDetailObj.companyType == 'GOV'">
            Government Organization
          </p>
          <p class="f-alg f28 text-white" *ngIf="jobsDetailObj.companyType == 'NGO'">
            NGO / INGO
          </p>
          <p class="f-alg f28 text-white" *ngIf="jobsDetailObj.companyType == 'CNS'">
            Consultant
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div class="col-md-12 company-bar">
        <div class="col-md-1 col-sm-2 col-xs-3 nopadding">
          <img *ngIf="jobsDetailObj.compLogo" class="company-logo-display" src="{{ jobsDetailObj.compLogo }}" />
        </div>
        <div class="col-md-11 col-xs-9" style="padding-top:6px;">
          <a href="" [routerLink]="[
              '/employer',
              replaceSpace(jobsDetailObj.companyName),
              jobsDetailObj.employerId
            ]" *ngIf="jobsDetailObj.empDetails == 'Y'" class="company-title">
            {{ jobsDetailObj.companyName }}
          </a>

          <div *ngIf="jobsDetailObj.empDetails == 'N'">
            <a *ngIf="jobsDetailObj.companyType == 'PVT'" class="company-title-black">
              Private Company
            </a>

            <a *ngIf="jobsDetailObj.companyType == 'PUB'" class="company-title-black">
              Public Company
            </a>

            <a *ngIf="jobsDetailObj.companyType == 'GOV'" class="company-title-black">
              Government
            </a>

            <a *ngIf="jobsDetailObj.companyType == 'NGO'" class="company-title-black">
              NGO / INGO
            </a>
            <a *ngIf="jobsDetailObj.companyType == 'CNS'" class="company-title-black">
              Consultant
            </a>
          </div>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>

    <div class="col-md-8">
      <div class="box-view col-md-12">
        <div class="col-md-12 box-view-inner">
          <p class="job-title text-ellipsis">
            {{ jobsDetailObj.title || "Not Available" }}
          </p>
          <p class=" text-ellipsis">
<<<<<<< HEAD
            <span class="fw500"><i class="fa fa-map-marker btn-icon-10"></i>Location:
            </span>{{ jobsDetailObj.location || "Not Available" }}
=======
            <span class="fw500"
              ><i class="fa fa-map-marker btn-icon-10"></i>Location: 
              </span>
              <span *ngIf="jobId == customJobId"> Thailand</span>
              <span *ngIf="jobId != customJobId">
                {{ jobsDetailObj.location || "Not Available" }}
              </span>
              
             
>>>>>>> origin/live
          </p>

          <div class="row">
            <div class="col-md-6">
              <p>
                <span class="fw500">
                  <i class="fa fa-hourglass-end btn-icon"></i> Job Type :
                </span>

                <span *ngIf="jobsDetailObj.jobTypeFlag == 'F'">Full Time</span>
                <span *ngIf="jobsDetailObj.jobTypeFlag == 'P'">Part Time</span>
                <span *ngIf="jobsDetailObj.jobTypeFlag == 'C'">Contract</span>
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <span class="fw500"><i class="fa fa-briefcase btn-icon"></i>Experience :
                </span>
                <span *ngIf="jobsDetailObj.expMin && jobsDetailObj.expMax">
                  {{ jobsDetailObj.expMin + " - " + jobsDetailObj.expMax }}
                  years
                </span>
                <span *ngIf="jobsDetailObj.expMin && !jobsDetailObj.expMax">
                  Upto {{ jobsDetailObj.expMin }} years
                </span>
                <span *ngIf="!jobsDetailObj.expMin && jobsDetailObj.expMax">
                  {{ jobsDetailObj.expMax }}+ years
                </span>
                <span *ngIf="jobsDetailObj.expType == 'F'">
                  Fresher
                </span>
                <span *ngIf="
                    !jobsDetailObj.expMin &&
                    !jobsDetailObj.expMax &&
                    jobsDetailObj.expType != 'F'
                  ">
                  Not Available
                </span>
              </p>
            </div>

            <div class="col-md-12">
              <p class="font-normal" *ngIf="
                  jobsDetailObj.hideCtc == 'N' && jobsDetailObj.ctcType == 'F'
                ">
                <span class="fw500"><i class="fa fa-money btn-icon"></i> Salary :
                </span>
                <span *ngIf="
                    jobsDetailObj.ctcLakhMin != 0 && jobsDetailObj.ctcLakhMin
                  ">{{ jobsDetailObj.ctcLakhMin }} Lakh</span>
                <span *ngIf="
                    jobsDetailObj.ctcThouMin != 0 && jobsDetailObj.ctcThouMin
                  ">
                  {{ jobsDetailObj.ctcThouMin }} Thousand</span>
                <span *ngIf="jobsDetailObj.salaryType == 'M'">(Monthly)</span>
                <span *ngIf="jobsDetailObj.salaryType == 'Y'">(Yearly)</span>
              </p>
              <p class="font-normal" *ngIf="
                  jobsDetailObj.hideCtc == 'N' && jobsDetailObj.ctcType == 'R'
                ">
                <span class="fw500"><i class="fa fa-money btn-icon"></i> Salary :
                </span>
                <span *ngIf="
                    jobsDetailObj.ctcLakhMin != 0 && jobsDetailObj.ctcLakhMin
                  ">{{ jobsDetailObj.ctcLakhMin }} Lakh</span>
                <span *ngIf="
                    jobsDetailObj.ctcThouMin != 0 && jobsDetailObj.ctcThouMin
                  ">
                  {{ jobsDetailObj.ctcThouMin }} Thousand</span>
                to
                <span *ngIf="
                    jobsDetailObj.ctcLakhMax != 0 && jobsDetailObj.ctcLakhMax
                  ">{{ jobsDetailObj.ctcLakhMax }} Lakh</span>
                <span *ngIf="
                    jobsDetailObj.ctcThouMax != 0 && jobsDetailObj.ctcThouMax
                  ">
                  {{ jobsDetailObj.ctcThouMax }} Thousand</span>
                <span *ngIf="jobsDetailObj.salaryType == 'M'">(Monthly)</span>
                <span *ngIf="jobsDetailObj.salaryType == 'Y'">(Yearly)</span>
              </p>

              <p class="font-normal" *ngIf="
                  jobsDetailObj.hideCtc == 'N' && jobsDetailObj.ctcType == 'N'
                ">
                <span class="fw500"><i class="fa fa-money btn-icon"></i> Salary :
                </span>
                Negotiable
              </p>

              <p class="nomargin font-normal" *ngIf="jobsDetailObj.hideCtc == 'Y'">
                <span class="fw500"><i class="fa fa-money btn-icon"></i> Salary :
                </span>
                Not Disclosed
              </p>
            </div>

            <div class="col-md-12">
              <span class="fw500"><i class="fa fa-lightbulb-o btn-icon"></i> Key Skills :
              </span>
              <label class="keyskills-small" *ngFor="
                  let tag of pushIntoArray(jobsDetailObj.keySkills);
                  let i;
                  of: index
                ">{{ tag || "Not Available" }}</label>
            </div>

            <div class="col-md-12" *ngIf="jobsDetailObj.question">
              <p>
                <span class="fw500">
                  <i class="fa fa-question-circle-o btn-icon"></i> Questionnaire
                  :</span>
                <a class="orange-link cursor">{{
                  jobsDetailObj.question || "Not Available"
                }}</a>
              </p>
            </div>

            <div class="col-md-6">
              <p>
                <span class="fw500">
                  <i class="fa fa-clock-o btn-icon"> </i> Posted On :</span>
                {{ jobsDetailObj.publishOn }}
              </p>
            </div>
            <div class="col-md-6">
              <p>
                <span class="fw500">
                  <i class="fa fa-calendar-check-o btn-icon"> </i> Valid until
                  :</span>
                {{ jobsDetailObj.jobExpiry }}
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4 box-view-footer">
          <i class="fa fa-thumbs-o-up btn-icon"></i>
          <!-- {{jobsDetailObj.likes || 0}} -->
        </div>
        <div class="col-md-4 col-sm-4 col-xs-4 box-view-footer cursor">
          <i class="fa fa-share-alt  btn-icon cursor" (click)="fbShare()"></i>
        </div>
        <div class="col-md-4 box-view-footer">
          <i class="fa fa-bookmark btn-icon"></i>
          <!-- {{jobsDetailObj.bookmarks || 0}} -->
        </div>
      </div>

      <!-- Job Description -->
      <div class="box-view col-md-12" *ngIf="jobsDetailObj.jobDesc">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Job Description
          </p>

          <div class="col-md-12">
            <div class="col-md-12" [innerHtml]="jobsDetailObj.jobDesc"></div>
          </div>
        </div>
      </div>

      <!-- desired candidate profile -->
      <div class="box-view col-md-12">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Desired Candidate Profile
          </p>

          <div class="col-md-5">
            <p class="fieldTitle">Qualifications Required</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue" *ngFor="let eduList of jobsDetailObj.eduList">
              <span><b>{{ eduList.degree }} </b></span>{{ eduList.spec ? "(" + eduList.spec + ")" : "" }}
              <br />
            </p>
          </div>
          <div class="clearfix"></div>

          <div class="col-md-5">
            <p class="fieldTitle">Preferred Age Range</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{
                jobsDetailObj.ageRangeFrom + " - " + jobsDetailObj.ageRangeTo
              }}
              years
            </p>
          </div>
          <div class="clearfix"></div>

          <div class="col-md-5">
            <p class="fieldTitle">Preferred Gender</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              <span *ngIf="jobsDetailObj.gender == 'A'">No Preferences</span>
              <span *ngIf="jobsDetailObj.gender == 'M'">Male</span>
              <span *ngIf="jobsDetailObj.gender == 'F'">Female</span>
            </p>
          </div>
        </div>
      </div>
      <!-- desired candidate profile -->

      <!--   Describe candidate profile -->
      <div class="box-view col-md-12" *ngIf="jobsDetailObj.candidProfDesc">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Describe Candidate Profile
          </p>

          <div class="col-md-12">
            <div class="col-md-12" [innerHtml]="jobsDetailObj.candidProfDesc"></div>
          </div>
        </div>
      </div>
      <!--   Describe candidate profile -->

      <!-- desired candidate profile -->
      <div class="box-view col-md-12">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Other Job Details
          </p>

          <div class="col-md-5">
            <p class="fieldTitle">Number of Vacancies</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{ jobsDetailObj.noVac || "Not
              Available" }}
            </p>
          </div>
          <div class="clearfix"></div>

          <div class="col-md-5">
            <p class="fieldTitle">Industry</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{ jobsDetailObj.industry || "Not
              Available" }}
            </p>
          </div>

          <div class="col-md-5">
            <p class="fieldTitle">Functional Area</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{ jobsDetailObj.funcArea || "Not
              Available" }}
            </p>
          </div>

          <div class="col-md-5">
            <p class="fieldTitle">Job Role/Designation</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{ jobsDetailObj.desig || "Not
              Available" }}
            </p>
          </div>

          <div class="col-md-5">
            <p class="fieldTitle">Cover Letter</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              <span *ngIf="jobsDetailObj.coverLetter == 'N'">Not Required</span>
              <span *ngIf="jobsDetailObj.coverLetter == 'Y'">Mandatory</span>
              <span *ngIf="!jobsDetailObj.coverLetter">Not Available</span>
            </p>
          </div>
        </div>
      </div>
      <!-- desired candidate profile -->

      <!--What we offer-->
      <div class="box-view col-md-12" *ngIf="jobsDetailObj.compOffer">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            What We Offer
          </p>

          <div class="col-md-12 nopadding">
            <div class="col-md-12" [innerHtml]="jobsDetailObj.compOffer"></div>
          </div>
        </div>
      </div>
      <!--What we offer-->

      <!-- Other Salary Details-->
      <div class="box-view col-md-12" *ngIf="jobsDetailObj.othCtcDet">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Other Salary Details
          </p>

          <div class="col-md-12">
            <div class="col-md-12" [innerHtml]="jobsDetailObj.othCtcDet"></div>
          </div>
        </div>
      </div>
      <!-- Other Salary Details-->

      <!-- desired candidate profile -->
      <div class="box-view col-md-12">
        <div class="col-md-12">
          <p class="text-ellipsis jtitle ">
            Instructions To Apply
          </p>

          <div class="col-md-5">
            <p class="fieldTitle">Apply Before</p>
          </div>
          <div class="col-md-7">
            <p class="fieldValue">
              {{ jobsDetailObj.jobExpiry || "Not
                Available" }}
            </p>
          </div>
          <div class="clearfix"></div>

          <div class="col-md-5">
            <p class="fieldTitle">Apply via</p>
          </div>
          <div class="col-md-7">
            <p class="controlLabelFontSupport">
              <span *ngIf="jobsDetailObj.appViaJobejee">Jobejee</span>
              <span *ngIf="jobsDetailObj.applyViaUrl">
                <a href="{{ jobsDetailObj.applyViaUrl }}" href="{{
                    jobsDetailObj.applyViaUrl.charAt(6) == '/'
                      ? jobsDetailObj.applyViaUrl
                      : 'http://' + jobsDetailObj.applyViaUrl
                  }}" target="_blank">{{ jobsDetailObj.applyViaUrl }}
                </a>
              </span>
              <span *ngIf="jobsDetailObj.appViaPost">{{
                jobsDetailObj.appViaPost
              }}</span>
              <span *ngIf="jobsDetailObj.appViaEmail">{{
                jobsDetailObj.appViaEmail
              }}</span>
              <span *ngIf="jobsDetailObj.appViaJobejee"></span>
            </p>
          </div>

          <div class="col-md-12" style="margin-top:10px;" [innerHtml]="jobsDetailObj.instrucApply"></div>
        </div>
      </div>
      <!-- desired candidate profile -->
    </div>

    <div class="col-md-4">
      <div class="col-md-12 box-view">
        <p class="text-ellipsis jtitle ">
          Job Action
        </p>

        <div *ngIf="
            jobsDetailObj.appViaJobejee == 'Y' &&
            jobsDetailObj.jobCurrentStatus == 'Running'
          ">
          <button class="btn btn-orange-transparent col-md-12 col-sm-5 col-xs-12" *ngIf="!jsInfoId"
            (click)="openModal(template)">
            APPLY JOB
          </button>

          <button *ngIf="
              jsInfoId && jobsDetailObj.applied == 'N' && jobId != customJobId
            " class=" btn btn-orange-transparent col-md-12 col-sm-5 col-xs-12" (click)="checkResume()">
            APPLY JOB
          </button>

          <button class="btn btn-orange-transparent col-md-12 col-sm-5 col-xs-12"
            *ngIf="jobId == customJobId && jobsDetailObj.applied == 'N'"
            (click)="openModalBank(templateBank); getBankData()">
            APPLY JOB
          </button>

          <div *ngIf="jobsDetailObj.applied == 'Y'">
            <p class="padding-top-15">
              <i class="fa fa-file-text-o" style="margin-right:5px;"> </i>
              <label class="font-500 text-center"> Status : </label>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'P'">In Progress</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'M'">In Progress
              </span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'UM'">In Progress</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'R'">Rejected</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR1'">Interview Round 1
              </span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR2'">Interview Round 2
              </span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR3'">Interview Round 3
              </span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'H'">On hold
              </span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'S'">Shortlisted</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'O'">Offer Offered</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'J'">Joined</span>
              <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'NTH'">In Progress</span>
            </p>
          </div>
        </div>

        <div *ngIf="jobsDetailObj.jobCurrentStatus == 'Expired'">
          <p class="padding-top-15">
            <i class="fa fa-file-text-o" style="margin-right:5px;"> </i>
            <label class="font-500 text-center"> Status : </label>
            <span class="text-orange">Expired</span>
          </p>
        </div>

        <div class="col-sm-12 nopadding" *ngIf="
            (jobsDetailObj.appViaJobejee == 'N' ||
              !jobsDetailObj.appViaJobejee) &&
            jobsDetailObj.jobCurrentStatus == 'Running'
          ">
          <label *ngIf="jobsDetailObj.appViaPost"> Apply Via Post </label>
          <a *ngIf="jobsDetailObj.applyViaUrl" href="{{ jobsDetailObj.applyViaUrl }}" target="_blank">Apply via company
            site</a>
        </div>

        <button class="btn btn-primary-transparent col-md-12 col-sm-6 col-xs-12 r-ml-5rem" [routerLink]="[
            '/employer',
            replaceSpace(jobsDetailObj.companyName),
            jobsDetailObj.employerId
          ]" *ngIf="jobsDetailObj.empDetails == 'Y'">
          More Jobs from this Company
        </button>
        <div class="clearfix"></div>
      </div>

      <div class="col-md-12 box-view res-box-view" style="padding-bottom:0px" *ngIf="similarList.length != 0">
        <p class="text-ellipsis jtitle ">
          Other Similar Job
        </p>

        <div class="row">
          <div class="col-md-12 col-sm-6 similar-box res-border" *ngFor="let jlist of similarList | slice: 0:8">
            <div class="col-md-3 col-xs-3 similar-box-container">
              <img src="{{ jlist.image }}" alt="{{ jlist.companyName }}" width="100%"
                *ngIf="jlist.image && jlist.empDetails == 'Y'" />
              <img src="../../../assets/images/building.png" alt="building" width="100%"
                *ngIf="!jlist.image || jlist.empDetails == 'N'" />
            </div>
            <div class="col-md-9 col-xs-9">
              <a [routerLink]="[
                  '/job',
                  replaceSpace(jlist.title),
                  jlist.jobCreateId
                ]" *ngIf="jlist.title" title="{{ jlist.title }}" class="similar-job-title">{{ jlist.title }} </a><br />

              <a href="" *ngIf="jlist.empDetails == 'Y'" [routerLink]="[
                  '/employer',
                  replaceSpace(jlist.companyName),
                  jlist.employerId
                ]" class="similar-job-company">{{ jlist.companyName }}</a>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <button type="button" class="btn btn-primary" *ngIf="!jsInfoId" (click)="openModal(template)">Create template
  modal</button> -->

<ng-template #template>
  <div class="modal-header c-m-head">
    <h4 class="modal-title pull-left">REGISTERED USER ?</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12 c-m-body">
        <div>
          <img src="../../assets/images/profile-circle.png" class="profile-circle" />
        </div>
        <p>Please login to your account to apply for this job</p>
        <button class="btn green-btn custom-margin" (click)="modalRef.hide()" [routerLink]="['/jobseeker/login']"
          [queryParams]="{ id: jobId, jn: jobTitle }">
          Login & Apply
        </button>
        <p class="sub-title">DONT HAVE AN ACCOUNT YET?</p>

        <div class="col-md-12">
          <p>
            Sign Up to get custom job alert, recommendations, personalized
            notification & many more.
          </p>
        </div>
      </div>
      <div class="form-horizontal col-md-12 text-center">
        <button class="btn green-btn" [routerLink]="['/jobseeker/register']" (click)="modalRef.hide()">
          Register & Apply
        </button>
        <button class="btn green-btn " style="margin-left:25px;" *ngIf="jobId != customJobId"
          (click)="modalRef.hide(); openModalFixed(templateFixed)">
          Apply without Registration
        </button>
      </div>
    </div>
  </div>
</ng-template>

<!-- 
<button type="button" class="btn btn-primary" (click)="openModal(templateFixed)">Open modal</button> -->

<ng-template #templateFixed>
  <div class="modal-header c-m-head ">
    <h4 class="modal-title pull-left">Application Form</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <alert type="success" class="calert" *ngIf="appmsg">
      {{ appmsg }}
    </alert>

    <form [formGroup]="candidateRegister" (ngSubmit)="registerCandidate()">
      <div class="form-group" [ngClass]="{
          'has-error':
            candidateRegister.get('firstName').touched &&
            candidateRegister.get('firstName').errors
        }">
        <label for="">First Name <span class="txt-validation">*</span></label>
        <input type="text" class="form-control" formControlName="firstName" placeholder="First Name" />
        <div *ngIf="
            candidateRegister.get('firstName').touched &&
            candidateRegister.get('firstName').invalid
          ">
          <span *ngIf="candidateRegister.get('firstName').errors.required">
            First Name is required
          </span>
        </div>
      </div>

      <div class="form-group" [ngClass]="{
          'has-error':
            candidateRegister.get('lastName').touched &&
            candidateRegister.get('lastName').errors
        }">
        <label for="">Last Name <span class="txt-validation">*</span></label>
        <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" />
        <div *ngIf="
            candidateRegister.get('lastName').touched &&
            candidateRegister.get('lastName').invalid
          ">
          <span *ngIf="candidateRegister.get('lastName').errors.required">
            Last Name is required
          </span>
        </div>
      </div>

      <div class="form-group" [ngClass]="{
          'has-error':
            candidateRegister.get('email').touched &&
            candidateRegister.get('email').errors
        }">
        <label for="">Email <span class="txt-validation">*</span></label>
        <input type="email" class="form-control" formControlName="email" placeholder="Email" autocomplete="off" />

        <div *ngIf="
            candidateRegister.get('email').touched &&
            candidateRegister.get('email').invalid
          ">
          <span *ngIf="candidateRegister.get('email').errors.required">
            Email is required
          </span>

          <span *ngIf="candidateRegister.get('email').errors.pattern">Invalid Email. Email format should be
            <b> alex10@gmail.com</b>
          </span>

          <div *ngIf="
              candidateRegister.get('email').errors &&
              candidateRegister.get('email').errors.emailTaken
            ">
            😢 Oh noes, this email is already taken!
          </div>
        </div>
      </div>

      <div class="form-group" [ngClass]="{
          'has-error':
            candidateRegister.get('skills').touched &&
            candidateRegister.get('skills').errors
        }">
        <label for="">Skills <span class="txt-validation">*</span></label>
        <input type="text" class="form-control" formControlName="skills" placeholder="Skills" />
        <div *ngIf="
            candidateRegister.get('skills').touched &&
            candidateRegister.get('skills').invalid
          ">
          <span *ngIf="candidateRegister.get('skills').errors.required">
            Skills is required
          </span>
        </div>
      </div>

      <div class="form-group">
        <label for="">Mobile No.</label>
        <div class="row">
          <div class="col-md-3" [ngClass]="{
              'has-error':
                candidateRegister.get('countryCode').touched &&
                candidateRegister.get('countryCode').errors
            }">
            <input type="text" class="form-control" formControlName="countryCode" required />

            <div *ngIf="
                candidateRegister.get('countryCode').touched &&
                candidateRegister.get('countryCode').invalid
              ">
              <span *ngIf="candidateRegister.get('countryCode').errors.required">Country Code is required
              </span>
            </div>
          </div>
          <div class="col-md-9" [ngClass]="{
              'has-error':
                candidateRegister.get('mobNo').touched &&
                candidateRegister.get('mobNo').errors
            }">
            <input type="text" class="form-control" minlength="10" maxlength="10" placeholder="Mobile No"
              formControlName="mobNo" required />

            <div *ngIf="
                candidateRegister.get('mobNo').touched &&
                candidateRegister.get('mobNo').invalid
              ">
              <span *ngIf="candidateRegister.get('mobNo').errors.required">Mobile Number is required</span>

              <span *ngIf="candidateRegister.get('mobNo').errors.pattern">Invalid Mobile Number</span>
              <span *ngIf="
                  !candidateRegister.get('mobNo').errors.pattern &&
                  candidateRegister.get('mobNo').errors.minlength
                ">Mobile Number should be minimum 10 digit.</span>
              <span *ngIf="
                  !candidateRegister.get('mobNo').errors.minlength &&
                  candidateRegister.get('mobNo').errors.maxlength
                ">Mobile Number should be maximum 10 digit.</span>

              <div *ngIf="
                  candidateRegister.get('mobNo').errors &&
                  candidateRegister.get('mobNo').errors.phoneTaken
                ">
                😢 Oh noes, this Mobile/Phone is already taken!
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group" [ngClass]="{
          'has-error':
            candidateRegister.get('resumeTitle').touched &&
            candidateRegister.get('resumeTitle').errors
        }">
        <label for="">Resume Title <span class="txt-validation">*</span></label>
        <input type="text" class="form-control" formControlName="resumeTitle" placeholder="Resume Title" />
        <div *ngIf="
            candidateRegister.get('resumeTitle').touched &&
            candidateRegister.get('resumeTitle').invalid
          ">
          <span *ngIf="candidateRegister.get('resumeTitle').errors.required">
            Resume Title is required
          </span>
        </div>
      </div>

      <div class="form-group relative">
        <label for="">Upload Resume</label>
        <input type="text" class="form-control" readonly value="{{ fileName }}" />

        <label class="fa fa-upload fileUploadIcon">
          <input type="file" file-model="myFile" id="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
            (change)="uploadFile($event)" />
        </label>
        <p>
          <span class="has-error" *ngIf="fileObj.fileStatus == 2">
            File size is greater then 3MB
          </span>
          <span class="has-error" *ngIf="fileObj.fileStatus == 3">
            File extension not allowed
          </span>
        </p>
      </div>

      <div class="form-group" (change)="changeProfile($event)">
        <label for="">Profile Type <span class="txt-validation">*</span></label><br />
        <label style="font-weight:normal; margin-top:5px; margin-bottom: 10px;">
          <input type="radio" value="F" formControlName="profileType" checked /><span class="radio_item">Fresher
            (Without Professional Experience)</span>
        </label>
        <br />

        <label style="font-weight:normal">
          <input type="radio" value="P" formControlName="profileType" /><span class="radio_item">Professional (With
            Professional Experience)</span>
        </label>
      </div>

      <div *ngIf="status == 'Professional'">
        <label for=""> Total Experience</label>

        <div class="row">
          <div class="col-md-4">
            <div class="form-group" [ngClass]="{
                'has-error':
                  candidateRegister.get('year').touched &&
                  candidateRegister.get('year').errors
              }">
              <label for="">Years <span class="txt-validation">*</span></label>
              <select class="form-control" formControlName="year">
                <option value="null">Select Years </option>
                <option value="0">0 </option>
                <option value="1">1 </option>
                <option value="2">2 </option>
                <option value="3">3 </option>
                <option value="4">4 </option>
                <option value="5">5 </option>
                <option value="6">6 </option>
                <option value="7">7 </option>
                <option value="8">8 </option>
                <option value="9">9 </option>
                <option value="10">10 </option>
                <option value="11">11 </option>
                <option value="12">12 </option>
                <option value="13">13 </option>
                <option value="14">14 </option>
                <option value="15">15 </option>
                <option value="16">16 </option>
              </select>
              <div *ngIf="
                  candidateRegister.get('year').touched &&
                  candidateRegister.get('year').invalid
                ">
                <span *ngIf="candidateRegister.get('year').errors.required">
                  Experience Years is required
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group" [ngClass]="{
                'has-error':
                  candidateRegister.get('month').touched &&
                  candidateRegister.get('month').errors
              }">
              <label for="">Months <span class="txt-validation">*</span></label>
              <select class="form-control" formControlName="month">
                <option value="null">Select Month </option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
              <div *ngIf="
                  candidateRegister.get('month').touched &&
                  candidateRegister.get('month').invalid
                ">
                <span *ngIf="candidateRegister.get('month').errors.required">
                  Month is required
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group" [ngClass]="{
            'has-error':
              candidateRegister.get('industryId').touched &&
              candidateRegister.get('industryId').errors
          }">
          <label for="">Industry <span class="txt-validation">*</span></label>
          <select class="form-control" formControlName="industryId">
            <option value="null">Select Industry</option>
            <option *ngFor="let industryList of IndustryList" [value]="industryList.industryId">
              {{ industryList.industryName }}</option>
          </select>
          <div *ngIf="
              candidateRegister.get('industryId').touched &&
              candidateRegister.get('industryId').invalid
            ">
            <span *ngIf="candidateRegister.get('industryId').errors.required">
              Industry is required
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{
            'has-error':
              candidateRegister.get('funcId').touched &&
              candidateRegister.get('funcId').errors
          }">
          <label for="">Functional Area <span class="txt-validation">*</span></label>
          <select class="form-control" formControlName="funcId">
            <option value="null">Select Functional Area</option>
            <option *ngFor="let FunctionalList of functionalList" [value]="FunctionalList.functionalId">
              {{ FunctionalList.functionalName }}</option>
          </select>
          <div *ngIf="
              candidateRegister.get('funcId').touched &&
              candidateRegister.get('funcId').invalid
            ">
            <span *ngIf="candidateRegister.get('funcId').errors.required">
              Functional Area is required
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{
            'has-error':
              candidateRegister.get('desigId').touched &&
              candidateRegister.get('desigId').errors
          }">
          <label for="">Designation <span class="txt-validation">*</span></label>
          <select class="form-control" formControlName="desigId">
            <option value="null">Select Designation</option>
            <option [value]="DesigDesireList.designationId" *ngFor="let DesigDesireList of desigDesireList">
              {{ DesigDesireList.designationName }}</option>
          </select>
          <div *ngIf="
              candidateRegister.get('desigId').touched &&
              candidateRegister.get('desigId').invalid
            ">
            <span *ngIf="candidateRegister.get('desigId').errors.required">
              Designation is required
            </span>
          </div>
        </div>

        <!-- 


        <div class="form-group"
          [ngClass]="{'has-error' : (candidateRegister.controls.desigId.touched && candidateRegister.controls.desigId.errors)}">
          <label>Designation <span class="txt-validation">*</span> </label>

          <select class="form-control" formControlName="desigId">
            <option value=null>Select Designation</option>
            <option [value]="DesigDesireList.designationId" *ngFor="let DesigDesireList of desigDesireList">
              {{DesigDesireList.designationName}}</option>
          </select>

          <div *ngIf="candidateRegister.controls.desigId.touched && candidateRegister.controls.desigId.invalid">
            <span *ngIf="candidateRegister.controls.desigId.errors.required">Designation is required
            </span>
          </div>
        </div> -->

        <div class="form-group" [ngClass]="{
            'has-error':
              candidateRegister.get('companyName').touched &&
              candidateRegister.get('companyName').errors
          }">
          <label for="">Company Name <span class="txt-validation">*</span></label>
          <input type="text" class="form-control" formControlName="companyName" placeholder="Company Name" />
          <div *ngIf="
              candidateRegister.get('companyName').touched &&
              candidateRegister.get('companyName').invalid
            ">
            <span *ngIf="candidateRegister.get('companyName').errors.required">
              Company Name is required
            </span>
          </div>
        </div>

        <div class="form-group" [ngClass]="{
            'has-error':
              candidateRegister.get('noticePeriod').touched &&
              candidateRegister.get('noticePeriod').errors
          }">
          <label for="">Notice Period <span class="txt-validation">*</span></label>
          <select class="form-control" formControlName="noticePeriod">
            <option value="">Select Days</option>
            <option value="1">Immediately</option>
            <option value="7">7 Days</option>
            <option value="15">15 Days</option>
            <option value="30">1 Month</option>
            <option value="60">2 Months</option>
            <option value="90">3 Months</option>
          </select>
          <div *ngIf="
              candidateRegister.get('noticePeriod').touched &&
              candidateRegister.get('noticePeriod').invalid
            ">
            <span *ngIf="candidateRegister.get('noticePeriod').errors.required">
              Notice Period is required
            </span>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <div class="col-md-12">
              <label> Expected Salary</label>
            </div>

            <div class="col-md-3">
              <label>
                <input type="radio" formControlName="salaryType" value="M" />
                Monthly
              </label>
            </div>

            <div class="col-md-3">
              <label>
                <input type="radio" formControlName="salaryType" value="Y" />
                Yearly
              </label>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <div class="form-group" [ngClass]="{
                'has-error':
                  candidateRegister.get('annualSalLakh').touched &&
                  candidateRegister.get('annualSalLakh').errors
              }">
              <select class="form-control" formControlName="annualSalLakh">
                <option value="null">Select Lakh </option>
                <option *ngFor="let LakhSal of lakhSal" [value]="LakhSal.val">{{ LakhSal.display }}
                </option>
              </select>
              <div *ngIf="
                  candidateRegister.controls.annualSalLakh.touched &&
                  candidateRegister.controls.annualSalLakh.invalid
                ">
                <span *ngIf="
                    candidateRegister.controls.annualSalLakh.errors.required
                  ">
                  Salary is required
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group" [ngClass]="{
                'has-error':
                  candidateRegister.get('annualSalThousand').touched &&
                  candidateRegister.get('annualSalThousand').errors
              }">
              <select class="form-control" formControlName="annualSalThousand">
                <option value="null">Select Thousand </option>
                <option *ngFor="let thouSal of ThouSal" [value]="thouSal.val">{{ thouSal.display }}
                </option>
              </select>
              <div *ngIf="
                  candidateRegister.controls.annualSalThousand.touched &&
                  candidateRegister.controls.annualSalThousand.invalid
                ">
                <span *ngIf="
                    candidateRegister.controls.annualSalThousand.errors.required
                  ">
                  Salary is required
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label class="checkbox-field"><input type="checkbox" [(ngModel)]="checkbox"
            [ngModelOptions]="{ standalone: true }" />
          I agree to Jobejee
          <a [routerLink]="['/terms-and-conditions']">
            Terms and Conditions.</a></label>
      </div>

      <div class="row" style="text-align:center">
        <div class="col-md-12">
          <button class="btn green-btn" [disabled]="
              candidateRegister.invalid ||
              checkbox == false ||
              clicked ||
              !fileValid
            ">
            Apply Now
          </button>
        </div>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #templateBank>
  <div class="modal-header c-m-head ">
    <h4 class="modal-title pull-left">Required Document</h4>
    <button type="button" class="close pull-right" id="closeDocument" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!-- <alert type="success" class="calert" *ngIf="appmsg">
      {{appmsg}}
    </alert> -->

    <form [formGroup]="bankJob" (ngSubmit)="registerBankJob()">
      <div class="col-md-12 notice">
        <p>
          All <span class="txt-validation">*</span> fields are mandatory.
          Document size must be less then 3MB.
        </p>
      </div>

      <div class="col-md-12">
        <div class="row custom-space">
          <div class="col-md-3">
            <label for="">Upload photo <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-9">
            <input type="file" file-model="myFile" accept="image/*" class="inputHidden"
              (change)="uploadPhoto($event)" />

            <div *ngIf="uploadedPhotoName" style="margin-top:10px; color:green">
              Upload File: <span></span>{{ uploadedPhotoName }}
            </div>

            <p class="nomargin">
              <span class="has-error" *ngIf="photoObj.fileStatus == 2">
                File size is greater then 3MB
              </span>
              <span class="has-error" *ngIf="photoObj.fileStatus == 3">
                File extension not allowed
              </span>
            </p>
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for="">
              Citizenship <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-9">
            <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
              (change)="uploadCitizen($event)" />

            <div *ngIf="uploadedcitizenName" style="margin-top:10px; color:green">
              Upload File: <span></span>{{ uploadedcitizenName }}
            </div>

            <p class="nomargin">
              <span class="has-error" *ngIf="citizenObj.fileStatus == 2">
                File size is greater then 3MB
              </span>
              <span class="has-error" *ngIf="citizenObj.fileStatus == 3">
                File extension not allowed
              </span>
            </p>
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for="">
              +2 Transcript <span class="txt-validation">*</span></label>
          </div>

          <div class="col-md-9 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="uploadMarksheetI($event)" />

              <div *ngIf="uploadedmarksheetIName" style="margin-top:10px; color:green">
                Upload File: <span></span>{{ uploadedmarksheetIName }}
              </div>
              <p class="nomargin">
                <span class="has-error" *ngIf="marksheetI.fileStatus == 2">
                  File size is greater then 3MB
                </span>
                <span class="has-error" *ngIf="marksheetI.fileStatus == 3">
                  File extension not allowed
                </span>
              </p>
            </div>

            <select class="form-control" formControlName="plusTwoFaculty" style="margin-right:5px">
              <!-- <option value="" selected>select Faculty</option> -->
              <option value="" disabled selected hidden>Select Faculty</option>
              <option values="Science">Science</option>
              <option values="Management">Management</option>
              <option values="Humanities">Humanities</option>
              <option values="Education">Education</option>
            </select>

            <select class="form-control" formControlName="plusTwoPercentType" (change)="markIChange($event)"
              style="margin-right:5px; margin-left:5px;">
              <option value="Percentage">Percentage</option>
              <option value="GPA">GPA</option>
            </select>

            <input class="form-control" type="number" formControlName="plusTwoMarks" style="margin-left:5px"
              placeholder="{{ markIPlaceholder }}" />
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for=""> Bachelor's Transcript</label>
          </div>
          <div class="col-md-9 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="uploadMarksheetII($event)" />

              <div *ngIf="uploadedmarksheetIIName" style="margin-top:10px; color:green">
                Upload File: <span></span>{{ uploadedmarksheetIIName }}
              </div>
              <p class="nomargin">
                <span class="has-error" *ngIf="marksheetII.fileStatus == 2">
                  File size is greater then 3MB
                </span>
                <span class="has-error" *ngIf="marksheetII.fileStatus == 3">
                  File extension not allowed
                </span>
              </p>
            </div>
            <select class="form-control" style="margin-right:7px" (change)="markIIChange($event)"
              formControlName="bachelorsPercentType">
              <option>Percentage</option>
              <option>GPA</option>
            </select>
            <input class="form-control" type="number" formControlName="bachelorsMarks" style="margin-left:7px"
              placeholder="{{ markIIPlaceholder }}" />
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for=""> Master's Transcript</label>
          </div>
          <div class="col-md-9 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="uploadMarksheetIII($event)" />

              <div *ngIf="uploadedmarksheetIIIName" style="margin-top:10px; color:green">
                Upload File: <span></span>{{ uploadedmarksheetIIIName }}
              </div>
              <p class="nomargin">
                <span class="has-error" *ngIf="marksheetIII.fileStatus == 2">
                  File size is greater then 3MB
                </span>
                <span class="has-error" *ngIf="marksheetIII.fileStatus == 3">
                  File extension not allowed
                </span>
              </p>
            </div>

            <select class="form-control" style="margin-right:7px" (change)="markIIIChange($event)"
              formControlName="msPercentType">
              <option>Percentage</option>
              <option>GPA</option>
            </select>
            <input class="form-control" type="number" style="margin-left:7px" formControlName="msMarks"
              placeholder="{{ markIIIPlaceholder }}" />
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for=""> Banking Experience 1</label>
          </div>
          <div class="col-md-9 horiantal-field">
            <!-- <input type="file" name="myFile"> -->

            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="bankingExpI($event)" />

              <div *ngIf="bankingExpName" style="margin-top:10px; color:green">
                Upload File: <span></span>{{ bankingExpName }}
              </div>
              <p class="nomargin">
                <span class="has-error" *ngIf="bankingExp.fileStatus == 2">
                  File size is greater then 3MB
                </span>
                <span class="has-error" *ngIf="bankingExp.fileStatus == 3">
                  File extension not allowed
                </span>
              </p>
            </div>

            <input class="form-control" type="text" formControlName="exp1Name" style="margin-right:7px"
              placeholder="Enter Bank Name" />

            <select class="form-control" formControlName="exp1years" style="margin-left:7px">
              <option>Experience Years</option>
              <option>0-1 Years</option>
              <option>1-2 Years</option>
              <option>2-3 Years</option>
              <option>3-4 Years</option>
              <option>4-5 Years</option>
              <option>6-7 Years</option>
              <option>7-8 Years</option>
            </select>
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for=""> Banking Experience 2</label>
          </div>
          <div class="col-md-9 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="bankingExpsII($event)" />

              <div *ngIf="bankingExpIIName" style="margin-top:10px; color:green">
                Upload File: <span></span>{{ bankingExpIIName }}
              </div>
              <p class="nomargin">
                <span class="has-error" *ngIf="bankingExpII.fileStatus == 2">
                  File size is greater then 3MB
                </span>
                <span class="has-error" *ngIf="bankingExpII.fileStatus == 3">
                  File extension not allowed
                </span>
              </p>
            </div>

            <input class="form-control" type="text" formControlName="exp2Name" style="margin-right:7px"
              placeholder="Enter Bank Name" />

            <select class="form-control" formControlName="exp2years" style="margin-left:7px">
              <option>Experience Years</option>
              <option>0-1 Years</option>
              <option>1-2 Years</option>
              <option>2-3 Years</option>
              <option>3-4 Years</option>
              <option>4-5 Years</option>
              <option>6-7 Years</option>
              <option>7-8 Years</option>
            </select>
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for="">Training Certificate</label>
          </div>
          <div class="col-md-4 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="traningCertificateUpload($event)" />
            </div>
          </div>

          <div class="col-md-2">
            <label for="">CV Upload <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-3 horiantal-field">
            <div>
              <input type="file" file-model="myFile" accept="doc, .docx, application/pdf" class="inputHidden"
                (change)="cvuploads($event)" />
            </div>
          </div>

          <div class="col-md-7">
            <div *ngIf="traningCertificateName" style="margin-top:10px; color:green">
              Upload File: <span></span>{{ traningCertificateName }}
            </div>
            <p class="nomargin">
              <span class="has-error" *ngIf="traningCertificate.fileStatus == 2">
                File size is greater then 3MB
              </span>
              <span class="has-error" *ngIf="traningCertificate.fileStatus == 3">
                File extension not allowed
              </span>
            </p>
          </div>

          <div class="col-md-5">
            <div *ngIf="cvUploadName" style="margin-top:10px; color:green">
              Upload File: <span></span>{{ cvUploadName }}
            </div>
            <p class="nomargin">
              <span class="has-error" *ngIf="cvUpload.fileStatus == 2">
                File size is greater then 3MB
              </span>
              <span class="has-error" *ngIf="cvUpload.fileStatus == 3">
                File extension not allowed
              </span>
            </p>
          </div>
        </div>

        <div class="row custom-space">
          <div class="col-md-3">
            <label for=""> Age <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-4 cunopadding">
            <input class="form-control" formControlName="age" type="number" placeholder="Enter Age" />
          </div>
        </div>

        <div class="row custom-space" style="border-bottom: 0px;">
          <div class="col-md-3">
            <label for="">
              Preferred Location(Written Exam)
              <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-4 cunopadding">
            <select class="form-control" formControlName="prefLocation">
              <option>Biratnagar</option>
              <option>Butwal</option>
              <option>Dhangadi</option>
              <option>Narayanghat</option>
              <option>Nepalgunj</option>
            </select>
          </div>
        </div>

        <div class="row custom-space" style="border-bottom: 0px;">
          <div class="col-md-3">
            <label for="">
              Applied Province <span class="txt-validation">*</span></label>
          </div>
          <div class="col-md-4 cunopadding">
            <select class="form-control" formControlName="province">
              <option value="Province 1">Province 1</option>
              <option value="Province 2">Province 2</option>
              <option value="Province 3">Province 3 (Except Kathmandu)</option>
              <option value="Province 4">Province 4</option>
              <option value="Province 5">Province 5</option>
              <option value="Province 6">Province 6</option>
              <option value="Province 7">Province 7</option>
              <option value="Kathmandu">Kathmandu Valley</option>
            </select>
          </div>
        </div>

        <div class="row custom-space" style="border-bottom: 0px;">
          <div class="col-md-12 cunopadding">
            <label for="">
              Are you a local candidate of selected Province
              <span class="txt-validation">*</span></label>
          </div>

          <div class="col-md-6 cunopadding">
            <label class="radio-inline"><input type="radio" formControlName="localCandidateTrue"
                (click)="changeLocal($event)" value="Yes" checked />Yes</label>
            <label class="radio-inline"><input type="radio" formControlName="localCandidateTrue"
                (click)="changeLocal($event)" value="No" />No</label>
          </div>

          <div class="col-md-6 nopadding" style="display: flex; align-items:center;"
            *ngIf="bankJob.get('localCandidateTrue').value == 'Yes'">
            <div class="col-md-4 cunopadding">
              <label for="">
                Local Address (District) <span class="txt-validation">*</span>
              </label>
            </div>
            <div class="col-md-8 cunopadding">
              <input class="form-control" formControlName="localCandidate" type="text" placeholder="Your Address" />
            </div>
          </div>
        </div>
      </div>

      <div class="clearfix"></div>

      <div class="row text-right">
        <button class="btn green-btn" style="margin-right:25px" [disabled]="bankJob.invalid">
          Apply Job
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- Apply without Register -->

<!-- <button type="button" class="btn btn-primary" *ngIf="!jsInfoId" (click)="staticModal.show()">Apply Without
  template modal</button>

<div class="modal fade" bsModal #staticModal="bs-modal" [config]="{backdrop: 'static'}" tabindex="-1" role="dialog"
  aria-labelledby="dialog-static-name">
  <div class="modal-header c-m-head">
    <h4 class="modal-title pull-left">REGISTERED USER ?</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">

      <div class="col-md-12">
        <div class="reg_form">

         
        </div>
      </div>

    </div>
  </div>
</div> -->

<div class="container-fluid footer-flud" *ngIf="!loader">
  <div class="row apply-footer">
    <div *ngIf="
        jobsDetailObj.appViaJobejee == 'Y' &&
        jobsDetailObj.jobCurrentStatus == 'Running'
      ">
      <!-- <button class="btn btn-orange " *ngIf="!jsInfoId" [routerLink]="['/jobseeker/login']"
        [queryParams]="{id:jobId, jn:jobTitle}">APPLY JOB</button> -->

      <button class="btn btn-orange " *ngIf="!jsInfoId" (click)="openModal(template)">
        APPLY JOB
      </button>

      <button *ngIf="jsInfoId && jobsDetailObj.applied == 'N' && jobId != customJobId" class=" btn btn-orange"
        (click)="checkResume()">
        APPLY JOB
      </button>

      <button class="btn btn-orange" *ngIf="jobId == customJobId && jobsDetailObj.applied == 'N'"
        (click)="openModalBank(templateBank); getBankData()">
        APPLY JOB
      </button>

      <div *ngIf="jobsDetailObj.applied == 'Y'">
        <p class="padding-top-15">
          <i class="fa fa-file-text-o" style="margin-right:5px;"> </i>
          <label class="font-500 text-center"> Status : </label>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'P'">In Progress</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'M'">In Progress
          </span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'UM'">In Progress</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'R'">Rejected</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR1'">Interview Round 1
          </span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR2'">Interview Round 2
          </span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'IR3'">Interview Round 3
          </span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'H'">On hold
          </span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'S'">Shortlisted</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'O'">Offer Offered</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'J'">Joined</span>
          <span class="text-orange" *ngIf="jobsDetailObj.jsStatus == 'NTH'">In Progress</span>
        </p>
      </div>
    </div>

    <div class="col-sm-12 nopadding" *ngIf="
        jobsDetailObj.appViaJobejee == 'N' ||
        !jobsDetailObj.appViaJobejee ||
        jobsDetailObj.jobCurrentStatus == 'Running'
      ">
      <label *ngIf="jobsDetailObj.appViaPost"> Apply Via Post </label>
      <a *ngIf="jobsDetailObj.applyViaUrl" href="{{ jobsDetailObj.applyViaUrl }}" target="_blank">Apply via company
        site</a>
    </div>

    <div *ngIf="jobsDetailObj.jobCurrentStatus == 'Expired'">
      <p class="padding-top-15">
        <i class="fa fa-file-text-o" style="margin-right:5px;"> </i>
        <label class="font-500 text-center"> Status : </label>
        <span class="text-orange">Expired</span>
      </p>
    </div>

    <!-- <div class="btn btn-orange">APPLY JOB</div> -->
  </div>
  <div class="clearfix"></div>
</div>
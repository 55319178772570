import { Pipe, PipeTransform, Injectable } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'dateFormat'
})
@Injectable()

export class DatetimePipe implements PipeTransform {

  transform(date: any, format: string): any {
    if (date) {
     return moment(date).format(format);
    }
  }

}

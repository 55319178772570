import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { ApiServiceService } from 'src/app/common/services/api-service.service';

@Component({
  selector: 'app-employment-index',
  templateUrl: './employment-index.component.html',
  styleUrls: ['./employment-index.component.scss']
})
export class EmploymentIndexComponent implements OnInit {


  constructor(
    private apiServiceService: ApiServiceService,
    private cookie:CookieService,

    private modalService: BsModalService,
  ) { }

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg"
  };

  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  ngOnInit(): void {

    this.getEmpIndex();
  }

  indexResponse: any = [];
  finalObj:any=[];
  yearList:any=[];
  spinner:boolean=false;
  getEmpIndex() {
    this.spinner=true;
    this.apiServiceService.empIndex().subscribe(
      (res: any) => {
        this.indexResponse = res.data;

        let group = this.indexResponse.reduce((r, a) => {
         
          r[a.year] = [...r[a.year] || [], a];
          return r;
         }, {});

         this.yearList= Object.values(group);


         console.log(this.yearList)
         this.spinner=false;

      }
    )
  }

  // yearWiseDetail: any = [];
  // yearWiseSpinner: boolean = false;
  // getEmploymentFromYear(year) {
  //   this.yearWiseSpinner = true;
  //   this.apiServiceService.logHistoryByYear(year).subscribe(
  //     (res: any) => {
  //       this.yearWiseDetail = res;
  //       this.yearWiseSpinner = false;
  //     }
  //   )
  // }


  // monthList: any = [];
  // monthSpinner: boolean = false;
  // monthName: any;
  // getEmploymentFromMonth(year, month, name) {
  //   this.monthSpinner = true;
  //   this.monthName = name
  //   this.apiServiceService.employmentIndexByMonth(year, month).subscribe(
  //     (res: any) => {
  //       this.monthList = res;
  //       this.monthSpinner = false;
  //     }
  //   )
  // }


  // closeModal() {
  //   this.modalRef.hide();

  // }


  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }
}


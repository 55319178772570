
<div class="container-fluid navbar-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}">
    <app-search-job></app-search-job>
  </div>

<div class="container ">
    <div class="row">
        <div class="col-md-9 box">
            <div class="col-md-12  header">
                <p>Jobejee Employment Index</p>
            </div>



            <ng-container *ngIf="!spinner">
                <div class="col-md-12 nopadding" style="margin-top: 15px;">
                    <accordion [closeOthers]="true" *ngFor="let item of yearList.slice().reverse()">
                        <accordion-group>
                            <!-- <button class="btn btn-link btn-block clearfix" accordion-heading type="button"
                            (click)="getEmploymentFromYear(item.year)"> -->
                            <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                                <div class="pull-left float-left " style="color: #313030; font-weight: 500;">
                                    {{item[0].fromDate | date :'yyyy'}}
                                </div>

                            </button>

                            <ng-container *ngFor="let items of item">

                                <div class="col-md-12 nopadding data" style="margin-bottom: 15px;">


                                    <div class="col-lg-6 col-md-6 nopadding">
                                        <p>{{items.fromDate | date:'MMMM' }} - {{items.toDate | date:'MMMM'}}</p>
                                    </div>




                                    <div class="col-lg-6 text-right nopadding ml">
                                        <a [href]="items.url" target="_blank">View</a>
                                    </div>

                                </div>

                            </ng-container>






                        </accordion-group>

                    </accordion>
                </div>
            </ng-container>

            <ng-container *ngIf="spinner">

                <div class="col-md-12">
                    <app-jobejee-loader></app-jobejee-loader>
                </div>
            </ng-container>

        </div>


        <div class="col-md-3 " style="margin-top:26px"> 
            <a href="https://jobejee.s3-ap-southeast-1.amazonaws.com/EmploymentIndex/GTS+Report+Final.pdf" target="_blank">
            
                <img src="../../../assets/images/globaltalent.png" alt="" style="max-width:248px">
            </a>
        </div>


    </div>
</div>
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  phoneNo: "01-4970596",
  email: "support@jobejee.com",

  // allUrl: "https://api.v1.jobejee.com/rest/",
  // token: "https://api.v1.jobejee.com/oauth/token"

  // allUrl: "http://192.168.205:8585/rest/",
  // token: "http://192.168.205:8585/oauth/token"

  // ALina
  // allUrl: "http://192.168.0.211:8080/v2/",
  // token: "http://192.168.0.211:8080/oauth/token"

  // live server
  allUrl: "https://api.v1.jobejee.com/v2/",
  token: "https://api.v1.jobejee.com/v2/oauth/token",

  // allUrl: "http://13.229.130.25:80/v2/",
  // token: "http://13.229.130.25:80/v2/oauth/token",

  // test sever
  // allUrl: "http://api.test.jobejee.com/v2/",
  // token: "http://api.test.jobejee.com/v2/oauth/token"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<div class="container-fluid navbar-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}"
  *ngIf="jobsDetailObj">
  <div class="row fullview">
    <div class="container">

      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 nopadding banner-box">
            <img
              src="{{jobsDetailObj.bannerImage ? jobsDetailObj.bannerImage : '../../../../../assets/images/login-banner.jpg'}}"
              alt="">
          </div>
        </div>




        <div class="row">
          <div class="col-md-9 nopadding" >
            <div class="flex-box bg-white" style="justify-content: space-between; align-items: center;">
              <div>
                <div class="flex-box">
                  <div class="ppimg">
                    <img *ngIf="jobsDetailObj.compLogo" src="{{jobsDetailObj.compLogo}}" alt="">
                    <img *ngIf="!jobsDetailObj.compLogo" src="../../../assets/images/default-comp.jpeg" alt="">
                  </div>
                  <div class="pp-info">
                    <p class="f-16-header" *ngIf="jobsDetailObj">{{jobsDetailObj.title || 'Loading...'}}</p>
                    <p *ngIf="jobsDetailObj"><a
                        [routerLink]="['/employer',  replaceSpace(splitcompany(jobsDetailObj.companyName, 0)),jobsDetailObj.employerId ]"
                        class="green-orange-link fw500 noDecoration">
                        {{jobsDetailObj.companyName || 'Loading...'}}
                      </a></p>
                    <p *ngIf="jobsDetailObj"><span>Vacancy :</span> {{jobsDetailObj.noVac || 'Loading...'}}</p>
                    <p *ngIf="jobsDetailObj && jobsDetailObj.jobLocation"> <span>Location : </span>

                      <ng-container *ngIf="jobsDetailObj.province=='All Over Nepal'">
                        <span *ngIf="jobsDetailObj.province" class="fwn">{{jobsDetailObj.province }}</span>
                        <span *ngIf="jobsDetailObj.jobLocation" class="fwn"> ({{jobsDetailObj.jobLocation }})</span>
                      </ng-container>

                      <ng-container *ngIf="jobsDetailObj.province!='All Over Nepal'">

                        <span class="fwn">{{jobsDetailObj.jobLocation}}</span>
                        <span *ngIf="jobsDetailObj.city" class="fwn">, {{jobsDetailObj.city }}</span>
                        <span *ngIf="jobsDetailObj.province" class="fwn">,
                          {{jobsDetailObj.province || 'Not Available'}}</span>
                        <span class="fwn">, {{jobsDetailObj.country || jobsDetailObj.jobLocation.interLocation}}</span>


                      </ng-container>

                    </p>

                  </div>
                </div>
              </div>

              <div class="pp-right">
                <ng-container *ngIf="jobsDetailObj.applied=='Y'; else notApplied">
                  <button class="applied" style="margin-bottom: 8px;">
                    Job Applied </button>
                </ng-container>

                <ng-template #notApplied>
                  <ng-container *ngIf="!applySpinner">
                    <button class="btn btn-jobejee" style="margin-bottom: 8px;" (click)="openModal(promptLogin)"
                      *ngIf="!jsInfoId && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i
                        class="fa fa-paper-plane" aria-hidden="true"></i>
                      Apply Now </button>
                    <button class="btn btn-jobejee" style="margin-bottom: 8px;" (click)="applyJob()"
                      *ngIf="jsInfoId && userRole=='JS' && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i
                        class="fa fa-paper-plane" aria-hidden="true"></i>
                      Apply Now</button>
                  </ng-container>

                  <ng-container *ngIf="applySpinner">
                    <button class="btn btn-jobejee" style="margin-bottom: 8px;"
                      *ngIf="jsInfoId && userRole=='JS' && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i
                        class="fa fa-paper-plane" aria-hidden="true"></i>
                      Applying

                      <span class="s-spinner">
                        <span class="bounce1"></span>
                        <span class="bounce2"></span>
                        <span class="bounce3"></span>
                      </span>
                    </button>
                  </ng-container>

                </ng-template>
                <p><i class="fa fa-share-alt" aria-hidden="true"></i> Share this job</p>
                <p>
                  <ng-container *ngIf="jobsDetailObj.bookmarked=='Y';else noBookmark">
                    <span class="icontxt icon-gap" (click)="bookmarkJob(jobsDetailObj)"> <i class="fa fa-bookmark"
                        aria-hidden="true"></i> Bookmarked</span>
                  </ng-container>

                  <ng-template #noBookmark>
                    <span class="icontxt icon-gap" (click)="bookmarkJob(jobsDetailObj)" *ngIf="userId"> <i
                        class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark </span>
                    <span class="icontxt icon-gap"
                      (click)="openModal(bookmark); setBookmarkId(jobsDetailObj.jobCreateId)" *ngIf="!userId"> <i
                        class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark
                    </span>
                    <span [ngStyle]="showBookmark(jobsDetailObj)" style="visibility:hidden;display: none">Hide</span>
                  </ng-template>
                </p>

                <p *ngIf="jobsDetailObj.compUrl"><i class="fa fa-globe" aria-hidden="true" style="color:#00737E;"></i>
                  <a href="{{checkUrl(jobsDetailObj.compUrl)}}" target="_blank"
                    style="text-decoration: none; color:#00737E;">
                    Visit Official Site</a>
                </p>
              </div>
            </div>

            <div class="higlighted">
              <span class="highlight-chip">
                <i>Views : {{jobsDetailObj.views || '0'}}</i>
                <span style="margin:0px 7px">|</span>
                Apply Before : {{jobsDetailObj.jobExpiry || 'Not Available'}}</span>
            </div>

            <div class="col-md-12 info-box bg-white" style="padding:30px 15px;">

              <div class="col-md-4 inner-box">
                <div class="flex-box flex-other">
                  <div class="info-icon"> <img src="../../../assets/images/payment.png" alt=""></div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Offered Salary</p>
                    <p *ngIf="jobsDetailObj.ctcType =='N'">Negotiable</p>
                    <!-- <p *ngIf="jobsDetailObj.ctcType =='R'">
                      {{jobsDetailObj.ctcLakhMin || 'Not Available'}} -
                      {{jobsDetailObj.ctcLakhMax || 'Not Available'}}
                      ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})</p> -->

                    <p *ngIf="jobsDetailObj.ctcType =='R'">

                      <span *ngIf="jobsDetailObj.ctcThouMin && jobsDetailObj.ctcThouMax">
                        {{jobsDetailObj.ctcThouMin | number }} -
                        {{jobsDetailObj.ctcThouMax | number }}
                        ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})
                      </span>

                      <span *ngIf="jobsDetailObj.ctcLakhMin && jobsDetailObj.ctcLakhMax">
                        {{jobsDetailObj.ctcLakhMin | number }} -
                        {{jobsDetailObj.ctcLakhMax | number}}
                        ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})
                      </span>

                    </p>
                    <p *ngIf="jobsDetailObj.ctcType =='F'">
                      {{jobsDetailObj.ctcLakhMin || 'Not Available'}} 
                      ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})</p>
                  </div>
                </div>
              </div>


              <div class="col-md-5 inner-box">
                <div class="col-md-2"></div>

                <div class="flex-box flex-other">
                  <div class="info-icon">
                    <img src="../../../assets/images/bag.png" alt="">
                  </div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Experience</p>
                    <p *ngIf="jobsDetailObj.expMin || jobsDetailObj.expMax">

                      <span *ngIf="jobsDetailObj.expMin == 0 && jobsDetailObj.expMax == 0">
                        Fresher
                      </span>

                      <span *ngIf="jobsDetailObj.expMin >= 0 && jobsDetailObj.expMax > 0">
                        {{jobsDetailObj.expMin}} -
                        {{jobsDetailObj.expMax }} Years
                      </span>

                      <span *ngIf="jobsDetailObj.expMin > jobsDetailObj.expMax">
                        {{jobsDetailObj.expMin}} Years
                      </span>



                    </p>
                    <p *ngIf="!jobsDetailObj.expMin && !jobsDetailObj.expMax">Not Available</p>
                  </div>
                </div>
              </div>


              <div class="col-md-3 inner-box">
                <div class="flex-box flex-other">
                  <div class="info-icon">
                    <img src="../../../assets/images/hourglass.png" alt="">
                  </div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Job Type</p>
                    <p *ngIf="jobsDetailObj.jobTypeFlag ==='F'">Full Time</p>
                    <p *ngIf="jobsDetailObj.jobTypeFlag ==='P'">Part Time</p>
                    <p *ngIf="jobsDetailObj.jobTypeFlag ==='C'">Contract Basis</p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 inner-box nomargin">
                <div class="flex-box flex-other">
                  <div class="info-icon">
                    <img src="../../../assets/images/apartment.png" alt="">
                  </div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Industry</p>
                    <p>{{jobsDetailObj.industry || 'Not Available'}}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-5 inner-box nomargin">
                <div class="col-md-2"></div>
                <div class="flex-box flex-other">
                  <div class="info-icon">
                    <img src="../../../assets/images/clock.png" alt="">
                  </div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Post Date</p>
                    <p>{{jobsDetailObj.publishOn || 'Not Available'}}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-3 inner-box nomargin">
                <div class="flex-box flex-other">
                  <div class="info-icon">
                    <img src="../../../assets/images/calendar.png" alt="">
                  </div>
                  <div class="icon-detail-box">
                    <p class="f-wt-500">Valid Until </p>
                    <p>{{jobsDetailObj.jobExpiry || 'Not Available'}}</p>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-md-12 descriptionbox jbox-section">
              <span class="des-header"> Job Specification</span>
              <div class="row line-height-28">
                <div class="col-md-4 sub-header">Qualification Required</div>
                <div class="col-md-8">{{jobsDetailObj.qualification || 'Not Available'}}</div>
                <div class="col-md-4 sub-header">Key Skills</div>

                <div class="col-md-8">
                  <span class="skill-chips" *ngFor="let items of keySkills">{{items || 'Not Available'}}</span>
                </div>
                <div class="col-md-4 sub-header">Prefered Age Range</div>
                <div class="col-md-8" *ngIf="jobsDetailObj.ageRangeFrom && jobsDetailObj.ageRangeTo">
                  {{jobsDetailObj.ageRangeFrom || 'Not Available'}} -
                  {{jobsDetailObj.ageRangeTo || 'Not Available'}}</div>
                <div class="col-md-8" *ngIf="!jobsDetailObj.ageRangeFrom || !jobsDetailObj.ageRangeTo">Not Available
                </div>

                <div *ngIf="jobsDetailObj.gender != 'A'">
                  <div class="col-md-4 sub-header">Prefered Gender</div>
                  <!-- <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'A'">Any</div> -->
                  <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'M'">Male</div>
                  <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'F'">Female</div>
                </div>
                <div class="clearfix"></div>

                <div>
                  <div class="col-md-4 sub-header">Cover Letter </div>
                  <div class="col-md-8">{{jobsDetailObj.coverletter == 'N' ? 'Not Required' : 'Required'}}</div>
                </div>

                <div class="col-md-4 sub-header">Functional Area</div>
                <div class="col-md-8">{{jobsDetailObj.functionalArea || 'Not Available'}}</div>
              </div>
            </div>

            <!-- <div class="col-md-12 jbox-section">
              <p class="f-wt-500"><i class="fa fa-lightbulb-o" aria-hidden="true" style="font-size: 20px;
                        margin-right: 4px;
                        color: #de6a26;
                        vertical-align: bottom;"></i>
                Key Skills</p>

              <span class="skill-chips" *ngFor="let items of keySkills">{{items || 'Not Available'}}</span>
            </div> -->


            <div class="col-md-12 descriptionbox jbox-section" *ngIf="jobsDetailObj.jobDesc">
              <span class="des-header">Job Description</span>
              <div [innerHtml]="jobsDetailObj.jobDesc">
              </div>
            </div>

            <div class="col-md-12 descriptionbox jbox-section" *ngIf="jobsDetailObj.compOffer">
              <span class="des-header">What We Offer</span>
              <div [innerHTML]="jobsDetailObj.compOffer"></div>
            </div>


            <div class="col-md-12 descriptionbox jbox-section">
              <span class="des-header">Instructions to Apply</span>
              <div [innerHTML]="jobsDetailObj.instrucApply"></div>
            </div>


            <!-- <div class="row">
             


             



           


              <div class="col-md-12 descriptionbox">
                <span class="des-header">Other Job Details</span>
                <div class="row line-height-28">
                  
                </div>
              </div>
            </div> -->
          </div>

          <div class="col-md-3" style="padding-right: 0px;">
            <div class="col-md-12 bg-white nopadding">
              <p class="green-header">About Company</p>
              <div class="col-md-12 about-box" [ngClass]="{  'about-box-open' :aboutOpen }">
                <div style="display: inline;" [innerHTML]="(jobsDetailObj.compDesc | slice:0:180)"
                  *ngIf="jobsDetailObj.compDesc && !aboutOpen"> </div>

                <div style="display: inline;" [innerHTML]="(jobsDetailObj.compDesc)"
                  *ngIf="jobsDetailObj.compDesc && aboutOpen"> </div>
                <span *ngIf="!aboutOpen">...</span>
                <span class="readmore" (click)="aboutOpen = !aboutOpen" *ngIf="!aboutOpen">Read More</span>

                <span *ngIf="aboutOpen" class="readmore" (click)="aboutOpen = !aboutOpen"> ... Hide</span>
              </div>
              <div class="col-md-12 about-box " *ngIf="!jobsDetailObj.compDesc"><span>Not Available</span></div>
            </div>


            <div class="col-md-12 bg-white nopadding" style="margin-top:25px;">
              <p class="green-header">Similar Jobs</p>
              <div class="col-md-12 similar" *ngFor="let items of similarJobs | slice:0:7;">
                <div class="flex sim-box">
                  <div class="logo-box cursor" [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]">
                    <img *ngIf="items.image" src="{{items.image}}" alt="">
                    <img src="../../../assets/images/default-comp.jpeg" alt="" *ngIf="!items.image">
                  </div>

                  <div class="sim-txt-box">

                    <p class="similar-title cursor"
                      [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]" title="{{items.title}}">
                      {{items.title || 'N/A'}}</p>
                    <p [routerLink]="['/employer/', replaceSpace(items.companyName),items.employerId]">
                      {{items.companyName || 'Company Name Not Available'}}</p>
                    <p><i class="fa fa-eye" aria-hidden="true"></i> {{items.views || '0' }} views</p>
                    <p> {{calculateDiff(items.expiry)}} </p>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-12 about-box " [innerHTML]="jobsDetailObj.compDesc"></div> -->
            </div>






            <!-- <div class="col-md-12 similarjobbox">
              <div class="row">
                <div class="col-md-12 green-header">Similar Jobs</div>
                <div class="col-md-12 info-box" *ngFor="let items of similarJobs">
                  <div class="row">
                    <div class="col-md-4">
                      <div >
                       
                      </div>
                    </div>
                    <div class="col-md-8">
                     
                    </div>
                    <div class="col-md-12">
                      <button class="btn jobDetailbtn"
                        [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]">View Job Detail</button>
                    </div>
                  </div>
                </div>
              </div>

            </div> -->
            <!-- </div> -->
          </div>
        </div>
      </div>

    </div>



  </div>


  <div class="row halfview">
    <div class="col-md-12 res-box">

      <div class="row res-overview">
        <div class="col-xs-4 nopadding">
          <div class="image">

            <app-skeleton-loader Cwidth="90" Cheight="100" *ngIf="!jobsDetailObj"></app-skeleton-loader>

            <ng-container *ngIf="jobsDetailObj">
              <img *ngIf="jobsDetailObj.compLogo" src="{{jobsDetailObj.compLogo}}" alt="">
              <img *ngIf="!jobsDetailObj.compLogo" src="../../../assets/images/default-comp.jpeg" alt="">
            </ng-container>


            <!-- <label class="profile-txt"
              *ngIf="!jobsDetailObj.compLogo">{{jobsDetailObj.companyName.split("")[0]}}</label> -->
          </div>
        </div>
        <div class="col-xs-8 text-light f-wt-500">
          <app-skeleton-loader Cwidth="85" Cheight="20" *ngIf="!jobsDetailObj"></app-skeleton-loader>
          <p class="f-16-header" *ngIf="jobsDetailObj">{{jobsDetailObj.title || 'Not Available'}}</p>
          <app-skeleton-loader Cwidth="55" Cheight="20" *ngIf="!jobsDetailObj"></app-skeleton-loader>
          <p *ngIf="jobsDetailObj"><a
              [routerLink]="['/employer',  replaceSpace(splitcompany(jobsDetailObj.companyName, 0)),jobsDetailObj.employerId ]"
              class="green-orange-link fw500 noDecoration">
              {{jobsDetailObj.companyName || 'Loading...'}}
            </a></p>
          <app-skeleton-loader Cwidth="70" Cheight="20" *ngIf="!jobsDetailObj"></app-skeleton-loader>
          <p *ngIf="jobsDetailObj">Vacancy : {{jobsDetailObj.noVac || 'Not Available'}}</p>
          <app-skeleton-loader Cwidth="86" Cheight="20" *ngIf="!jobsDetailObj"></app-skeleton-loader>

          <p *ngIf="jobsDetailObj"> <span>Location : </span>

            <ng-container *ngIf="jobsDetailObj.province=='All Over Nepal'">
              <span *ngIf="jobsDetailObj.province" class="fwn">{{jobsDetailObj.province }}</span>
              <span *ngIf="jobsDetailObj.jobLocation" class="fwn"> ({{jobsDetailObj.jobLocation }})</span>
            </ng-container>

            <ng-container *ngIf="jobsDetailObj.province!='All Over Nepal'">

              <span class="fwn">{{jobsDetailObj.jobLocation}}</span>
              <span *ngIf="jobsDetailObj.city" class="fwn">, {{jobsDetailObj.city }}</span>
              <span *ngIf="jobsDetailObj.province" class="fwn">,
                {{jobsDetailObj.province || 'Not Available'}}</span>
              <span class="fwn" *ngIf="jobsDetailObj.jobLocation">, {{jobsDetailObj.country ||
                jobsDetailObj.jobLocation.interLocation}}</span>


            </ng-container>
          </p>

          <p *ngIf="jobsDetailObj.compUrl"><i class="fa fa-globe" aria-hidden="true" style="color:#00737E;"></i>
            <a href="{{checkUrl(jobsDetailObj.compUrl)}}" target="_blank" style="text-decoration: none; color:#00737E;">
              Visit Official Site</a>
          </p>
        </div>

        <div class="col-xs-12 nopadding res-details">
          <div class="col-xs-4 nopadding"><i class="fa fa-eye" aria-hidden="true"></i> {{ jobsDetailObj.views || 0}}
            views</div>
          <div class="col-xs-4"><i class="fa fa-share-alt" aria-hidden="true"></i> Share</div>
          <div class="col-xs-4" style="white-space: pre;">
            <p>
              <ng-container *ngIf="jobsDetailObj.bookmarked=='Y';else noBookmark">
                <span class="icontxt icon-gap" (click)="bookmarkJob(jobsDetailObj)"> <i class="fa fa-bookmark"
                    aria-hidden="true"></i> Bookmarked</span>
              </ng-container>

              <ng-template #noBookmark>
                <span class="icontxt icon-gap" (click)="bookmarkJob(jobsDetailObj)" *ngIf="userId"> <i
                    class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark </span>
                <span class="icontxt icon-gap" (click)="openModal(bookmark); setBookmarkId(jobsDetailObj.jobCreateId)"
                  *ngIf="!userId"> <i class="fa fa-bookmark-o" aria-hidden="true"></i> Bookmark
                </span>
                <span [ngStyle]="showBookmark(jobsDetailObj)" style="visibility:hidden;display: none">Hide</span>
              </ng-template>
            </p>

          </div>
        </div>
      </div>



      <div class="row res-job-detail">
        <div class="col-xs-12  job-detailheader">
          Job Details
        </div>

        <div class="col-xs-12 job-detail-info">
          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/payment.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p class="f-wt-500">Offered Salary</p>
                <p class="f-wt-500" *ngIf="jobsDetailObj.ctcType =='N'">Negotiable</p>
                <p class="f-wt-500" *ngIf="jobsDetailObj.ctcType =='R'">

                  <span *ngIf="jobsDetailObj.ctcThouMin && jobsDetailObj.ctcThouMax">
                    {{jobsDetailObj.ctcThouMin | number }} -
                    {{jobsDetailObj.ctcThouMax |number }}
                    ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})
                  </span>

                  <span *ngIf="jobsDetailObj.ctcLakhMin && jobsDetailObj.ctcLakhMax">
                    {{jobsDetailObj.ctcLakhMin | number }} -
                    {{jobsDetailObj.ctcLakhMax | number }}
                    ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})
                  </span>

                </p>



                <p class="f-wt-500" *ngIf="jobsDetailObj.ctcType =='F'">
                  {{jobsDetailObj.ctcLakhMin || 'Not Available'}} 
                  <!-- <span *ngIf="jobsDetailObj.ctcLakhMin">Lakhs</span> -->
                  ({{jobsDetailObj.salaryType == 'Y' ? 'Yearly' : 'Monthly'}})</p>
              </div>
            </div>
          </div>

          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/bag.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p><b>Experience</b></p>
                <p class="f-wt-500">{{jobsDetailObj.expMin || 'Not Available'}} -
                  {{jobsDetailObj.expMax || 'Not Available'}} Years</p>
              </div>
            </div>
          </div>

          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/hourglass.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p><b>Job Type</b></p>
                <p class="f-wt-500" *ngIf="jobsDetailObj.jobTypeFlag ==='F'">Full Time</p>
                <p class="f-wt-500" *ngIf="jobsDetailObj.jobTypeFlag ==='P'">Part Time</p>
                <p class="f-wt-500" *ngIf="jobsDetailObj.jobTypeFlag ==='C'">Contract Basis</p>
              </div>
            </div>
          </div>

          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/apartment.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p><b>Industry</b></p>
                <p class="f-wt-500">{{jobsDetailObj.industry || 'Not Available'}}</p>
              </div>
            </div>
          </div>

          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/clock.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p><b>Post Date</b></p>
                <p class="f-wt-500">{{jobsDetailObj.publishOn || 'Not Available'}}</p>

              </div>
            </div>
          </div>

          <div class="col-xs-12 inner-box">
            <div class="row">
              <div class="col-xs-2 nopadding">
                <div class="icon-box">
                  <img src="../../../assets/images/calendar.png" alt="">
                </div>
              </div>
              <div class="col-xs-10 nopadding icon-detail-box">
                <p><b>Valid until </b></p>
                <p class="f-wt-500">{{jobsDetailObj.jobExpiry || 'Not Available'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xs-12 res-btn-clmn">

          <ng-container *ngIf="jobsDetailObj.applied=='Y'; else notApplied">
            <button class="applied" style="margin-bottom: 15px;">
              Job Applied </button>
          </ng-container>

          <ng-template #notApplied>

            <ng-container *ngIf="!applySpinner">
              <button class="btn btn-orange" (click)="openModal(promptLogin)"
                *ngIf="!jsInfoId && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i class="fa fa-paper-plane"
                  aria-hidden="true"></i>
                Apply Now </button>
              <button class="btn btn-orange" (click)="applyJob()"
                *ngIf="jsInfoId && userRole=='JS' && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i
                  class="fa fa-paper-plane" aria-hidden="true"></i>
                Apply Now </button>
            </ng-container>

            <ng-container *ngIf="applySpinner">
              <button class="btn btn-orange"
                *ngIf="jsInfoId && userRole=='JS' && !expiryStatus && jobsDetailObj.appViaJobejee=='Y'"><i
                  class="fa fa-paper-plane" aria-hidden="true"></i>
                Applying
                <span class="s-spinner">
                  <span class="bounce1"></span>
                  <span class="bounce2"></span>
                  <span class="bounce3"></span>
                </span>

              </button>
            </ng-container>


            <!-- </ng-container> -->
          </ng-template>
        </div>
      </div>


      <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header"> Job Specification</span>
          <div class="row line-height-28">
            <div class="col-md-4 sub-header">Qualification Required</div>
            <div class="col-md-8">{{jobsDetailObj.qualification || 'Not Available'}}</div>
            <div class="col-md-4 sub-header">Key Skills</div>

            <div class="col-md-8">
              <span class="skill-chips" *ngFor="let items of keySkills">{{items || 'Not Available'}}</span>
            </div>
            <div class="col-md-4 sub-header">Prefered Age Range</div>


            <div class="col-md-8" *ngIf="jobsDetailObj.ageRangeFrom && jobsDetailObj.ageRangeTo">
              {{jobsDetailObj.ageRangeFrom || 'Not Available'}} -
              {{jobsDetailObj.ageRangeTo || 'Not Available'}}</div>
            <div class="col-md-8" *ngIf="!jobsDetailObj.ageRangeFrom || !jobsDetailObj.ageRangeTo">Not Available</div>

            <div *ngIf="jobsDetailObj.gender != 'A'">
              <div class="col-md-4 sub-header">Prefered Gender</div>
              <!-- <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'A'">Any</div> -->
              <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'M'">Male</div>
              <div class="col-md-8" *ngIf="jobsDetailObj.gender == 'F'">Female</div>
            </div>
            <div class="clearfix"></div>

            <div>
              <div class="col-md-4 sub-header">Cover Letter</div>
              <div class="col-md-8">{{jobsDetailObj.coverletter == 'N' ? 'Not Required' : 'Required'}}</div>
            </div>

            <div class="col-md-4 sub-header">Functional Area</div>
            <div class="col-md-8">{{jobsDetailObj.functionalArea || 'Not Available'}}</div>
          </div>
        </div>
      </div>

      <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">Job Description</span>
          <div class="col-xs-12" [innerHtml]="jobsDetailObj.jobDesc">
          </div>
        </div>
      </div>

      <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">What We Offer</span>

          <div [innerHTML]="jobsDetailObj.compOffer"></div>
        </div>
      </div>

      <!-- <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">Desired Candidate Profile</span>

          <div class="col-xs-12 sub-box nopadding">
            <p class="sub-header">Qualification Required</p>
            <p>{{jobsDetailObj.qualification || 'Not Available'}}</p>
          </div>

          <div class="col-xs-12 sub-box nopadding">
            <p class="sub-header">prefered Age Range</p>
            <p>{{jobsDetailObj.ageRangeFrom || 'Not Available'}} - {{jobsDetailObj.ageRangeTo || 'Not Available'}}</p>
          </div>
          <div class="col-xs-12 sub-box nopadding">
            <p class="sub-header">prefered Gender</p>
            <p *ngIf="jobsDetailObj.gender == 'A'">Any</p>
            <p *ngIf="jobsDetailObj.gender == 'M'">Male</p>
            <p *ngIf="jobsDetailObj.gender == 'F'">Female</p>
          </div>

        </div>
      </div> -->

      <!-- <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">Additional Desired Profile</span>
          <ul>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
            <li>asdaskdasd</li>
          </ul>
        </div>
      </div> -->

      <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">Instruction to Apply</span>
          <div [innerHTML]="jobsDetailObj.instrucApply" style="text-align: justify;"></div>
        </div>
      </div>


      <!-- <div class="row res-job-des">
        <div class="col-xs-12">
          <span class="des-header">Other Job Details</span>

          <div class="col-xs-12 sub-box">
            <p class="sub-header">Cover letter</p>
            <p>{{jobsDetailObj.coverLetter == 'N' ? 'Not Required' : 'Required'}}</p>
          </div>

          <div class="col-xs-12 sub-box">
            <p class="sub-header">Functional Area</p>
            <p>{{jobsDetailObj.functionalArea || 'Not Available'}}</p>
          </div>
        </div>
      </div> -->
    </div>
    <div class="col-md-3 col-xs-12 nopadding">
      <div class="col-md-12 bg-white nopadding">
        <p class="green-header">About Company</p>
        <div class="col-md-12 about-box " [innerHTML]="jobsDetailObj.compDesc"></div>
      </div>


      <div class="col-md-12 bg-white nopadding" style="margin-top:25px;">
        <p class="green-header">Similar Jobs</p>
        <div class="col-md-12 similar" *ngFor="let items of similarJobs | slice:0:7;">
          <div class="flex sim-box">
            <div class="logo-box cursor" [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]">
              <img *ngIf="items.image" src="{{items.image}}" alt="">
              <img src="../../../assets/images/default-comp.jpeg" alt="" *ngIf="!items.image">
            </div>

            <div class="sim-txt-box">

              <p class="similar-title cursor" [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]"
                title="{{items.title}}">
                {{items.title || 'N/A'}}</p>
              <p [routerLink]="['/employer/', replaceSpace(items.companyName),items.employerId]">
                {{items.companyName || 'Company Name Unavailable'}}</p>
              <p><i class="fa fa-eye" aria-hidden="true"></i> {{items.views || '0' }} views</p>
              <p> {{calculateDiff(items.expiry)}} </p>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-12 about-box " [innerHTML]="jobsDetailObj.compDesc"></div> -->
      </div>






      <!-- <div class="col-md-12 similarjobbox">
        <div class="row">
          <div class="col-md-12 green-header">Similar Jobs</div>
          <div class="col-md-12 info-box" *ngFor="let items of similarJobs">
            <div class="row">
              <div class="col-md-4">
                <div >
                 
                </div>
              </div>
              <div class="col-md-8">
               
              </div>
              <div class="col-md-12">
                <button class="btn jobDetailbtn"
                  [routerLink]="['/job/', replaceSpace(items.title),items.jobCreateId]">View Job Detail</button>
              </div>
            </div>
          </div>
        </div>

      </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>

<div class="container-fluid " style="margin-top: 200px; margin-bottom: 15px;" *ngIf="!jobsDetailObj">

  <app-jobejee-loader></app-jobejee-loader>

</div>

<!-- Modal -->
<ng-template #promptLogin>
  <div class="modal-header" style="position: relative;">
    <p>Job Apply </p>
    <!-- <p>HR OFFICER</p> -->
    <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row divider">
      <div class="col-md-12 text-center">
        <img src="../../../assets/images/profile-circle1.png" alt="">
        <p style="margin-top:15px">Please login to your account to apply for this job</p>
      </div>

      <div class="col-md-12 text-center">

        <button class="btn btn-green" [routerLink]="[ '/jobseeker/login' ]" (click)="closeModal()">Login &
          Apply</button>
        <p style=" font-weight:700;font-size: 18px;color: #067080;margin-top:15px">DON'T HAVE AN ACCOUNT YET?</p>
        <p style="margin-top:15px">Sign Up to get custom job alert, recommendations, personalized notification & many
          more.</p>
      </div>
    </div>

    <div class="row">

      <div class="col-md-12 text-center" style="margin-top:15px">
        <button class="btn btn-green first" style="margin-right: 15px;" [routerLink]="[ '/jobseeker/register' ]"
          (click)="closeModal()">Register and Apply</button>
        <button class="btn btn-green second" style="margin-left: 15px;"
          (click)="closeModal();openModal(withoutRegister);initializeJobApplyForm()"
          *ngIf="jobsDetailObj.regMand=='N'">Apply without Registration</button>
      </div>

    </div>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #withoutRegister>
  <div class="modal-header" style="position: relative;">
    <p>Application Form </p>
    <!-- <p>HR OFFICER</p> -->
    <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="jobForm">
      <div class="row ">

        <!-- First Name -->
        <div class="col-md-6 form-group">
          <label for="firstName">First Name <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter first name" class="form-control" id="firstName"
            formControlName="firstName" autocomplete="off">
          <div class="invalid-feedback"
            *ngIf="jobForm.controls.firstName.touched && jobForm.controls.firstName.invalid">
            <span> First Name is required.</span>
          </div>
        </div>

        <!-- Last Name -->
        <div class="col-md-6 form-group">
          <label for="lastName">Last Name <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter last name" class="form-control" id="lastName" formControlName="lastName"
            autocomplete="off">
          <div class="invalid-feedback" *ngIf="jobForm.controls.lastName.touched && jobForm.controls.lastName.invalid">
            <span> Last Name is required.</span>
          </div>
        </div>

        <!-- Email -->
        <div class="col-md-12 form-group">
          <label for="email">Email <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter email" class="form-control" id="email" formControlName="email"
            autocomplete="off">
          <div class="invalid-feedback" *ngIf="jobForm.controls.email.touched && jobForm.controls.email.invalid">
            <span *ngIf="jobForm.controls.email.errors.required"> Email is required </span>
            <span *ngIf="jobForm.controls.email.errors.pattern" class="mat-error">Invalid
              Email. Email format
              should be
              <b> jobejee@gmail.com</b>
            </span>
            <span *ngIf="jobForm.controls.email.errors.maxlength">Email Address should be
              maximum 50
              characters</span>
            <span *ngIf="jobForm.get('email').errors['notAvailability']">Sorry! Email
              already
              exist.
            </span>

          </div>
        </div>

        <!-- Skill -->
        <div class="col-md-12 form-group">
          <label for="skills">Skills <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter your skills" class="form-control" id="skills"
            formControlName="keySkills" autocomplete="off">
          <span class="sm-green-txt">Specify your skills separated by comma.</span>
          <div class="invalid-feedback"
            *ngIf="jobForm.controls.keySkills.touched && jobForm.controls.keySkills.invalid">
            <span> Skill is required.</span>
          </div>
        </div>

        <!-- Mobile Number -->
        <div class="col-md-12 form-group">
          <label for="mobNo">Mobile Number <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter mobile number" class="form-control" id="mobNo" formControlName="mobNo"
            maxlength="10" autocomplete="off">
          <div class="invalid-feedback" *ngIf="jobForm.controls.mobNo.touched && jobForm.controls.mobNo.invalid "
            style="margin-left:5px">
            <span *ngIf="jobForm.controls.mobNo.errors.required">Mobile Number is
              required. </span>
            <span *ngIf="jobForm.controls.mobNo.errors.pattern ">Invalid mobile
              number</span>
            <span *ngIf="!jobForm.controls.mobNo.errors.pattern && jobForm.controls.mobNo.errors.minlength">Mobile
              Number should be minimum 10
              digit.</span>
          </div>
        </div>

        <!-- Resume Title -->
        <div class="col-md-12 form-group">
          <label for="title">Resume Title <span class="red-asterist">*</span></label>
          <input type="text" placeholder="Enter resume title" class="form-control" id="title"
            formControlName="resumeTitle" autocomplete="off">
          <div class="invalid-feedback"
            *ngIf="jobForm.controls.resumeTitle.touched && jobForm.controls.resumeTitle.invalid">
            <span> Resume title is required.</span>
          </div>

        </div>

        <!-- Upload Resume -->
        <div class="col-md-12 form-group">
          <label for="upload">Resume Upload <span class="red-asterist">*</span></label>
          <!-- <input type="file" class="form-control" id="upload" #file name="myFile" accept=".doc,.docx,.rtf, .pdf" -->
          <input type="file" class="form-control" id="upload" #file name="myFile"
            (click)="file.value = null" value="" (change)="uploadFiles(file.files)">
          <p class="red-asterist" *ngIf="errorMsg">{{errorMsg}}</p>
        </div>

        <!-- Profile Type -->
        <div class="col-md-12 form-group">
          <label for="upload">Profile Type <span class="red-asterist">*</span></label>
          <div class="row">
            <div class="col-md-12">
              <input type="radio" id="fresher" value="F" formControlName="profileType" (change)="changeValidators()">
              <label for="fresher" class="label-custom"> Fresher (Without Professional Experience)</label>
            </div>
            <div class="col-md-12">
              <input type="radio" id="professional" value="P" formControlName="profileType"
                (change)="changeValidators()">
              <label for="professional" class="label-custom"> Professional (With Professional Experience)</label>

            </div>
          </div>
        </div>

        <div *ngIf="jobForm.value.profileType=='P'">

          <!-- Total Experience -->
          <div class="col-md-12 form-group">
            <label for="Experience">Total Experience </label>

            <div class="row">
              <!-- Years -->
              <div class="col-md-6 ">
                <label for="years">Years <span class="red-asterist">*</span></label>
                <select id="years" class="form-control" formControlName="expYear">
                  <option value="null" disabled>Select Years </option>
                  <option value={{item}} *ngFor="let item of years">{{item}}

                    <span *ngIf="item==0">Year</span>
                    <span *ngIf="item>0">Years</span>
                  </option>
                </select>

              </div>

              <!-- Month -->
              <div class="col-md-6 ">
                <label for="month">Months <span class="red-asterist">*</span></label>

                <select id="month" class="form-control" formControlName="expMonth">

                  <option value="null" disabled>Select Months</option>
                  <option value={{item}} *ngFor="let item of months">{{item}}

                    <span *ngIf="item==0">Month</span>
                    <span *ngIf="item>0">Months</span>
                  </option>

                </select>
              </div>
            </div>
          </div>

          <!-- Industry -->
          <div class="col-md-12 form-group">
            <label for="industry">Industry <span class="red-asterist">*</span></label>
            <ngx-select-dropdown [config]="config11" [options]="industryList" value="industryId" [multiple]="false"
              formControlName="industryId">
            </ngx-select-dropdown>
          </div>

          <!-- Functional Area -->
          <div class="col-md-12 form-group">
            <label for="functionalArea">Functional Area <span class="red-asterist">*</span></label>
            <ngx-select-dropdown [config]="config2" [options]="functionalAreaList" value="categoryId" [multiple]="false"
              formControlName="funcAreaId">
            </ngx-select-dropdown>
          </div>

          <!-- designation -->
          <div class="col-md-12 form-group">
            <label for="designation">Designation <span class="red-asterist">*</span></label>
            <ngx-select-dropdown [config]="config12" [options]="desigList" value="categoryId" [multiple]="false"
              formControlName="desigId">
            </ngx-select-dropdown>
          </div>

          <!-- Company Name -->
          <div class="col-md-12 form-group">
            <label for="Company">Company Name<span class="red-asterist">*</span></label>
            <input type="text" placeholder="Enter company name" class="form-control" id="Company"
              formControlName="companyName">
            <div class="invalid-feedback"
              *ngIf="jobForm.controls.companyName.touched && jobForm.controls.companyName.invalid">
              <span> Company Name is required.</span>
            </div>
          </div>


          <!-- Expected Salary -->
          <div class="col-md-12 form-group">
            <label for="expected">Expected Salary </label>

            <div class="row">
              <div class="col-md-3">
                <input type="radio" id="monthly" formControlName="salaryType" value="M">
                <label for="monthly" class="label-custom"> Monthly</label>
              </div>
              <div class="col-md-3">
                <input type="radio" id="yearly" formControlName="salaryType" value="Y">
                <label for="yearly" class="label-custom"> Yearly</label>

              </div>
            </div>


            <div class="row">
              <!-- Years -->
              <div class="col-md-6 form-group">
                <!-- <label for="years">Years <span class="red-asterist">*</span></label> -->
                <select id="lakh" class="form-control" formControlName="expCtcLakh">
                  <option value="null" disabled>Select Lakh </option>
                  <option value={{item}} *ngFor="let item of lakhs">{{item}} 
                    <span *ngIf="item==0">Lakh</span>
                    <span *ngIf="item>0">Lakhs</span>
                  </option>

                </select>

              </div>

              <!-- Month -->
              <div class="col-md-6 form-group">
                <!-- <label for="month">Months <span class="red-asterist">*</span></label> -->

                <select id="thousand" class="form-control" formControlName="expCtcThousand">

                  <option value="null" disabled>Select Thousand</option>
                  <option value={{item}} *ngFor="let item of thousand">{{item}} 
                    <span *ngIf="item==0">Thousand</span>
                    <span *ngIf="item>0">Thousands</span>
                  </option>

                </select>

              </div>



            </div>

          </div>

        </div>

        <!-- Agreement -->
        <div class="col-md-12 text-center  form-group">

          <label style=" font-weight: normal;">
            <span style="vertical-align: text-bottom; cursor: pointer; font-weight: normal;"> By
              clicking on 'Apply Now' below you are agreeing to the <a class="green-link noDecoration"
                [routerLink]="['/terms-and-conditions']" (click)="closeModal()">Terms
                and Conditions </a> and <a [routerLink]="['/privacy-policies']" class="green-link noDecoration"
                (click)="closeModal()"> Privacy Policy</a> governing the use
              of Jobejee.</span></label>

        </div>
      </div>



      <div class="row">
        <div class="col-md-12 text-center">
          <button class="btn btn-green first" [disabled]="jobForm.invalid" (click)="applyWithoutRegistration();"
            *ngIf="!processing">Apply
            Now</button>

          <button class="btn btn-green first" [disabled]="true" *ngIf="processing">Applying
            <span class="s-spinner">
              <span class="bounce1"></span>
              <span class="bounce2"></span>
              <span class="bounce3"></span>
            </span>
          </button>

        </div>
      </div>

    </form>
  </div>
</ng-template>

<!-- Modal -->
<ng-template #bookmark>
  <ng-container>
    <div class="modal-header" style="position: relative;">
      <p>Please Login to Continue</p>
      <!-- <p>HR OFFICER</p> -->
      <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <app-bookmark-job></app-bookmark-job>
      </div>
    </div>
  </ng-container>

</ng-template>
<!-- <app-spinner1 *ngIf="loader"></app-spinner1> -->

<div class="container-fluid container-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}">
  <app-search-job></app-search-job>
  <img src="../../../assets/images/notfound.png" style="display: none; visibility: hidden;" />

</div>

<div class="container" style="padding:0px">
  <div class="row wrapper">

    <!-- Side Bar Filter -->
    <div class="col-md-3">

      <div class="col-md-12 nopadding">
        <div class=" col-md-12 refine-search">
          <p class="sm-heading">Refine Search</p>
        </div>
      </div>

      <div class="col-md-12 sm-box border">

        <div class="row">
          <div class="col-md-12">
            <!--Side bar items-->
            <div class="side-item cursor" (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed"
              aria-controls="collapseBasic" style="border-top: none;">
              <span class="sm-16-heading">Industry</span><i class="caret float-r"></i>
            </div>
            <label class="side-lbl" id="collapseBasic" [collapse]="isCollapsed">
              <ng-container *ngIf="industryList.length>0">
                <label class="check-container" *ngFor="let data of industryList" title={{data.name}}>
                  <span class="mob-res">{{(data.name |slice:0:30) + '...'|| '-'}}({{data.count}})</span>
                  <span class="web-res">{{(data.name |slice:0:15) + '...'|| '-'}}({{data.count}})</span>


                  <input type="checkbox" value="data.name" ngModel
                    (ngModelChange)="onChecked(data.name, $event, 'industry')">
                  <span class="checkmark"></span>
                </label>
              </ng-container>

              <ng-container *ngIf="industryList.length==0">
                <span style="text-align: center;color: #aba7a7;">Not Available</span>
              </ng-container>


            </label>

            <div class="side-item cursor" (click)="locationCollapsed = !locationCollapsed"
              [attr.aria-expanded]="!locationCollapsed" aria-controls="collapseBasic">
              <span class="sm-16-heading">Location</span><i class="caret float-r"></i>
            </div>
            <label class="side-lbl" id="collapseBasic" [collapse]="locationCollapsed">
              <ng-container *ngIf="locList.length>0">
                <label class="check-container" *ngFor="let data of locList">
                  <span class="mob-res">{{(data.name |slice:0:30) + '...'|| '-'}}({{data.count}})</span>
                  <span class="web-res">{{(data.name |slice:0:15) + '...'|| '-'}}({{data.count}})</span>
                  <input type="checkbox" ngModel (ngModelChange)="onChecked(data.name, $event, 'location')">
                  <span class="checkmark"></span>
                </label>
              </ng-container>
              <ng-container *ngIf="locList.length==0">
                <span style="text-align: center;color: #aba7a7;">Not Available</span>
              </ng-container>


            </label>


            <!-- <div class="side-item cursor" (click)="publicationCollapsed = !publicationCollapsed"
              [attr.aria-expanded]="!publicationCollapsed" aria-controls="collapseBasic">
              <span class="sm-16-heading">Publication Name</span><i class="caret float-r"></i>
            </div>
            <label class="side-lbl" id="collapseBasic" [collapse]="publicationCollapsed">
              <label class="check-container"
                *ngFor="let NavbarObj of navbarObj.publicationNameList">{{NavbarObj.publicationName}}
                ({{NavbarObj.count}})
                <input type="checkbox" value="NavbarObj" ngModel
                  (ngModelChange)="onChecked(NavbarObj, $event, 'publication')">
                <span class="checkmark"></span>
              </label>
            </label> -->

            <div class="side-item cursor" (click)="comCollapsed = !comCollapsed" [attr.aria-expanded]="!comCollapsed"
              aria-controls="collapseBasic">
              <span class="sm-16-heading">Company Name</span><i class="caret float-r"></i>
            </div>
            <label class="side-lbl" id="collapseBasic" [collapse]="comCollapsed">
              <ng-container *ngIf="companyList.length>0">
                <label class="check-container" *ngFor="let data of companyList"
                  title={{data.name}}>{{(data.name |slice:0:15) + '...'|| '-'}} ({{data.count}})
                  <input type="checkbox" ngModel (ngModelChange)="onChecked(data.name, $event, 'company')">
                  <span class="checkmark"></span>
                </label>
              </ng-container>
              <ng-container *ngIf="companyList.length==0">
                <span style="text-align: center;color: #aba7a7;">Not Available</span>
              </ng-container>


            </label>

          </div>
        </div>
      </div>
    </div>


    <div class="col-md-9" *ngIf="jobSearchList.length>0 && !loader">


      <div class="row wt-box">

        <div class="col-md-12 nopadding grey-row" style="margin-bottom: 15px;">

          <div class="col-md-6 col-xs-6 col-sm-6">
            <span style="white-space: pre;">Showing
              {{jobResponse.pageNumber*jobResponse.size+1}}-{{jobResponse.numberOfElements+ (jobResponse.pageNumber*jobResponse.size)}}
              of {{totalElements}}</span>

          </div>
          <div class="col-md-6 text-right">
            <div class="btn-group" dropdown placement="buttom-right">
              <span dropdownToggle class="action fw500 cursor" aria-controls="dropdown-disabled-menu">
                View {{size}} per page <span class="caret"></span>
              </span>

              <ul id="dropdown-disabled-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-disabled-menu">

                <li role="menuitem " class="cursor view-select" (click)="page=0;size=10;changePage()" *ngIf="size!=10">
                  View 10 per
                  page</li>
                <li role="menuitem " class="cursor view-select" (click)="page=0;size=20;changePage() " *ngIf="size!=20">
                  View 20
                  per page</li>

                <li role="menuitem " class="cursor view-select" (click)="page=0;size=30;changePage()" *ngIf="size!=30">
                  View 30 per
                  page</li>



              </ul>
            </div>
          </div>
        </div>


        <div class="col-md-12 lg-box" *ngFor="let data of jobSearchList; index as i">
          <div class=" col-md-12 higlighted nopadding">
            <div class="highlight-chip">
              Views : {{data.views}} <span class="pipegap">|</span> {{calculateDiff(data.job_expiry)}}

            </div>
          </div>
          <div class="col-md-12 nopadding border">
            <div class="col-md-12  info-box">
              <div class="col-md-2">
                <div class="row">
                  <div class="col-md-12 border image-holder">
                    <img src="https://api.v1.jobejee.com/resource/employer-logo/default-comp.png" *ngIf="!data.image && data.isNewsJob == 'JobejeeJob'">
                    <img src="{{data.image}}" *ngIf="data.image && data.isNewsJob == 'JobejeeJob'">
                    <img src="{{data.image}}" *ngIf="data.image && data.isNewsJob == 'NewsJob'">
                    <img src="https://jobejee.s3-ap-southeast-1.amazonaws.com/homeDefaultImg/newspaperjobs.png" *ngIf=" !data.image &&  data.isNewsJob == 'NewsJob'">

                    
                  </div>
                  <div class="col-md-12 nopadding text-center">
                    <a class="btn green-btn res-margin-btm" *ngIf="data.isNewsJob == 'JobejeeJob'"
                      [routerLink]="['/job/', replaceSpace(data.title), data.job_create_id]" type="button">View
                      Details</a>

                    <a class="btn green-btn res-margin-btm" *ngIf="data.isNewsJob == 'NewsJob'"
                      [routerLink]="['/newspaper/', replaceSpace(data.title), data.job_create_id]" type="button">View
                      Details</a>
                  </div>
                </div>
              </div>

              <div class="col-md-10 data-container res-nopadding">
                <div class="row">
                  <div class="col-md-7">
                    <p class="inner-header">
                      <a class="green-orange-link noDecoration" *ngIf="data.isNewsJob == 'JobejeeJob'"
                        [routerLink]="['/job/', replaceSpace(data.title), data.job_create_id]">
                        {{(data.title | uppercase) || 'Not Available'}}</a>

                      <!-- <a class="green-orange-link noDecoration" *ngIf="data.isNewsJob == 'NewsJob'"
                        [routerLink]="['/newspaper/', replaceSpace(data.title), data.job_create_id]">
                        {{(data.title | uppercase) || 'Not Available'}}</a> -->
                        <a class="green-orange-link noDecoration" *ngIf="data.isNewsJob == 'NewsJob'"
                        [routerLink]="['/newspaper/', replaceSpace(data.title), data.job_create_id]">
                        {{(data.title | uppercase) || 'Not Available'}}</a>
                    </p>
                  </div>
                  <div class="col-md-5 text-right res-share-row">

                    <ng-container *ngIf="data.bookmark=='Y';else noBookmark">
                      <span class="icontxt icon-gap" (click)="bookmarkJob(data, i)"> <i class="fa fa-bookmark"
                          aria-hidden="true"></i> Saved</span>
                    </ng-container>

                    <ng-template #noBookmark>
                      <span class="icontxt icon-gap" (click)="bookmarkJob(data,i)" *ngIf="userId"> <i
                          class="fa fa-bookmark-o" aria-hidden="true"></i> Save</span>
                      <span class="icontxt icon-gap" (click)="openModal(bookmark); setBookmarkId(data.job_create_id)"
                        *ngIf="!userId"> <i class="fa fa-bookmark-o" aria-hidden="true"></i> Save
                      </span>
                      <span [ngStyle]="showBookmark(data,i)" style="visibility:hidden;display: none">Hide</span>
                    </ng-template>
                    <span class="icontxt"> <img src="../../../assets/images/icons/share.png" alt=""> Share</span>
                  </div>
                </div>

                <div class="client-name">
                  <div>
                    <p><i class="fa fa-building-o buliding" aria-hidden="true"></i>
                      <a class="green-orange-link noDecoration" *ngIf="data.isNewsJob == 'JobejeeJob'"
                      [routerLink]="['/employer/', replaceSpace(data.company_name), data.empId]">
                      {{(data.company_name ) || 'Not Available'}}</a>
                      <span *ngIf="data.isNewsJob == 'NewsJob'">  {{(data.company_name ) || 'Not Available'}}</span>
                      
                      <!-- <b>{{data.company_name || 'Not Available'}}
                      </b> -->
                    </p>
                  </div>

                </div>
                <div>
                  <p><img src="../../../assets/images/icons/location.png" class="imgicon" alt=""> <b>Location :
                    </b>{{data.location || 'Not Available'}}</p>
                </div>

                <div class="col-md-6 nopadding">
                  <p><img src="../../../assets/images/icons/clock.png" class="imgicon" alt="">
                    <b>Job Type :</b>
                    <span *ngIf="data.jobType=='F'"> Full Time</span>
                    <span *ngIf="data.jobType=='P'"> Part Time</span>
                    <span *ngIf="data.jobType=='C'"> Contract Job</span>
                  </p>
                </div>
                <div class="col-md-6 nopadding">
                  <p><img src="../../../assets/images/icons/experience.png" class="imgicon" alt=""> <b>Experience
                      :</b> {{data.exp_min}}-{{data.exp_max}} <span *ngIf="data.exp_min || data.exp_max">Years</span> <span *ngIf="!data.exp_min && !data.exp_max">Not Available</span> </p>
                </div>
                <div>
                  <p><img src="../../../assets/images/icons/bulb.png" class="imgicon" alt="">
                    <b>Key Skills : </b>
                    <!-- {{data.key_skills}} -->
                    <label class="keyskills-small" *ngFor="let tag of pushIntoArray(data.key_skills)">{{tag }}</label>
                  </p>

                  <!-- <p><img src="../../../assets/images/icons/bulb.png" class="imgicon" alt="">Key Skills : -->

                  <!-- </p> -->
                </div>
                <div class="text-right">
                  <span class="red-txt-sm">Posted Date : {{(data.publish_on | date) || 'Not Available'}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- pagination -->
        <div class=" col-md-12  text-center nopadding">
          <nav aria-label="Page navigation">
            <ul class="pagination" *ngIf="totalPages > 1">
              <li [ngClass]="{disabled: currentPage == 1}">
                <a *ngIf="currentPage != 1; else disFirst" (click)="page = 0; changePage()">First</a>
                <ng-template #disFirst>
                  <a>First</a>
                </ng-template>
              </li>
              <li [ngClass]="{disabled: currentPage == 1}">
                <a *ngIf="currentPage != 1; else disPrevious" (click)="page = (currentPage-2); changePage()"><i
                    class="fa fa-angle-double-left fa-lg side" aria-hidden="true"></i></a>
                <ng-template #disPrevious>
                  <a><i class="fa fa-angle-double-left fa-lg side" aria-hidden="true" style="color: #999;"></i></a>
                </ng-template>
              </li>
              <li [ngClass]="currentPage == x ? 'current' : ''" *ngFor="let x of totalPagination.pages">
                <a (click)="page = (x-1); changePage()">{{x}}</a>
              </li>
              <li [ngClass]="{disabled: currentPage == totalPages}">
                <a *ngIf="currentPage != totalPages; else disNext" (click)="page = (currentPage); changePage()"><i
                    class="fa fa-angle-double-right fa-lg side" aria-hidden="true"></i></a>
                <ng-template #disNext>
                  <a><i class="fa fa-angle-double-right fa-lg side" aria-hidden="true" style="color: #999;"></i></a>
                </ng-template>
              </li>
              <li [ngClass]="{disabled: currentPage == totalPages}">
                <a *ngIf="currentPage != totalPages; else disLast"
                  (click)="page = (totalPages-1); changePage()">Last</a>
                <ng-template #disLast>
                  <a>Last</a>
                </ng-template>
              </li>
            </ul>
          </nav>
        </div>
      </div>




    </div>

    <div class="col-md-9 " *ngIf="jobSearchList.length==0 && !loader">


      <div class="col-md-12 nodata ">
        <div class="col-md-12 nopadding">
          <p class="grey-row">0 Jobs Found</p>
        </div>

        <div class="col-md-12">
          <img src="../../../assets/images/notfound.png" />
        </div>

        <div style="margin-top: 15px;" class="col-md-12">
          <p>No jobs found for <b *ngIf="keyskillList"> '{{keyskillList}}'</b></p>
          <p style="color: #777;
            font-weight: 600;">Please search with another keyword.</p>
        </div>
      </div>

    </div>



    <div class="col-md-9" *ngIf="loader">
      <div class="row">
        <div class="col-md-12" style="padding:150px">

          <app-jobejee-loader></app-jobejee-loader>
        </div>
      </div>

      <!-- <app-jobejee-loader></app-jobejee-loader> -->
    </div>




  </div>
</div>

<!-- Modal -->
<ng-template #bookmark>
  <ng-container>
    <div class="modal-header" style="position: relative;">
      <p>Please Login to Continue</p>
      <!-- <p>HR OFFICER</p> -->
      <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <app-bookmark-job></app-bookmark-job>
      </div>
    </div>
  </ng-container>

</ng-template>
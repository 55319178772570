import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder} from '@angular/forms';
import { EmployerApi } from 'src/app/common/services/employerApi.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/timer';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/switchMap';
import { ApiServiceService } from '../../services/api-service.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  constructor(private apiService: ApiServiceService,
    private toastr: ToastrService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.initilizeForm();
  }
  passwordForm: FormGroup;
  initilizeForm() {
    this.passwordForm = this.fb.group({
      email: [null, [Validators.required, Validators.pattern('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$'), Validators.maxLength(50)], this.checkEmail.bind(this)],

    });
  }


  checkEmail(control: FormControl) {
    return Observable.timer(1000).switchMap(() => {
      return this.apiService.checkEmail(control.value)
        .mapTo(null)
        .catch(err =>
          Observable.of({ notAvailability: true }));
    });
  }




  processing: boolean = false;
  onSubmit() {
    this.processing = true;
    if (this.passwordForm.valid) {
      let email = this.passwordForm.controls.email.value;
      this.apiService.forgetPassword(email).subscribe(
        (res: any) => {
          this.toastr.success('Successful', res.msg);
          this.processing = false;
          this.passwordForm.reset();
        },
        (error: any) => {
          this.toastr.error('Unsuccessful', error.msg, {
            timeOut: 3000
          });
          this.processing = false;
        }
      )
    }

  }
}

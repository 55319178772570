<div class="container-fluid " style="margin-top: 200px; margin-bottom: 15px;" *ngIf="loader">
  <app-jobejee-loader></app-jobejee-loader>
</div>
<div class="container navbar-top" *ngIf="!loader" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}">
  <div class="row">
    <div class="col-md-12">
      <ol class="breadcrumb">
        <li><a class="cursor" [routerLink]="['/']"><i class="fa fa-home btn-icon"></i>Home</a></li>
        <li class="active"><a>Newspaper Job</a></li>
      </ol>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="col-md-12 job_summary_box">
        <div class="col-md-12 heading_bar">
          <p class="heading">Job Summary</p>
        </div>
        <div class="col-md-12 job_content">
          <div class="col-md-2 nopadding">
            <div class="col-md-12 prof_img">
              <img src="https://jobejee.s3-ap-southeast-1.amazonaws.com/homeDefaultImg/newspaperjobs.png"
                *ngIf="!jobsObj.image">
              <img src="{{jobsObj.image}}" *ngIf="jobsObj.image">
            </div>
            <!-- <div class="col-md-12 nopadding" *ngIf="jobsObj.newspaperEmail && jobsObj.applied == 'N'">
              <button class="apply_btn">APPLY JOB</button>
            </div>
            <div *ngIf="jobsObj.applied == 'Y'">
              <p class="nomargin text-center">
                <i class="fa fa-file-text-o"></i> <label class="font-500 text-center">Status</label><br>
                <span class="text-orange">In Progress </span>
              </p>
            </div> -->
          </div>

          <div class="col-md-10">
            <div class="row">
              <div class="col-md-12">
                <p class="orange_heading">{{jobsObj.title}}</p>
                <p class="sub_heading"><i class="fa fa-building-o icon_space" aria-hidden="true"></i>
                  {{jobsObj.organizationName || 'Company Name'}}
                </p>
                <p><i class="fa fa-map-marker icon_space_12"></i>Location:
                  <span>Nepal, </span>
                  <span *ngIf="jobsObj.province">{{jobsObj.province}}, </span>
                  <span *ngIf="jobsObj.location">{{jobsObj.location}}</span>
                  <span *ngIf="jobsObj.jobLocation">, {{jobsObj.jobLocation}}</span>

                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p><i class="fa fa-hourglass-end icon_space"></i>Job Type:
                  <span *ngIf="jobsObj.jobTypeFlag == 'F'">Full Time</span>
                  <span *ngIf="jobsObj.jobTypeFlag == 'P'">Part Time</span>
                  <span *ngIf="jobsObj.jobTypeFlag == 'C'">Contract</span>
                </p>
              </div>
              <div class="col-md-6">
                <p *ngIf="jobsObj.ctcType == 'F'"><i class="fa fa-money icon_space"></i>Salary :
                  <span *ngIf="jobsObj.ctcLakhMin != 0 && jobsObj.ctcLakhMin">{{(jobsObj.ctcLakhMin | number) || 'Not
                    Available'}}
                  </span>
                  <span *ngIf="jobsObj.ctcThouMin != 0 && jobsObj.ctcThouMin"> {{jobsObj.ctcThouMin | number}} </span>
                  <span *ngIf="jobsObj.salaryType == 'M'">(Monthly)</span>
                  <span *ngIf="jobsObj.salaryType == 'Y'">(Yearly)</span>
                </p>

                <p *ngIf="jobsObj.ctcType == 'R'"><i class="fa fa-money icon_space"></i>Salary :
                  <span *ngIf="jobsObj.ctcLakhMin != 0 && jobsObj.ctcLakhMin">{{jobsObj.ctcLakhMin|number}}
                  </span>
                  <span *ngIf="jobsObj.ctcThouMin != 0 && jobsObj.ctcThouMin"> {{jobsObj.ctcThouMin|number}}
                  </span>
                  to
                  <span *ngIf="jobsObj.ctcLakhMax != 0 && jobsObj.ctcLakhMax">{{jobsObj.ctcLakhMax|number}}
                  </span>
                  <span *ngIf="jobsObj.ctcThouMax != 0 && jobsObj.ctcThouMax"> {{jobsObj.ctcThouMax|number}}
                  </span>
                  <span *ngIf="jobsObj.salaryType == 'M'">(Monthly)</span>
                  <span *ngIf="jobsObj.salaryType == 'Y'">(Yearly)</span>
                </p>

                <p *ngIf="jobsObj.ctcType == 'N'"><i class="fa fa-money icon_space"></i>Salary :
                  <span>Negotiable</span>
                </p>


              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p><i class="fa fa-briefcase icon_space"></i>Experience:
                  <span *ngIf="jobsObj.expMin || jobsObj.expMax">{{jobsObj.expMin}}{{jobsObj.expMax == 0
                    ? '' : ' - '+jobsObj.expMax}} Years</span>

                  <span *ngIf="!(jobsObj.expMin || jobsObj.expMax)">Fresher</span>
                </p>
              </div>
              <div class="col-md-6">
                <p><i class="fa fa-users icon_space" aria-hidden="true"></i>Vacancies : {{jobsObj.noVac}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <span><i class="fa fa-lightbulb-o" style="margin-right: 12px;" aria-hidden="true"></i>Key
                  Skills: </span>
                <label class="keyskills-small" *ngFor="let tag of pushIntoArray(jobsObj.keySkills); let i = index">{{tag
                  || 'Not Available'}}</label>


              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <p><i class="fa fa-clock-o icon_space_12"></i>Posted On: {{jobsObj.publishOn}}</p>
              </div>
              <div class="col-md-6">
                <p><i class="fa fa-calendar-check-o icon_space_12"></i>Expiry Date: {{jobsObj.jobExpiry}}</p>
              </div>
              <div class="col-md-12">
                <p><i class="fa fa-newspaper-o icon_space" aria-hidden="true"></i>Source : {{jobsObj.newsPublication}}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-12 footer nopadding">
          <div class="col-md-4 boxes"><i class="fa fa-thumbs-o-up"></i> 0</div>
          <div class="col-md-4 boxes"><i class="fa fa-share-alt"></i></div>
          <div class="col-md-4 box"><i class="fa fa-bookmark"></i> 0</div>
        </div> -->
      </div>

      <div class="col-md-12 overview_box">
        <div class="row">
          <div class="col-md-12 heading_bar">
            <p class="heading">Job overview</p>
          </div>
          <div class="col-md-12 body">
            <div class="form-horizontal">
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <div class="col-md-4">
                    <label class="control-label">Location :</label>
                  </div>
                  <div class="col-md-8">
                    <label class="control-label values">
                      <span>Nepal, </span>
                      <span *ngIf="jobsObj.province">{{jobsObj.province}}, </span>
                      <span *ngIf="jobsObj.location">{{jobsObj.location}}</span>
                      <span *ngIf="jobsObj.jobLocation">, {{jobsObj.jobLocation}}</span>

                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-horizontal">
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <div class="col-md-4">
                    <label class="control-label">Job industry :</label>
                  </div>
                  <div class="col-md-8">
                    <label class="control-label values">{{jobsObj.industry || 'Not Available'}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-horizontal">
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <div class="col-md-4">
                    <label class="control-label">Functional Area :</label>
                  </div>
                  <div class="col-md-8">
                    <label class="control-label values">{{jobsObj.funcArea || 'Not Available'}}</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-horizontal">
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <div class="col-md-4">
                    <label class="control-label">Education :</label>
                  </div>
                  <div class="col-md-8">
                    <label class="control-label values">{{jobsObj.qualification || 'Not Available'}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 apply_box" *ngIf="jobsObj.jobDesc">
        <div class="row">
          <div class="col-md-12 heading_bar">
            <p class="heading">Job Description</p>
          </div>
          <div class="col-md-12 body">
            <label class="control-label values" [innerHtml]="jobsObj.jobDesc"></label>
          </div>
        </div>
      </div>

      <div class="col-md-12 news_box">
        <div class="row">
          <div class="col-md-12 heading_bar">
            <p class="heading">Original Newspaper Post</p>
          </div>
          <div class="col-md-12 body">
            <div class="col-md-12 nopadding">
              <img src="{{jobsObj.image}}">
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 apply_box">
        <div class="row">
          <div class="col-md-12 heading_bar">
            <p class="heading">Instruction to Apply</p>
          </div>
          <div class="col-md-12 body">
            <div class="col-md-4">
              <label class="control-label">Instruction To Apply :</label>
            </div>
            <div class="col-md-8">
              <label class="control-label values" [innerHtml]="jobsObj.instrucApply"></label>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="col-md-4 other_jobs" *ngIf="similarList.length">
      <div class="col-md-12 heading_box">
        <p class="heading">Other Similar Jobs</p>
      </div>
      <div class="col-md-12 other_list" *ngFor="let SimilarList of similarList | slice : 0 : 10">
        <a class="cursor" [routerLink]="['/newspaper', replaceSpace(SimilarList.title), SimilarList.jobCreateId]">
          <p class="o1 txt">{{SimilarList.title}}</p>
          <p class="o2 txt">{{SimilarList.organizationName}}</p>
        </a>
      </div>
    </div>
  </div>
</div>



<div class="container-fluid footer-flud" *ngIf="!loader">
  <div class="row apply-footer">


    <div class="col-md-12 nopadding" *ngIf="jobsObj.newspaperEmail && jobsObj.applied == 'N'">
      <!-- <button class=" btn btn-orange">APPLY JOB</button> -->
      <label>
        Please Go through Instruction to Apply Section
      </label>
    </div>
    <div *ngIf="jobsObj.applied == 'Y'">
      <p class="nomargin text-center">
        <i class="fa fa-file-text-o"></i> <label class="font-500 text-center">Status</label><br>
        <span class="text-orange">In Progress </span>
      </p>
    </div>


    <!-- <div *ngIf="jobsObj.appViaJobejee == 'Y'">
      <button class="btn btn-orange " *ngIf="!jsInfoId" [routerLink]="['/jobseeker/login']"
        [queryParams]="{id:jobId, jn:jobTitle}">APPLY JOB</button>

      <button *ngIf=" jsInfoId && jobsObj.applied=='N'" class=" btn btn-orange" (click)="checkResume()">APPLY
        JOB</button>

      <div *ngIf="jobsObj.applied == 'Y'">
        <p class="padding-top-15">
          <i class="fa fa-file-text-o" style="margin-right:5px;"> </i>
          <label class="font-500 text-center"> Status : </label>
          <span class="text-orange" *ngIf="jobsObj.status == 'P'">In Progress</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'M'">In Progress </span>
          <span class="text-orange" *ngIf="jobsObj.status == 'UM'">In Progress</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'R'">Rejected</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'IR1'">Interview Round 1 </span>
          <span class="text-orange" *ngIf="jobsObj.status == 'IR2'">Interview Round 2 </span>
          <span class="text-orange" *ngIf="jobsObj.status == 'IR3'">Interview Round 3 </span>
          <span class="text-orange" *ngIf="jobsObj.status == 'H'">On hold </span>
          <span class="text-orange" *ngIf="jobsObj.status == 'S'">Shortlisted</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'O'">Offer Offered</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'J'">Joined</span>
          <span class="text-orange" *ngIf="jobsObj.status == 'NTH'">In Progress</span>
        </p>
      </div>
    </div> -->

    <!-- <div class="col-sm-12 nopadding" *ngIf="jobsObj.appViaJobejee == 'N' || !jobsObj.appViaJobejee">
      <label *ngIf="jobsObj.appViaPost"> Apply Via Post </label>
      <a *ngIf="jobsObj.applyViaUrl" href="{{jobsObj.applyViaUrl}}" target="_blank">Apply via company
        site</a>
    </div> -->
    <!-- <div class="btn btn-orange">APPLY JOB</div> -->
  </div>
  <div class="clearfix"></div>
</div>
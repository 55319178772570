<div class="row">
  <div class="col-md-12 search-container">
    <div class="container">
      <div class="row search-inp">
        <div class="form" [formGroup]="searchForm">
          <div class="col-md-5 search-custom">
            <input type="text" class="form-control" formControlName="keyskillList" (ngModelChange)="txtEnter()"
              (keyup.enter)="keyChange($event)" placeholder="Search Job by Title, Keyskill, Company">
            <div class="text-center txt-validation " style="margin-top:5px;">
              {{erro}}
            </div>
          </div>
          <div class="col-md-5 res-m-b-15">
            <input type="text" class="form-control" formControlName="locationList" (keyup.enter)="keyChange($event)"
              placeholder="Location">
          </div>
        </div>
        <!-- <div class="col-md-5 res-m-b-15">
          <select class="form-control" formControlName="experience">
            <option *ngFor="let ExpYears of expYears" [value]="ExpYears.val">{{ExpYears.display}}</option>
          </select>
        </div> -->
        <div class="col-md-2">
          <button class="btn-orange col-sm-6 col-md-12 col-xs-6" (click)="keyChange($event)">SEARCH JOB</button>

          <div class="text-center search-adv col-sm-6 col-md-12">
            <span (click)="openModal(template)" style="white-space: pre;">Advanced Search</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg-new-green mob-view" style="font-size: 14px;">
      <div class="container">
        <p> Resume Generate, Application Status, Job Recommendation, Bookmarked Jobs,<span class="cursor learn-more"
            [routerLink]="['/employment-index']" routerLinkActive="router-link-active" >Employment Index</span>
          <b><a [routerLink]="['/learn-more']" class="learn-more">Learn More</a></b>
        </p>
      </div>
    </div>

  </div>
</div>
<!--=================Modal==========================-->

<ng-template #template>
  <!--Heading Section-->
  <div class="modal-header top-poper-heading">
    <h4 class="modal-title text-left">Advance Job Search</h4>
    <div class="x-close" (click)="modalRef.hide()">
      <span>&times;</span>
    </div>
  </div>
  <!--Body Section-->
  <div class="modal-body">
    <form [formGroup]='searchForm'>
      <div class="row">

        <!--Key words-->
        <div class="col-md-3">
          <span class="modal-label">Job Title</span>
        </div>
        <div class="col-md-9 form-group">
          <input type="text" class="form-control" formControlName="keyskillList" placeholder="E.g. Java, Database">
        </div>


        <!--Location-->
        <div class="col-md-3">
          <span class="modal-label">Location</span>
        </div>
        <div class="col-md-9 form-group">
          <input type="text" class="form-control" formControlName="locationList" placeholder="Location">
        </div>

        <!--Work Experience-->
        <div class="col-md-3">
          <span class="modal-label"> Experience</span>
        </div>
        <div class="col-md-9 form-group">
          <select class="form-control" formControlName="experience">
            <option value="null" disabled>Select Experience Year</option>
            <option *ngFor="let ExpYears of expYears" [value]="ExpYears.val">{{ExpYears.display}}</option>
          </select>
        </div>


        <!--Industry-->
        <div class="col-md-3">
          <span class="modal-label">Industry</span>
        </div>
        <div class="col-md-9 form-group">

          <ng-select [items]="industryList" placeholder="Select Industry" bindLabel="categoryDesc"
            bindValue="categoryId" formControlName="industry">
          </ng-select>


        </div>


        <!--Functional Area-->
        <div class="col-md-3">
          <span class="modal-label">Functional Area</span>
        </div>
        <div class="col-md-9 form-group">
          <ng-select [items]="featureList" placeholder="Select Functional Area" bindLabel="categoryDesc"
            bindValue="categoryId" formControlName="funcArea">
          </ng-select>

          <!-- <select class="form-control select" formControlName="funcArea">
            <option value=null disabled>Select one</option>
            <option value={{item.categoryId}} *ngFor="let item of featureList">{{item.categoryDesc}}</option>
          </select> -->
        </div>


        <!--Job Type-->
        <div class="col-md-3">
          <span class="modal-label">Job Type</span>
        </div>
        <div class="col-md-9 form-group">
          <select class="form-control select" formControlName="jobTypeFlag">
            <option value=null disabled>Select Job Type</option>
            <option value="F">Full Time</option>
            <option value="P">Part Time</option>
            <option value="C">Contract</option>
          </select>
        </div>

        <div class="col-md-12 text-center" style="margin-top:15px">
          <button class="btn green-btn" (click)="clickSearch()" [disabled]="searchForm.invalid  "
            style="margin-right: 15px;">Search</button>
          <button class="btn-cancel" (click)="cancelForm()">Cancel</button>
        </div>

      </div>
    </form>
  </div>

  <!--Border-->
  <!--Buttons Section-->
  <!-- <div class="mfooter">

  </div> -->

</ng-template>
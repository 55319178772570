import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
@Component({
  selector: 'app-log-history',
  templateUrl: './log-history.component.html',
  styleUrls: ['./log-history.component.scss']
})
export class LogHistoryComponent implements OnInit {

 
  constructor(
    private apiServiceService: ApiServiceService,

    private modalService: BsModalService,
  ) { }

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg"
  };

  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  ngOnInit(): void {

    this.getLogHistory();
  }

  yearList: any = [];
  getLogHistory() {
    this.apiServiceService.logHistory().subscribe(
      (res: any) => {
        this.yearList = res;
      }
    )
  }

  yearWiseDetail: any = [];
  yearWiseSpinner: boolean = false;
  getEmploymentFromYear(year) {
    this.yearWiseSpinner = true;
    this.apiServiceService.logHistoryByYear(year).subscribe(
      (res: any) => {
        this.yearWiseDetail = res;
        this.yearWiseSpinner = false;
      }
    )
  }


  monthList: any = [];
  monthSpinner: boolean = false;
  monthName:any;
  getEmploymentFromMonth(year, month,name) {
    this.monthSpinner = true;
    this.monthName=name
    this.apiServiceService.employmentIndexByMonth(year, month).subscribe(
      (res: any) => {
        this.monthList = res;
        this.monthSpinner = false;
      }
    )
  }


  closeModal() {
    this.modalRef.hide();

  }
}

<!-- <div class="container-fluid footer_wrapper">
  <br>
  <div class="container">
    <div class="col-md-4">
      <p><img src="../../../assets/images/logo_white.png"></p>
      <p>Jobejee is the fastest growing job portal in Nepal.
        Find your career opportunities here!Search among thousands
        of jobs from top companies, industries and locations of your choice!</p>
    </div>
    <div class="col-md-8 quicklinks">
      <div class="row-responsive">
        <div class="col-md-4 col-xs-6">
          <p class="headings">Quick Links</p>
          <p><a class="cursor" target="_blank" [routerLink]="['/aboutus']">About Us</a></p>
          <p><a class="cursor" [routerLink]="['']">Blogs</a></p>
          <p><a class="cursor" target="_blank" [routerLink]="['/faq-question']">FAQ / Help</a></p>
          <p><a class="cursor" target="_blank" [routerLink]="['/privacy-policies']">Privacy Policy</a></p>
          <p><a class="cursor" target="_blank" [routerLink]="['/terms-and-conditions']">Terms & Conditions</a></p>
        </div>
        <div class="col-md-4 col-xs-6">
          <p class="headings">Jobseekers</p>
          <p><a [routerLink]="['/jobseeker/login']">Login</a></p>
          <p><a [routerLink]="['/jobseeker/register']">Create an account</a></p>
        </div>
        <div class="col-md-4  col-xs-6">
          <p class="headings">Employers</p>
          <p><a class="cursor" href="https://www.employers.jobejee.com/#!/employer-login">Login</a></p>
          <p><a class="cursor" href="https://www.employers.jobejee.com/#!/employer-register">Register</a></p>
          <p><a [routerLink]="['/']">Register</a></p>
          <p><a [routerLink]="['/']">Hiring & Branding plans</a></p>
        </div>
      </div>
    </div>

  </div>
</div>


<div class="container-fluid footer_two">
  <div class="container">
    <div class="col-md-4">
      <span class="btn-icon">Connect With Us:</span>
      <a href="https://facebook.com/jobejee" target="_blank">
        <span class="btn-icon"><i class="fa fa-facebook" aria-hidden="true"></i></span>
      </a>

      <a href="https://www.linkedin.com/company/jobejee" target="_blank">
        <span class="btn-icon"><i class="fa fa-linkedin" aria-hidden="true"></i></span>
      </a>

      <a href="https://twitter.com/jobejeenepal" target="_blank">
        <span class="btn-icon"><i class="fa fa-twitter" aria-hidden="true"></i></span>
      </a>

      <a href="https://www.instagram.com/jobejee/" target="_blank">
        <span class="btn-icon"><img src="../../../assets/images/instagram.png"></span>
      </a>


    </div>
    <div class="col-md-4">
      <p>All rights reserved © 2017 Suvidha Tech Pvt. Ltd.</p>
    </div>
    <div class="col-md-3 text-right">
      <p> <span class="btn-icon">Download App</span>
        <a href="https://play.google.com/store/apps/details?id=com.suvidhatech.application" target="_blank">
          <img class="playStore_img" src="../../../assets/images/playstorecircle.png"></a>
      </p>
    </div>
  </div>
</div> -->


<div class="container-fluid footer_wrapper">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <div class="logo-holder">
          <img src="../../../assets/images/logo_white.png" alt="">
        </div>
        <p class="jsutify">Jobejee is the fastest-growing job portal in Nepal. Find your career opportunities
          here. Search among thousands of jobs from top companies, industries, and locations of your choice.</p>
      </div>
      <div class="col-md-2 col-xs-6 col-sm-4">
        <p class="headings">Quick Links</p>
        <ul>
          <li><a class="footer-link" routerLink="/aboutus" target="_blank">About Us</a></li>
          <li><a class="footer-link" routerLink="/faq-question" target="_blank">FAQ / Help</a></li>
          <li><a class="footer-link" routerLink="/privacy-policies" target="_blank">Privacy Policy</a></li>
          <!-- <li><a class="footer-link" routerLink="/privacy-policies" target="_blank">Site Map</a></li> -->
          <li><a class="footer-link" routerLink="/terms-and-conditions" target="_blank">Terms & Conditions</a></li>
          <li><a class="footer-link" routerLink="/account-deletion" target="_blank">Account Deletion</a></li>
        </ul>
      </div>


      <!-- <div class="col-md-3 col-xs-6 col-sm-4">
        <p class="headings">Jobs By Industry</p>
        <ul class="industry" *ngFor="let item of industryList">
          <li (click)="searchJobByIndustry(item.industryName)" style="line-height: 15px;">{{item.industryName}}</li>
          
        </ul>
      </div> -->

      <div class="col-md-3 col-xs-6 col-sm-4">
        <p class="headings">Jobs By Functional Area</p>
        <ul class="industry" *ngFor="let item of functionalAreaList">
          <li (click)="searchJobByFunctionalArea(item.funcArea)" style="line-height: 15px;">{{item.funcArea}}</li>
          
        </ul>
      </div>


      <div class="col-md-3 col-xs-8 col-sm-4">
        <p class="headings">Our Location</p>
        <p>Sorhakhutte,
          Kathmandu, Nepal</p>
        <div class="location-box">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.954559848152!2d85.30724151506232!3d27.71868923278717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb19ab465964fb%3A0x1986763a21ed8014!2sJobejee!5e0!3m2!1sen!2snp!4v1584092876556!5m2!1sen!2snp"
            width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="container-fluid footer_two">
  <div class="container">
    <div class="col-md-4 col-xs-12"><span class="btn-icon">Connect With
        Us:</span><a href="https://facebook.com/jobejee" target="_blank"><span class="btn-icon"><i aria-hidden="true"
            class="fa fa-facebook"></i></span></a><a href="https://www.linkedin.com/company/jobejee"
        target="_blank"><span class="btn-icon"><i aria-hidden="true" class="fa fa-linkedin"></i></span></a><a
        href="https://twitter.com/jobejeenepal" target="_blank"><span class="btn-icon"><i aria-hidden="true"
            class="fa fa-twitter"></i></span></a><a href="https://www.instagram.com/jobejee/" target="_blank"><span
          class="btn-icon"><img src="../../../assets/images/instagram.png"></span></a>
    </div>
    <div class="col-md-4 col-xs-12">
      <p>All rights reserved © 2017 - {{getYear}} Suvidha Tech Pvt. Ltd.</p>
    </div>
    <div class="col-md-3 col-xs-12 mob-res">
      <p><span class="btn-icon">Download App</span><a
          href="https://play.google.com/store/apps/details?id=com.suvidhatech.application" target="_blank"><img
            class="playStore_img" src="../../../assets/images/playstorecircle.png"></a></p>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-display-items',
  templateUrl: './display-items.component.html',
  styleUrls: ['./display-items.component.scss']
})
export class DisplayItemsComponent implements OnInit {
  @Input() numberOfItems:number[];
  @Input() totalElements:number[];
  @Output() size= new EventEmitter()
  constructor(
    private activatedRoute: ActivatedRoute,

  ) { }

  items:number=null;
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      // this.page = +params['page'] - 1 ? +params['page'] - 1 : 0;
      params['size'] ? this.items = params['size'] : this.items = this.numberOfItems[0];
    });
   
  }

  sizeChanged(selectedItem){
    this.size.emit(selectedItem);
    this.items=selectedItem;
    console.log(this.items);
  }
}

<div class="container-fluid container-fixed-top-nav">
    <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12 wt-box">
        <p class="header">Forgot Password</p>
        <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
            <div class="co-lmd-12 nopadding form-group">
                <input type="text" formControlName="email" placeholder="Enter your Email" class="form-control">

                <div class="invalid-feedback"
                    *ngIf="passwordForm.controls.email.touched && passwordForm.controls.email.invalid">
                    <span *ngIf="passwordForm.controls.email.errors.required"> Email is required </span>
                    <span *ngIf="passwordForm.controls.email.errors.pattern" class="mat-error">Invalid
                        Email. Email format
                        should be
                        <b> jobejee@gmail.com</b>
                    </span>
                    <span *ngIf="passwordForm.controls.email.errors.maxlength">Email Address should be
                        maximum 50
                        characters</span>
                    <span *ngIf="passwordForm.get('email').errors['notAvailability']">Sorry! Email
                        doesn't exist.
                    </span>

                </div>

            </div>
            <button class="btn green-btn" [disabled]="passwordForm.invalid" type="submit">Send
                Email</button>
        </form>
        <p class="info-txt"><span class="orange-text">Note : </span>Enter the email address you have used to
            register your account with
            Jobejee. You will receive an email with which you can change your password.</p>
    </div>
</div>
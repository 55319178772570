import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {
  constructor(private router: Router) {}

  public href: string = "";

  // need to remove later

  remoteUrl =  "hulas-hipco-steel-fabrication";


  ngOnInit() {
    let location = window.location.href;
    let a = location.split("/");
    this.href = (a[a.length-1]);
    console.log(this.href);
   
  }

}

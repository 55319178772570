import { Component, OnInit, TemplateRef, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { EmployerApi } from 'src/app/common/services/employerApi.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-jobseeker-profile',
  templateUrl: './jobseeker-profile.component.html',
  styleUrls: ['./jobseeker-profile.component.scss']
})
export class JobseekerProfileComponent implements OnInit {

  public scale = 0.8;

  config = {
    backdrop: false,
    ignoreBackdropClick: false,
    class: 'modal-lg'
  };


  @Input() profileData: any;
  modalRef: BsModalRef;

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  constructor(

    private modalService: BsModalService,
    private empService: EmployerApi,
    private router: Router,
    public rest: RestApiService,

  ) {

  }

  p: any;
  pd: any = ' ';
  allowDownload: boolean = false;
  ngOnInit(): void {


    // if (href.match('/resume-access-plan/')) {
    //   this.allowDownload = false;
    // }
    // else {
    //   this.allowDownload = true;
    // }

    this.p = this.profileData;

    console.log(this.p)
    this.getProfile();

  }

  edu: any = [];
  exp: any = [];
  jsRef: any = [];
  jsCert: any = [];
  loader: boolean = true;
  getProfile() {

    this.empService.profileData(this.p.jsInfoId).subscribe(
      (res: any) => {
        console.log(res);
        this.pd = res;
        if (res.jsEdu)
          this.edu = res.jsEdu;
        if (res.jsExp)
          this.exp = res.jsExp;
        if (res.jsRef)
          this.jsRef = res.jsRef;
        if (res.jsCert)
          this.jsCert = res.jsCert;
        this.loader = false;

        // let href = this.router.url;
        // if (href.match('/resume-access-plan'))
        //   this.checkPermission();
        // else
        //   this.allowPermission = true;
      }


    )
  }


  // Download CV for Mobile view
  downloadCv() {

    this.empService.downloadCv(this.p.jsInfoId).subscribe(
      (res: any) => {

      }
    )
  }

  //For Resume Access Plan
  check() {

    let href = this.router.url;
    if (href.match('/resume-access-plan'))
      this.downloadLimitCv();

  }



  downloadLimitCv() {
    this.empService.checkDownloadLimit(this.p.jsInfoId).subscribe(
      (res: any) => {
        // this.downloadResume(res.msg);
        this.getPurchaseDetails();
      },
      (error: any) => {
        // this.openModal(this.modalTemplate);
        // this.errorMsg = error.error.msg;
      }
    )
  }

  purchaseDetails: any = '';
  getPurchaseDetails() {
    this.empService.resumePurchaseDetails().subscribe(
      (res: any) => {
        this.purchaseDetails = res;
        this.rest.resumePurchaseDetail = this.purchaseDetails;

      }
    )
  }



  closeModal() {
    this.modalRef.hide();
  }


  // checkPermission() {
  //   let href = this.router.url;
  //   if (href.match('/resume-access-plan/'))
  //     return false;
  //   else
  //     return true;
  // }

  allowPermission: boolean = false;
  checkPermission() {

    console.log("Checking permission")
    this.empService.checkDownloadLimit(this.p.jsInfoId).subscribe(
      (res: any) => {
        this.allowPermission = true;
      },
      (error: any) => {
        this.allowPermission = false;

      }
    )
  }




}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-employer-email',
  templateUrl: './verify-employer-email.component.html',
  styleUrls: ['./verify-employer-email.component.scss']
})
export class VerifyEmployerEmailComponent implements OnInit {

  constructor(
    private route: Router,
    private apiService: ApiServiceService,
    private cookie: CookieService,
    private toastr: ToastrService
  ) { }

  code: any;
  url: any;
  ngOnInit() {
    this.url = this.route.url;
    let data = this.url.split('=')
    this.code = data[1];
    this.verify();
  }

  verifyStatus: boolean = false;
  errorMsg: boolean = false;
  verify() {
    this.apiService.verifyEmai(this.code).subscribe(
      (res: any) => {
        this.verifyStatus = true;
        this.errorMsg = false;
        this.cookie.deleteAll();
        this.toastr.success('Successful', 'Email verified Successfully.');
        this.route.navigate(['/login']);
      },
      (error: any) => {
        this.errorMsg = true;
        this.verifyStatus = true;
        this.toastr.error('Unsuccessful', error.error.errorMessage, {
          timeOut: 3000
        });
        this.route.navigate(['/login']);

      }
    )
  }

}

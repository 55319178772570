<div class="container-fluid " style="margin-top: 200px; margin-bottom: 15px;" *ngIf="loader">
  <app-jobejee-loader></app-jobejee-loader>
</div>

<div class="container-fluid navbar-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}" *ngIf="!loader">
  <div class="row fullview">
    <div class="container nopadding">
      <div class="col-md-12 banner-box">
        <img *ngIf="!clientData.bannerImage" src="../../../assets/images/login-banner.jpg" class="banner-img" alt="">
        <img *ngIf="clientData.bannerImage" src="{{clientData.bannerImage}}" class="banner-img" alt="">
      </div>
    </div>
    <div class="container detail-box res-nopadding">

      <!-- Introduction -->
      <div class="col-md-12 wt-box">
        <div class="row" *ngIf="clientData">
          <div class="col-md-9 col-xs-12">
            <div class="logo-section">
              <div class="col-md-3 col-xs-12 nopadding logobox">
                <img class="img-thumbnail" src="{{clientData.image}}" alt="" *ngIf="clientData.image">
                <img class="img-thumbnail" src="../../../assets/images/default-comp.jpeg" alt=""
                  *ngIf="!clientData.image">
              </div>

              <div class=" col-md-9 col-xs-12 companyinfo res-nopadding">
                <h2 class="companytitle">{{clientData.companyName  || 'N/A'}}</h2>
                <p class="industry">{{clientData.industries || 'N/A'}}</p>
                <p class="address"><span style="color:#00737E"><b>Views: {{clientData.views || 'N/A'}}</b></span></p>
              </div>
            </div>

          </div>


          <div class="col-md-3 col-xs-12 companyinfo">
            <p><img class="imgicon" src="../../../assets/images/icons/phone.png" alt="">

              <span *ngIf="clientData.landline=='0'">Not Available</span>
              <span *ngIf="clientData.landline!='0'">{{clientData.landline || 'Not Available'}}</span>

            </p>
            <p><img class="imgicon" src="../../../assets/images/icons/location.png" alt="">
              <span *ngIf="clientData.address">{{clientData.address || 'N/A'}}</span>
              <span *ngIf="clientData.location">, {{clientData.location}}</span>
              <span *ngIf="clientData.country">, {{clientData.country}}</span>

            </p>
            <p>
              <img class="imgicon" src="../../../assets/images/icons/globe.png" alt="">
              <a *ngIf="clientData.url" class="green-link" href="{{checkUrl(clientData.url)}}" target="_blank"> Visit
                Official
                Website
                <!-- {{(clientData.url | slice:0:30)+'...'}} -->
              </a>

              <span *ngIf="!clientData.url">Not Available</span>
            </p>
          </div>

        </div>

        <div class="row" *ngIf="!clientData">
          <div class="col-md-9">
            <div class="logo-section">
              <div class=" col-md-3 logobox">
                <app-skeleton-loader Cwidth="100" Cheight="128"></app-skeleton-loader>
              </div>

              <div class="col-md-9 companyinfo">
                <h2 class="companytitle">
                  <app-skeleton-loader Cwidth="40" Cheight="30"></app-skeleton-loader>

                </h2>
                <p class="industry">
                  <app-skeleton-loader Cwidth="20" Cheight="20"></app-skeleton-loader>
                </p>
                <p class="address"><span style="color:#00737E">
                    <app-skeleton-loader Cwidth="15" Cheight="20"></app-skeleton-loader>
                  </span></p>
              </div>
            </div>
          </div>

          <div class="col-md-3 companyinfo">
            <p>
              <app-skeleton-loader Cwidth="55" Cheight="20"></app-skeleton-loader>
            </p>
            <p>
              <app-skeleton-loader Cwidth="75" Cheight="20"></app-skeleton-loader>
            </p>
            <p>
              <app-skeleton-loader Cwidth="85" Cheight="20"></app-skeleton-loader>
            </p>
          </div>
        </div>
      </div>


      <div class="col-md-9 nopadding">
        <!-- About Us -->
        <div class="col-md-12 wt-box">
          <div class="row" *ngIf="clientData">
            <div class="col-md-12">
              <p class="orange-header">About Us</p>
              <p class="justify" [innerHTML]="(clientData.compDesc || 'N/A')"></p>
            </div>
          </div>

          <div class="row" *ngIf="!clientData">
            <div class="col-md-9">
              <p class="orange-header">
                <app-skeleton-loader Cwidth="20" Cheight="30"></app-skeleton-loader>
              </p>
              <p>
                <app-skeleton-loader Cwidth="100" Cheight="20"></app-skeleton-loader>
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-12 wt-box">

          <div class="col-md-12 nopadding">
            <p class="orange-header" *ngIf="clientData">Current Job Openings</p>
            <p *ngIf="!clientData">
              <app-skeleton-loader Cwidth="40" Cheight="30"></app-skeleton-loader>
            </p>

          </div>

          <div class="row">
            <div class="col-md-12" *ngIf="clientData">
              <div class="col-md-12 job-box" *ngFor="let items of job; let i=index">
                <div class="higlighted">
                  <div class="highlight-chip">
                    Views : {{items.views || 'N/A'}} <span class="pipegap">|</span> Expiry Date :
                    {{items.expiry || 'N/A'}}
                  </div>
                </div>
                <div class="col-md-12 info-box">
                  <div class="row">
                    <div class="col-md-9">
                      <p class="green-heading"> <a *ngIf="items.title"
                          [routerLink]="['/job/', replaceSpace(items.title), items.jobCreateId]"
                          class="green-link noDecoration">{{items.title}}</a>
                        <span *ngIf="!items.title">Not Available</span></p>
                    </div>
                    <div class="col-md-3 text-right res-hide">
                      <span class="icontxt icon-gap">
                        <!-- <i class="fa fa-bookmark-o" aria-hidden="true"></i> -->

                        <ng-container *ngIf="items.jobBookmark=='Y';else noBookmark">
                          <span class="icontxt icon-gap" (click)="bookmarkJob(items, i)"> <i class="fa fa-bookmark"
                              aria-hidden="true"></i> Saved</span>
                        </ng-container>

                        <ng-template #noBookmark>
                          <span class="icontxt icon-gap" (click)="bookmarkJob(items,i)" *ngIf="userId"> <i
                              class="fa fa-bookmark-o" aria-hidden="true"></i> Save</span>
                          <span class="icontxt icon-gap" (click)="openModal(bookmark); setBookmarkId(items.jobCreateId)"
                            *ngIf="!userId"> <i class="fa fa-bookmark-o" aria-hidden="true"></i> Save
                          </span>
                          <span [ngStyle]="showBookmark(items,i)" style="visibility:hidden;display: none">Hide</span>
                        </ng-template>


                      </span>
                      <span class="icontxt"> <img src="../../../assets/images/icons/share.png" alt=""> Share</span>
                    </div>
                    <div class="col-xs-6 res-divide-box"> <span class="icontxt icon-gap">

                        <ng-container *ngIf="items.jobBookmark=='Y';else noBookmark">
                          <span class="icontxt icon-gap" (click)="bookmarkJob(items, i)"> <i class="fa fa-bookmark"
                              aria-hidden="true"></i> Saved</span>
                        </ng-container>

                        <ng-template #noBookmark>
                          <span class="icontxt icon-gap" (click)="bookmarkJob(items,i)" *ngIf="userId"> <i
                              class="fa fa-bookmark-o" aria-hidden="true"></i> Save</span>
                          <span class="icontxt icon-gap" (click)="openModal(bookmark); setBookmarkId(items.jobCreateId)"
                            *ngIf="!userId"> <i class="fa fa-bookmark-o" aria-hidden="true"></i> Save
                          </span>
                          <span [ngStyle]="showBookmark(items,i)" style="visibility:hidden;display: none">Hide</span>
                        </ng-template>


                      </span></div>
                    <div class="col-xs-6 res-divide-box"><span class="icontxt"> <img
                          src="../../../assets/images/icons/share.png" alt=""> Share</span></div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p><img src="../../../assets/images/icons/location.png" class="imgicon" alt=""> <b>Location :
                        </b>{{items.location || 'N/A'}}</p>
                    </div>

                    <div class="col-md-5">
                      <p><img src="../../../assets/images/icons/clock.png" class="imgicon" alt=""> <b>Job Type :</b>
                        {{items.jobTypeFlag == 'F' ? 'Full Time' : 'Part Time'}}</p>
                    </div>
                    <div class="col-md-5">
                      <p><img src="../../../assets/images/icons/experience.png" class="imgicon" alt=""> <b>Experience
                          :</b> {{items.expMin || 'N/A'}} - {{items.expMax || 'N/A'}} Years</p>
                    </div>

                    <div class="col-md-12">
                      <p><img src="../../../assets/images/icons/bulb.png" class="imgicon" alt=""> <b>Key Skills : </b>

                        <span class="skill-chips"
                          *ngFor="let items of getSkills(items.keySkills)">{{items || 'Not Available'}}</span>
                        <!-- {{items.keySkills || 'N/A'}} -->
                      </p>
                    </div>

                    <div class="col-md-4 col-xs-4">

                      <a style="color: #00737E;"  [routerLink]="['/job/', replaceSpace(items.title), items.jobCreateId]">View Details</a>
                     
                      <!-- <button class="btn green-btn"   [routerLink]="['/job/', replaceSpace(items.title), items.jobCreateId]">View Details</button> -->
                    </div>

                    <div class="col-md-8 col-xs-8 text-right">
                      <span class="red-txt-sm">Posted Date : {{items.publishOn || 'N/A'}}</span>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <div class="col-md-12" *ngIf="!clientData">
              <div class="col-md-12 job-box">
                <div class="higlighted mt-25">

                </div>

                <div class="col-md-12 info-box">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="green-heading">
                        <app-skeleton-loader Cwidth="30" Cheight="20"></app-skeleton-loader>

                      </p>
                    </div>
                    <div class="col-md-3 text-right res-hide">

                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <p>
                        <app-skeleton-loader Cwidth="50" Cheight="20"></app-skeleton-loader>
                      </p>
                    </div>

                    <div class="col-md-5">
                      <p>
                        <app-skeleton-loader Cwidth="50" Cheight="20"></app-skeleton-loader>
                      </p>
                    </div>
                    <div class="col-md-5">
                      <p>
                        <app-skeleton-loader Cwidth="90" Cheight="20"></app-skeleton-loader>
                      </p>
                    </div>

                    <div class="col-md-12">
                      <p>
                        <app-skeleton-loader Cwidth="50" Cheight="20"></app-skeleton-loader>
                      </p>
                    </div>

                    <div class="col-md-12 text-right">
                      <span class="red-txt-sm">
                        <app-skeleton-loader Cwidth="50" Cheight="20"></app-skeleton-loader>
                      </span>
                    </div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- Similar Companies -->
      <div class="col-md-3 res-pad-right">
        <div class="col-md-12">
          <div class="row  ">
            <div class="col-md-12 similarjobbox">
              <div class="row">
                <div class="col-md-12 green-header">Similar Companies</div>
                <ng-container *ngIf="similarJobs.length>0">
                  <div class="col-md-12 info-box" *ngFor="let items of similarJobs">

                    <div class="higlighted">
                      <div class="highlight-chip">
                        Views : {{items.profileView || 'N/A'}}
                      </div>
                    </div>
                    <div class="col-md-12 inner-box">
                      <div class="row">
                        <div class="col-md-4 logo-holder">
                          <div class="logo-box">
                            <img src="../../../assets/images/default-comp.jpeg" alt="" *ngIf="!items.image">
                            <img src="{{items.image}}" alt="" *ngIf="items.image">
                          </div>
                        </div>
                        <div class="col-md-8">
                          <p class="f-16-header">{{items.companyName || 'N/A'}}</p>
                          <p>{{items.location || 'N/A'}}</p>
                          <p>Job Opening : {{items.jobsCount || 'N/A'}}</p>
                        </div>
                      </div>
                    </div>
                    <button class="btn jobDetailbtn"
                      [routerLink]="['/employer', replaceSpace(items.companyName),items.empId]">
                      View Company Profile
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="similarJobs.length==0">
                  <div class="col-md-12 mt-15">
                    <p style="text-align: center;">No Company Found</p>
                  </div>
                </ng-container>




              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>


<!-- Modal -->
<ng-template #bookmark>
  <ng-container>
    <div class="modal-header" style="position: relative;">
      <p>Please Login to Continue</p>
      <!-- <p>HR OFFICER</p> -->
      <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <app-bookmark-job></app-bookmark-job>
      </div>
    </div>
  </ng-container>

</ng-template>
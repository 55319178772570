import { Component, OnInit } from "@angular/core";
import { RestApiService } from "../../common/services/rest-api.service";
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { environment } from "../../../environments/environment";
import { EmployerApi } from "src/app/common/services/employerApi.service";

@Component({
  selector: "app-header1",
  templateUrl: "./header1.component.html",
  styleUrls: ["./header1.component.scss"]
})
export class Header1Component implements OnInit {
  constructor(private restApiService: RestApiService, private router: Router, public cookie: CookieService, private employeeService: EmployerApi) { }

  name: string;
  jsinfo: string;


  loginStatus: boolean = false;
  collapsed = true;
  phoneNo: any = environment.phoneNo;
  email: any = environment.email;
  userRole: any;
  employeeId: any;
  ngOnInit() {

    this.employeeId = this.cookie.get('empId');
    if (this.employeeId) {
      this.checkResumeAccess();
    }
    this.userRole = this.cookie.get('uTy');

    this.getInfo();
    if (this.cookie.get('at')) {
      this.loginStatus = true;
    }
  }

  userObj = {
    uid: null,
    jsInfoId: null,
    name: null
  };

  getInfo() {
    this.restApiService.currentMessage.subscribe(response => {
      this.userObj = response;
    });
  }

  signOut() {
    this.restApiService.storageLogout();
    this.router.navigate(["/"]);
  }

  doLogout() {
    this.cookie.deleteAll('/');
    this.router.navigate(['']);
    this.loginStatus = false;
  }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }

  checkResumeAccess() {
    this.employeeService.getResumeStatus(this.employeeId).subscribe(
      (res: any) => {

        if (res.resumeAccessStatus == 'Y')
          this.cookie.set('rap', res.resumeAccessStatus ? res.resumeAccessStatus : 'N', 365, '/');

      }
    )
  }

}

<div class="container-fluid container-fixed-top container-fixed-top-nav">
    <div class="row">
        <app-admin-navbar></app-admin-navbar>
    </div>

</div>


<div class="container ">

    <div class="row">


        <div class="col-md-12 box">
            <div class="col-md-12 text-right nopadding">
                <p> <span class="cursor" [routerLink]="['/admin/dashboard']" routerLinkActive="router-link-active"><i
                            class="fa fa-long-arrow-left" aria-hidden="true"></i> Go Back</span></p>
            </div>

            <div class="col-md-12  header">
                <p>Job Applied History</p>
            </div>

            <div class="col-md-12 nopadding" style="margin-top: 15px;" *ngIf="yearList.length; else yearSpinner">
                <accordion [closeOthers]="true">
                    <accordion-group *ngFor="let item of yearList">
                        <button class="btn btn-link btn-block clearfix" accordion-heading type="button"
                            (click)="getEmploymentFromYear(item.year)">
                            <div class="pull-left float-left " style="color: #313030; font-weight: 500;">{{item.year}}
                            </div>

                            <span class=" badge-secondary float-right  pull-right"
                                style="width:160px; text-align: center;">Total : {{item.total |
                                number}}</span>

                            <span class=" badge-secondary float-right badge-sm pull-right"
                                style="width:160px;text-align: left">Unregistered :
                                {{item.unregisteredApplications | number}}</span>

                            <span class=" badge-secondary float-right badge-sm pull-right"
                                style="width:160px;text-align: left">
                                Registered :
                                {{(+item.registeredWebApplications + +item.registeredMobApplications) | number}}</span>

                            <span class=" badge-secondary float-right badge-sm pull-right"
                                style="width:160px;text-align: left">
                                Web :
                                {{item.registeredWebApplications | number}}</span>


                            <span class=" badge-secondary float-right badge-sm pull-right"
                                style="width:160px;text-align: left">
                                Mobile :
                                {{item.registeredMobApplications | number}}</span>



                        </button>

                        <ng-container *ngIf="!yearWiseSpinner; else spinner">

                            <div class="col-md-12 nopadding data" *ngFor="let items of yearWiseDetail"
                                style="margin-bottom: 15px;">


                                <div class="col-lg-2 col-md-2 nopadding">
                                    <p>{{items.month}}</p>
                                </div>

                                <div class="col-lg-2  col-md-12 col-sm-12 nopadding ml">
                                    <p style="width: 160px;text-align: right;">{{items.registeredMobApplications |
                                        number}}</p>
                                </div>

                                <div class="col-lg-2  col-md-12 col-sm-12  nopadding ml">
                                    <p style="width: 160px; text-align: right;">{{items.registeredWebApplications |
                                        number}}</p>

                                </div>


                                <div class="col-lg-2  col-md-12 col-sm-12 nopadding ml">
                                    <p style="width: 160px;text-align: right;">{{(+items.registeredWebApplications + +items.registeredMobApplications) |
                                        number}}</p>
                                </div>

                                <div class="col-lg-2  col-md-12 col-sm-12  nopadding ml">
                                    <p style="width: 160px; text-align: center;"> {{items.unregisteredApplications |
                                        number}}</p>

                                </div>


                                <div class="col-lg-2  col-md-12 col-sm-12 text-center nopadding ml">

                                    <span style="width: 160px;">{{items.total | number}}</span>

                                    <span>
                                        <a style="margin-left: 5px;"
                                            (click)="getEmploymentFromMonth(item.year,items.monthNo,monthName=items.month);openModal(modal)"
                                            class="cursor">(View)</a>
                                    </span>

                                </div>


                            </div>

                        </ng-container>

                        <ng-template #spinner>
                            <div class="row">
                                <div class="col-md-12">
                                    <app-jobejee-loader></app-jobejee-loader>
                                </div>
                            </div>
                        </ng-template>




                    </accordion-group>

                </accordion>
            </div>


            <ng-template #yearSpinner>
                <div class="col-md-12 text-center">
                    <app-jobejee-loader></app-jobejee-loader>
                </div>
            </ng-template>
        </div>


    </div>
</div>


<!-- Modal -->
<ng-template #modal>
    <div class="modal-header" style="position: relative;">
        <p>{{monthName}} Applicants List</p>
        <!-- <p>HR OFFICER</p> -->

        <button type="button" class="x-close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Applied Date</th>
                            <th scope="col">Mobile </th>
                            <th scope="col">Web </th>
                            <th scope="col">Registered </th>
                            <th scope="col">Unregistered</th>
                            <th scope="col">Total</th>
                        </tr>
                    </thead>
                    <tbody>

                        <ng-container *ngIf="!monthSpinner">
                            <tr *ngFor="let item of monthList">
                                <td>{{item.appliedDate}}</td>
                                <td>{{item.registeredMobApplications}}</td>
                                <td>{{ item.registeredWebApplications}}</td>
                                <td>{{(+item.registeredWebApplications + +item.registeredMobApplications) | number}}
                                </td>
                                <td>{{item.unregisteredApplications | number}}</td>
                                <td>{{item.total}}</td>
                            </tr>
                        </ng-container>

                        <ng-container *ngIf="monthSpinner">


                            <tr class="text-center">
                                <td colspan="4">
                                    <app-jobejee-loader></app-jobejee-loader>

                                </td>
                            </tr>

                            <div class="row">
                                <div class="col-md-12 text-center">
                                </div>
                            </div>

                        </ng-container>


                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
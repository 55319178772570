<div class="container container-fixed-top">
  <!--White Box-->
  <div class="col-md-12 box">
    <!--Green header-->
    <p class="green_heading">Frequently Asked Questions</p>

    <!--Links in top section-->
    <div class="col-md-12 links_box">
      <a class="green-orange-link noDecoration"><label class="normal">1. Registration</label></a><br>
      <a class="green-orange-link noDecoration"><label class="normal">2. Log In</label></a><br>
      <a class="green-orange-link noDecoration"><label class="normal">3. Explore/Search</label></a><br>
      <a class="green-orange-link noDecoration"><label class="normal">4. Profile Maintainance</label></a><br>
      <a class="green-orange-link noDecoration"><label class="normal">5. Jobejee Features</label></a>
    </div>

    <!--Description Box 1-->
    <div class="col-md-12 des_box">

      <!--Registration section-->
      <p class="heading">1.Registration</p>
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">1. How do I register an account at Jobejee?</label>
          <!--Description section-->
          <p>
            It's a simple 2-step process. Just log on to www.jobejee.com and click on ‘Register’ in the ‘Job Seeker’
            section of the top left corner. <br>
            Step 1: Fill in your details<br>
            Step 2: Activate your registration by clicking on the link sent to you in the welcome email.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">2. How do I register an account at Jobejee?</label>
          <!--Description section-->
          <p class="justify">
            You can use Jobejee and search for jobs at Jobejee without registering an account with us. You can also
            choose to apply for jobs externally whether or not you have an account.<br>
            The benefits of registering an account and apply via Jobejee are that you get access to all the Jobejee
            features which are designed to make every step of your job application process easy. For example: you get
            job recommendations which are jobs our intelligent algorithm thinks that you might be interested in: so you
            get them delivered to you without searching for them. If you apply for a job through Jobejee, you will also
            get notified anytime your application makes progress and whenever you get called in for an interview: so you
            don’t miss getting contacted by employers.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">3. Does it cost anything to me?</label>
          <!--Description section-->
          <p>
            Registering a Jobejee account is free of cost for employers. Access to and use of all the features are free
            as well.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">4. Do I need valid email address and phone no. for registration?</label>
          <!--Description section-->
          <p class="justify">
            Yes, you need a valid email address and phone number to register on jobejee.com .You have to verify your
            email address and phone number. The same email address and phone number will be used to communicate with you
            in the future. We value your privacy, and we hate spam as much as you do. We are not going to spam or sell
            your email address to any third party. We still need your email account to be able to provide you with your
            job related information or for any other career related notifications you may choose.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">5. What is OTP? Why do I need it?</label>
          <!--Description section-->
          <p>
            OTP stands for One Time Password which is required to verify your mobile number and to register your
            account. OTP is sent to the provided mobile number.
          </p>
        </div>
      </div>
    </div>



    <!--Description Box 2-->
    <div class="col-md-12 des_box">

      <!--Login Section-->
      <p class="heading">2. Log In</p>
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">1. How do I log in?</label>
          <!--Description section-->
          <p class="justify">
            Click Login in the top navigation bar of the homepage header and enter your username (email id) and password
            registered with us.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">2. I have created my profile but not able to log in?</label>
          <!--Description section-->
          <p class="justify">
            You need to check your username and password if you are not able to log in to your account. Please visit
            your email inbox and look for the welcome mail received from us.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">3. I don't remember my password. How do I reset my password?</label>
          <!--Description section-->
          <p class="justify">
            If you are not able to trace the welcome mail received from us or if you don't remember your password, click
            on the "Forgot Password" link below the log in box. It will prompt you to enter your username (email id). A
            new password will be sent to you on your email address.
          </p>
        </div>
      </div>
    </div>



    <!--Description Box 3-->
    <div class="col-md-12 des_box">

      <!--Explore/Search Section-->
      <p class="heading">3. Explore/Search</p>
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">1. What is Search?</label>
          <!--Description section-->
          <p class="justify">
            Search gives you an option to look for relevant jobs based on your specific requirements. To search for a
            job, you can use the given search window. You may use the following criteria to look for relevant jobs.
          </p>
        </div>
      </div>

      <!--keywords-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Keywords:</label>
          <!--Description section-->
          <p class="justify">
            For a keyword search you may enter Designation, Key Skills, or company name of desired job. Keyword search
            is of the following types
          </p>
        </div>
      </div>


      <!--Locations-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Locations:</label>
          <!--Description section-->
          <p class="justify">
            You may enter the cities you are interested in working at. The search result will display only those jobs
            which are based out of the locations that you have specified.
          </p>
        </div>
      </div>




      <!--Work Experience:-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Work Experience:</label>
          <!--Description section-->
          <p class="justify">
            You may enter the number of years you have worked for. The search result will display all those jobs with
            the required work experience range matching the one you have specified.
          </p>
        </div>
      </div>




      <!--Expected Salary-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Expected Salary:</label>
          <!--Description section-->
          <p class="justify">
            This field gives you an option to specify the minimum and the maximum limit for the salary that you expect.
            This will arrange / order the search results to better suit your requirements. The jobs matching the salary
            range that you have entered will be shown first followed by the ones which do not match the selected salary
            range.
          </p>
        </div>
      </div>




      <!--Industry-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Industry:</label>
          <!--Description section-->
          <p class="justify">
            This will help you find jobs matching your desired job Industry. This may not be required if you have
            already entered designation as a keyword.
          </p>
        </div>
      </div>




      <!--Functional Area-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Functional Area:</label>
          <!--Description section-->
          <p class="justify">
            This will help you find jobs matching your desired job function. This may not be required if you have
            already entered designation as a keyword.
          </p>
        </div>
      </div>




      <!--Job Type-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Job Type:</label>
          <!--Description section-->
          <p class="justify">
            You may select what type of job you want: full time, part time or correspondence.
          </p>
        </div>
      </div>


      <!--Employer Type-->
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="headers">Employer Type:</label>
          <!--Description section-->
          <p class="justify">
            You may select the type of employer you wish to work for.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">2. How can I search for jobs?</label>
          <!--Description section-->
          <p class="justify">
            As our system works for you and finds you the matched positions for your skills and preferences, you can
            also use the search functions to search for jobs. To search jobs, use the search bar at the top of homepage.
          </p>
        </div>
      </div>




      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">3. The job search sometimes shows results running into thousands. How can I
            refine my search further?</label>
          <!--Description section-->
          <p class="justify">
            To refine your search you can use the ‘Advanced Search’ option given towards the left of the search result
            page. Based on the criteria entered, the search result displays all the jobs matching your requirements.
            ‘Refine your Search’ option in turn categorizes the results based on the criteria you have provided. It
            streamlines the job results into four broad categories:<br>

            1- Jobs based on the industry type <br>
            2- Role required to be performed <br>
            3- Source of the Job advertised: company or consultant <br>
            4- Date of job posting
          </p>
        </div>
      </div>




      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">4. How do I search for relevant jobs?</label>
          <!--Description section-->
          <p class="justify">
            Search gives you an option to look for relevant jobs based on your specific requirements. To search for a
            job, you can use the given search window. It is recommended that you specify the keywords, preferred
            locations and your total experience criteria to access job search results which are closest to what you are
            looking for. In case, you get results running into hundreds, you can narrow down your search by specifying
            all available criteria. In case of ‘No Results Found, it is recommended that you may broaden your search by
            entering only a limited number of criteria.
          </p>
        </div>
      </div>




      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">5. How do I apply for the jobs which I find in the search?</label>
          <!--Description section-->
          <p class="justify">
            To apply for a job from the search result, you need to first log in and then click on the apply button given
            with the job posting. If you are not logged in then it will ask to login first or register and apply or
            apply without registration. You have to fill a small informative form to apply.
          </p>
        </div>
      </div>
    </div>



    <!--Description Box 4-->
    <div class="col-md-12 des_box">

      <!--Profile Maintainance-->
      <p class="heading">4. Profile Maintainance</p>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">1. How do I edit/update my profile?</label>
          <!--Description section-->
          <p class="justify">
            You can Edit/Update your Profile by following these steps.<br>
            1) First Login to your Account Using you verified Email ID and Password.<br>
            2) Then go to "My Profile" page.<br>
            3) There you can edit your profile by clicking on the "Edit" link against each Parameter.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">2. How can I upload my new resume and change my old resume?</label>
          <!--Description section-->
          <p class="justify">
            To Upload a New Resume or to Change your Old resume, click on the my Profile section where at the bottom of
            the page you will find the "Resume Tab" where you will find the options of ‘Browse’. You can ‘Browse’ for a
            new or updated resume and upload it. This step will automatically delete your old resume.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">3. How can I update my employment details? How can I enter more details in
            it?</label>
          <!--Description section-->
          <p class="justify">
            You can add previous job(s) details by selecting the area of work, specialization, role and no. of years in
            that job and click on ‘add another job’ from edit section from your profile.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">4. How can I update my education details? How can I enter more details in
            it?</label>
          <!--Description section-->
          <p class="justify">
            To add another educational qualification, just click ‘add more’ in education edit section and select the
            qualification, specialization, institute and course type and press add to list. Press the save button, to
            save the changes to your profile.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">5. What kind of information can employer see in my profile?</label>
          <!--Description section-->
          <p>
            Employers can view all the information you have uploaded in your profile.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">6. How often do I have to update my profile?</label>
          <!--Description section-->
          <p>
            It is recommended to update your profile every six month so that new skills and experience.
          </p>
        </div>
      </div>

      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">7. What are the benefits of a completed profile?</label>
          <!--Description section-->
          <p class="justify">
            You can search and apply for jobs with an incomplete Jobejee profile. However, a completed Jobejee profile
            has two main advantages.
            The first advantage of a completed Jobejee profile is that you will get noticed more by employers. For
            example, a jobseeker with his/her education information displayed is more likely to get shortlisted by
            employers than a jobseeker who does not have his/her education information displayed; simply because they do
            not know the educational background of the second jobseeker.<br>

            The second advantage of a completed Jobejee profile is that our algorithms and programs will give you better
            results. For example, if you have a completed profile, our algorithm will give you relevant job
            recommendations because it knows exactly what you want so it can narrow down thousands of jobs to a few that
            match your needs and skills. You will also receive a more accurate estimation of your salary benchmark
            because our algorithm can use your data to give you personalized results.
          </p>
        </div>
      </div>
    </div>



    <!--Description Box 5-->
    <div class="col-md-12 des_box">

      <!--Jobejee Features-->
      <p class="heading">5. Jobejee Features</p>
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">1. What are bookmarked jobs and how can I utilize it?</label>
          <!--Description section-->
          <p class="justify">
            If you find jobs that you want to apply to cannot at the moment, you can save them by bookmarking them. They
            will appear in ‘Jobs For You’ page in the left most section under ‘Bookmarked Jobs’. You will also receive
            notifications when your bookmarked jobs are about to expire.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">2. What are job alerts and how can I activate them?</label>
          <!--Description section-->
          <p class="justify">
            Job alerts are created by you for jobs that you would not want to miss. Anytime a job that matches your
            description gets posted, you will get a notification.
            You can activate job alerts by clicking on ‘create job alerts’ in the ‘Jobs For You’ page. Fill in the
            parameters that you would like to receive job alerts in.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">3. What are job recommendations and how can I activate them?</label>
          <!--Description section-->
          <p class="justify">
            Job recommendations are jobs that might be of interest to you. They are generated by our algorithm and are
            based on the information provided to you on your profile. They are automatically turned on for you after you
            create your profile.
          </p>
        </div>
      </div>


      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">4. What is applicant ranking and how can I utilize it?</label>
          <!--Description section-->
          <p class="justify">
            Applicant ranking is a feature of Jobejee where you can see where you rank amongst other applicants for the
            same job. This is based on education, work experience, skills, salary expectations and job location.
            You can use this feature to learn which section you have performed above average in and which sections still
            require improvements.
          </p>
        </div>
      </div>



      <div class="col-md-12 sm_des">
        <!--Sub heading-->
        <div class="col-md-12 nopadding">
          <label for="" class="sub_heading">5. What is salary benchmark and how accurate is it?</label>
          <!--Description section-->
          <p class="justify">
            Salary benchmark gives you an estimated minimum and maximum salary you can hope to receive from a particular
            job and designation. This is based on the current job market and our database and therefore may not always
            be correct.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>

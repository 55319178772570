import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { TermsConditionComponent } from "./nonAuth/terms-condition/terms-condition.component";
import { PrivacyPolicyComponent } from "./nonAuth/privacy-policy/privacy-policy.component";
import { NewpaperJobComponent } from "./nonAuth/newpaper-job/newpaper-job.component";
import { LearnMoreComponent } from "./nonAuth/learn-more/learn-more.component";
import { JobSearchComponent } from "./nonAuth/job-search-list/job-search-list.component";
import { HomeComponent } from "./nonAuth/home/home.component";
import { FaqComponent } from "./nonAuth/faq/faq.component";
import { DeletionComponent } from "./nonAuth/deletion/deletion.component";
import { ClientProfileComponent } from "./nonAuth/client-profile/client-profile.component";
import { AboutUsComponent } from "./nonAuth/about-us/about-us.component";

import { LoginComponent } from "./jobseeker/login/login.component";
import { RegisterComponent } from "./jobseeker/register/register.component";
import { VerifyEmailComponent } from "./nonAuth/verify-email/verify-email.component";

import { NewJobDetailComponent } from "./nonAuth/new-job-detail/new-job-detail.component";
import { NewCompanyProfileComponent } from "./nonAuth/new-company-profile/new-company-profile.component";
import { EmployersLoginComponent } from "./employers/employers-login/employers-login.component";
import { JobApplyComponent } from "./nonAuth/job-apply/job-apply.component";
import { ResetPasswordComponent } from "./common/components/reset-password/reset-password.component";
import { ForgetPasswordComponent } from "./common/components/forget-password/forget-password.component";
import { BillingListComponent } from "./admin/billing-list/billing-list.component";
import { EmploymentIndexComponent } from "./nonAuth/employment-index/employment-index.component";
import { LogHistoryComponent } from "./nonAuth/log-history/log-history.component";
import { HipcoComponent } from "./nonAuth/hipco/hipco.component";
import { UefaComponent } from "./nonAuth/uefa/uefa.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "ebl/teller",
    redirectTo: "job/teller/88278e1ddca2bbe1e7a3942350697b8f12abecfa",
    pathMatch: "full",
  },
  {
    path: "job/:jobName/:jobId",
    component: NewJobDetailComponent,
  },
  {
    path: "job/:jobId",
    component: NewJobDetailComponent,
  },

  {
    path: "hulas-hipco-steel-fabrication",
    component: HipcoComponent,
  },
  {
    path: "employer/:clientName/:clientId",
    component: NewCompanyProfileComponent,
  },

  {
    path: "employer",
    loadChildren: () =>
      import("./employers/employer.module").then((e) => e.EmployerModule),
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((e) => e.AdminModule),
  },
  {
    path: "jobseeker",
    loadChildren: () =>
      import("./jobseeker/jobseeker.module").then((e) => e.JobseekerModule),
  },
  {
    path: "mobile",
    loadChildren: () =>
      import("./mobile/mobile-non-auth/mobile-non-auth.module").then(
        (e) => e.MobileNonAuthModule
      ),
  },
  {
    path: "mobile/jobseeker",
    loadChildren: () =>
      import("./mobile/m-jobseeker/m-jobseeker.module").then(
        (e) => e.MJobseekerModule
      ),
  },
  {
    path: "employers/:clientName/:clientId",
    component: ClientProfileComponent,
  },
  {
    path: "verify-email",
    component: EmployersLoginComponent,
  },
  {
    path: "js/verify-email",
    component: LoginComponent,
  },

  {
    path: "new-company-profile",
    component: NewCompanyProfileComponent,
  },
  {
    path: "jobseeker/login",
    component: LoginComponent,
  },
  {
    path: "jobseeker/register",
    component: RegisterComponent,
  },
  {
    path: "newspaper/:jobName/:jobId",
    component: NewpaperJobComponent,
  },

  {
    path: "verifyemail/:url",
    component: VerifyEmailComponent,
  },
  {
    path: "aboutus",
    component: AboutUsComponent,
  },
  {
    path: "terms-and-conditions",
    component: TermsConditionComponent,
  },
  {
    path: "faq-question",
    component: FaqComponent,
  },
  {
    path: "account-deletion",
    component: DeletionComponent,
  },
  {
    path: "privacy-policies",
    component: PrivacyPolicyComponent,
  },
  {
    path: "learn-more",
    component: LearnMoreComponent,
  },
  {
    path: "job-search",
    component: JobSearchComponent,
  },

  {
    path: "job-apply",
    component: JobApplyComponent,
  },
  {
    path: "admin/billing-list",
    component: BillingListComponent,
  },
  {
    path: "password/reset/:token",
    component: ResetPasswordComponent,
  },

  {
    path: "forget-password",
    component: ForgetPasswordComponent,
  },

  {
    path: "employment-index",
    component: EmploymentIndexComponent,
  },

  {
    path: "log-history",
    component: LogHistoryComponent,
  },
  {
    path: "eurocup2024",
    component: UefaComponent,
  },
  { path: "**", component: HomeComponent },

  // {
  //   path: "everestbank/taylor",
  //   redirectTo: "job/taylor/:jobId",
  //   pathMatch: "full"
  // }

  // path: 'job/:jobName/:jobId', component: JobDetailComponent

  // https://www.jobejee.com/job/teller/88278e1ddca2bbe1e7a3942350697b8f12abecfa

  // {
  //   path: 'employer/login', component: EloginComponent
  // },
  // {
  //   path: 'employer/register', component: RegisterComponent
  // },
  // {
  //   path: 'employer/billing', component: BillingComponent
  // },
  // {
  //   path: 'employer/profile', component: CompanyProfileComponent
  // },
  // {
  //   path: 'employer/list-user', component: UserManagementComponent
  // },
  // {
  //   path: 'employer/branding-solution', component: BrandingSolutionComponent
  // },
  // {
  //   path: 'employer/dashboard', component: EmployerDashboardComponent
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<div
  class="container-fluid navbar-top"
  [ngClass]="{ 'container-fixed-top': checkLoginStatus() === true }"
>
  <app-search-job></app-search-job>
</div>

<div class="container" style="margin-top: 15px">
  <div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-12 home-banner">
          <img
            src="../../../assets/images/browsejobs_banner.jpg"
            alt="logo"
            class="logo-responsive"
          />
        </div>
        <div class="col-md-12 home-tab">
          <ul class="custom-tab">
            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="activeTab == 'Jobs By Company' ? 'active' : ''"
            >
              <li (click)="activeTab = 'Jobs By Company'; getJobsByCompany()">
                <a> Jobs By Company</a>
              </li>
            </div>

            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="activeTab == 'Jobs By Industry' ? 'active' : ''"
            >
              <li (click)="activeTab = 'Jobs By Industry'; getJobsByIndustry()">
                <a> Jobs By Industry</a>
              </li>
            </div>

            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="activeTab == 'Jobs By Location' ? 'active' : ''"
            >
              <li (click)="activeTab = 'Jobs By Location'; getJobsByLocation()">
                <a> Jobs By Location</a>
              </li>
            </div>
            <div class="clearfix"></div>
          </ul>
        </div>

        <!-- Jobs By Company -->
        <div class="col-md-12">
          <div
            class="row job-box-container"
            *ngIf="activeTab == 'Jobs By Company'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of jobsObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <img
                    src="{{ JobsObj.image }}"
                    *ngIf="i < 22"
                    class="j-box-img"
                    alt="{{ splitJob(JobsObj.companyName, 0) }}"
                  />
                  <img
                    [defaultImage]="defaultImage"
                    [lazyLoad]="JobsObj.image"
                    *ngIf="i >= 22"
                    class="j-box-img"
                  />

                  <div class="title">
                    <a
                      title="{{ splitJob(JobsObj.companyName, 0) }}"
                      [routerLink]="[
                        '/employer',
                        replaceSpace(splitcompany(JobsObj.companyName, 0)),
                        splitJob(JobsObj.companyName, 1)
                      ]"
                      class="green-orange-link fw500 noDecoration"
                    >
                      {{ splitJob(JobsObj.companyName, 0) }}
                    </a>

                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/job/',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>

                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByCompany(JobsObj.companyName)"
                        >
                          View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12 inner-loading" *ngIf="jobsObj.length == 0 && activeTab == 'Jobs By Company'">
            <img src="../../../assets/images/loading.gif" class="loading-icon" />
          </div> -->

          <!-- Skeleton Loader -->
          <div
            class="row job-box-container"
            style="padding-top: 0px"
            *ngIf="jobsObj.length == 0 && activeTab == 'Jobs By Company'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let item of items"
            >
              <div class="col-md-12 job-box">
                <div class="col-md-12 job-box-inner">
                  <div class="col-xs-4 nopadding">
                    <app-skeleton-loader
                      Cwidth="100"
                      Cheight="70"
                    ></app-skeleton-loader>
                  </div>
                  <div class="col-xs-8">
                    <p>
                      <app-skeleton-loader
                        Cwidth="100"
                        Cheight="20"
                      ></app-skeleton-loader>
                    </p>
                    <p>
                      <app-skeleton-loader
                        Cwidth="80"
                        Cheight="20"
                      ></app-skeleton-loader>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Jobs By Company -->

          <!-- Jobs By Industry -->
          <div
            class="row job-box-container"
            *ngIf="activeTab == 'Jobs By Industry'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of jobsIndObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <img
                    src="{{ JobsObj.image }}"
                    class="j-box-img"
                    alt="{{ JobsObj.industry }}"
                  />
                  <div class="title">
                    <a
                      [routerLink]="['/jobDetail', JobsObj.industryId]"
                      title="{{ JobsObj.industry }}"
                      class="txt-light fw500 noDecoration"
                    >
                      {{ JobsObj.industry }}
                    </a>

                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/job/',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>
                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByIndustry(JobsObj.industry)"
                          >View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Jobs By Industry -->

          <div
            class="row job-box-container"
            *ngIf="activeTab == 'Jobs By Location'"
          >
            <!-- Jobs By Location -->
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of jobsLocObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <img
                    src="{{ JobsObj.image }}"
                    class="j-box-img"
                    alt="{{ JobsObj.locationName }}"
                  />
                  <div class="title">
                    <a
                      routerLink="['/jobDetail', JobsObj.locationId]"
                      title="{{ JobsObj.locationName }}"
                      class="txt-light fw500 noDecoration"
                    >
                      {{ JobsObj.locationName }}
                    </a>

                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/job/',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>
                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByLocation(JobsObj.locationName)"
                          >View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- Jobs By Location -->
          </div>

          <!-- Half Banner Ads -->
          <div class="row job-box-ads" *ngFor="let AdsObj of halfBannerList">
            <div
              class="col-md-12 margin-bottom-15 hidethis"
              style="padding: 0px 10px"
              *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'HB'"
            >
              <a
                href="{{ AdsObj.url }}"
                target="_blank"
                (click)="updateImpression(AdsObj.adContentId)"
              >
                <img src="{{ AdsObj.image }}" width="100%" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Newspaper Job -->

      <div class="row">
        <div class="col-md-12 home-banner">
          <img
            src="../../../assets/images/newspaperjobs_banner.jpg"
            alt="logo"
            class="logo-responsive"
          />
        </div>

        <div class="col-md-12 home-tab">
          <ul class="custom-tab">
            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="
                newsPaperactiveTab == 'Jobs By Company' ? 'active' : ''
              "
            >
              <li
                (click)="
                  newsPaperactiveTab = 'Jobs By Company'; getJobsByCompany()
                "
              >
                <a> Jobs By Company</a>
              </li>
            </div>

            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="
                newsPaperactiveTab == 'Jobs By Industry' ? 'active' : ''
              "
            >
              <li
                (click)="
                  newsPaperactiveTab = 'Jobs By Industry'; getJobsByIndustry()
                "
              >
                <a> Jobs By Industry</a>
              </li>
            </div>

            <div
              class="col-md-4 col-sm-4 col-xs-4 res-text res-nopadding"
              [ngClass]="
                newsPaperactiveTab == 'Jobs By Location' ? 'active' : ''
              "
            >
              <li
                (click)="
                  newsPaperactiveTab = 'Jobs By Location'; getJobsByLocation()
                "
              >
                <a> Jobs By Location</a>
              </li>
            </div>
            <div class="clearfix"></div>
          </ul>
        </div>

        <!-- Newspaper Jobs By Company -->
        <div class="col-md-12">
          <div
            class="row job-box-container"
            *ngIf="newsPaperactiveTab == 'Jobs By Company'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of NewsjobsObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <!-- <img src="{{JobsObj.image}}" class="j-box-img" alt="{{splitJob(JobsObj.companyName, 0)}}" /> -->
                  <img
                    [defaultImage]="defaultNewspaper"
                    [lazyLoad]="JobsObj.image"
                    class="j-box-img"
                  />

                  <div class="title">
                    <span class="fw500" title="{{ JobsObj.companyName }}">
                      {{ splitJob(JobsObj.companyName, 0) }}</span
                    >
                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/newspaper',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>

                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByCompany(JobsObj.companyName)"
                          >View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Skeleton Loader -->
          <div
            class="row job-box-container"
            style="padding-top: 0px"
            *ngIf="
              NewsjobsObj.length == 0 && newsPaperactiveTab == 'Jobs By Company'
            "
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let item of longItems"
            >
              <div class="col-md-12 job-box">
                <div class="col-md-12 job-box-inner">
                  <div class="col-xs-4 nopadding">
                    <app-skeleton-loader
                      Cwidth="100"
                      Cheight="70"
                    ></app-skeleton-loader>
                  </div>
                  <div class="col-xs-8">
                    <p>
                      <app-skeleton-loader
                        Cwidth="100"
                        Cheight="20"
                      ></app-skeleton-loader>
                    </p>
                    <p>
                      <app-skeleton-loader
                        Cwidth="80"
                        Cheight="20"
                      ></app-skeleton-loader>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Newspaper Jobs By Company -->

          <!-- Newspaper Jobs By Industry -->
          <div
            class="row job-box-container"
            *ngIf="newsPaperactiveTab == 'Jobs By Industry'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of NewsjobsIndObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <img
                    src="{{ JobsObj.image }}"
                    class="j-box-img"
                    alt="{{ JobsObj.industry }}"
                  />
                  <div class="title">
                    <!-- <a routerLink="['/jobDetail', JobsObj.industryId]" title="{{JobsObj.industry}}"
                      class="green-orange-link fw500 noDecoration">
                      {{JobsObj.industry}}
                    </a> -->

                    <span class="fw500" title="{{ JobsObj.industry }}">
                      {{ JobsObj.industry }}</span
                    >

                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/newspaper',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>
                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByIndustry(JobsObj.industry)"
                          >View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- NewsJobs By Industry -->

          <!-- NewsJobs By Location -->
          <div
            class="row job-box-container"
            *ngIf="newsPaperactiveTab == 'Jobs By Location'"
          >
            <div
              class="col-md-4 col-sm-4 job-box-array"
              *ngFor="let JobsObj of NewsjobsLocObj; let i = index"
            >
              <div class="col-md-12 job-box">
                <div
                  class="col-md-12 job-box-inner"
                  [ngClass]="
                    JobsObj.result.length > 3 ? 'hover-j-box-inner' : ''
                  "
                >
                  <img
                    src="{{ JobsObj.image }}"
                    class="j-box-img"
                    alt="{{ JobsObj.locationName }}"
                  />
                  <div class="title">
                    <!-- <a routerLink="['/jobDetail', JobsObj.locationId]" title="{{JobsObj.locationName}}"
                      class="green-orange-link fw500 noDecoration">
                      {{JobsObj.locationName}}
                    </a> -->
                    <span class="fw500" title="{{ JobsObj.locationName }}">
                      {{ JobsObj.locationName }}</span
                    >

                    <div
                      class="sub-title"
                      *ngFor="
                        let JobsList of JobsObj.result | slice: 0:10;
                        let i = index
                      "
                    >
                      &#9900;
                      <a
                        [routerLink]="[
                          '/newspaper',
                          replaceSpace(splitJob(JobsList, 0)),
                          splitJob(JobsList, 2)
                        ]"
                        title="{{ splitJob(JobsList, 0) }}"
                        title="{{ splitJob(JobsList, 0) }}"
                      >
                        {{ splitJob(JobsList, 0) }}
                      </a>
                      <div class="viewmore-show" *ngIf="i == 9">
                        <a
                          class="orange-link cursor"
                          (click)="searchByLocation(JobsObj.locationName)"
                          >View More</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="icon-show-hide" *ngIf="JobsObj.result.length > 3">
                    <i class="fa fa-angle-down" aria-hidden="true"></i>
                    <i class="fa fa-angle-up" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </div>
            <!-- Jobs By Location -->
          </div>
        </div>
      </div>

      <!-- Half Banner -->
      <!-- <div class="row">
        <div class=" margin-bottom-15 hidethis" *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'HB'">
          <a href="{{AdsObj.url}}" target="_blank" (click)="updateImpression(AdsObj.adContentId)">
            <img src="{{AdsObj.image }}" width="100%">
          </a>
        </div>
      </div> -->
    </div>

    <div class="col-md-3 res-hide">
      <div class="row add-margin" *ngFor="let AdsObj of adsObj">
        <!-- Top ad small one -->
        <div
          class="col-md-12 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TO'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top ad small two -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TO1'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top ad big one -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TT'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top Ad Big two -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TT1'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top Ad small three -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TO2'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top Ad small four -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TO3'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <!-- Top Ad Big 3 -->
        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TT2'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TT3'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>

        <div
          class="col-md-12 margin-bottom-15 hidethis"
          *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'TT4'"
        >
          <a
            href="{{ AdsObj.url }}"
            target="_blank"
            (click)="updateImpression(AdsObj.adContentId)"
          >
            <img src="{{ AdsObj.image }}" width="100%" />
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 margin-bottom-15 hidethis">
          <a href="http://www.the-network.com" target="_blank">
            <img src="../../../assets/images/thenetwork.png" width="100%" />
          </a>
        </div>
      </div>
      <div class="row" *ngIf="testimonial && testimonial.length">
        <div class="col-md-12 margin-bottom-15 hidethis">
          <!-- Carousel container -->
          <div class="testimonial-title">Testimonial</div>
          <div
            id="my-pics"
            class="carousel slide"
            data-ride="carousel"
            style="width:100%; margin: auto"
          >
            <!-- Indicators -->
            <!-- Content -->
            <div class="carousel-inner" role="listbox">
              <!-- Slide 1 -->
              <div
                class="item testimonial-slider"
                *ngFor="let item of testimonial; let i = index"
                [ngClass]="i == 0 ? 'active' : ''"
              >
                <img
                  [src]="'https://t4.ftcdn.net/jpg/03/64/21/11/360_F_364211147_1qgLVxv1Tcq0Ohz3FawUfrtONzz8nq3e.jpg'"
                  alt="profile picture"
                />
                <div class="name">{{ item.name }}</div>
                <div class="companyName">{{ item.companyName }}</div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="small-ratings">
                    <i
                      class="fa fa-star"
                      [ngClass]="
                        item.rating == 1 ||
                        item.rating == 2 ||
                        item.rating == 3 ||
                        item.rating == 4 ||
                        item.rating == 5
                          ? 'rating-color'
                          : ''
                      "
                    ></i>
                    <i
                      class="fa fa-star"
                      [ngClass]="
                        item.rating == 2 ||
                        item.rating == 3 ||
                        item.rating == 4 ||
                        item.rating == 5
                          ? 'rating-color'
                          : ''
                      "
                    ></i>
                    <i
                      class="fa fa-star"
                      [ngClass]="
                        item.rating == 3 || item.rating == 4 || item.rating == 5
                          ? 'rating-color'
                          : ''
                      "
                    ></i>
                    <i
                      class="fa fa-star"
                      [ngClass]="
                        item.rating == 4 || item.rating == 5
                          ? 'rating-color'
                          : ''
                      "
                    ></i>
                    <i
                      class="fa fa-star"
                      [ngClass]="item.rating == 5 ? 'rating-color' : ''"
                    ></i>
                  </div>
                </div>
                <div class="comment">{{ item.comment }}</div>
              </div>

              <!-- <div class="item">
                <img
                  src="https://www.quackit.com/pix/samples/4l.jpg"
                  alt="Rob Roy Glacier"
                />
              </div>

              <div class="item">
                <img
                  src="https://www.quackit.com/pix/samples/15l.jpg"
                  alt="Longtail boats at Phi Phi"
                />
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 nopadding"
        style="background: #fff"
        *ngIf="featureComanyObj.length"
      >
        <!-- <div class="col-md-12 nopadding">
          <div class="feature-company">
            Featured Companies
          </div>
        </div> -->

        <!-- <div class="col-sm-12 custom-feature" *ngFor="let FeatureComanyObj of featureComanyObj">
          <a href="" title="{{FeatureComanyObj.companyName}}"
            [routerLink]="['/employer',  replaceSpace(FeatureComanyObj.companyName), FeatureComanyObj.employerDetailId]"
            style="display: flex; align-items: center; text-decoration: none;">
            <img src="{{FeatureComanyObj.image}}" class="feature-img" alt="{{FeatureComanyObj.companyName}}">

            <div class="feature-company-name text-ellipsis" style="max-width: 70%"> {{FeatureComanyObj.companyName}}
            </div>
          </a>
        </div> -->
      </div>

      <button
        type="button"
        id="bannerAds"
        (click)="openModal(template)"
      ></button>
    </div>
  </div>
</div>

<!-- Full Banner Ads -->
<div class="container">
  <div class="row job-box-ads" *ngFor="let AdsObj of fullBannerList">
    <div
      class="col-md-12 margin-bottom-15 hidethis"
      style="padding: 0px 8px"
      *ngIf="AdsObj.image && AdsObj.url && AdsObj.adFlag == 'FB'"
    >
      <a
        href="{{ AdsObj.url }}"
        target="_blank"
        (click)="updateImpression(AdsObj.adContentId)"
      >
        <img src="{{ AdsObj.image }}" width="100%" />
      </a>
    </div>
  </div>
</div>

<!--Quick Links-->
<!-- <div class="container-fluid quick_links" *ngIf="footerJobObj">
  <div class="container">
    <div class="row">
      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">Jobs by Industry</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.industryList">
            <a class="cursor" [routerLink]="['/job-search']" [queryParams]="{ indqs: FooterJobObj.industryId}">
              {{FooterJobObj.industryName}}</a>
          </h3>
        </div>
      </div>

      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">Jobs by Functional Area</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.funcAreaList">
            <a class="cursor" [routerLink]="['/job-search']" [queryParams]="{ funqs: FooterJobObj.funcAreaId}">
              {{FooterJobObj.funcAreaName}}</a>
          </h3>
        </div>
      </div>
      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">Jobs by Location</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.locationList">
            <a class="cursor" target="_blank" [routerLink]="['/job-search']"
              [queryParams]="{ locqs: FooterJobObj.locationId}">
              {{FooterJobObj.locationName}}</a>
          </h3>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">Company List</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.companyList">
            <a class="cursor" target="_blank"
              [routerLink]="['/employer', replaceSpace(FooterJobObj.employerName), FooterJobObj.employerId]">
              {{FooterJobObj.employerName}}</a>
          </h3>
        </div>
      </div>

      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">IT Jobs</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.itJobs">

            <a class="cursor" target="_blank"
              [routerLink]="['/job/', replaceSpace(FooterJobObj.jobTitle), FooterJobObj.jobCreateId]"
              *ngIf="!FooterJobObj.newsPublicationId"> {{FooterJobObj.jobTitle}}</a>
            <a class="cursor" target="_blank"
              [routerLink]="['/newspaper/', replaceSpace(FooterJobObj.jobTitle), FooterJobObj.jobCreateId]"
              *ngIf="FooterJobObj.newsPublicationId">{{FooterJobObj.jobTitle}}</a>
          </h3>
        </div>
      </div>

      <div class="col-md-4 col-sm-4">
        <div class="box">
          <h2 class="t2 text ">Freshers Jobs</h2>
          <h3 class="t3 text" *ngFor="let FooterJobObj of footerJobObj.fresherJobs">
            <a class="cursor" target="_blank"
              [routerLink]="['/job/', replaceSpace(FooterJobObj.jobTitle), FooterJobObj.jobCreateId]">
              {{FooterJobObj.jobTitle}}</a>
          </h3>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-footer></app-footer>

<ng-template #template>
  <div class="modal-header" *ngIf="fullBanner">
    <button
      type="button"
      class="close custom-close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">x</span>
    </button>

    <a href="{{ fullBanner.url }}"
      ><img
        [src]="fullBanner.image"
        alt="{{ fullBanner.companyName }}"
        (click)="updateImpression(fullBanner.adContentId)"
        style="width: 100%"
    /></a>
  </div>
</ng-template>

<!-- <button type="button" class="btn btn-primary" (click)="openModal(template)">Create template modal</button> -->

<ng-template #popup>
  <div>
    <button
      type="button"
      class="close custom-close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>

    <a href="{{ popUpAds.url }}" target="_blank"
      ><img
        [src]="popUpAds.image"
        alt="Image"
        style="width: 100%"
        *ngIf="popUpAds"
        (click)="modalRef.hide(); updateImpression(popUpAds.adContentId)"
    /></a>
  </div>
  <!-- <div class="modal-body">
    This is a modal.
  </div> -->
</ng-template>

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiServiceService } from "../../common/services/api-service.service";
import { Meta, Title } from "@angular/platform-browser";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-newpaper-job",
  templateUrl: "./newpaper-job.component.html",
  styleUrls: ["./newpaper-job.component.scss"]
})
export class NewpaperJobComponent implements OnInit {
  jobId: string;
  jobTitle: string;

  loader = false;

  constructor(
    private route: ActivatedRoute,
    private apiServiceService: ApiServiceService,
    public meta: Meta,
    public title: Title,
    private router: Router,
    private cookie:CookieService
  ) {
    this.loader = true;

    this.route.params.subscribe(params => {
      this.jobId = params["jobId"];
      this.jobTitle = params["jobName"];

      this.title.setTitle(this.jobTitle + " | " + "Jobejee");

      // <!-- Open Graph data -->
      this.meta.updateTag({
        property: "og:title",
        content: this.jobTitle + " | " + "Jobejee"
      });
      this.meta.updateTag({ property: "og:site_name", content: "jobejee" });
      this.meta.updateTag({ property: "og:type", content: "website" });
      this.meta.updateTag({
        property: "fb:app_id",
        content: "2458134394205611"
      });
      this.meta.updateTag({
        property: "og:url",
        content: "https://www.jobejee.com" + this.router.url
      });

      this.getJobDetail();
    });
  }

  ngOnInit() { }

  jobsObj;

  getJobDetail() {
    this.loader = true;
    let Obj = {
      jobCreateId: this.jobId
    };
    this.apiServiceService
      .NewspaperJobDetails(Obj)
      .subscribe((response: any) => {
        this.jobsObj = response;

        let seotitle =
          this.jobsObj.title +
          " - " +
          this.jobsObj.organizationName +
          " | " +
          "Jobejee";
        this.title.setTitle(seotitle);
        this.meta.updateTag({ property: "og:title", content: seotitle });

        this.meta.updateTag({
          property: "og:image",
          content: "https://www.jobejee.com/assets/images/newspaperjobs.png"
        });
        // this.meta.updateTag({ property: 'og:description', content: this.jobsObj.jobDesc.replace(/<[^>]*>?/gm, '') });

        this.getSimilarJobDetail();
        this.loader = false;
      });
  }

  similarList:any = [];
  getSimilarJobDetail() {
    let Obj = {
      jobCreateId: this.jobId,
      // jobType: "NJ"
    };
    this.apiServiceService.SimilarNewspaperJobDetails(Obj)
      .subscribe((response: any) => {
        this.similarList = response;
      });
  }

  pushIntoArray(value) {
    let splitData = value.split(",");
    return splitData;
  }

  replaceSpace(value) {
    let str = value.replace(/\s+/g, "-");
    return str;
  }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }
}

<div class="container-fluid navbar-custom">
  <div class="container">
    <div class="grid-container">
      <div
        class="grid-item"
        [routerLink]="['/admin/dashboard']"
        [ngClass]="urlLastData == 'dashboard' ? 'active' : ''"
      >
        <i class="fa fa-tachometer" aria-hidden="true"></i>
        <span class="res-hide">Dashboard</span>
      </div>
      <div
        class="grid-item"
        [routerLink]="['/admin/employer-list']"
        [ngClass]="
          urlLastData == 'employer-list' || matchEmpUrl() ? 'active' : ''
        "
      >
        <i class="fa fa-user"></i> <span class="res-hide">Employers</span>
      </div>
      <div
        class="grid-item"
        [routerLink]="['/admin/job-list']"
        [ngClass]="urlLastData == 'job-list' || matchUrl() ? 'active' : ''"
      >
        <i class="fa fa-inbox" aria-hidden="true"></i>
        <span class="res-hide">Jobejee Jobs</span>
      </div>

      <div
        class="grid-item"
        [routerLink]="['/admin/newspaper-list']"
        [ngClass]="
          urlLastData == 'newspaper-list' ||
          urlLastData == 'newspaper-job-create' ||
          urlLastData == 'newspaper-approval' ||
          getMatch()
            ? 'active'
            : ''
        "
      >
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
        <span class="res-hide">Newspaper Jobs</span>
      </div>

      <div
        class="grid-item"
        [routerLink]="['/admin/jobseeker-list']"
        [ngClass]="
          urlLastData == 'jobseeker-list' ||
          urlLastData == 'unregistered-js' ||
          urlLastData == 'applicant-list'
            ? 'active'
            : ''
        "
      >
        <i class="fa fa-file-text-o" aria-hidden="true"></i>
        <span class="res-hide">Jobseeker</span>
      </div>

      <div
        class="grid-item"
        [routerLink]="['/admin/advertisement-list']"
        [ngClass]="
          urlLastData == 'advertisement-list' ||
          urlLastData == 'create-advertisement'
            ? 'active'
            : ''
        "
      >
        <i class="fa fa-newspaper-o" aria-hidden="true"></i>
        <span class="res-hide">Advertisement</span>
      </div>

      <!-- <div class="grid-item" [routerLink]="['/admin/billing-list']"
        [ngClass]="urlLastData == 'billing-list' ? 'active' : ''">
        <i class="fa fa-cog" aria-hidden="true"></i> Billing List
      </div> -->

      <!-- <div class="grid-item" [routerLink]="['/examlist']" [ngClass]="urlLastData == 'examlist' ? 'active' : ''"
        *ngIf="examFlag">
        <i class="fa fa-cog" aria-hidden="true"></i> Exam List
      </div> -->
      <!-- <div
        class="grid-item"
        [routerLink]="['/admin/employment-index']"
        [ngClass]="urlLastData == 'employment-index' ? 'active' : ''"
      >
        <i class="fa fa-cog" aria-hidden="true"></i>
        <span class="res-hide">Employment Index</span>
      </div> -->
    </div>
  </div>
</div>

import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  LazyLoadImageModule,
  intersectionObserverPreset,
} from "ng-lazyload-image";

import { AuthInterceptorService } from "./common/services/auth-interceptor.service";

//non auth
import { TermsConditionComponent } from "./nonAuth/terms-condition/terms-condition.component";
import { SearchJobComponent } from "./nonAuth/search-job/search-job.component";
import { PrivacyPolicyComponent } from "./nonAuth/privacy-policy/privacy-policy.component";
import { NewpaperJobComponent } from "./nonAuth/newpaper-job/newpaper-job.component";
import { LearnMoreComponent } from "./nonAuth/learn-more/learn-more.component";
import { JobSearchComponent } from "./nonAuth/job-search-list/job-search-list.component";
import { JobDetailComponent } from "./nonAuth/job-detail/job-detail.component";
import { HomeComponent } from "./nonAuth/home/home.component";
import { FaqComponent } from "./nonAuth/faq/faq.component";
import { DeletionComponent } from "./nonAuth/deletion/deletion.component";
import { ClientProfileComponent } from "./nonAuth/client-profile/client-profile.component";
import { AboutUsComponent } from "./nonAuth/about-us/about-us.component";

//jsseeker
import { LoginComponent } from "./jobseeker/login/login.component";
import { RegisterComponent } from "./jobseeker/register/register.component";
import { SmallSpinnerComponent } from "./includes/small-spinner/small-spinner.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { VerifyEmailComponent } from "./nonAuth/verify-email/verify-email.component";
import { JsforgetPasswordComponent } from "./nonAuth/jsforget-password/jsforget-password.component";

import { NewJobDetailComponent } from "./nonAuth/new-job-detail/new-job-detail.component";
import { NewCompanyProfileComponent } from "./nonAuth/new-company-profile/new-company-profile.component";

import { CookieService } from "ngx-cookie-service";
import { VerifyEmployerEmailComponent } from "./nonAuth/verify-employer-email/verify-employer-email.component";
import { JobApplyComponent } from "./nonAuth/job-apply/job-apply.component";

// Modules
import { SharedModule } from "./common/modules/shared.module";

//password Reset components
import { ResetPasswordComponent } from "./common/components/reset-password/reset-password.component";
import { ForgetPasswordComponent } from "./common/components/forget-password/forget-password.component";
import { BookmarkJobComponent } from "./nonAuth/bookmark-job/bookmark-job.component";
import { EmploymentIndexComponent } from "./nonAuth/employment-index/employment-index.component";
import { LogHistoryComponent } from "./nonAuth/log-history/log-history.component";
import { TableListComponent } from "./common/components/table-list/table-list.component";
import { HipcoComponent } from "./nonAuth/hipco/hipco.component";

@NgModule({
  declarations: [
    AppComponent,

    //non auth
    AboutUsComponent,
    ClientProfileComponent,
    FaqComponent,
    DeletionComponent,
    HomeComponent,
    JobDetailComponent,
    JobSearchComponent,
    LearnMoreComponent,
    NewpaperJobComponent,
    PrivacyPolicyComponent,
    SearchJobComponent,
    TermsConditionComponent,

    // js seeker
    LoginComponent,
    RegisterComponent,
    SmallSpinnerComponent,
    VerifyEmailComponent,
    JsforgetPasswordComponent,
    NewJobDetailComponent,
    NewCompanyProfileComponent,
    VerifyEmployerEmailComponent,
    VerifyEmployerEmailComponent,
    JobApplyComponent,

    //password reset
    ResetPasswordComponent,
    ForgetPasswordComponent,
    BookmarkJobComponent,
    EmploymentIndexComponent,
    LogHistoryComponent,
    TableListComponent,
    HipcoComponent,
  ],

  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,

    //Modules
    AppRoutingModule,
    SharedModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset, // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppModule {}

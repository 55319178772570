import { Injectable } from '@angular/core';
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class UniversalService {

  constructor() { }

  formatDate(value) {
    let dateTime = new Date(value);
    let date = moment(dateTime).format("YYYY-MM-DD");
    return date
  }

  monthYear(value) {
    let dateTime = new Date(value);
    let date = moment(value).format("YYYY/MM");
    return date
  }

  getDateSelected(data) {
    let year = data.getFullYear();
    let m = data.getMonth() + 1;
    let month = m > 9 ? m : "0" + m;
    let d = data.getDate();
    let day = d > 9 ? d : "0" + d;
    let fullDate = year + "-" + month + "-" + day;
    return fullDate;
  }

  getDate(dateValue: any) {
    let date = moment(dateValue).format("YYYY-MM-DD");
    return date;
  }

  getDateTime(date, time) {
    let yy = date.getFullYear();
    let m = parseInt(date.getMonth()) + 1;
    let mm = m > 9 ? m : "0" + m;
    let d = date.getDate();
    let dd = d > 9 ? d : "0" + d;
    let fullDate = yy + "-" + mm + "-" + dd;
    let fullTime = time + ":00";
    return fullDate + " " + fullTime;
  }

  getDateTimeAttach(data, time) {
    let sendDate: any = new Date(data);
    let yy = sendDate.getFullYear();
    let m = parseInt(sendDate.getMonth()) + 1;
    let mm = m > 9 ? m : "0" + m;
    let d = sendDate.getDate();
    let dd = d > 9 ? d : "0" + d;
    let fullDate = yy + "-" + mm + "-" + dd;
    let fullTime = time + ":00";
    return fullDate + " " + fullTime;
  }

  getallDateSelected(data) {
    if (data) {
      let sendDate = new Date(data);
      let year = sendDate.getFullYear();
      let m = sendDate.getMonth() + 1;
      let month = m > 9 ? m : "0" + m;
      let d = sendDate.getDate();
      let day = d > 9 ? d : "0" + d;
      let fullDate = year + "-" + month + "-" + day;
      return fullDate;
    }
  }

  getallDateTimeSelected(data) {
    if (data) {
      let sendDate = new Date(data);
      let year = sendDate.getFullYear();
      let m = sendDate.getMonth() + 1;
      let month = m > 9 ? m : "0" + m;
      let d = sendDate.getDate();
      let day = d > 9 ? d : "0" + d;
      let fullDate = year + "-" + month + "-" + day;
      let hour = sendDate.getHours();
      let minute = sendDate.getMinutes();
      let fullTime = hour + ":" + minute;
      return fullDate + " " + fullTime;
    }
  }

  // this function is for pagination
  getPage(totalpages: number, currentPage: number) {
    let startPage: number, endPage: number;
    if (totalpages <= 5) {
      startPage = 1;
      endPage = totalpages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalpages) {
        startPage = totalpages - 4;
        endPage = totalpages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    let pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return {
      startPage: startPage,
      endPage: endPage,
      pages: pages
    };
  }

  formatTime(dateValue: any) {
    let time = moment(dateValue).format("HH:mm:ss");
    return time;
  }

  get24hTime(str) {
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace('am', '').replace('pm', '');
    // if hour, convert to hour:00
    if (str.indexOf(':') < 0) str = str + ':00';
    // now it's hour:minute
    // we add am/pm back if striped out before 
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us 
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return (parseInt(n) < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
  }

  romanize(num) {
    if (!+num) return NaN;
    var digits = String(+num).split(""),
      key = [
        "",
        "C",
        "CC",
        "CCC",
        "CD",
        "D",
        "DC",
        "DCC",
        "DCCC",
        "CM",
        "",
        "X",
        "XX",
        "XXX",
        "XL",
        "L",
        "LX",
        "LXX",
        "LXXX",
        "XC",
        "",
        "I",
        "II",
        "III",
        "IV",
        "V",
        "VI",
        "VII",
        "VIII",
        "IX"
      ],
      roman = "",
      i = 3;
    while (i--) roman = (key[+digits.pop() + i * 10] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
  }

  fileUpload(filevalue: any, fileExt: string[], filesize: number) {
    if (filevalue.length == 1) {
      if (filevalue[0].size < filesize) {
        let filename = filevalue[0].name;
        let getFileExtension = filename.slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1);
        let checkExtension = fileExt.indexOf(getFileExtension);
        if (checkExtension != -1) {
          let uploadFile = filevalue[0];
          let formData: FormData = new FormData();
          formData.append('file', uploadFile);
          let Obj = {
            fileStatus: 1,
            sendFile: formData
          }
          return Obj;
        } else {
          let Obj = { fileStatus: 3, sendFile: null }
          return Obj;
        }
      } else {
        let Obj = { fileStatus: 2, sendFile: null }
        return Obj;
      }
    }
  }


}



<div class="container container-fixed-top">
    <div class="row">
        <div class="col-md-2 col-md-offset-10">
            <label for="sel1">Select Language</label>
            <select class="form-control" name="langauge change" (change)="SelectChange($event)" id="selectLanguage ">
              <option>English</option>
              <option>Nepali</option>
              <option>Bhojpuri</option>
            </select>
        </div>
    </div>
    <div class="row" style="margin-top:15px; margin-bottom: 15px;">
        <div class="col-md-12">   
            <img src="../../../assets/images/update_group.png" alt ="Banner Image" class="bimage">
        </div>
    </div>
  
        <div class="box-container col-md-12">
           
            <h4 class="hulastitle">
                <span *ngIf="languageSelected == 'English'">Hulas Steel - Hipco Superbuild <br /> <br /> <span style="margin-top:10px;">Steel Fabrication Training and Employment Program</span></span>
                <span *ngIf="languageSelected == 'Nepali' || languageSelected == 'Bhojpuri'">हुलास स्टील - हिप्को सुपरबिल्ड <br /> <br /> स्टील फ्याब्रिकेशन तालिम तथा रोजगार कार्यक्रम </span>
                <!-- <span *ngIf="languageSelected == 'Bhojpuri'">हुलास – हिप्को स्टील फ्याब्रिकेशन ट्रेनिङ्ग औरी रोजगार कार्यक्रम</span> -->
            </h4>
            <div class="col-md-12 registerform">
                <button class="btn custom-danger">
                    <span *ngIf="languageSelected == 'English'">Registration Form</span>
                    <span *ngIf="languageSelected == 'Nepali'">दर्ता फारम</span>
                    <span *ngIf="languageSelected == 'Bhojpuri'">रजिस्ट्रेशन के फार्म बा</span>
                </button>
            </div>

            <div class="row">
                <!-- <div class="col-md-1"></div> -->
                <div class="col-md-10 col-md-offset-1">
                    <p class="form-title col-md-12">
                        <span *ngIf="languageSelected == 'English'">General Information</span>
                        <span *ngIf="languageSelected == 'Nepali' || languageSelected == 'Bhojpuri'">सामान्य जानकारी</span>
                      <sup>*</sup></p>

                    <form [formGroup]="myForm" (ngSubmit)="saveData()"  (keyup.enter)="saveData()">
                        <div class="form-group col-md-12">
                          <label for="firstName">
                            <span *ngIf="languageSelected == 'English'">Full Name</span>
                            <span *ngIf="languageSelected == 'Nepali'">पुरा नाम थर</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">पुरा नाम जात / जाति बा</span>
                            <sup style="color:Red">*</sup>
                            
                            </label>
                          <input type="text" formControlName ="firstName" class="form-control" id="firstName">
                            <div class="text-danger"
                                    *ngIf="myForm.controls.firstName.touched && myForm.controls.firstName.errors?.required">
                                   
                                    <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                                    <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="form-group col-md-6">
                            <label for="age">
                                <span *ngIf="languageSelected == 'English'">Age</span>
                                <span *ngIf="languageSelected == 'Nepali'">उमेर</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">उमर</span>
                            </label>
                            <input type="text" formControlName ="age" class="form-control" id="age">
                            <div class="text-danger"
                            *ngIf="myForm.controls.age.touched && myForm.controls.age.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>                     

                        <div class="form-group col-md-6">
                            <label for="Marital">
                                <span *ngIf="languageSelected == 'English'">Marital Status</span>
                                <span *ngIf="languageSelected == 'Nepali'">वैवाहिक अवस्था</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">शादी भईल / नाभईल </span>
                            </label>
                            <select class="form-control" id="Marital" formControlName ="maritalStatus">
                                <option value="Married">
                                    <span *ngIf="languageSelected == 'English'">Married</span>
                                    <span *ngIf="languageSelected == 'Nepali'">विवाहित</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">शादीशुदा </span>
                                </option>
                                <option value="Unmarried">
                                    <span *ngIf="languageSelected == 'English'">Unmarried</span>
                                    <span *ngIf="languageSelected == 'Nepali'">अविवहित</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">कुवाँरा / कुवाँर </span>
                                </option>
                                <option value="Single Woman">
                                    <span *ngIf="languageSelected == 'English'">Single Woman</span>
                                    <span *ngIf="languageSelected == 'Nepali'">एकल महिला</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">बिदुवा</span>
                                </option>
                                <option value="Widower">
                                    <span *ngIf="languageSelected == 'English'">Widower</span>
                                    <span *ngIf="languageSelected == 'Nepali'">बिदुर</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">जेकर पत्नी मरगईल बा</span>
                                </option>
                            </select>
                            <div class="text-danger"
                            *ngIf="myForm.controls.maritalStatus.touched && myForm.controls.maritalStatus.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                        <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'">   Permanent Address (According to Citizenship)</span>
                            <span *ngIf="languageSelected == 'Nepali'">स्थाई ठेगाना (नागरिकता अनुसार) </span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">जनम् भईल जगह (नागरिकता के हिसाब से)</span>
                            <sup>*</sup></p>

                        <div class="form-group col-md-6">
                            <label for="province">
                                <span *ngIf="languageSelected == 'English'">Select Province</span>
                                <span *ngIf="languageSelected == 'Nepali'">प्रदेश</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">प्रदेश</span>
                            </label>
                            <select class="form-control" id="province" formControlName="permProvince" (change)="provienceChange($event)">
                                <option value={{item.locationName}} *ngFor="let item of stateList">
                                    {{item.locationName}}
                                  </option>
                            </select>
                            <div class="text-danger"
                            *ngIf="myForm.controls.permProvince.touched && myForm.controls.permProvince.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                        
                          <div class="form-group col-md-6">
                            <label for="permdistrict">
                                <span *ngIf="languageSelected == 'English'">Select District</span>
                                <span *ngIf="languageSelected == 'Nepali'">जिल्ला</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">जिल्ला</span>
                            </label>
                            <select class="form-control" id="province" formControlName="permDistrict">
                                <option value={{item.locationName}} *ngFor="let item of citiList">
                                    {{item.locationName}}
                                  </option>
                            </select>
                            <div class="text-danger" 
                            *ngIf="myForm.controls.permDistrict.touched && myForm.controls.permDistrict.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                          <div class="form-group col-md-6">
                            <label for="permRural">
                                <span *ngIf="languageSelected == 'English'">Rural Municipality / Municipality Name</span>
                                <span *ngIf="languageSelected == 'Nepali'">गाउँपालिका / नगरपालिकाको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">गाउँपालिका / नगरपालिका के नाम</span>
                            </label>
                            <input type="text" class="form-control"  formControlName="permMunicipality" id="permRural">
                            <div class="text-danger"
                            *ngIf="myForm.controls.permMunicipality.touched && myForm.controls.permMunicipality.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="permward">
                                <span *ngIf="languageSelected == 'English'">Ward No.</span>
                                <span *ngIf="languageSelected == 'Nepali'">वडा नं</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">वडा नं</span>
                            </label>
                            <input type="text" class="form-control" id="permward" formControlName="permWard">
                            <div class="text-danger"
                            *ngIf="myForm.controls.permWard.touched && myForm.controls.permWard.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="clearfix"></div>
                        <div class="form-group col-md-12">
                            <label for="strepermstreetet">
                                <span *ngIf="languageSelected == 'English'">Street Address</span>
                                <span *ngIf="languageSelected == 'Nepali'">टोलको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">टोला के नाम</span>
                            </label>
                            <input type="text" class="form-control" formControlName="permStreet" id="permstreet">
                            <div class="text-danger"
                            *ngIf="myForm.controls.permStreet.touched && myForm.controls.permStreet.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <!-- Temporary -->
                        <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'"> Temporary Address (Currently Living Address)</span>
                            <span *ngIf="languageSelected == 'Nepali'">अस्थाई ठेगाना (हाल बसोबास गरिरहेको) </span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">अभि के रहेवाला जगह</span>
                            <sup>*</sup></p>
                        <div class="form-group col-md-6">
                            <label for="tempprovince">
                                <span *ngIf="languageSelected == 'English'">Select Province</span>
                                <span *ngIf="languageSelected == 'Nepali'">प्रदेश</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">प्रदेश</span>
                            </label>
                            <select class="form-control" id="tempprovince" formControlName="tempProvince" (change)="provienceTempChange($event)">
                                <option value={{item.locationName}} *ngFor="let item of stateList">
                                    {{item.locationName}}
                                </option>
                            </select>
                            <div class="text-danger"
                            *ngIf="myForm.controls.tempProvince.touched && myForm.controls.tempProvince.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                        
                          <div class="form-group col-md-6">
                            <label for="tempDistrict">
                                <span *ngIf="languageSelected == 'English'">Select District</span>
                                <span *ngIf="languageSelected == 'Nepali'">जिल्ला</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">जिल्ला</span>
                            </label>
                            <select class="form-control" id="province" formControlName="tempDistrict">
                                <option value={{item.locationName}} *ngFor="let item of tempcitiList">
                                    {{item.locationName}}
                                  </option>
                            </select>

                            <div class="text-danger"
                            *ngIf="myForm.controls.tempDistrict.touched && myForm.controls.tempDistrict.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                          <div class="clearfix"></div>

                     


                          <div class="form-group col-md-6">
                            <label for="tempMunicipality">
                                <span *ngIf="languageSelected == 'English'">Rural Municipality / Municipality Name</span>
                                <span *ngIf="languageSelected == 'Nepali'">गाउँपालिका / नगरपालिकाको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">गाउँपालिका / नगरपालिका के नाम</span>
                            </label>
                            <input type="text" class="form-control"  formControlName="tempMunicipality" id="tempMunicipality">
                            <div class="text-danger"
                            *ngIf="myForm.controls.tempMunicipality.touched && myForm.controls.tempMunicipality.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="tempWard">
                                <span *ngIf="languageSelected == 'English'">Ward No.</span>
                                <span *ngIf="languageSelected == 'Nepali'">वडा नं</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">वडा नं</span>
                            </label>
                            <input type="text" class="form-control" id="tempWard" formControlName="tempWard">
                            <div class="text-danger"
                            *ngIf="myForm.controls.tempWard.touched && myForm.controls.tempWard.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label for="tempStreet">
                                <span *ngIf="languageSelected == 'English'">Street Address</span>
                                <span *ngIf="languageSelected == 'Nepali'">टोलको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">टोला के नाम</span>
                            </label>
                            <input type="text" class="form-control" formControlName="tempStreet" id="tempStreet">
                            <div class="text-danger"
                            *ngIf="myForm.controls.tempStreet.touched && myForm.controls.tempStreet.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                            
                        </div>
                

                        <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'">Contact Number</span>
                            <span *ngIf="languageSelected == 'Nepali'">फोन नं</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">फोन न</span>
                        </p>

                        <div class="form-group col-md-6">
                            <label for="telephone">
                                <span *ngIf="languageSelected == 'English'">Telephone Number</span>
                                <span *ngIf="languageSelected == 'Nepali'">ल्याण्डलाईन नं</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">टेलिफोन न</span>
                            </label>
                            <input type="text" class="form-control" formControlName="phone" id="telephone">
                            <div class="text-danger"
                            *ngIf="myForm.controls.phone.touched && myForm.controls.phone.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="mobile">
                                <span *ngIf="languageSelected == 'English'">Mobile Number</span>
                                <span *ngIf="languageSelected == 'Nepali'">तपाईको मोबाईल नं </span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">अपने के मोबाईल न</span>
                            </label>
                            <input type="text" class="form-control" formControlName="mobile" id="mobile">
                            <div class="text-danger"
                            *ngIf="myForm.controls.mobile.touched && myForm.controls.mobile.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>
                        <div class="clearfix"></div>

                        <div class="form-group col-md-12">
                            <label for="emergency">
                                <span *ngIf="languageSelected == 'English'">In case of emergency, please provide contact number of a close one  besides yours</span>
                                <span *ngIf="languageSelected == 'Nepali'">आपत् कालीन अवस्थामा तपाईको बाहेक अन्य नजिकको व्यक्तिको मोबाईल नं</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">समय पर्लापर अपने के बाहेक कौनो करीबी आद्मी के मोबाईल न</span>
                            </label>
                            <input type="text" class="form-control" formControlName="emergencyNo" id="emergency">
                            <div class="text-danger"
                            *ngIf="myForm.controls.emergencyNo.touched && myForm.controls.emergencyNo.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label>
                                <span *ngIf="languageSelected == 'English'">Mother Tongue(Language)</span>
                                <span *ngIf="languageSelected == 'Nepali'">मातृभाषा</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">मातृभाषा</span>
                            </label><br />
                            <select class="form-control" id="motherTongue" formControlName ="motherTongue" name="motherTongue" (change)="SelectChange($event)">
                                <option value="Nepali">
                                    <span *ngIf="languageSelected == 'English'">Nepali</span>
                                    <span *ngIf="languageSelected == 'Nepali'">नेपाली</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">नेपाली</span>
                                </option>
                                <option value="English">
                                    <span *ngIf="languageSelected == 'English'">English</span>
                                    <span *ngIf="languageSelected == 'Nepali'">अंग्रेजी</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">अंग्रेजी</span>
                                </option>
                                <option value="Bhojpuri">
                                    <span *ngIf="languageSelected == 'English'">Bhojpuri</span>
                                    <span *ngIf="languageSelected == 'Nepali'">भोजपुरी</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">भोजपुरी</span>
                                </option>
                                <option value="Maithili">
                                    <span *ngIf="languageSelected == 'English'">Maithili</span>
                                    <span *ngIf="languageSelected == 'Nepali'">मैथिली</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">मैथिली</span>
                                </option>
                                <option value="Other">
                                    <span *ngIf="languageSelected == 'English'">Other</span>
                                    <span *ngIf="languageSelected == 'Nepali'">अन्य</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">अन्य</span>
                                </option>
                            </select>
                            <div class="text-danger"
                            *ngIf="myForm.controls.motherTongue.touched && myForm.controls.motherTongue.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>

                          <div class="form-group col-md-12" *ngIf="myForm.controls.motherTongue.value == 'Other'">
                            <label for="otherlanguage"> 
                                <span *ngIf="languageSelected == 'English'">Other Language</span>
                                <span *ngIf="languageSelected == 'Nepali'">अन्य</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">अन्य</span>
                            </label>
                            <input type="text" class="form-control" formControlName ="otherMotherTongue" id="otherlanguage">
                            <div class="text-danger"
                            *ngIf="myForm.controls.otherMotherTongue.touched && myForm.controls.otherMotherTongue.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label>
                                <span *ngIf="languageSelected == 'English'">Are you working right now ?</span>
                                <span *ngIf="languageSelected == 'Nepali'">के तपाई कुनै काम गर्दै हुनुहुन्छ ?</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">का अपने कौनो काम कर रहल बानि ?</span>
                            </label><br />
                            <label class="radio-inline"><input type="radio" formControlName="isWorking" (change)="SelectChange($event)" value="Y" name="isWorking" checked>
                                <span *ngIf="languageSelected == 'English'">Yes</span>
                                <span *ngIf="languageSelected == 'Nepali'">छ</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">ह</span>
                            </label>
                            <label class="radio-inline"><input type="radio" formControlName="isWorking"  (change)="SelectChange($event)" value ="N" name="isWorking">
                                <span *ngIf="languageSelected == 'English'">No</span>
                                <span *ngIf="languageSelected == 'Nepali'">छैन</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">ना</span></label>
                          </div>
                          
                          <div class="form-group col-md-6" *ngIf="myForm.controls.isWorking.value == 'Y'">
                            <label for="Organizationname">
                                <span *ngIf="languageSelected == 'English'">Organization's Name </span>
                                <span *ngIf="languageSelected == 'Nepali'">संस्थाको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">काम कर रहल संस्थाके नाम</span>
                            </label>
                            <input type="text" class="form-control" id="Organizationname" formControlName ="organizationName">
                            <div class="text-danger"
                            *ngIf="myForm.controls.organizationName.touched && myForm.controls.organizationName.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6" *ngIf="myForm.controls.isWorking.value == 'Y'">
                            <label for="organizationAddress">
                                <span *ngIf="languageSelected == 'English'">Organization Address </span>
                                <span *ngIf="languageSelected == 'Nepali'">संस्थाको ठेगाना</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">काम कर रहल संस्थाके ठेगाना</span>
                            </label>
                            <input type="text" class="form-control" id="organizationAddress" formControlName ="organizationAddress">
                            <div class="text-danger"
                            *ngIf="myForm.controls.organizationAddress.touched && myForm.controls.organizationAddress.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6" *ngIf="myForm.controls.isWorking.value == 'Y'">
                            <label for="position"> 
                                <span *ngIf="languageSelected == 'English'">Position</span>
                                <span *ngIf="languageSelected == 'Nepali'">पद</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">पद</span>
                            </label>
                            <input type="text" class="form-control" id="position" formControlName ="position">
                            <div class="text-danger"
                            *ngIf="myForm.controls.position.touched && myForm.controls.position.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6" *ngIf="myForm.controls.isWorking.value == 'Y'">
                            <label>
                                <span *ngIf="languageSelected == 'English'">In an average, how much do you earn in one month from your work? (In Nepali Rupees)</span>
                                <span *ngIf="languageSelected == 'Nepali'">तपाईले आफ्नो कामबाट एक महिनामा औषत रुपमा कति रकम कमाउनु हुन्छ ? (नेपाली रुपैयामा)</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">रौवा अपना एक महिनाके कमाई से औषत केतना कमाएला ?</span>
                            </label><br />
                            <select class="form-control" formControlName="salary" id="salary" name="salary">
                                <option value="Less than 15 thousand">
                                    <span *ngIf="languageSelected == 'English'">Less than 15 thousand</span>
                                    <span *ngIf="languageSelected == 'Nepali'">१५ हजार भन्दा कम </span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">१५ हजार से कम </span>
                                </option>

                                <option value="15 thousand - 30 thousand">
                                    <span *ngIf="languageSelected == 'English'">15 thousand - 30 thousand</span>
                                    <span *ngIf="languageSelected == 'Nepali'">१५ से ३० हजार </span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">पन्द्रह हजार से तीस हजार तक के बा</span>
                                </option>

                                <option value="More than 30 thousand">
                                    <span *ngIf="languageSelected == 'English'">More than 30 thousand</span>
                                    <span *ngIf="languageSelected == 'Nepali'">३० हजार भन्दा माथि</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">३० हजार से ज्यादा</span>
                                </option>
                            </select>
                            <div class="text-danger"
                            *ngIf="myForm.controls.salary.touched && myForm.controls.salary.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                        

                        <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'">Citizenship</span>
                            <span *ngIf="languageSelected == 'Nepali'">नागरिकता</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">नागरिकता</span>
                            <sup>*</sup></p>
                        <div class="form-group col-md-6">
                            <label for="Citizeshipnumber">
                                <span *ngIf="languageSelected == 'English'">Citizenship Number</span>
                                <span *ngIf="languageSelected == 'Nepali'">नागरिकता नम्बर</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">नागरिकता नम्बर</span>
                                <sup style="color:Red">*</sup>
                             </label>
                            <input type="text" class="form-control" id="Citizeshipnumber" formControlName="ctzNumber">
                            <div class="text-danger"
                            *ngIf="myForm.controls.ctzNumber.touched && myForm.controls.ctzNumber.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-6">
                            <label for="ctzIssuedDistrict">
                                <span *ngIf="languageSelected == 'English'">Select Issued District</span>
                                <span *ngIf="languageSelected == 'Nepali'">नागरिकता जारी गरिएको जिल्लाको नाम</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">नागरिकता जारी भईल जिल्ला के नाम</span>
                             </label>
                            <input type="text" class="form-control" id="ctzIssuedDistrict" formControlName="ctzIssuedDistrict">
                            <div class="text-danger"
                            *ngIf="myForm.controls.ctzIssuedDistrict.touched && myForm.controls.ctzIssuedDistrict.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>
                          <div class="clearfix"></div>
                          <div class="form-group col-md-6">
                              <label for="Issueddate">
                                <span *ngIf="languageSelected == 'English'">Citizenship Issued Date YYYY/MM/DD (B.S)</span>
                                <span *ngIf="languageSelected == 'Nepali'">नागरिकता जारी गरिएको मिति (YYYY/MM/DD)</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">नागरिकता जारी भईल साल (YYYY/MM/DD)</span>
                              </label>
                              <input type="text" class="form-control" id="Issueddate" formControlName="ctzIssuedDate">
                              <div class="text-danger"
                            *ngIf="myForm.controls.ctzIssuedDate.touched && myForm.controls.ctzIssuedDate.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                          </div>

                          <div class="form-group col-md-6">
                            <label for="citizenshipPhoto">
                                <span *ngIf="languageSelected == 'English'">
                                    Please upload both sides of your citizenship and upload them at once
                                </span>
                                <span *ngIf="languageSelected == 'Nepali'">
                                    कृपया आफ्नो नागरिकता अपलोड गर्दा दुवै तर्फको एउटै फाईल बनाई एकैपल्ट अपलोड गर्नुहोला।
                                </span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">कृपया अपने के नागरिकता के दुनु तरफके एकहि फाईल बनाके अपलोड करी।</span>
                            </label>
                            <input type="file" id="citizenshipPhoto" class="form-control" #file name="myFile"
                            (click)="file.value = null" value="" (change)="uploadPhoto(file.files)"
                           >
                           
                          <p *ngIf="errorMsg" style="color: red; margin-top: 10px;">{{errorMsg}}</p>
                          </div>

                          <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'">Training Time Commitment</span>
                            <span *ngIf="languageSelected == 'Nepali'">तालिम सम्बन्धी समयको सुनिश्चता</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">ट्रेनिङ्ग के समय के सम्बन्धी</span>
                            <sup>*</sup></p>
                          <div class="form-group col-md-12">
                            <label>
                            <span *ngIf="languageSelected == 'English'">This Training will be conducted for seven days in Hipco Infra factory at Birgunj Metropolitan City Ward no 31, Belwa.
                                I ensure and commit to giving full seven days of my time for it.</span>
                            <span *ngIf="languageSelected == 'Nepali'">यो तालिम सात दिनसम्म बिरगंज महानगरपालिका वडा नं ३१ को बेलवा स्थित हिप्को ईन्फ्राको कारखानामा हुनेछ, त्यसको लागि म सात दिनको पूर्ण समय दिन</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">ई तालिम सात दिन तक बिरगंज महानगरपालिका के वार्ड नं ३१ के बेलवा मे रहल हिप्को करखाना मे होई । वकरा खातिर हम सात दिन पुरा समय</span>

                            </label><br />

                            <label class="radio-inline"><input type="radio" formControlName="commitment" value="Y" name="commitment" checked>
                                <span *ngIf="languageSelected == 'English'">Yes</span>
                                <span *ngIf="languageSelected == 'Nepali'">सक्छु</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">देवे सकेम</span>
                            </label>
                            <label class="radio-inline"><input type="radio" formControlName="commitment"  value ="N" name="commitment">
                                <span *ngIf="languageSelected == 'English'">No</span>
                                <span *ngIf="languageSelected == 'Nepali'">सक्दिन</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">ना देवे सकेम</span>
                            </label>
                          </div>
                 

                          <p class="form-title col-md-12">
                            <span *ngIf="languageSelected == 'English'">Emergency Related Information</span>
                            <span *ngIf="languageSelected == 'Nepali'">आपत्कालीनसंग सम्बन्धित जानकारी</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">समय पर्लापर सम्बन्धी के जानकारी</span>
                            <sup>*</sup></p>

                          <div class="form-group col-md-12">
                            <label>
                            <span *ngIf="languageSelected == 'English'">Do you have any kind of disease ?</span>
                            <span *ngIf="languageSelected == 'Nepali'">के तपाईलाई कुनै प्रकारको दिर्घ रोग ?</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">का अपने के कौनो किसिम के घातक बेमारी</span>
                            </label><br />
                            <label class="radio-inline"><input type="radio" formControlName="ifDisease" value="Y" (change)="SelectChange($event)" name="ifDisease" checked>
                                <span *ngIf="languageSelected == 'English'">Yes</span>
                                <span *ngIf="languageSelected == 'Nepali'">छ</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">बा</span>
                            </label>
                            <label class="radio-inline"><input type="radio" formControlName="ifDisease" value ="N" (change)="SelectChange($event)"  name="ifDisease">
                                <span *ngIf="languageSelected == 'English'">No</span>
                                <span *ngIf="languageSelected == 'Nepali'">छैन</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">नईख</span>
                            </label>
                          </div>

                          <div class="form-group col-md-12" *ngIf="myForm.controls.ifDisease.value == 'Y'">
                            <ng-container *ngFor="let data of diseaseCheckbox">
                                <input type="checkbox" id="{{data.english}}" (change)="checkboxSelect($event)" name="{{data.english}}" value="{{data.english}}" >
                                <label for="{{data.english}}" style="margin-left:5px;">
                                    <span *ngIf="languageSelected == 'English'">{{data.english}}</span>
                                    <span *ngIf="languageSelected == 'Nepali'">{{data.nepali}}</span>
                                    <span *ngIf="languageSelected == 'Bhojpuri'">{{data.bhojpuri}}</span>
                                </label><br>
                            </ng-container>
                            
                          </div>

                          <div class="form-group col-md-12" *ngIf="myForm.controls.ifDisease.value == 'Y' && checkOthersData()">
                            <label for="disease">
                                <span *ngIf="languageSelected == 'English'">Mention Name of disease</span>
                                <span *ngIf="languageSelected == 'Nepali'"> यदि छ भने, वर्णन गर्नुहोस्</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">अगर बा त बताई</span>
                            </label>
                            <input type="text" class="form-control" id="disease"  formControlName="diseaseOther">

                            <div class="text-danger"
                            *ngIf="myForm.controls.disease.touched && myForm.controls.disease.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="form-group col-md-12">
                            <label>
                                <span *ngIf="languageSelected == 'English'">Do you take any form of medication ?</span>
                                <span *ngIf="languageSelected == 'Nepali'"> के तपाईले कुनै प्रकारको औषधि सेवन गर्नु हुन्छ ?</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">का अपने कौनो किसिम के दवाई खानि ?</span>

                            </label><br />
                            <label class="radio-inline"><input type="radio" formControlName="ifMedication" value="Y" (change)="SelectChange($event)" name="ifMedication" checked>
                                <span *ngIf="languageSelected == 'English'">Yes</span>
                                <span *ngIf="languageSelected == 'Nepali'">गर्छु</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">खानि</span>
                            </label>
                            <label class="radio-inline"><input type="radio" formControlName="ifMedication" value="N" (change)="SelectChange($event)" name="ifMedication">
                                <span *ngIf="languageSelected == 'English'">No</span>
                                <span *ngIf="languageSelected == 'Nepali'"> गर्दिन</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">ना खानि</span>
                            </label>
                            
                          </div>

                        <div class="form-group col-md-12" *ngIf="myForm.controls.ifMedication.value == 'Y'">
                            <label for="medicineDetail">
                                <span *ngIf="languageSelected == 'English'">Please provide details of the medicine that you are having</span>
                                <span *ngIf="languageSelected == 'Nepali'">  यदि गर्नुहुन्छ भने, औषधिको विवरण लेख्नुहोस्</span>
                                <span *ngIf="languageSelected == 'Bhojpuri'">अगर खानि त, उ दवाई के बारेमे लिखि</span>
                            </label>
                            <textarea class="form-control" formControlName="medication" rows="5" id="medicineDetail"></textarea>
                            <div class="text-danger"
                            *ngIf="myForm.controls.medication.touched && myForm.controls.medication.errors?.required">
                            <span *ngIf="languageSelected == 'English'">{{englishReq}}</span>
                            <span *ngIf="languageSelected == 'Nepali'">{{nepaliReq}}</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">{{bhojpuriReq}}</span>
                            </div>
                        </div>

                        <div class="col-md-12">

                            <div style="display:flex;">
                                <input type="checkbox" id="accept" style="margin-top: 3px;" (change)="checkboxAccept()" name="accept" >
                                <label for="accept" style="margin-left:5px;">
                               
                                        <span *ngIf="languageSelected == 'English'">   I <span>{{myForm.controls.firstName.value}}</span> express my commitment to
                                        provide full time for seven days in this training. I shall not conduct any forms of political activities, 
                                        revolutions, form unions and banda that may hamper the project and company’s activities. I also 
                                        ensure not to consume any form of drug or alcohol throughout the training period. If I conduct any 
                                        activities that are against the rules and regulations of the organizers and Hipco Infra Pvt. Ltd , then 
                                        I understand and I shall bear all the consequences as per the law given by the Government of Nepal
                                        (GoN)
                                    </span>
            
        
                                        <span *ngIf="languageSelected == 'Nepali'"> म <span>{{myForm.controls.firstName.value}}</span> यस सात दिनको तालिम अवधि भरि पूर्ण रुपमा समय दिन
                                    सक्छु। तालिम अवधि भरि कुनै प्रकारको राजनितिक गतिविधि, नारा, जुलुस, हड्ताल गर्ने छैन । तालिम
                                    अवधि भरि लागु पदार्थ र मादक पदार्थ सेवन गर्ने छैन । मेरो गतिविधि र चालचलन आयोजकले निर्धारण
                                    गरेको नियम, कानुन बिपरित रहेमा नेपालको कानुन बमोजिम गरिने कारवाहीमा भागिदार हुने विषयमा
                                    जानकार रहेको र सहमत रहेको जानकारी गराउँदछु।
                                </span>
         

                                        <span *ngIf="languageSelected == 'Bhojpuri'">
                                            हम <span>{{myForm.controls.firstName.value}}</span> ई सात दिन के ट्रेनिङ्ग भर पुरा रुपसे समय देवे सकेम ।
                                            ट्रेनिङ्ग समय भर कौनो किसिम के राजनितिक काम, नारा, जुलुस, हड़त्ताल ना करेम । ट्रेनिंङ्ग के समय
                                            भर कौनो किसिम के नसा ना करेम, दारु ना पिएम्। हमर चालचलन समिति औरी कानुन के उल्टा
                                            भईलापर नेपाल के कानुनी नियम अनुसार कारवाही के भागिदार रहेम्औरी सहमति भी बानि जानकारी
                                            करावत बानि ।
                                        </span>
   
                                </label>
                            </div>
                        <p>
                            <span *ngIf="languageSelected == 'English'">For more information contact @ 9702016381</span>
                            <span *ngIf="languageSelected == 'Nepali'">थप जानकारीको लागि सम्पर्क न ः ९७०२०१६३८१</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">औरी जानकारी खातिर सम्पर्क न ः ९७०२०१६३८१ </span>
    
                        </p>
                        <p>
                            <span *ngIf="languageSelected == 'English'">Thank you!</span>
                            <span *ngIf="languageSelected == 'Nepali'">धन्यवाद ।</span>
                            <span *ngIf="languageSelected == 'Bhojpuri'">धन्यवाद ।</span>
                        </p>
                        </div>
                       

                    <button class="btn custom-danger">
                        <span >Save </span>
                    </button>

                    

                    </form>
                </div>
                <div class="col-md-1"></div>
            </div>
        </div>
    
</div>

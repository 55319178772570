import { Component, TemplateRef, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { EmployerApi } from 'src/app/common/services/employerApi.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements AfterViewInit {

  @Input() email: any;
  modalRef: BsModalRef;
  @ViewChild('template') modalTemplate: TemplateRef<any>;
  emailStep:any="first";

  constructor(private modalService: BsModalService, 
    public rest: RestApiService, 
    private apiServiceService: ApiServiceService, 
    private empService: EmployerApi, 
    private toastr:ToastrService,
    private cookie:CookieService
  ) { }


  ngAfterViewInit() {
    let userInfo = this.rest.getCookieStorage();
    this.email = userInfo.tmpE;
    this.openModal(this.modalTemplate);
    // this.resendCode();
  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  closeModal() {
    this.modalRef.hide();
    this.rest.emailVerification = false;
  }

  proceedForVerification(){
    this.emailStep="second";
    this.resendCode();
    this.timer();
  }


  // TIMER
  min: number;
  sec: number;
  disableResend: boolean = false;
  timer() {

    this.min = 1;
    this.sec = 0;
    this.disableResend = true;
    document.getElementById('timers').style.visibility = 'visible';

    document.getElementById('timers').innerHTML = this.min + ":" + this.sec;
    this.startTimer();
  }

  m: any;
  s: any;
  timeArray: any = [];
  startTimer() {

    if (document.getElementById('timers').innerHTML) {
      var presentTime = document.getElementById('timers').innerHTML;
      this.timeArray = presentTime.split(/[:]+/);
      this.m = this.timeArray[0];
      this.s = this.checkSecond((this.timeArray[1] - 1));
      if (this.s == 59) { this.m = this.m - 1 }
      document.getElementById('timers').innerHTML =
        this.m + ":" + this.s;

      if (this.m == 0 && this.s == 0) {
        this.disableResend = false;
        document.getElementById('timers').style.visibility = 'hidden';
      }
      else {
        setTimeout(() => {
          this.startTimer();
        }, 1000)
      }
    }
  }

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) { sec = "0" + sec };
    if (sec < 0) { sec = "59" };
    return sec;
  }

  resendCode() {
    // this.timer();

    this.empService.registerCodeResend(this.email).subscribe(
      (res: any) => {

        // this.toastr.success('', 'Verificaiton link has been sent to you email. Please verify your email.');

      },
      (err: any) => {
        this.closeModal();
        this.toastr.error('Unsuccessful', 'Verification link cannot be sent to your email. Please try again later. ', {
          timeOut: 3000
        });
      }
    )
  }

  // checkEmailVerification() {
  //   if (this.cookie.get('emailVerified') == 'Y')
  //     this.router.navigate(['/employer/login'])
  // }

}

import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class Uploadfile {


    // ========== File Upload =========================

    /*return fileStatus
    1 = success;
    2 = file size error;
    3 file extension error*/

    fileUpload(filevalue: any, fileExt: string[], filesize: number) {
        if (filevalue.length == 1) {
            if (filevalue[0].size < filesize) {
                let filename = filevalue[0].name;
                let getFileExtension = filename.slice((Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1);
                let checkExtension = fileExt.indexOf(getFileExtension);
                if (checkExtension != -1) {
                    let uploadFile = filevalue[0];
                    let formData: FormData = new FormData();
                    formData.append('file', uploadFile);
                    let Obj = {
                        fileStatus: 1,
                        sendFile: formData
                    }
                    return Obj;
                } else {
                    let Obj = { fileStatus: 3, sendFile: null }
                    return Obj;
                }
            } else {
                let Obj = { fileStatus: 2, sendFile: null }
                return Obj;
            }
        }
    }

    // Obj = {
    //     docStatus|:'',
    //     docUrl:''
    // }
    downloadFile(Obj) {
        if (Obj.docStatus == 'SUCCESS') {
            const link = document.createElement('a');
            link.target = '_blank';
            link.href = Obj.docUrl;
            link.download = Obj.docUrl;
            link.setAttribute('visibility', 'hidden');
            link.click();
        }
    }


    formatDate(value) {
        let dateTime = new Date(value);
        let date = moment(dateTime).format("YYYY-MM-DD");
        return date
    }

    monthYear(value) {
        let dateTime = new Date(value);
        let date = moment(value).format("YYYY/MM");
        if (date)
            return date
        else
            return value
    }
}
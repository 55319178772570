<div class="container-fluid container-fixed-top">
  <app-search-job></app-search-job>
</div>

<div class="container">

  <!-- TOP HEADER BOX -->
  <div class="col-lg-12 col-md-12 wt-box btn-no-margin">
    <span class="orange-header-20">Jobejee Features</span>
  </div>


  <div class="row">
    <!-- MENU SECTION -->
    <div class="col-md-3 col-lg-3" style="position: relative">
      <div class="col-md-12 menu-box nopadding" id="alx">
        <a (click)="sectionSelectTop('intelligent')" class="link-box ">Intelligent Search Algorithm</a>
        <a (click)="sectionSelect('bookmarked')" class="link-box ">Bookmarked Jobs</a>
        <a (click)="sectionSelect('jobalert')" class="link-box ">Job Alerts</a>
        <a (click)="sectionSelect('jobRecommendations')" class="link-box ">Job Recommendations</a>
        <a (click)="sectionSelect('ApplicationTracking')" class="link-box ">Application Tracking</a>
        <a (click)="sectionSelect('ApplicationRanking')" class="link-box ">Applicant Ranking</a>
      </div>
    </div>

    <!--- Content Box -->
    <div class="col-md-9 col-lg-9">

      <!-- Intelligent Search Algorithm  -->
      <div class="col-md-12 wt-box " id="intelligent">
        <p class="main-heading">Intelligent Search Algorithm :</p>
        <p class="justify">Are you tired of scouring through thousands of jobs in all newspapers and websites? Jobejee
          lets you search for the most suitable job for you with its Intelligent Search Algorithm. Now, you can search
          jobs based on your skills, desired industry, designation and even location!
        </p>
        <p>This is how you can search for jobs at Jobejee:</p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Do you know what kind of job you want? Which industry or designation
          do you want work in? What are your key skills? Simply type them into our search bar!
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>Sort through your search results from with our ‘Refine Search’.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 3 : </span>Zero-in to the jobs most suitable to you!
        </p>


        <!-- VIDEO SECTION -->

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/9ClQ1SIPPt8?rel=0&controls=0" frameborder="0"></iframe>
        </div>

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/NydSf52vUJs" frameborder="0" frameborder="0"></iframe>
        </div>
      </div>


      <!--- Bookmarked Jobs -->
      <div class="col-md-12 wt-box" id="bookmarked">
        <p class="main-heading">Bookmarked Jobs :</p>
        <p class="justify">Saving and organizing the jobs you want to apply is easy at Jobejee. Simply Bookmark them!
          How you can bookmark jobs at Jobejee:
        </p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Go to www.jobejee.com and find the job that you want to apply for.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>You will find a bar below the ‘Apply Job’ button. Find the icon for
          ‘Bookmarked Jobs’: the second one from your right hand side. Click on it. The icon should turn orange. This
          means that you have bookmarked the job successfully.Now you will be able to :
        </p>
        <p>Find all the jobs you want to apply to a single space.</p>
        <p>Keep track of job applications you need to complete.</p>
        <p>Get notified when your bookmarked jobs are about to expire.</p>
        <p>Find all the jobs you want to apply to a single space.</p>
        <p>So you don’t miss out on opportunities!.</p>


        <!-- VIDEO SECTION -->

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/5_Uxexi2Kqk" frameborder="0"></iframe>
        </div>
      </div>


      <!-- Job Alerts -->
      <div class="col-md-12 wt-box" id="jobalert">
        <p class="main-heading">Job Alerts :</p>
        <p class="justify">Can’t find the perfect for you yet? Leave it to Jobejee! Tell us your criteria for your
          perfect
          job with our customizable Job Alerts and we will notify you when they get posted! How to create a Job Alert?
        </p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Register and login to your jobseeker account at Jobejee.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>Go to ‘Jobs For You’ section on the navigation bar on your left hand
          side
        </p>

        <p class="justify">
          <span class="sub-heading">Step 3 : </span>Click on ‘Create a Job Alert’ on the right corner.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 4 : </span>A pop-up box should appear. Enter all or some of the information in
          the fields.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 5 : </span>Save your new Job Alert.
        </p>

        <p>Now, you will be the first one to know about the job vacancy that you want to apply to.</p>

        <!-- VIDEO SECTION -->

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/R7yp7Zriuhg" frameborder="0"></iframe>
        </div>

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/04RB-2DYRRE" frameborder="0" frameborder="0"></iframe>
        </div>
      </div>

      <!-- Job Recommendations -->
      <div class="col-md-12 wt-box" id="jobRecommendations">
        <p class="main-heading">Job Recommendations :</p>
        <p class="justify">No need to look through numerous newspapers and job portals and search among thousands of
          jobs. Let us send you jobs that you are qualified for and interested in! To ensure that you get recommended
          jobs that you want to apply to, follow the steps below:
        </p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Verify your email address. A verified email address means that you
          have a valid email address. Jobejee can send job recommendations to emails that are verified.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>Complete your Jobejee profile as much as possible. If you let us
          know what you are interested in and are qualified for, we can find relevant jobs and send them to you.
        </p>

        <p>Now, you will be the first one to know about the job vacancy that you want to apply to.</p>

        <!-- VIDEO SECTION -->

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/a7hkrU51vM4" frameborder="0"></iframe>
        </div>

        <div class="video-box">
          <iframe src="https://www.youtube.com/embed/-ozu4OxndU4" frameborder="0" frameborder="0"></iframe>
        </div>
      </div>


      <!-- Application Tracking -->
      <div class="col-md-12 wt-box" id="ApplicationTracking">
        <p class="main-heading">Application Tracking :</p>
        <p class="justify">After applying for a job, most applicants are left in the dark about the status of their
          applications. They do not know if it has been rejected or if they are still under consideration.
        </p>
        <p>Not at Jobejee!</p>

        <p class="justify">At Jobejee we want applicants to have an easy time not only searching applying for jobs but
          also have an easy post application period. At Jobejee, you can track your application and know at any given
          time what stage your application is at. Here is how you can do it :</p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Apply for a job at <a href="https://www.jobejee.com" target="_blank"
            class="blue-link">www.jobejee.com</a>
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>Go to your Jobejee dashboard.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 3 : </span>Click on ‘Jobs Applied’. It should be on the top right area of your
          screen.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 4 : </span>Check the top right corner of the job you have applied to. It should
          state ‘In Progress’, ‘Called for Interview’ or other things according to your status.
        </p>

        <p class="justify">
          You will also receive an email at your Jobejee email as well as your verified email address!
        </p>
      </div>

      <!-- Applicant Ranking -->
      <div class="col-md-12 wt-box" id="ApplicationRanking">
        <p class="main-heading">Applicant Ranking :</p>
        <p class="justify">With Jobejee, you will have an advantage of knowing where you rank among other applicants.
          With this knowledge, you know where you need to improve and interpret the probability of you getting a job.
          Here is how you can do it:
        </p>

        <p class="justify">
          <span class="sub-heading">Step 1 : </span>Apply for a job at <a href="https://www.jobejee.com" target="_blank"
            class="blue-link">www.jobejee.com</a>
        </p>

        <p class="justify">
          <span class="sub-heading">Step 2 : </span>Go to your Jobejee dashboard.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 3 : </span>There should be a section titled ‘Applicant Ranking’.
        </p>

        <p class="justify">
          <span class="sub-heading">Step 4 : </span>Check this section to understand where you are ranking among other
          applicants based on: your education, your work experience, expected salary, key skills and job location.
        </p>
      </div>

    </div>
  </div>
</div>

<div class="col-md-12 grey-row nopadding">

    <div class="col-md-6 col-xs-6 col-sm-6">
        <!-- <span style="white-space: pre;">Showing
            {{items}}
            of {{totalElements}}</span> -->

    </div>
    <div class="col-md-6 text-right nopadding">
        <div class="btn-group" dropdown placement="buttom-right">
            <span dropdownToggle class="action fw500 cursor" aria-controls="dropdown-disabled-menu">
                View {{items}} per page <span class="caret"></span>
            </span>

            <ul id="dropdown-disabled-menu" *dropdownMenu class="dropdown-menu dropdown-menu-right zindex" role="menu"
                aria-labelledby="button-disabled-menu">
                <ng-container *ngFor="let item of numberOfItems">
                    <li role="menuitem " class="items" (click)="sizeChanged(item)" *ngIf="item != items">
                        View {{item}} per page
                    </li>
                </ng-container>


            </ul>
        </div>
    </div>
</div>
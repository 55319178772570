import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.addEventListener('scroll', this.scrollEvent, true);
  }


  sectionSelect(id) {
    let elmnt = document.getElementById(id);
    elmnt.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  sectionSelectTop(id) {
    let elmnt = document.getElementById(id);
    elmnt.scrollIntoView({ behavior: "smooth", block: "start" });
  }


  scrollEvent = (event: any): void => {
    const number = event.srcElement.scrollTop;
    let postion = event.path[1].pageYOffset;
    console.log(postion);

    if (postion >= 300) {
      let element = document.getElementById("alx");
      if (element) {
        element.classList.add("fixed-header");
      }
    } else if (postion <= 300) {
      let element = document.getElementById("alx");
      if (element) {
        element.classList.remove("fixed-header");

      }
    }
  }


}

import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { RestApiService } from "../../common/services/rest-api.service";
import { ApiServiceService } from "../../common/services/api-service.service";
import {
  TransferState,
  makeStateKey,
  Meta,
  Title
} from "@angular/platform-browser";
import {
  BsModalService,
  BsModalRef,
  ModalDirective
} from "ngx-bootstrap/modal";
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

const jobCompany = makeStateKey("jobsObj");
const jobInd = makeStateKey("jobsIndObj");
const jobLoc = makeStateKey("jobsLocObj");
// const jobInd = makeStateKey('jobsIndObj');

const newJobCompany = makeStateKey("NewsjobsObj");
const newJobInd = makeStateKey("NewsjobsIndObj");
const newJobLoc = makeStateKey("NewsjobsLocObj");

const adsData = makeStateKey("adsObj");
// const footerJobStore = makeStateKey("footerJobObj");
const featureCompany = makeStateKey("featureComanyObj");

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  modalRef: BsModalRef;
  constructor(
    private restApiService: RestApiService,
    private modalService: BsModalService,
    private apiServiceService: ApiServiceService,
    private state: TransferState,
    public meta: Meta,
    public title: Title,
    private cookie: CookieService,
    private router: Router
  ) { }

  activeTab = "Jobs By Company";
  newsPaperactiveTab = "Jobs By Company";
  items = [1, 2, 3];
  longItems = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]

  defaultImage =
    "https://jobejee.s3-ap-southeast-1.amazonaws.com/homeDefaultImg/default-comp.jpeg";

  defaultNewspaper = "https://jobejee.s3-ap-southeast-1.amazonaws.com/homeDefaultImg/newspaperjobs.png";
  // defaultImage = '../../../assets/images/default-comp.jpeg';
  ngOnInit() {
    this.callthis();

    this.title.setTitle("Jobs in Nepal | Jobejee");
    this.meta.updateTag({
      name: "keywords",
      content:
        "jobs in Nepal, job vacancy in Nepal, employment, recent job in nepal, IT jobs in Nepal, job opportunities in Nepal"
    });
    this.meta.updateTag({
      name: "description",
      content:
        "Jobejee is the fastest growing job portal in Nepal. Find your career opportunities here! Search among thousands of jobs from top companies, industries and locations of your choice!"
    });

    // <!-- Open Graph data -->
    this.meta.updateTag({
      property: "og:title",
      content: "Jobs in Nepal | Jobejee"
    });
    this.meta.updateTag({ property: "og:site_name", content: "jobejee" });
    this.meta.updateTag({ property: "og:type", content: "website" });
    this.meta.updateTag({ property: "fb:app_id", content: "2458134394205611" });
    this.meta.updateTag({
      property: "og:url",
      content: "https://www.jobejee.com"
    });
    this.meta.updateTag({
      property: "og:description",
      content:
        "Jobejee is the fastest growing job portal in Nepal. Find your career opportunities here! Search among thousands of jobs from top companies, industries and locations of your choice!"
    });
  }

  @ViewChild("autoShownModal", { static: false })
  autoShownModal: ModalDirective;
  isModalShown = false;

  showModal(): void {
    this.isModalShown = true;
  }

  hideModal(): void {
    this.autoShownModal.hide();
  }

  onHidden(): void {
    this.isModalShown = false;
  }

  jobsObj = [];
  jobsIndObj = [];
  jobsLocObj = [];

  NewsjobsObj = [];
  NewsjobsIndObj = [];
  NewsjobsLocObj = [];

  adsObj = [];
  footerJobObj = null;
  featureComanyObj = [];

  callthis() {

    this.getAdList();
    this.getTestimonials();


    this.jobsObj = this.state.get(jobCompany, []);
    if (this.jobsObj.length == 0) {
      this.getJobsByCompany();
    } else {
      this.state.set(jobCompany, this.jobsObj);
    }

    this.jobsIndObj = this.state.get(jobInd, []);
    if (this.jobsIndObj.length == 0) {
      this.getJobsByIndustry();
    } else {
      this.state.set(jobInd, this.jobsIndObj);
    }

    this.jobsLocObj = this.state.get(jobLoc, []);
    if (this.jobsLocObj.length == 0) {
      this.getJobsByLocation();
    } else {
      this.state.set(jobLoc, this.jobsLocObj);
    }

    this.NewsjobsObj = this.state.get(newJobCompany, []);
    if (this.NewsjobsObj.length == 0) {
      this.getNewsJobsByCompany();
    } else {
      this.state.set(newJobCompany, this.NewsjobsObj);
    }

    this.NewsjobsIndObj = this.state.get(newJobInd, []);
    if (this.NewsjobsIndObj.length == 0) {
      this.NewsgetJobsByIndustry();
    } else {
      this.state.set(newJobInd, this.NewsjobsIndObj);
    }

    this.NewsjobsLocObj = this.state.get(newJobLoc, []);
    if (this.NewsjobsLocObj.length == 0) {
      this.NewsgetJobsByLocation();
    } else {
      this.state.set(newJobLoc, this.NewsjobsLocObj);
    }

    // this.getAdList();

    // this.adsObj = this.state.get(adsData, []);
    // if (this.adsObj.length == 0) {
    //   this.getadsHome();
    // } else {
    //   this.state.set(adsData, this.adsObj);
    // }

    // this.featureComanyObj = this.state.get(featureCompany, []);
    // if (this.featureComanyObj.length == 0) {
    //   this.getFeatureCompany();
    // } else {
    //   this.state.set(featureCompany, this.featureComanyObj);
    // }

    // this.footerJobObj = this.state.get(footerJobStore, null);
    // if (!this.footerJobObj) {
    //   this.getfooterJob();
    // } else {
    //   this.state.set(footerJobStore, this.footerJobObj);
    // }
  }

  getJobsByCompany() {
    if (this.jobsObj.length == 0) {
      this.apiServiceService.JobsByCompany().subscribe((response: any) => {
        this.jobsObj = response;
        this.state.set(jobCompany, response);
      });
    }
  }

  getJobsByIndustry() {
    if (this.jobsIndObj.length == 0) {
      this.apiServiceService.JobsByIndustry().subscribe((response: any) => {
        this.jobsIndObj = response;
        this.state.set(jobInd, response);
      });
    }
  }

  getJobsByLocation() {
    if (this.jobsLocObj.length == 0) {
      this.apiServiceService.JobsByLocation().subscribe((response: any) => {
        this.jobsLocObj = response;
        this.state.set(jobLoc, response);
      });
    }
  }

  getNewsJobsByCompany() {
    if (this.NewsjobsObj.length == 0) {
      this.apiServiceService.NewsJobsByCompany().subscribe((response: any) => {
        this.NewsjobsObj = response;
        this.state.set(newJobCompany, response);
      });
    }
  }
  testimonial:any=[];
  getTestimonials() {
    if (this.testimonial.length == 0) {
      this.apiServiceService.getTestimonials().subscribe((response: any) => {
        this.testimonial = response;
      });
    }
  }

  NewsgetJobsByIndustry() {
    if (this.NewsjobsIndObj.length == 0) {
      this.apiServiceService.NewsJobsByIndustry().subscribe((response: any) => {
        this.NewsjobsIndObj = response;
        this.state.set(newJobInd, response);
      });
    }
  }

  NewsgetJobsByLocation() {
    if (this.NewsjobsLocObj.length == 0) {
      this.apiServiceService.NewsJobsByLocation().subscribe((response: any) => {
        this.NewsjobsLocObj = response;
        this.state.set(newJobLoc, response);
      });
    }
  }

  // getFeatureCompany() {
  //   if (this.NewsjobsObj.length == 0) {
  //     this.apiServiceService.featureCompany().subscribe((response: any) => {
  //       if (response) {
  //         this.featureComanyObj = response;
  //         this.state.set(featureCompany, response);
  //       }
  //     });
  //   }
  // }

  fullBanner = null;

  // getadsHome() {
  //   let Obj = {
  //     adType: "H"
  //   };
  //   this.apiServiceService.getAds(Obj).subscribe((response: any) => {
  //     this.adsObj = response;
  //     for (let data of this.adsObj) {
  //       if (data.adFlag == "FB") {
  //         this.fullBanner = data;
  //       }
  //     }
  //     if (this.fullBanner) {
  //       event.preventDefault();
  //       let element: HTMLElement = document.getElementById(
  //         "bannerAds"
  //       ) as HTMLElement;

  //       element.click();
  //     }
  //     this.state.set(adsData, response);
  //   });
  // }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  // updateImpression(id) {
  //   let Obj = {
  //     adContentId: id
  //   };
  //   this.apiServiceService.impression(Obj).subscribe((response: any) => { });
  // }

  // featureCompany(id) {
  //   let Obj = {
  //     "userDetailId": "d6944f6bd9dbb821fca557df93ee516db87c4a84"
  //   }
  //   this.apiServiceService.featureCompany(Obj).subscribe(
  //     (response: any) => {
  //       console.log(response);
  //     }
  //   )
  // }

  // https://api.v1.jobejee.com/rest/mob/jsInfo/homePage

  // getfooterJob() {
  //   this.apiServiceService.footerJob().subscribe((response: any) => {
  //     this.footerJobObj = response;
  //     this.state.set(footerJobStore, response);
  //   });
  // }

  splitJob(value, i) {

    if (value) {
      let splitData = value.split("|");
      if (i == 0) {
        return splitData[0];
      } else if (i == 1) {
        return splitData[1];
      } else if (i == 2) {
        return splitData[2];
      }
    }

  }

  splitcompany(value, i) {

    if (value) {
      let splitData = value.split("(");
      if (i == 0) {
        let str = splitData[0].replace(/\s+/g, "-");
        return str;
      } else if (i == 1) {
        return splitData[1];
      }
    }

  }

  replaceSpace(value) {

    if (value) {
      let str = value.replace(/\s+/g, "-");
      return str;
    }

  }

  //   $scope.showJob = function (res, i, type) {
  //     if (res) {
  //         var resArray = res.split("|");
  //         if (i == 0) {
  //             return resArray[0]; //Returns Job title
  //         } else if (i == 1) {
  //             if (type == 'thirdId') {
  //                 return resArray[2];
  //             } else {
  //                 return resArray[1]; // Returns Job Id
  //             }
  //         }

  //     }
  // };

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }

  searchByCompany(key) {

    var search = key.split("(").map((item: string) => item.trim());;

    this.router.navigate(['/job-search'], {
      queryParams: {
        'q': search[0]
        // 'q': search[0]

      }
    });
  }

  searchByIndustry(id) {
    var search = id.split("(").map((item: string) => item.trim());;

    this.router.navigate(['/job-search'], {
      queryParams: {
        'ind': search[0], 'f': 'ind'
        // 'ind': id,'f':'ind'

      }
    });
  }

  searchByLocation(location) {
    var search = location.split("(").map((item: string) => item.trim());;

    this.router.navigate(['/job-search'], {
      queryParams: {
        'l': search[0], 'f': 'ind'
        // 'l': search[0],'f':'ind'

      }
    });
  }

  popUpAds: any = null;
  halfBannerList: any = [];
  fullBannerList: any = [];
  getAdList() {




    this.popUpAds = null;
    this.halfBannerList = [];
    this.fullBannerList = [];

    this.apiServiceService.getNewAds().subscribe(
      (res: any) => {
        this.adsObj = res.content;
        var sortOrder = ['TO', 'TO1', 'TT', 'TT1', 'TO2', 'TO3', 'TT2', 'TT3', 'TT4'];

        this.adsObj.sort(function (a, b) {

          console.log(a)
          return sortOrder.indexOf(a.adFlag) - sortOrder.indexOf(b.adFlag);
        });


        for (let item of this.adsObj) {
          if (item.adFlag == 'HB') {
            this.halfBannerList.push(item)
          }
        }

        for (let item of this.adsObj) {
          if (item.adFlag == 'FB') {
            this.fullBannerList.push(item)
          }
        }

        for (let item of this.adsObj) {
          if (item.adFlag == 'POP' && this.restApiService.adsFlag == true) {
            this.popUpAds = item;
            this.restApiService.adsFlag = false;
            break;
          }
        }

        if (this.popUpAds) {
          this.openModalForPopUp(this.modalTemplate);

        }

      }
    );
  }

  // config = {
  //   backdrop: true,
  //   ignoreBackdropClick: true
  // };

  @ViewChild('popup') modalTemplate: TemplateRef<any>;

  openModalForPopUp(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  updateImpression(id) {
    this.apiServiceService.getCtrForAds(id).subscribe(
      (res: any) => {
        // this.adsObj = res.content;
      }
    );
  }
}

import { ApiServiceService } from './../../common/services/api-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jsforget-password',
  templateUrl: './jsforget-password.component.html',
  styleUrls: ['./jsforget-password.component.scss']
})
export class JsforgetPasswordComponent implements OnInit {

  constructor(
    private ApiServiceService: ApiServiceService
  ) { }

  form: FormGroup;

  ngOnInit() {

    this.form = new FormGroup({
      email: new FormControl(null,  [Validators.required, Validators.pattern('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$')])
    });

  }

  onSubmit() {

    // this.ApiServiceService.sendEmail(this.form.value).subscribe(
    //   (res) => {  

    //   }
    // );
  }
}


import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from '../../common/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private apiServiceService: ApiServiceService,
    private router: Router,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }


  urlId;
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.urlId = params.url;
    });


    if (isPlatformBrowser(this.platformId)) {
      if (this.urlId) {
        this.verifyEmail(this.urlId);
      }
    }

  }


  verifyEmail(id) {
    let Obj = {
      activationCode: id,
    }
    this.apiServiceService.emailVerification(Obj).subscribe(
      (response: any) => {
        this.showSuccess(200, "Email verified Successfully");
        if (response.userType == 'E') {
          this.router.navigate(['https://www.employers.jobejee.com/#!/employer-login'])
        } else if (response.userType == 'JS') {
          this.router.navigate(['/jobseeker/login']);
        }
      },
      (Error: any) => {
        this.toastr.error(Error.error.errorCode, Error.error.errormessage, {
          timeOut: 2000
        });
        this.router.navigate(['/']);
      }
    )
  }

  showSuccess(status, message) {
    this.toastr.success(status, message, {
      timeOut: 2000
    });
  }




}



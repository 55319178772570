<div class="container container-fixed-top">
  <div class="col-md-12 box">
    <div class="row">
      <p class="main_heading">Terms and Conditions</p>

      <!--Top links-->
      <div class="col-md-12 des_box">
        <p class="justify"><a class="green-orange-link noDecoration">1. Introduction to Terms and Condition</a></p>
        <p class="justify"><a class="green-orange-link noDecoration">2. Binding Agreement</a></p>
        <p class="justify"><a class="green-orange-link noDecoration">3. Definitions</a></p>
        <p class="justify"><a class="green-orange-link noDecoration">4. Terms and Conditions applicable for Job
            Seekers</a></p>
        <p class="justify"><a class="green-orange-link noDecoration">5. Terms and Conditions applicable for
            Employers</a></p>
        <p class="justify"><a class="green-orange-link noDecoration">6. Terms and Conditions applicable for all
            Users</a></p>
      </div>


      <!--Introduction to the Terms and Conditions-->
      <div class="col-md-12 des_box">
        <p class="title">1. Introduction to the Terms and Conditions:</p>
        <p class="justify">This page has the terms and conditions (the "Terms and Conditions") governing your access
          to
          and use of Jobejee.com If you do not accept these Terms and Conditions or you do not meet or conform to
          these,
          you may not access and use (https://www.Jobejee.com).</p>
      </div>


      <!--Binding Agreement-->
      <div class="col-md-12 des_box">
        <p class="title">2. Binding Agreement:</p>
        <p class="justify">These Terms and Conditions form a binding agreement (the "Agreement") between you and
          Jobejee.com. When you access and/or use the Jobejee website, you are accepting these Terms and Conditions. You
          are agreeing to use the Jobejee.com at your own risk. This Agreement shall be under the governance of the laws
          of Nepal and by using Jobejee and agreeing to this Agreement, you hereby submit to the exclusive jurisdiction
          of the Nepal courts.</p>
      </div>



      <!-- Definitions-->
      <div class="col-md-12 des_box">
        <p class="title">3. Definitions:</p>
        <p class="justify">The definitions listed below are applicable to the Agreement:</p>
        <!--list-->
        <ol class="list">
          <li>
            <p class="justify">Jobejee Materials" includes all the materials, methodologies,
              implementation plans or other intellectual property used during the provision of Services.</p>
          </li>
          <li>
            <p class="justify">“Content" means Jobejee's web pages, web forms, programming (including software
              code used on the Jobejee websites and in the Services, including</p>
            <ol class="inner_list">
              <li>
                <p class="justify">Tools, kits, and object libraries</p>
              </li>
              <li>
                <p class="justify">All third-party or open source code embedded therein</p>
              </li>
              <li>
                <p class="justify">Any upgrades, updates, releases, fixes, enhancements or modifications to the
                  foregoing), graphics, images, design (color combinations and page layout), text, information, data,
                  resumes stored in various commercial databases operated and licensed by Jobejee (including, and not
                  limited to: its "Applicant Ranking ," "Profile Completeness," and "Salary Benchmark") data submitted
                  via
                  the Jobejee by Users and other content made available through the Jobejee website</p>
              </li>
            </ol>
          </li>
          <li>
            <p class="justify">"Document" refers to anything posted to the Jobejee website by either Job Seeker
              or Employer.</p>
          </li>
          <li>
            <p class="justify">"Employer" means a person or entity that is using the Jobejee website to post a
              job and/or seeking applicants for recruitment.</p>
          </li>
          <li>
            <p class="justify">"Employer Materials" includes any brochures, emails, sample job postings, web site
              content, career fair material, audio, videos, photographs, logos, trademarks, service marks, domain names,
              documents or other materials provided by Employer.</p>
          </li>
          <li>
            <p class="justify">"Job Seeker" means a User who is accessing the Jobejee website to search for a job
              or for any other purposes except as an Employer.</p>
          </li>
          <li>
            <p class="justify">"Services" means any services provided by Jobejee.</p>
          </li>
          <li>
            <p class="justify">"User" refers to any individual or entity that uses any aspect of the Jobejee
              website.</p>
          </li>
        </ol>
      </div>



      <!--Terms and Conditions applicable for Jobseekers-->
      <div class="col-md-12 des_box">
        <p class="title">4.Terms and Conditions applicable for Jobseekers:</p>
        <!--list-->
        <ol class="list">
          <li><span class="sm_title">Rules regarding Site Usage</span>
            <p class="justify">Jobejee is intended for people seeking employment. Jobejee hereby grants you a limited
              and non-exclusive right to access and use the Jobejee website only for your personal use. This authorizes
              you to view and download a single copy of the material on the Jobejee website solely for your personal,
              non-commercial use. You agree that you are solely responsible for the content of any Document you post to
              Jobejee website and Jobejee is not liable for any consequences arising from such posting. Your use of the
              Jobejee website is a privilege. Jobejee reserves the right to suspend or terminate that privilege for any
              reason at any time, in its sole discretion.
            </p>
          </li>



          <li><span class="sm_title"> Other Specific Rules Regarding Site Usage </span>
            <p>You represent, warrant and agree that you </p>

            <ol class="inner_list">
              <li>
                <p class="justify">Are at least 13 years of age or older, and if under the age of 18
                  or the age of majority as that is defined in your jurisdiction, you will only use the Jobejee website
                  and Services under the supervision of a parent, legal guardian, or other responsible adult.</p>
              </li>
              <li>
                <p class="justify"> You will not use (or plan, encourage or help others to use) the Jobejee
                  website for any purpose or in any manner that is prohibited by these Terms and Conditions or by
                  applicable law. It is your responsibility to ensure that your use of the Jobejee website complies with
                  these Terms and Conditions and all applicable laws.
                </p>
              </li>
            </ol>
          </li>


          <li><span class="sm_title"> Damage Limitation</span>
            <p>You represent, warrant and agree that you </p>

            <ol class="inner_list">
              <li>
                <p class="justify">
                  You assume all responsibility and risk for your use of the Jobejee website, the internet generally,
                  and
                  the documents that you post, provide or access and for your conduct on and off the sites.
                </p>
              </li>
              <li>
                <p class="justify"> Jobejee will also not be held responsible for any loss of information occurred
                  due to any reasons.
                </p>
              </li>
            </ol>
          </li>


          <li><span class="sm_title">Disclaimer of all the services and features provided by Jobejee</span>
            <ol class="inner_list">
              <li>
                <p class="justify">
                  Jobejee uses an automated algorithm to generate ‘Job Recommendations’ and does not offer any
                  warranties
                  regarding its relevance to the users.
                </p>
              </li>
              <li>
                <p class="justify">
                  Jobejee uses an automated algorithm to generate ‘Applicant Ranking’ and does
                  not offer any warranties regarding the authenticity and reality of competitions among applicants.
                </p>
              </li>
              <li>
                <p class="justify">
                  Jobejee uses an automated algorithm to generate ‘Salary Benchmark’ and does
                  not offer any warranties regarding the authenticity and reality of maximum and minimum salary earnable
                  in any industry and any post or designations.
                </p>
              </li>
              <li>
                <p class="justify">Jobejee will not be responsible for failure of any back-end technologies of
                  mail exchange server which might result in failure of receiving mails.</p>
              </li>
              <li>
                <p class="justify">If you should require termination of any services that you have subscribed to,
                  please send an email at info@jobejee.com
                </p>
              </li>
            </ol>
          </li>

          <li><span class="sm_title">Permission to create an account</span>
            <p class="justify">
              Jobejee holds the right to create an account for you given that they have received your written or
              verbal approval to do so.
            </p>
          </li>

          <li><span class="sm_title">Terms and Conditions regarding ‘Refer a friend’</span>
            <ol class="inner_list">
              <li>
                <p class="justify"> According to the terms set by Jobejee, Jobejee gives Users
                  awards if they have accumulated certain amount of Points on their User Accounts. Terms of collecting
                  Points or Experience Points and detailed conditions of an award shall be regulated by Jobejee. To be
                  awarded with the Reward the User must fulfil all the terms and conditions.
                </p>
              </li>
              <li>
                <p class="justify">Jobejee reserves the right to delete all Points awarded to the User in the
                  event the User entered manipulated or false information during the process of the creation of User
                  Account or while receiving the Reward.
                </p>
              </li>
              <li>
                <p class="justify">Jobejee reserves the right to terminate to award Points to the Users and
                  reserves the right to cease all possibilities for the Users to gain Points, at any time.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>



      <!--Terms and conditions applicable for Employers-->
      <div class="col-md-12 des_box">
        <p class="title">5.Terms and conditions applicable for Employers:</p>
        <ol class="list">
          <li><span class="sm_title">Rules regarding site usage </span>
            <p class="justified">Jobejee is intended for employers seeking candidates for recruitment. Jobejee hereby
              grants you a limited, terminable, non-exclusive right to access and use the Jobejee only for your internal
              business:
              for the purposes of seeking applicants for recruitment. This authorizes you to view and download a single
              copy of the material on the Jobejee website only for your personal use directly related to searching for
              and recruiting job prospects. Jobejee also grants you a limited, terminable, non-exclusive license to use
              the Jobejee Materials and Services for your internal use only. You may not sell, transfer or assign any of
              the Services or your rights to any of the Services provided by Jobejee to any third party without the
              express written authorization of Jobejee. You agree that you are solely responsible for the content of any
              Document you post to a Site and Jobejee is not liable for any consequences arising from such posting.
              Jobejee reserves the right to suspend or terminate your access and use at any time if Jobejee determines
              that you are in breach of these Terms and Conditions.
            </p>
          </li>
          <li><span class="sm_title">Employer Materials</span>
            <p class="justified">Employer represents, warrants and covenants that any Employer Materials provided by
              Employer for use
              in connection with the Services will not violate any laws or regulations or third-party proprietary
              rights, including, without limitation, copyright, trademark, obscenity, rights of publicity or privacy,
              and defamation laws. Employer hereby grants Jobejee a non-exclusive, worldwide, royalty-free license to
              use the Employer Materials and to hyperlink to Employer's website in connection with the Services.
            </p>
          </li>
          <li><span class="sm_title">Warranty Disclaimer </span>
            <p class="justify">Jobejee makes no representations or guarantees regarding the effectiveness of the
              services or
              timeliness of the services in meeting your employment objectives. Jobejee does not guarantee that the
              services will result in candidates being hired, positions being filled or employees being retained, and is
              not responsible or liable for any business, employment, hiring and/or salary decisions, for whatever
              reason made, made by you.
            </p>
          </li>

          <li><span class="sm_title">Damage Liability </span>

            <ol class="inner_list">
              <li>
                <p class="justify">
                  You assume all responsibility and risk for your use of the Jobejee website,
                  the internet generally, and employer materials that you post, provide or access and for your conduct
                  on
                  and off the sites.
                </p>
              </li>
              <li>
                <p class="justify">Jobejee will also not be held responsible for any loss of information occurred
                  due to any reasons.
                </p>
              </li>
            </ol>
          </li>
          <li><span class="sm_title">Allocation of Liability </span>
            <p class="justify">
              In no event shall jobejee (or any of its officers, directors, shareholders, employees,
              subsidiaries,
              affiliates, agents or advertisers), be liable for any non-direct damages whatsoever (including, without
              limitation, incidental and consequential damages, lost profits, or damages resulting from lost data, lost
              employment opportunity, or business interruption) resulting from or arising under or in connection with
              services or the use or access to, or the inability to use or access, the sites and/or any document,
              whether based on warranty, contract, tort, or any other legal theory, and whether or not joejee is advised
              of the possibility of such damages.
            </p>
          </li>
          <li><span class="sm_title">Equitable Relief </span>
            <p class="justify">Due to the nature of this Agreement, in addition to money damages, you agree that Jobejee
              will be
              entitled to equitable relief upon a breach of this agreement by you.
            </p>
          </li>
          <li><span class="sm_title">Conduct Rules </span>
            <p calss="justify">You may not respond to postings by other Users in any manner or for any purpose other
              than that which
              is expected (i.e., to initiate further discussion with the applicants). Communications soliciting the
              Employer's business are prohibited.</p>
          </li>
          <li><span class="sm_title">Disclaimer of all the services and features provided by
              Jobejee </span>
            <ol class="inner_list">
              <li>
                <p class="justify">The payment made by user is not refundable and the amount paid
                  will stand appropriated.
                </p>
              </li>
              <li>
                <p class="justify">The amount paid entitles the user the access to Jobejee services for the
                  period of time the period of the time the amount is for.
                </p>
              </li>
              <li>
                <p class="justify">The amount paid is not transferrable between users and entities.

                </p>
              </li>
              <li>
                <p class="justify">Jobejee will not be responsible for failure of any back-end
                  technologies of mail exchange server which might result in failure of receiving mails.
                </p>
              </li>
              <li>
                <p class="justify">If you should require termination of any services that you have subscribed to,
                  please send an email at info@jobejee.com
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>



      <!--Terms and conditions applicable for all Users-->
      <div class="col-md-12 des_box">
        <p class="title">6.Terms and conditions applicable for all Users:</p>
        <!--List-->
        <ol class="list">
          <li><span class="sm_title">Rules Regarding Site Usage</span>
            <ol class="inner_list">
              <li>
                <p class="justify">Jobejee reserves for itself and its licensors all other rights, title and
                  interest except for the ones granted to you by this Terms and Conditions. You may not reproduce,
                  modify,
                  display, sell, or distribute the Content or Jobejee Materials, or use them in any other way for public
                  or commercial purpose.
                </p>
              </li>
              <li>
                <p class="justify">The use of the Content on any other web site or in a networked computer
                  environment for any purpose is strictly prohibited. The Jobejee design logo and certain other names or
                  logos are service marks applicants for recruitment or trademarks of Jobejee, and all related product
                  and
                  service names, design marks and slogans are the service marks or trademarks of Jobejee. In addition,
                  the
                  duplication of the "look" and "feel" of the Joebejee website (including color combinations, button
                  shapes, layout, design and all other graphical elements) are strictly prohibited.</p>
              </li>
              <li>
                <p class="justify">All other product and service marks contained on the Jobejee website are the
                  trademarks of their respective owners.</p>
              </li>
            </ol>
          </li>

          <li><span class="sm_title">Allocation of Responsibility</span>
            <p class="justify">Jobejee assumes no responsibility for Documents posted by Users and no responsibility for
              the
              activities, omissions or other conduct of Users. Jobejee acts as a portal for the online distribution and
              publication of User submitted information and has no obligation to screen communications or information in
              advance and is not responsible for screening or monitoring Documents posted by Users. If notified by a
              User of a Document which allegedly does not conform to these Terms and Conditions, Jobejee may investigate
              the allegation and determine in good faith and in its sole discretion whether to remove or request the
              removal of such Document. Jobejee has no liability or responsibility to Users for performance or
              nonperformance of such activities. Jobejee may take any action with respect to User submitted information
              that it deems necessary or appropriate, in its sole discretion.
            </p>
          </li>

          <li><span class="sm_title">Warranty Disclaimers</span>
            <ol class="inner_list">
              <li>
                <p class="justify">Jobejee website is provided on an 'as is' basis without any warranties of any
                  kind, express or implied. Jobejee, to the fullest extent permitted by law, disclaims all warranties,
                  including, but not limited to, the warranties of merchantability, title, non-infringement of third
                  parties' rights, and fitness for a particular purpose. Jobejee makes no warranties about the accuracy,
                  reliability, completeness, or timeliness of the sites.</p>
              </li>
              <li>
                <p class="justify"> Jobejee makes no representations or guarantees regarding the truthfulness,
                  accuracy, legality, completeness, timeliness or reliability of any Documents posted by Users, or of
                  any
                  other form of communication engaged in by Users. Documents may contain inaccuracies or typographical
                  errors. You agree that any reliance on Documents posted by Users, or on any other form of
                  communication
                  with Users, will be at your own risk.</p>
              </li>
              <li>
                <p class="justify">Jobejee makes no representations or guarantees regarding the Content of the
                  Jobejee website, including, but not limited to, broken links, inaccuracies or typographical errors.
                </p>
              </li>
            </ol>
          </li>

          <li><span class="sm_title">User Authentication </span>
            <p class="justify">Because User authentication on the Internet is difficult, Jobejee cannot and does not
              confirm that
              each User is who they claim to be. Because Jobejee does not and cannot be involved in User-to-User
              dealings or control the behavior of participants on the Sites, in the event that you have a dispute with
              one or more Users, you release Jobejee from claims, demands and damages (actual and consequential and
              direct and indirect) of every kind and nature, known and unknown, suspected and unsuspected, disclosed and
              undisclosed, arising out of or in any way connected with such disputes.</p>
          </li>


          <li><span class="sm_title">Amendments to this Agreement and Changes to Sites</span>
            <p class="justify">Jobejee may revise these Terms and Conditions at any time by updating this page. Changes
              will be
              binding on you on the date they are posted on the Jobejee website (or as otherwise stated in the any
              notice of such changes). Any use of the Jobejee website will be considered acceptance by you of the
              then-current Terms and Conditions (including any exhibits thereto) contained on such Jobejee website. If
              at any time you find the Terms and Conditions unacceptable, you may not use the Jobejee website any
              longer.
            </p>
          </li>

          <li><span class="sm_title">Questions and Notices</span>
            <p class="justified">When you register on the Jobejee website or in connection with the Services, you will
              be asked to
              create an account and provide Jobejee with certain information, including but not limited to, a valid
              email address. You acknowledge and agree that you have no ownership rights in your account.</p>
          </li>

          <li><span class="sm_title">Posting Rules</span>
            <ol class="inner_list">
              <li>
                <p class="justify">You may not post any Document to the Jobejee website that contains: anything
                  that is discriminatory, sexually explicit, obscene, libelous, defamatory, threatening, harassing,
                  abusive, or hateful; or anything that is embarrassing or offensive to another person or entity.</p>
              </li>
              <li>
                <p class="justify">You may not use a Document(s) to: impersonate another person, living or dead;
                  and/or post false, inaccurate or misleading information.</p>
              </li>
              <li>
                <p class="justify">Resume postings must contain the accurate resume of a living individual
                  seeking employment on a full-time, part-time, or contractual basis on his or her own behalf. </p>
              </li>
              <li>
                <p class="justify">Jobejee is under no obligation to monitor the Documents posted on the Jobejee
                  website, but it may monitor Documents at random. Documents found to violate the above Posting Rules
                  may
                  be removed at Jobejee's sole discretion. </p>
              </li>
            </ol>
          </li>

          <li><span class="sm_title">Conduct Rules</span>
            <ol class="inner_list">
              <li>
                <p class="justify">You may not send unsolicited commercial email to Users.</p>
              </li>
              <li>
                <p class="justify">Protect your password. Your Jobejee account is to be accessed and used solely
                  by you. You may not provide your password or otherwise permit access your Jobejee account to any third
                  party. You are responsible for maintaining the confidentiality of your information and password. If
                  others use your password to post inappropriate material on the Jobejee, you risk losing your access to
                  the Sites. You agree to notify Jobejee immediately of any unauthorized use of your account or
                  password.
                </p>
              </li>
              <li>
                <p class="justify"> Report inappropriate postings or conduct to Feedback. </p>
              </li>
              <li>
                <p class="justify">You may not delete or revise any material posted by any other person or
                  entity.</p>
              </li>
            </ol>
          </li>

          <li><span class="sm_title">Security Rules</span>
            <p class="justify">Users are prohibited from violating or attempting to violate the security of the Jobejee
              website,
              including, without limitation: </p>
            <ol class="inner_list">
              <li>
                <p class="justify">Accessing data not intended for such User or logging into a server or account
                  which the User is not authorized to access.</p>
              </li>
              <li>
                <p class="justify">Attempting to probe, scan or test the vulnerability of a system or network or
                  to breach security or authentication measures without proper authorization.</p>
              </li>
              <li>
                <p class="justify">Attempting to interfere with service to any User, host or network, including,
                  without limitation, via means of submitting a virus to the Sites, overloading, "flooding", "mail
                  bombing" or "crashing".</p>
              </li>
              <li>
                <p class="justify">Sending unsolicited e-mail, including promotions and/or advertising of
                  products or services.</p>
              </li>
              <li>
                <p class="justify">Forging any TCP/IP packet header or any part of the header information in any
                  e-mail or newsgroup posting.</p>
              </li>
            </ol>
          </li>
        </ol>
      </div>

    </div>
  </div>
</div>
<app-footer></app-footer>

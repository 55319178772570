<div class="col-md-12 table-responsive" style="margin-right: 15px; padding: 0px">
    <table class="table">
        <thead>
            <tr>
                <th>SN</th>
            </tr>
        </thead>

        <tbody>
        </tbody>
    </table>
</div>


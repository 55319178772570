import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../../common/services/api-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {

  loader = true;
  clientId: string;
  clientName: string

  constructor(
    private apiServiceService: ApiServiceService,
    private route: ActivatedRoute,
    private router: Router,
    public meta: Meta, public title: Title,
  ) {

    this.route.params.subscribe(params => {
      this.clientId = params['clientId'];
      this.clientName = params['clientName'];
      this.title.setTitle(this.clientName + " - Job vacancy in Nepal" + " | " + "Jobejee");

      this.meta.updateTag({ property: 'og:title', content: this.clientName + " - Job vacancy in Nepal" + " | " + "Jobejee" });
      this.meta.updateTag({ property: 'og:site_name', content: "jobejee" });
      this.meta.updateTag({ property: 'og:type', content: "website" });
      this.meta.updateTag({ property: 'fb:app_id', content: "2458134394205611" });
      this.meta.updateTag({ property: 'og:url', content: "https://www.jobejee.com" + this.router.url });


      this.getCompanyDetail();
      // this.getCompanyMoto();
    })
  }

  ngOnInit() {
  }

  clientObj;

  jobSearchList = [];
  getCompanyDetail() {
    this.loader = true;
    let Obj = {
      employerDetailId: this.clientId
    }
    this.apiServiceService.clientDetails(Obj).subscribe(
      (response: any) => {
        this.clientObj = response;
        this.jobSearchList = this.clientObj.employersJobViewsModel.jobList;
        this.loader = false;
      }
    )
  }

  // clientObjMoto;
  // getCompanyMoto() {
  //   let Obj = {
  //     employerDetId: this.clientId
  //   }
  //   this.apiServiceService.clientMoto(Obj).subscribe(
  //     (response: any) => {
  //       this.clientObjMoto = response;
  //     }
  //   )
  // }

  skillArray(value) {
    let splitData = value.split(",");
    return splitData;
  }

  filterLink(value) {
    let splitData = value.split("//");
    return "http://" + splitData[splitData.length - 1]
  }

  replaceSpace(value) {
    let str = value.replace(/\s+/g, '-');
    return str;
  }

}

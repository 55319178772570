<div class="modal-body">
    <div class="row" *ngIf="!loader">



        <div class="center" >
            <a (click)="pdf.saveAs(pd.fullName+ '_resume.pdf'); check()" class="download-w" *ngIf="p">Download CV</a>
            <!-- <a (click)=" downloadCv(); check()" class="download-m" *ngIf="p">Download
                CV </a> -->
        </div>



        <!-- 
        <app-skeleton-loader Cwidth="100" Cheight="100"></app-skeleton-loader>
        <app-skeleton-loader Cwidth="200" Cheight="50"></app-skeleton-loader>
        <app-skeleton-loader Cwidth="100" Cheight="100" circle="true"></app-skeleton-loader> -->

        <!-- <div style="position: absolute; left: -1000px; top: 0;"> -->
        <div class="col-md-12" style="position: relative;">
            <kendo-pdf-export #pdf paperSize="A4" margin="0.5cm" [scale]="scale">
                <div class="wrapper">
                    <div class="row nopadding ">
                        <div class="col-md-12 nopadding">
                            <div class="profile-box col-md-2 col-sm-3 col-xs-3 ">
                                <img src="../../../../assets/images/prof.png" class="profile-pic"
                                    style="height: 120px; width: 118px;object-fit: cover;" alt="image"
                                    *ngIf="!pd.profilePic">

                                <img src={{pd.profilePic}} class="profile-pic"
                                    style="height: 120px; width: 118px;object-fit: cover;" alt="image"
                                    *ngIf="pd.profilePic">

                                <app-skeleton-loader Cwidth="100" Cheight="100" circle="true" *ngIf="!pd">
                                </app-skeleton-loader>

                            </div>
                            <div class="info-box col-md-7 col-sm-5 col-xs-7 " style="padding-left: 30px !important;">
                                <p style="font-size: 18px; font-weight: 600; margin-bottom:8px;">
                                    {{p.name || p.firstName + ' ' +p.lastName}}</p>
                                <p>{{p.mobileNo || p.mobNo}} </p>
                                <p>{{pd.email || 'Not Available'}}</p>
                                <p *ngIf="pd.tempAddress!='null, null, null'">{{pd.tempAddress || 'Not Available'}}</p>
                                <p *ngIf="pd.tempAddress=='null, null, null'">Not Available</p>
                            </div>
                            <div class="logo-box text-right col-md-3 col-sm-4 col-xs-4">
                                <img src="../../../../assets/images/logo_146x70.png" alt="">
                            </div>
                        </div>
                    </div>

                    <br>

                    <div style="font-weight: 600;">

                        <!--------------------- Personal Details --------------------->
                        <p class="sub-title">Personal Detail</p>
                        <hr>
                        <div style="width:100%">
                            <p style="width:30%;display: inline-block;vertical-align: text-top;">Age</p>
                            <p style="width:5%;display: inline-block;vertical-align: text-top">:</p>
                            <p style="width:65%;display: inline-block" class="fn">
                                {{pd.age ? pd.age + ' Years Old' : 'Not Available'}} </p>

                            <p style="width:30%;display: inline-block;vertical-align: text-top;">Gender</p>
                            <p style="width:5%;display: inline-block;vertical-align: text-top">:</p>
                            <p style="width:65%;display: inline-block" class="fn">{{pd.gender || 'Not Available'}}</p>

                            <p style="width:30%;display: inline-block;vertical-align: text-top;">Nationality</p>
                            <p style="width:5%;display: inline-block;vertical-align: text-top">:</p>
                            <p style="width:65%;display: inline-block" class="fn">Nepali</p>

                            <p style="width:30%;display: inline-block;vertical-align: text-top;">Marital Status</p>
                            <p style="width:5%;display: inline-block;vertical-align: text-top">:</p>
                            <p style="width:65%;display: inline-block" class="fn">
                                {{pd.maritalStatus || 'Not Available'}}</p>

                            <!-- <p style="width:22%;display: inline-block;vertical-align: text-top;">Language</p>
                            <p style="width:5%;display: inline-block;vertical-align: text-top">:</p>
                            <p style="width:68%;display: inline-block" class="fn">{{pd.language || 'Not Available'}}</p> -->
                        </div>
                        <!--------------------- Personal Details --------------------->
                        <br>

                        <!--------------------- Summary --------------------->
                        <ng-container *ngIf="pd.summary">
                            <p class="sub-title">Summary</p>
                            <hr>
                            <div style="width:100%" style="margin-bottom: 15px;">
                                <p style="text-align: justify;" class="fn">{{pd.summary || 'Not Available'}}</p>
                            </div>
                        </ng-container>
                        <!--------------------- Summary --------------------->

                        <!--------------------- Key Skills --------------------->
                        <ng-container *ngIf="pd.keySkill">
                            <p class="sub-title">Key Skills</p>
                            <hr>
                            <div style="width:100%">
                                <p style="text-align: justify;" class="fn">{{pd.keySkill || 'Not Available'}}</p>

                            </div>
                            <br>
                        </ng-container>

                        <!--------------------- Key Skills --------------------->



                        <!--------------------- Education Details --------------------->
                        <ng-container *ngIf="edu.length > 0">
                            <p class="sub-title">Education</p>
                            <hr>


                            <div class="col-md-12 noAvaib" *ngIf="edu.length<0;else edudata">
                                <p>Not Available</p>
                            </div>

                            <ng-template #edudata>
                                <div style="width:100%; margin-bottom: 7px;" *ngFor="let item of edu">
                                    <p style="width:30%;display: inline-block;vertical-align: top;">
                                        {{item.passedYr || 'Not Available'}}</p>
                                    <p style="width:70%;display: inline-block" class="fn"> <b>{{item.course}}</b> |
                                        {{item.marks}}
                                        <span *ngIf="item.gradingSystem == 'PER'"> %</span>
                                        <span *ngIf="item.gradingSystem == 'G4'"> CGPA / 4</span>
                                        <span *ngIf="item.gradingSystem == 'G10'"> CGPA / 10</span>
                                        <span *ngIf="item.gradingSystem == 'CR'"> CR</span>
                                        <br>
                                        {{item.instName}}, {{item.uniName}}
                                        <br>
                                    </p>


                                </div>
                            </ng-template>

                            <br>
                        </ng-container>

                        <!--------------------- Education Details --------------------->


                        <!--------------------- Experience --------------------->
                        <ng-container *ngIf="exp.length>0">
                            <p class="sub-title">Experience</p>
                            <hr>


                            <div class="col-md-12 noAvaib" *ngIf="exp.length<0; else expdata">
                                <p>Not Available</p>
                            </div>

                            <ng-template #expdata>
                                <div style="width:100%; margin-bottom: 7px;" *ngFor="let item of exp">
                                    <p style="width:40%;display: inline-block;vertical-align: top;">{{item.dateFrom}} -
                                        {{(item.dateTo )}}</p>
                                    <p style="width:60%;display: inline-block" class="fn"> <b>{{item.company}}</b>
                                        <br>{{item.desig}}
                                    </p>
                                </div>
                            </ng-template>
                            <br>
                        </ng-container>


                        <!--------------------- Experience --------------------->



                        <!--------------------- Other Information --------------------->
                        <p class="sub-title">Other Information</p>
                        <hr>
                        <div style="width:100%">
                            <p style="width:35%;display: inline-block; vertical-align: text-top;">Language</p>
                            <p style="width:5%;display: inline-block">:</p>
                            <p style="width:60%;display: inline-table" class="fn">{{pd.language || 'Not Available'}}</p>

                            <p style="width:35%;display: inline-block; vertical-align: text-top;">Driving Licence
                            </p>
                            <p style="width:5%;display: inline-block">:</p>
                            <p style="width:60%;display: inline-block" class="fn">
                                {{pd.drivingLicense || 'Not Available'}}</p>
                        </div>
                        <!--------------------- Other Information --------------------->

                        <br>



                        <ng-container *ngIf="jsRef.length>0">
                            <p class="sub-title">References</p>
                            <hr>


                            <div class="col-md-12 noAvaib" *ngIf="jsRef.length<1">
                                <p>Not Available</p>
                            </div>


                            <div style="width:100%; margin-bottom: 7px;" *ngFor="let item of jsRef">
                                <p style="width:40%;display: inline-block;vertical-align: top;">
                                    {{item.fullName}}</p>
                                <p style="width:60%;display: inline-block" class="fn"> <b>{{item.companyName}}</b>
                                    <br>
                                    {{item.designation}} <br> {{item.mobNo}} <br> <span
                                        *ngIf="item.email">{{item.email}}</span>
                                </p>

                            </div>
                        </ng-container>

                        <ng-container *ngIf="jsCert.length>0">
                            <p class="sub-title">Certificate</p>
                            <hr>


                            <div class="col-md-12 noAvaib" *ngIf="jsCert.length<1">
                                <p>Not Available</p>
                            </div>




                            <div style="width:100%; margin-bottom: 7px;" *ngFor="let item of jsCert">
                                <p style="width:30%;display: inline-block;vertical-align: top;">
                                    Certification</p>
                                <p style="width:70%;display: inline-block" class="fn"> {{item.certificateName}}</p>
                                <p style="width:30%;display: inline-block;vertical-align: top;">
                                    Year</p>
                                <p style="width:70%;display: inline-block" class="fn"> {{item.year}}</p>
                            </div>
                        </ng-container>



                    </div>
                </div>


            </kendo-pdf-export>
        </div>
    </div>

    <div class="row" *ngIf="loader">
        <div class="col-md-12" style="margin: 40px 0px">
            <app-jobejee-loader></app-jobejee-loader>

        </div>
    </div>
</div>
//core angular modules
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CaptchaComponent } from "../../includes/captcha/captcha.component";
//other modules
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { TagInputModule } from "ngx-chips";
import { NgtUniversalModule } from "@ng-toolkit/universal";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDocViewerModule } from "ngx-doc-viewer";
import { ImageCropperModule } from "ngx-image-cropper";
import { HotkeyModule } from "angular2-hotkeys";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { SkeletonLoaderModule } from "src/app/skeleton-loader/skeleton-loader.module";

//Ngx Bootstrap
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import {
  AccordionModule,
  AlertModule,
  TooltipModule,
  CarouselModule,
} from "ngx-bootstrap";
import { ProgressbarModule } from "ngx-bootstrap";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { PopoverModule } from "ngx-bootstrap/popover";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { ModalModule } from "ngx-bootstrap/modal";
import { ToastrModule } from "ngx-toastr";
import { SelectDropDownModule } from "ngx-select-dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
//Multi Select Dropdown
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown-angular7";
import { RecaptchaModule, RecaptchaFormsModule } from "ng-recaptcha";
//pipe Filter
import { PipePipe } from "../filter/pipe.pipe";
import { DatetimePipe } from "../filter/datetime.pipe";

//Common Components
import { Header1Component } from "src/app/includes/header1/header1.component";
import { FooterComponent } from "src/app/includes/footer/footer.component";
import { Spinner1Component } from "src/app/includes/spinner1/spinner1.component";
import { NavbarComponent } from "src/app/includes/navbar/navbar.component";
import { ApplyFooterComponent } from "src/app/includes/apply-footer/apply-footer.component";
import { MobileVerificationComponent } from "src/app/includes/mobile-verification/mobile-verification.component";
import { EmailVerificationComponent } from "src/app/includes/email-verification/email-verification.component";
import { SettingComponent } from "src/app/includes/setting/setting.component";
import { JobseekerProfileComponent } from "src/app/includes/jobseeker-profile/jobseeker-profile.component";
import { JobejeeLoaderComponent } from "src/app/includes/jobejee-loader/jobejee-loader.component";
import { AdminNavbarComponent } from "src/app/includes/admin-navbar/admin-navbar.component";
import { DisplayItemsComponent } from "../components/display-items/display-items.component";

@NgModule({
  declarations: [
    //Common Components
    Header1Component,
    AdminNavbarComponent,
    FooterComponent,
    Spinner1Component,
    NavbarComponent,
    ApplyFooterComponent,
    MobileVerificationComponent,
    EmailVerificationComponent,
    SettingComponent,
    JobseekerProfileComponent,
    JobejeeLoaderComponent,
    DisplayItemsComponent,
    CaptchaComponent,
    //pipe
    PipePipe,
    DatetimePipe,
  ],
  imports: [
    //modules
    CommonModule,
    RouterModule,
    HttpClientModule,
    TransferHttpCacheModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    //other modules
    PDFExportModule,
    TagInputModule,
    NgtUniversalModule,
    NgSelectModule,
    NgxDocViewerModule,
    ImageCropperModule,
    HotkeyModule.forRoot(),
    CKEditorModule,
    SkeletonLoaderModule,

    //Ngx Bootstrap
    BsDropdownModule.forRoot(),
    AlertModule.forRoot(),
    ProgressbarModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PopoverModule.forRoot(),
    ModalModule.forRoot(),
    CollapseModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
    }),
    SelectDropDownModule,
    TooltipModule.forRoot(),
    AccordionModule.forRoot(),
    CarouselModule.forRoot(),
    TimepickerModule.forRoot(),

    //Multi Select Dropdown
    NgMultiSelectDropDownModule.forRoot(),
  ],
  exports: [
    //Components
    Header1Component,
    AdminNavbarComponent,
    FooterComponent,
    Spinner1Component,
    NavbarComponent,
    ApplyFooterComponent,
    MobileVerificationComponent,
    EmailVerificationComponent,
    SettingComponent,
    JobseekerProfileComponent,
    JobejeeLoaderComponent,
    DisplayItemsComponent,
    CaptchaComponent,
    //Ngx Bootstrap
    BsDropdownModule,
    AlertModule,
    TooltipModule,
    ProgressbarModule,
    BsDatepickerModule,
    PopoverModule,
    ModalModule,
    CollapseModule,
    ToastrModule,
    SelectDropDownModule,
    AccordionModule,
    CarouselModule,
    TimepickerModule,
    //Multi Select Dropdown
    NgMultiSelectDropDownModule,

    //pipe
    PipePipe,
    DatetimePipe,
    //other modules
    RouterModule,
    PDFExportModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgtUniversalModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDocViewerModule,
    NgSelectModule,
    ImageCropperModule,
    TagInputModule,
    CKEditorModule,
    HotkeyModule,
    SkeletonLoaderModule,
    CommonModule,
  ],
})
export class SharedModule {}

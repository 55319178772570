import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiServiceService } from "../../common/services/api-service.service";
import { RestApiService } from "../../common/services/rest-api.service";
import { Router } from "@angular/router";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import {
  TransferState,
  makeStateKey,
  Meta,
  Title
} from "@angular/platform-browser";

import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  AbstractControl
} from "@angular/forms";
import { Uploadfile } from "../../common/function/upload";
import { map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

const sjobDetail = makeStateKey("jobsDetailObj");
const sjobDetailId = makeStateKey("sjobId");

@Component({
  selector: "app-job-detail",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-detail.component.scss"]
})
export class JobDetailComponent implements OnInit {
  jobId: string;
  jobTitle: string;

  loader = false;

  browserUrl;
  modalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private apiServiceService: ApiServiceService,
    public meta: Meta,
    public title: Title,
    private state: TransferState,
    private fbBUilder: FormBuilder,
    private uploadfile: Uploadfile,
    private toastr: ToastrService,
    private restApiService: RestApiService
  ) {
    this.loader = true;
    this.route.params.subscribe(params => {
      this.jobId = params["jobId"];
      this.jobTitle = params["jobName"];

      this.sjobId = this.state.get(sjobDetailId, null);
      if (this.sjobId != this.jobId) {
        this.state.set(sjobDetailId, this.jobId);
        this.state.set(sjobDetail, null);
      }

      this.title.setTitle(this.jobTitle + " | " + "Jobejee");

      // <!-- Open Graph data -->
      this.meta.updateTag({
        property: "og:title",
        content: this.jobTitle + " | " + "Jobejee"
      });
      this.meta.updateTag({ property: "og:site_name", content: "jobejee" });
      this.meta.updateTag({ property: "og:type", content: "website" });
      this.meta.updateTag({
        property: "fb:app_id",
        content: "1027280544272676"
      });
      this.meta.updateTag({
        property: "og:url",
        content: "https://www.jobejee.com" + this.router.url
      });

      // this.meta.updateTag({ name: 'title', content: this.jobTitle });
      // this.userInfo = this.restApiService.getStorage();
      this.userInfo = this.restApiService.getCookieStorage();
      console.log(this.userInfo)
      if (this.userInfo) {
        this.jsInfoId = this.userInfo.jsInfoId;
        // this.callthis();
      }
      this.getJobDetail();

      // getSimilarJobDetail();
    });
    this.browserUrl = this.router.url;
  }

  // const sjobDetail = makeStateKey("sjobDetailObj");
  sjobDetailObj = null;
  jobsDetailObj;
  sjobId = null;

  callthis() {
    this.jobsDetailObj = this.state.get(sjobDetail, null);
    if (!this.jobsDetailObj) {
      this.getJobDetail();
    } else {
      this.loader = false;
      this.state.set(sjobDetail, this.jobsDetailObj);
    }
  }

  customJobId = "a974548eeb75f42f9e665e1c9d7abaebcaa20470";
  userInfo;
  jsInfoId: string = null;
  ngOnInit() { }

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  config1 = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg"
  };

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openModalFixed(templateFixed: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateFixed, this.config);
  }

  openModalBank(templateBank: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateBank, this.config1);
  }

  getJobDetail() {
    // if (!this.jobsDetailObj) {
    this.loader = true;
    let Obj: any = {
      jobCreateId: this.jobId
    };
    if (this.jsInfoId) {
      Obj.jsInfoId = this.jsInfoId;
    }
    this.apiServiceService.JobDetails(Obj).subscribe((response: any) => {
      this.jobsDetailObj = response;
      this.state.set(sjobDetail, response);

      let seotitle =
        this.jobsDetailObj.title +
        " - " +
        this.jobsDetailObj.companyName +
        " | " +
        "Jobejee";
      this.title.setTitle(seotitle);
      this.meta.updateTag({ property: "og:title", content: seotitle });

      this.meta.updateTag({
        property: "og:image",
        content: this.jobsDetailObj.compLogo
      });
      this.meta.updateTag({
        property: "og:description",
        content: this.jobsDetailObj.jobDesc.replace(/<[^>]*>?/gm, "")
      });
      this.loader = false;
    });
    // }
  }

  similarList = [];
  getSimilarJobDetail() {
    let Obj = {
      jobCreateId: this.jobId,
      jobType: "OJ"
    };
    // this.apiServiceService.SimilarJobDetails(Obj).subscribe((response: any) => {
    //   this.similarList = response.jobSearchList;
    // });
  }

  pushIntoArray(value) {
    let splitData = value.split(",");
    return splitData;
  }

  replaceSpace(value) {
    let str = value.replace(/\s+/g, "-");
    return str;
  }

  checkResume() {
    let Obj = {
      jsInfoId: this.jsInfoId
    };
    this.apiServiceService.resumeCheck(Obj).subscribe((response: any) => {
      if (response.message == "Resume is available") {
        this.appyJob();
      } else {
        this.showError(response.message, response.status);
      }
    });
  }

  msg;
  appyJob() {
    let Obj = {
      jsInfoId: this.jsInfoId,
      jobCreateId: this.jobId
    };
    this.apiServiceService.appyjob(Obj).subscribe(
      (response: any) => {
        if (response.status == "SUCCESS") {
          this.showSuccess(response.message, response.status);
          this.noLoaderJobDetail();
        } else {
          this.showError(response.message, response.status);
        }
      },
      (error: any) => {
        let err = error.error;
        if (err.validDataList) {
          this.showError(
            "Your Keyskills doesn't match with job requirements",
            err.status
          );
        } else {
          this.showError(err.eduMsg || err.message, err.status);
        }
      }
    );
  }

  showSuccess(status, message) {
    this.toastr.success(status, message, {
      timeOut: 2000,
      positionClass: "toast-top-right"
      // positionClass: 'toast-top-center',
    });
  }

  showError(status, message) {
    this.toastr.error(status, message, {
      timeOut: 2500,
      positionClass: "toast-top-right"
      // positionClass: 'toast-top-center',
    });
  }

  noLoaderJobDetail() {
    let Obj: any = {
      jobCreateId: this.jobId
    };
    if (this.jsInfoId) {
      Obj.jsInfoId = this.jsInfoId;
    }
    this.apiServiceService.JobDetails(Obj).subscribe((response: any) => {
      this.jobsDetailObj = response;
      this.loader = false;
    });
  }

  fbShare() {
    window.open(
      "https://www.facebook.com/dialog/share?app_id=2458134394205611&display=popup&href=https://www.jobejee.com" +
      this.browserUrl +
      "&redirect_uri=https://www.jobejee.com" +
      this.browserUrl
    );
  }

  //// APPLY WITHOUT REGISTER

  candidateRegister: FormGroup = this.fbBUilder.group({
    firstName: [null, [Validators.required]],
    lastName: [null, [Validators.required]],
    email: [
      null,
      [
        Validators.required,
        Validators.pattern(
          "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$"
        )
      ],
      this.validateEmailNotTaken.bind(this)
    ],
    skills: [null, [Validators.required]],
    resumeTitle: [null, [Validators.required]],
    profileType: ["F", [Validators.required]],
    countryCode: [977, [Validators.required]],
    mobNo: [
      null,
      [
        Validators.required,
        Validators.pattern("[0-9]*"),
        Validators.minLength(10),
        Validators.maxLength(10)
      ]
    ]
  });

  validateEmailNotTaken(control: AbstractControl) {
    return this.apiServiceService.emailValidation(control.value).pipe(
      map(response => {
        return response ? null : { emailTaken: true };
      })
    );
  }

  status = "fresher";
  changeProfile(e) {
    if (e.target.value == "P") {
      this.getIndustry();
      this.getFunctionalArea();
      this.getSalary();
      this.getDesignList();

      this.candidateRegister.addControl(
        "industryId",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.controls["industryId"].updateValueAndValidity();

      this.candidateRegister.addControl(
        "funcId",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "year",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "month",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "companyName",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "noticePeriod",
        new FormControl("", Validators.required)
      );
      this.candidateRegister.addControl(
        "salaryType",
        new FormControl("M", Validators.required)
      );
      this.candidateRegister.addControl(
        "annualSalLakh",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "annualSalThousand",
        new FormControl(null, Validators.required)
      );
      this.candidateRegister.addControl(
        "desigId",
        new FormControl(null, Validators.required)
      );

      this.candidateRegister.controls["funcId"].updateValueAndValidity();
      this.candidateRegister.controls["year"].updateValueAndValidity();
      this.candidateRegister.controls["month"].updateValueAndValidity();
      this.status = "Professional";
    } else if (e.target.value == "F") {
      this.candidateRegister.removeControl("industryId");
      this.candidateRegister.removeControl("funcId");
      this.candidateRegister.removeControl("year");
      this.candidateRegister.removeControl("month");
      this.candidateRegister.removeControl("companyName");
      this.candidateRegister.removeControl("noticePeriod");
      this.candidateRegister.removeControl("salaryType");
      this.candidateRegister.removeControl("desigId");

      this.candidateRegister.removeControl("annualSalLakh");
      this.candidateRegister.removeControl("annualSalThousand");
      this.status = "fresher";
    }
  }

  checkbox = false;
  msg1: string = null;
  jsinfoId;
  clicked = false;
  registerCandidate() {
    if (this.candidateRegister.valid) {
      this.clicked = true;
      let Obj = this.candidateRegister.value;
      Obj.phone = this.candidateRegister.value.mobNo;
      Obj.keySkills = this.candidateRegister.value.skills;

      if (Obj.profileType == "P") {
        (Obj.expCtcLakh = Obj.annualSalLakh),
          (Obj.expCtcThousand = Obj.annualSalThousand),
          (Obj.expCtcType = Obj.salaryType),
          (Obj.expMonth = Obj.month),
          (Obj.expYear = Obj.year),
          (Obj.funcAreaId = Obj.funcId);
      }
      this.apiServiceService.applyWithoutRegister(Obj).subscribe(response => {
        if (response.status == "SUCCESS") {
          this.jsinfoId = response.jsInfoId;
          if (this.fileValid == true && this.jsinfoId) {
            this.sendFile(this.jsinfoId);
          }
        }
      });
    }
  }

  fileObj = {
    fileStatus: 0,
    sendFile: null
  };
  fileName = null;
  fileValid = false;

  checkboxField;
  uploadFile(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.fileObj = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        1097152
      );
      if (this.fileObj.fileStatus == 1) {
        this.fileValid = true;
      }
    }
  }

  sendFile(id) {
    if (this.fileObj.fileStatus == 1) {
      this.fileObj.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadCV(this.fileObj.sendFile)
        .subscribe(response => {
          if (response.status == "SUCCESS") {
            this.JobApply(id);
          }
        });
    }
  }
  appmsg;
  JobApply(id) {
    let Obj = {
      jsInfoId: id,
      jobCreateId: this.jobId
    };
    this.apiServiceService.appyjob(Obj).subscribe((response: any) => {
      if (response.status == "SUCCESS") {
        this.appmsg = response.message;
        setTimeout(() => {
          this.candidateRegister.reset();
          this.clicked = false;
          this.appmsg = null;
          this.modalRef.hide();
          this.candidateRegister.reset();
          this.candidateRegister.controls["profileType"].patchValue("F");
          this.candidateRegister.controls["countryCode"].patchValue("977");
        }, 2200);
      }
    });
  }

  IndustryList = [];
  getIndustry() {
    let Obj = {
      type: "ind"
    };
    this.apiServiceService
      .getAllIndustryFunctional(Obj)
      .subscribe((response: any) => {
        this.IndustryList = [];
        this.IndustryList = response;
      });
  }

  functionalList = [];
  getFunctionalArea() {
    let Obj = {
      type: "func"
    };
    this.functionalList = [];
    this.apiServiceService
      .getAllIndustryFunctional(Obj)
      .subscribe((response: any) => {
        this.functionalList = response;
      });
  }

  desigDesireList = [];
  getDesignList() {
    let Obj = {
      type: "desig"
    };
    this.apiServiceService.getAllIndustry(Obj).subscribe((response: any[]) => {
      this.desigDesireList = response;
    });
  }

  lakhSal = [];
  ThouSal = [];
  getSalary() {
    this.lakhSal = [];
    this.ThouSal = [];
    for (let i = 0; i <= 99; i++) {
      this.lakhSal.push({
        display: i + " Lakh",
        val: i
      });
      this.ThouSal.push({
        display: i + " Thousand",
        val: i
      });
    }
  }

  bankJob: FormGroup = this.fbBUilder.group({
    photo: [null, [Validators.required]],
    citizenship: [null, [Validators.required]],
    plusTwo: [null, [Validators.required]],
    plusTwoPercentType: ["", [Validators.required]],
    plusTwoMarks: ["", [Validators.required]],
    bachelors: [],
    bachelorsPercentType: [],
    bachelorsMarks: [],
    ms: [],
    msPercentType: [],
    msMarks: [],
    exp1: [],
    exp1Name: [],
    exp1years: [],
    exp2: [],
    exp2Name: [],
    exp2years: [],
    training: [],
    age: [null, [Validators.required]],
    prefLocation: [null, [Validators.required]],
    cv: [null, [Validators.required]],
    province: [null, [Validators.required]],
    localCandidateTrue: ["No", [Validators.required]],
    localCandidate: [null],
    plusTwoFaculty: ["", [Validators.required]]
  });

  changeLocal(value) {
    let data = value.target.value;
    if (data == "Yes") {
      this.bankJob.controls["localCandidate"].setValidators([
        Validators.required
      ]);
      this.bankJob.controls["localCandidate"].updateValueAndValidity();
    } else {
      this.bankJob.controls["localCandidate"].setValidators([]);
      this.bankJob.controls["localCandidate"].updateValueAndValidity();
    }
  }

  markIPlaceholder = "Enter Percentage";
  markIChange(e) {
    if (e.target.value == "Percentage") {
      this.markIPlaceholder = "Enter Percentage";
    } else {
      this.markIPlaceholder = "Enter GPA";
    }
  }

  markIIPlaceholder = "Enter Percentage";
  markIIChange(e) {
    if (e.target.value == "Percentage") {
      this.markIIPlaceholder = "Enter Percentage";
    } else {
      this.markIIPlaceholder = "Enter GPA";
    }
  }

  markIIIPlaceholder = "Enter Percentage";
  markIIIChange(e) {
    if (e.target.value == "Percentage") {
      this.markIIIPlaceholder = "Enter Percentage";
    } else {
      this.markIIIPlaceholder = "Enter GPA";
    }
  }

  getBankData() {
    this.apiServiceService.jobBank(this.jsInfoId).subscribe(response => {
      if (response.length) {
        let data = response[0];

        if (data.photo) {
          this.uploadedPhotoName = data.photo.split("/")[
            data.photo.split("/").length - 1
          ];

          this.bankJob.patchValue({
            photo: this.uploadedPhotoName
          });
        }

        if (data.citizenship) {
          this.uploadedcitizenName = data.citizenship.split("/")[
            data.citizenship.split("/").length - 1
          ];
          this.bankJob.patchValue({
            citizenship: this.uploadedcitizenName
          });
        }

        if (data.plusTwo) {
          this.uploadedmarksheetIName = data.plusTwo.split("/")[
            data.plusTwo.split("/").length - 1
          ];
          this.bankJob.patchValue({
            plusTwo: this.uploadedmarksheetIName
          });
        }

        if (data.bachelors) {
          this.uploadedmarksheetIIName = data.bachelors.split("/")[
            data.bachelors.split("/").length - 1
          ];
          this.bankJob.patchValue({
            bachelors: this.uploadedmarksheetIIName
          });
        }

        if (data.ms) {
          this.uploadedmarksheetIIIName = data.ms.split("/")[
            data.ms.split("/").length - 1
          ];
          this.bankJob.patchValue({
            ms: this.uploadedmarksheetIIIName
          });
        }

        if (data.exp1) {
          this.bankingExpName = data.exp1.split("/")[
            data.exp1.split("/").length - 1
          ];
          this.bankJob.patchValue({
            exp1: this.bankingExpName
          });
        }

        if (data.training) {
          this.traningCertificateName = data.training.split("/")[
            data.training.split("/").length - 1
          ];
          this.bankJob.patchValue({
            training: this.traningCertificateName
          });
        }

        if (data.cv) {
          this.cvUploadName = data.cv.split("/")[data.cv.split("/").length - 1];
          this.bankJob.patchValue({
            cv: this.cvUploadName
          });
        }

        this.bankJob.patchValue({
          plusTwoPercentType: data.plusTwoPercentType
        });
        this.bankJob.patchValue({ plusTwoMarks: data.plusTwoMarks });
        this.bankJob.patchValue({
          bachelorsPercentType: data.bachelorsPercentType
        });
        this.bankJob.patchValue({ bachelorsMarks: data.bachelorsMarks });

        if (data.plusTwoFaculty) {
          this.bankJob.patchValue({ plusTwoFaculty: data.plusTwoFaculty });
        }

        this.bankJob.patchValue({ msPercentType: data.msPercentType });
        this.bankJob.patchValue({ msMarks: data.msMarks });
        this.bankJob.patchValue({ exp1Name: data.exp1Name });
        this.bankJob.patchValue({ exp1years: data.exp1years });

        this.bankJob.patchValue({ exp2Name: data.exp2Name });
        this.bankJob.patchValue({ exp2years: data.exp2years });
        this.bankJob.patchValue({ age: data.age });
        this.bankJob.patchValue({ prefLocation: data.prefLocation });
        this.bankJob.patchValue({ province: data.province });

        if (data.localCandidateTrue) {
          this.bankJob.patchValue({
            localCandidateTrue: data.localCandidateTrue
          });
        } else {
          this.bankJob.patchValue({
            localCandidateTrue: "No"
          });
        }

        if (data.localCandidate) {
          this.bankJob.controls["localCandidate"].setValidators([
            Validators.required
          ]);
          this.bankJob.patchValue({ localCandidate: data.localCandidate });
        }
        this.bankJob.addControl(
          "id",
          new FormControl(data.id, Validators.required)
        );
      } else {
        this.bankJob.reset();
        this.uploadedPhotoName = null;
        this.uploadedcitizenName = null;
        this.uploadedmarksheetIName = null;
        this.uploadedmarksheetIIName = null;
        this.uploadedmarksheetIIIName = null;
        this.bankingExpName = null;
        this.traningCertificateName = null;
        this.cvUploadName = null;

        this.defaultValue();
      }
    });
  }

  defaultValue() {
    this.bankJob.patchValue({ plusTwoPercentType: "Percentage" });
    this.bankJob.patchValue({ bachelorsPercentType: "Percentage" });
    this.bankJob.patchValue({ msPercentType: "Percentage" });
    this.bankJob.patchValue({ localCandidateTrue: "No" });
    this.bankJob.patchValue({ plusTwoFaculty: "" });
  }

  registerBankJob() {
    let Obj = this.bankJob.value;
    Obj.jsInfoId = this.jsInfoId;
    this.loader = true;
    this.apiServiceService.uploadPhto(Obj, "exam").subscribe(response => {
      if (response.status == "Success") {
        this.appyJob();
        this.cancelForm();
      }
    });
  }

  cancelForm() {
    this.bankJob.reset();
    this.uploadedPhotoName = null;
    this.uploadedcitizenName = null;
    this.uploadedmarksheetIName = null;
    this.uploadedmarksheetIIName = null;
    this.uploadedmarksheetIIIName = null;
    this.bankingExpName = null;
    this.traningCertificateName = null;
    this.cvUploadName = null;
    this.defaultValue();

    let close = document.getElementById("closeDocument");
    close.click();
  }
  photoObj = {
    fileStatus: 0,
    sendFile: null
  };

  uploadPhoto(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.photoObj = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.photoObj.fileStatus == 1) {
        this.sendUploadPhoto(this.jsInfoId);
      }
    }
  }

  uploadedPhotoName;
  sendUploadPhoto(id) {
    if (this.photoObj.fileStatus == 1) {
      this.photoObj.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.photoObj.sendFile, "photo")
        .subscribe(response => {
          this.uploadedPhotoName = response.fileName;
          this.bankJob.patchValue({ photo: this.uploadedPhotoName });
        });
    }
  }

  citizenObj = {
    fileStatus: 0,
    sendFile: null
  };

  uploadCitizen(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.citizenObj = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.citizenObj.fileStatus == 1) {
        this.sendUploadCitizen(this.jsInfoId);
      }
    }
  }
  uploadedcitizenName;
  sendUploadCitizen(id) {
    if (this.citizenObj.fileStatus == 1) {
      this.citizenObj.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.citizenObj.sendFile, "citizen")
        .subscribe(response => {
          this.uploadedcitizenName = response.fileName;
          this.bankJob.patchValue({ citizenship: this.uploadedcitizenName });
        });
    }
  }

  marksheetI = {
    fileStatus: 0,
    sendFile: null
  };

  uploadMarksheetI(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.marksheetI = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.marksheetI.fileStatus == 1) {
        this.sendUploadmarksheetI(this.jsInfoId);
      }
    }
  }
  uploadedmarksheetIName;
  sendUploadmarksheetI(id) {
    if (this.marksheetI.fileStatus == 1) {
      this.marksheetI.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.marksheetI.sendFile, "marksheetI")
        .subscribe(response => {
          this.uploadedmarksheetIName = response.fileName;
          this.bankJob.patchValue({ plusTwo: this.uploadedmarksheetIName });
        });
    }
  }

  marksheetII = {
    fileStatus: 0,
    sendFile: null
  };

  uploadMarksheetII(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.marksheetII = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.marksheetII.fileStatus == 1) {
        this.sendUploadmarksheetII(this.jsInfoId);
      }
    }
  }
  uploadedmarksheetIIName;
  sendUploadmarksheetII(id) {
    if (this.marksheetII.fileStatus == 1) {
      this.marksheetII.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.marksheetII.sendFile, "marksheetII")
        .subscribe(response => {
          this.uploadedmarksheetIIName = response.fileName;
          this.bankJob.patchValue({ bachelors: this.uploadedmarksheetIIName });
        });
    }
  }

  marksheetIII = {
    fileStatus: 0,
    sendFile: null
  };

  uploadMarksheetIII(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.marksheetIII = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.marksheetIII.fileStatus == 1) {
        this.sendUploadmarksheetIII(this.jsInfoId);
      }
    }
  }
  uploadedmarksheetIIIName;
  sendUploadmarksheetIII(id) {
    if (this.marksheetIII.fileStatus == 1) {
      this.marksheetIII.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.marksheetIII.sendFile, "marksheetIII")
        .subscribe(response => {
          this.uploadedmarksheetIIIName = response.fileName;
          this.bankJob.patchValue({ ms: this.uploadedmarksheetIIIName });
        });
    }
  }

  bankingExp = {
    fileStatus: 0,
    sendFile: null
  };

  bankingExpI(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.bankingExp = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.bankingExp.fileStatus == 1) {
        this.sendBankingExpI(this.jsInfoId);
      }
    }
  }
  bankingExpName;
  sendBankingExpI(id) {
    if (this.bankingExp.fileStatus == 1) {
      this.bankingExp.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.bankingExp.sendFile, "bankingExp")
        .subscribe(response => {
          this.bankingExpName = response.fileName;
          this.bankJob.patchValue({ exp1: this.bankingExpName });
        });
    }
  }

  bankingExpII = {
    fileStatus: 0,
    sendFile: null
  };

  bankingExpsII(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.bankingExpII = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.bankingExpII.fileStatus == 1) {
        this.sendBankingExpII(this.jsInfoId);
      }
    }
  }
  bankingExpIIName;
  sendBankingExpII(id) {
    if (this.bankingExpII.fileStatus == 1) {
      this.bankingExpII.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.bankingExpII.sendFile, "bankingExpII")
        .subscribe(response => {
          this.bankingExpIIName = response.fileName;
          this.bankJob.patchValue({ exp2: this.bankingExpIIName });
        });
    }
  }

  traningCertificate = {
    fileStatus: 0,
    sendFile: null
  };

  traningCertificateUpload(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.traningCertificate = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.traningCertificate.fileStatus == 1) {
        this.sendtraningCertificate(this.jsInfoId);
      }
    }
  }
  traningCertificateName;
  sendtraningCertificate(id) {
    if (this.traningCertificate.fileStatus == 1) {
      this.traningCertificate.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.traningCertificate.sendFile, "Training")
        .subscribe(response => {
          this.traningCertificateName = response.fileName;
          this.bankJob.patchValue({ training: this.traningCertificateName });
        });
    }
  }

  cvUpload = {
    fileStatus: 0,
    sendFile: null
  };

  cvuploads(e) {
    if (e.target.files.length) {
      this.fileName = e.target.files[0].name;
      let extensionType = [
        "doc",
        "docx",
        "pdf",
        "PDF",
        "jpg",
        "JPG",
        "jpeg",
        "PNG",
        "png"
      ];
      this.cvUpload = this.uploadfile.fileUpload(
        e.target.files,
        extensionType,
        3017152
      );
      if (this.cvUpload.fileStatus == 1) {
        this.sendcvUpload(this.jsInfoId);
      }
    }
  }

  cvUploadName;
  sendcvUpload(id) {
    if (this.cvUpload.fileStatus == 1) {
      this.cvUpload.sendFile.append("jsInfoId", id);
      this.apiServiceService
        .uploadPhto(this.cvUpload.sendFile, "cv")
        .subscribe(response => {
          this.cvUploadName = response.fileName;
          this.bankJob.patchValue({ cv: this.cvUploadName });
        });
    }
  }
}

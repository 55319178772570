import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmployerApi } from 'src/app/common/services/employerApi.service';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employers-login',
  templateUrl: './employers-login.component.html',
  styleUrls: ['./employers-login.component.scss']
})
export class EmployersLoginComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private employerService: EmployerApi,
    private cookieService: CookieService,
    private route: Router,
    private cookie: CookieService,
    private toastr: ToastrService

  ) {


  }

  url;
  code;
  type: string = 'password';
  ngOnInit() {

    this.url = this.route.url;
    if (this.url.match('verify-email')) {

      console.log("We ar in email verification portion.");

      let data = this.url.split('=')
      this.code = data[1];
      this.verify();
    }
    this.initializeLoginForm();
  }

  changeType() {
    if (this.type == 'text')
      this.type = 'password';
    else
      this.type = 'text';
  }


  verify() {
    this.employerService.verifyEmai(this.code).subscribe(
      (res: any) => {
        // this.cookie.deleteAll();
        this.cookie.set('emailVerified', 'Y');
        this.toastr.success('Successful', 'Email verified Successfully.');
        this.cookie.deleteAll('/');

      },
      (error: any) => {
        this.toastr.error('Unsuccessful', error.error.errorMessage, {
          timeOut: 3000
        });
      }
    )
  }

  loginForm: FormGroup
  initializeLoginForm() {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]],
      grant_type: ['password']
    })
  }

  errorMsg: any;
  logging: boolean = false;
  login() {
    this.logging = true;
    const data = this.loginForm.value;
    const datas = 'grant_type=password&username=' + data.username + '&password=' + data.password;
    this.employerService.doLogin(datas).subscribe(
      (res: any) => {

        this.cookieService.set('at', res.access_token, 365, '/');
        this.cookieService.set('rt', res.refresh_token, 365, '/');

        this.getUserInfo();



      },
      (error: any) => {
        this.errorMsg = error.error.error_description;
        this.loginForm.controls.password.reset();
        this.logging = false;
        setTimeout(() => {
          this.errorMsg = null;
        }, 2000);
      }
    )
  }

  getUserInfo() {
    this.employerService.userInfo().subscribe(
      (res: any) => {

        this.logging = false;
        this.cookieService.set('userRole', res.role, 365, '/');
        if (res.role == "E") {
          this.cookieService.set('uR', "f2c1de33", 365, '/');
          this.cookieService.set('empId', res.empDetId, 365, '/');
          this.cookieService.set('rap', res.resumeAccessPlan ? res.resumeAccessPlan : 'N', 365, '/');
          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('addInfoType', res.contactInfo, 365, '/');
          this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
          this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');
          this.cookieService.set('tmpE', res.email, 365, '/');
          this.cookieService.set('username', res.companyName, 365, '/');
          if (res.emailVerified == 'N') {
            this.route.navigate(['/employer/employer-email-verification']);
          }
          else {
            if (res.contactInfo == 'Y')
              this.route.navigate(['/employer/dashboard'])

            if (res.contactInfo == 'N')
              this.route.navigate(['/employer/welcome'])
          }
        }
        else if (res.role == "SE") {
          this.cookieService.set('ar', res.adminType, 365, '/');
          this.cookieService.set('uR', "f2c1da34", 365, '/');
          if (res.adminType == "SUPERVISOR") {
            this.cookieService.set('uTy', "sr112", 365, '/');
          } else if (res.adminType == "MANAGER") {
            this.cookieService.set('uTy', "mr112", 365, '/');
          }
          else {
            this.cookieService.set('uTy', "an112", 365, '/');
          }
          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('username', res.email, 365, '/');
          this.route.navigate(['/admin/dashboard']);
        }
        else if (res.role == "JS") {
          this.cookieService.set('uR', "f2c1dj32", 365, '/');

          this.cookieService.set('userId', res.userDetailId, 365, '/');
          this.cookieService.set('emailVerified', res.emailVerified, 365, '/');
          this.cookieService.set('mobileVerified', res.mobileVerified ? res.mobileVerified : 'N', 365, '/');

          this.cookieService.set("username", res.firstName + ' ' + res.lastName, 365, '/');
          this.cookieService.set('tmpE', res.email, 365, '/');

          this.route.navigate(['/jobseeker/dashboard']);
        }

      }
    )
  }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }
}

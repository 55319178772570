<div class="container container-fixed-top">
  <!--White Box-->
  <div class="col-md-12 box">
    <!--Green header-->
    <p class="green_heading">Delete Account</p>

    <!--Links in top section-->
    <div class="col-md-12 des_box">
      <div class="col-md-12 sm_des">
        <div class="col-md-12 nopadding">
          <p>
            <b class="green_heading1">
              Your Privacy is Our Priority at Jobejee
            </b>
            <br /><br />
            At Jobejee, we highly value your trust and privacy. In line with
            privacy regulations and our commitment to transparency, we offer a
            simple way for you to request the deletion of your data directly
            through this page.
            <br /><br />

            <label class="sub_heading">
              How to Request Account Deletion:
            </label>
            <br />
            Step 1: Login To Your Jobejee Account <br />
            Step 2: Go to Setting -> Select Delete Account<br />
            Step 3: Enter Your Login Password to Confirm <br />Step 4: Click
            Delete Account Button <br /><br />
            Once your request is submitted, our team will have final
            confirmation and permanently delete your data from<br />
            your systems. Please note, this action is irreversible and will
            result in the deletion of your Jobejee account and all associated
            data. <br /><br />
            <label class="sub_heading"> What Happens Next? </label><br />
            Completion Timeframe: The process may take up to 7 days to complete.
            <br /><br />
            <label class="sub_heading"> Need Assistance? </label><br />
            If you have any questions or need help with your data privacy, feel
            free to reach out to us at
            <a class="green-color" href="mailto:support@jobejee.com">{{
              "support@jobejee.com"
            }}</a>
            . We’re here to assist you. <br /><br />
            <b class="green_heading1">
              Thank you for being a part of the Jobejee community. Your privacy
              and trust are our top priorities.</b
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

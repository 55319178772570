import { Component, OnInit, TemplateRef } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormControl,
} from "@angular/forms";
import { ApiServiceService } from "../../common/services/api-service.service";
import { map } from "rxjs/operators";
import { Uploadfile } from "../../common/function/upload";
import { RestApiService } from "../../common/services/rest-api.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Title, Meta } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { CookieService } from "ngx-cookie-service";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/timer";
import "rxjs/add/observable/of";
import "rxjs/add/operator/mapTo";
import "rxjs/add/operator/switchMap";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  constructor(
    private fbBUilder: FormBuilder,

    private apiServiceService: ApiServiceService,
    private uploadfile: Uploadfile,
    private restApiService: RestApiService,
    private router: Router,
    public meta: Meta,
    private modalService: BsModalService,
    public title: Title,
    private toastr: ToastrService,
    private cookie: CookieService
  ) {
    this.title.setTitle("JobSeeker Register | Jobejee");
    this.meta.updateTag({
      name: "keywords",
      content:
        "jobs in Nepal, jobsite in Nepal, job vacancy in Nepal, employment, recent job in nepal, IT jobs in Nepal, job opportunities in Nepal",
    });
    this.meta.updateTag({
      name: "description",
      content:
        "Jobejee is the fastest growing job portal in Nepal. Find your career opportunities here! Search among thousands of jobs from top companies, industries and locations of your choice!",
    });
    this.meta.updateTag({
      name: "url",
      content: "https://www.jobejee.com/jobseeker/register",
    });
  }
  configsm = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-captcha",
  };
  modalRef: BsModalRef;

  config1 = {
    displayKey: "categoryDesc", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: "200px", //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: "Select Desired Industry", // text to be displayed when no item is selected defaults to Select,
    customComparator: () => {}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: "more", // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: "No results found!", // text to be displayed when no items are found while searching
    searchPlaceholder: "Search",
    searchOnKey: "categoryDesc",
  };

  ngOnInit() {
    this.getIndustry();
    this.initializeRegisterForm();
  }
  captcha: any = null;
  getCaptcha(evt: any) {
    this.captcha = evt;
    if (this.captcha) {
      this.closeModal();
      this.registerCandidate();
    }
  }

  industryList: any = [];
  getIndustry() {
    this.apiServiceService.industriesList().subscribe((res: any) => {
      this.industryList = res.ind;
    });
  }

  candidateRegister: FormGroup;
  initializeRegisterForm() {
    this.candidateRegister = this.fbBUilder.group(
      {
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        gender: ["A", [Validators.required]],
        email: [
          null,
          [
            Validators.required,
            Validators.pattern(
              "^[a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,15})$"
            ),
          ],
          this.checkEmail.bind(this),
        ],
        password: [null, [Validators.required, Validators.minLength(5)]],
        confirmPassword: [null, [Validators.required, Validators.minLength(5)]],
        industry: [null],
        mobNo: [
          null,
          [
            Validators.required,
            Validators.pattern("[0-9]*"),
            Validators.minLength(10),
            Validators.maxLength(10),
          ],
        ],
        createdFrom: ["web"],
        platform: ["web"],
      },
      { validator: this.matchingPasswords("password", "confirmPassword") }
    );
  }

  matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
    return (group: FormGroup): { [key: string]: any } => {
      let password = group.controls[passwordKey];
      let confirmPassword = group.controls[confirmPasswordKey];

      if (password.value && confirmPassword.value) {
        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true,
          };
        }
      }
    };
  }

  // check() {

  //   let obj = this.candidateRegister.value;
  //   if (obj.firstName && obj.lastName && obj.email && obj.password && obj.mobNo)
  //     return true;
  //   else
  //     return false;

  // }

  checkEmail(control: FormControl) {
    return Observable.timer(1000).switchMap(() => {
      return this.apiServiceService
        .emailDuplicate(control.value)
        .mapTo(null)
        .catch((err) => Observable.of({ notAvailability: true }));
    });
  }

  validateEmailNotTaken(control: AbstractControl) {
    return this.apiServiceService.emailValidation(control.value).pipe(
      map((response) => {
        return response ? null : { emailTaken: true };
      })
    );
  }

  validatePhoneNotTaken(control: AbstractControl) {
    return this.apiServiceService
      .phoneValidation(
        control.value,
        this.candidateRegister.get("countryCode").value
      )
      .pipe(
        map((response) => {
          return response ? null : { phoneTaken: true };
        })
      );
  }

  processing: Boolean = false;
  registerCandidate() {
    this.processing = true;
    if (this.candidateRegister.valid) {
      let Obj = this.candidateRegister.value;
      // Obj.fullName = Obj.firstName + " " + Obj.lastName;

      if (Obj.industry) {
        Obj.desiredIndustryId = Obj.industry.categoryId;
      }

      delete Obj.industry;

      this.apiServiceService.registerCandidate(Obj).subscribe(
        (response: any) => {
          this.processing = false;
          this.cookie.set("tmpE", this.candidateRegister.value.email, 365, "/");
          this.cookie.set("emailVerified", "N", 365, "/");
          this.toastr.success("Successful", "Registered successfully.");
          this.router.navigate(["/jobseeker/jobseeker-email-verification"]);
          // this.router.navigate(['/jobseeker/login'])
        },
        (error: any) => {
          this.processing = false;

          this.toastr.error("Unsuccessful", error.errorMessage, {
            timeOut: 3000,
          });
        }
      );
    }
  }

  checkLoginStatus() {
    if (this.cookie.get("at")) return true;
    else return false;
  }

  closeModal() {
    this.modalRef.hide();
  }

  openModalSmall(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.configsm);
  }
}

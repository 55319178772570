<div class="container navbar-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}">
  <div class="row text-center" style="margin-top: 30px;">

  </div>

  <div class="row login_box ">
    <div class="col-md-12 res-nopadding">
      <div class="col-md-6 res-nopadding">
        <div class="col-md-12 login_box_left">
          <p class="main_heading">JOBSEEKER LOGIN</p>
          <p class="sub_heading">Already a registered member? Log in here </p>

          <div class="alert alert-danger text-center" role="alert" *ngIf="errorMsg">
            {{errorMsg}}
          </div>

          <form [formGroup]="loginForm">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" class="form-control" id="email" formControlName="username" placeholder="Email">
            </div>

            <div class="form-group" style="position: relative;">
              <label for="password">Password</label>
              <input type={{type}} class="form-control" id="password" formControlName="password" placeholder="Password">
              <span class="show-password" *ngIf="type=='text'" (click)="changeType()"><i class="fa fa-eye"
                  aria-hidden="true"></i></span>
              <span class="show-password" *ngIf="type=='password'" (click)="changeType()"><i class="fa fa-eye-slash"
                  aria-hidden="true"></i></span>
            </div>

            <div class="form-group text-right">
              <span for="password"><a class="red-link" [routerLink]="['/forget-password']"> Forget password ?
                </a></span>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-md-6">
                  <button class="btn green-btn" (click)="login()" [disabled]="loginForm.invalid" *ngIf="!logging">Log
                    In</button>
                  <button class="btn green-btn" [disabled]="true" *ngIf="logging">Logging in
                    <span class="s-spinner">
                      <span class="bounce1"></span>
                      <span class="bounce2"></span>
                      <span class="bounce3"></span>
                    </span>
                  </button>

                </div>

              </div>
            </div>
          </form>



          <br />
          <p>
            Don't have any account yet?
            <a href="" [routerLink]="['/jobseeker/register']" class="green-orange-link noDecoration">Create a new
              account</a>
            today !
          </p>
          <p class="note">
            <span class="focus_txt">Note:</span> We recommend you to logout after
            using Jobejee in case you are using a public/shared computer to
            prevent any un-authorized access to your account.
          </p>
        </div>
      </div>
      <div class="col-md-6 res-nopadding">
        <div class="col-md-12 login_box_right"><a
            href="https://play.google.com/store/apps/details?id=com.suvidhatech.application" target="_blank">
            <img src="../../../assets/images/jsloginimgv1.jpg" class="login_box_img" /></a>
        </div>
      </div>
    </div>
  </div>
  <br />
  <div class="row footer_img">
    <a href="https://www.the-network.com/" target="_blank"><img src="../../../assets/images/capture.png"
        class="login_box_img" /></a>
  </div>
</div>
<div
  class="container navbar-top"
  [ngClass]="{ 'container-fixed-top': checkLoginStatus() === true }"
>
  <div class="register_box">
    <div class="row">
      <div class="col-md-8">
        <div class="reg_form">
          <p class="heading">Create your free Jobseeker Account</p>
          <p>
            Register with basic information, complete your profile and start
            applying for jobs for free.
          </p>
          <br />
          <form [formGroup]="candidateRegister">
            <div class="row nopadding">
              <div
                class="form-group col-md-6"
                [ngClass]="{
                  'has-error':
                    candidateRegister.get('firstName').touched &&
                    candidateRegister.get('firstName').errors
                }"
              >
                <label for=""
                  >First Name <span class="txt-validation">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="firstName"
                  placeholder="Enter Your First Name"
                />
                <div
                  *ngIf="
                    candidateRegister.get('firstName').touched &&
                    candidateRegister.get('firstName').invalid
                  "
                >
                  <span
                    *ngIf="candidateRegister.get('firstName').errors.required"
                  >
                    First Name is required
                  </span>
                </div>
              </div>

              <div
                class="form-group col-md-6"
                [ngClass]="{
                  'has-error':
                    candidateRegister.get('lastName').touched &&
                    candidateRegister.get('lastName').errors
                }"
              >
                <label for=""
                  >Last Name <span class="txt-validation">*</span></label
                >
                <input
                  type="text"
                  class="form-control"
                  formControlName="lastName"
                  placeholder="Enter Your Last Name"
                />
                <div
                  *ngIf="
                    candidateRegister.get('lastName').touched &&
                    candidateRegister.get('lastName').invalid
                  "
                >
                  <span
                    *ngIf="candidateRegister.get('lastName').errors.required"
                  >
                    Last Name is required
                  </span>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Gender <span class="txt-validation">*</span></label
              ><br />

              <label style="font-weight: normal; margin-right: 25px">
                <input type="radio" value="M" formControlName="gender" /><span
                  class="radio_item cursor"
                  ><i class="fa fa-male"></i> Male</span
                >
              </label>

              <label style="font-weight: normal; margin-right: 25px">
                <input type="radio" value="F" formControlName="gender" /><span
                  class="radio_item cursor"
                  ><i class="fa fa-female"></i> Female</span
                >
              </label>

              <label style="font-weight: normal">
                <input type="radio" value="A" formControlName="gender" /><span
                  class="radio_item cursor"
                  ><i class="fa fa-neuter"></i> Do Not Disclose</span
                >
              </label>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-error':
                  candidateRegister.get('email').touched &&
                  candidateRegister.get('email').errors
              }"
            >
              <label for="">Email <span class="txt-validation">*</span></label>
              <input
                type="email"
                class="form-control"
                formControlName="email"
                placeholder="Enter Your Email Address"
                autocomplete="off"
              />

              <div
                *ngIf="
                  candidateRegister.get('email').touched &&
                  candidateRegister.get('email').invalid
                "
              >
                <span *ngIf="candidateRegister.get('email').errors.required">
                  Email is required
                </span>

                <span *ngIf="candidateRegister.get('email').errors.pattern"
                  >Invalid Email. Email format should be
                  <b> hello@gmail.com</b>
                </span>

                <span
                  *ngIf="
                    candidateRegister.get('email').errors['notAvailability']
                  "
                  >Sorry! Email already exist.
                </span>
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-error':
                  candidateRegister.get('password').touched &&
                  candidateRegister.get('password').errors
              }"
            >
              <label for=""
                >Password <span class="txt-validation">*</span></label
              >
              <input
                type="password"
                class="form-control"
                formControlName="password"
                placeholder="Enter Password"
                autocomplete="off"
              />
              <div
                *ngIf="
                  candidateRegister.get('password').touched &&
                  candidateRegister.get('password').invalid
                "
              >
                <span *ngIf="candidateRegister.get('password').errors.required">
                  Password is required
                </span>
                <span *ngIf="candidateRegister.get('password').errors.minlength"
                  >Password should be minimum 5 character.</span
                >
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-error':
                  candidateRegister.get('confirmPassword').touched &&
                  candidateRegister.get('confirmPassword').errors
              }"
            >
              <label for=""
                >Confirm Password <span class="txt-validation">*</span></label
              >
              <input
                type="password"
                class="form-control"
                formControlName="confirmPassword"
                placeholder=" Confirm Password"
                autocomplete="off"
              />
              <div
                *ngIf="
                  candidateRegister.get('confirmPassword').touched &&
                  candidateRegister.get('confirmPassword').invalid
                "
              >
                <span
                  *ngIf="
                    candidateRegister.get('confirmPassword').errors.required
                  "
                >
                  Password is required
                </span>
                <span
                  *ngIf="
                    candidateRegister.get('confirmPassword').errors.minlength
                  "
                  >Password should be minimum 5 character.</span
                >
              </div>

              <div
                *ngIf="
                  candidateRegister.hasError('mismatchedPasswords') &&
                  candidateRegister.controls.password.valid &&
                  candidateRegister.controls.confirmPassword.valid
                "
                class="has-error"
              >
                <span>Passwords doesn't match.</span>
              </div>
            </div>

            <div class="form-group">
              <label for=""
                >Mobile Number <span class="text-validation">*</span></label
              >
              <div class="row">
                <div
                  class="col-md-12"
                  [ngClass]="{
                    'has-error':
                      candidateRegister.get('mobNo').touched &&
                      candidateRegister.get('mobNo').errors
                  }"
                >
                  <input
                    type="text"
                    class="form-control"
                    minlength="10"
                    maxlength="10"
                    placeholder="Enter Mobile Number"
                    formControlName="mobNo"
                    required
                  />

                  <div
                    *ngIf="
                      candidateRegister.get('mobNo').touched &&
                      candidateRegister.get('mobNo').invalid
                    "
                  >
                    <span *ngIf="candidateRegister.get('mobNo').errors.required"
                      >Mobile Number is required</span
                    >

                    <span *ngIf="candidateRegister.get('mobNo').errors.pattern"
                      >Invalid Mobile Number</span
                    >
                    <span
                      *ngIf="
                        !candidateRegister.get('mobNo').errors.pattern &&
                        candidateRegister.get('mobNo').errors.minlength
                      "
                      >Mobile Number should be minimum 10 digit.</span
                    >
                    <span
                      *ngIf="
                        !candidateRegister.get('mobNo').errors.minlength &&
                        candidateRegister.get('mobNo').errors.maxlength
                      "
                      >Mobile Number should be maximum 10 digit.</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-group"
              [ngClass]="{
                'has-error':
                  candidateRegister.get('industry').touched &&
                  candidateRegister.get('industry').errors
              }"
            >
              <div class="col-md-12 mt-9 nopadding">
                <label for="industry">Desired Industry</label>
              </div>

              <div class="col-md-12 nopadding" tabindex="2">
                <ngx-select-dropdown
                  [config]="config1"
                  [options]="industryList"
                  formControlName="industry"
                  value="categoryId"
                  [multiple]="false"
                >
                </ngx-select-dropdown>
              </div>
            </div>

            <!-- Agreement -->
            <div class="col-md-12 nopadding mt-25">
              <label style="font-weight: normal">
                <!-- <input type="checkbox" formControlName='agree' tabindex="7"> -->
                <span
                  style="
                    vertical-align: text-bottom;
                    cursor: pointer;
                    font-weight: normal;
                  "
                >
                  By clicking on 'Register Now' below you are agreeing to the
                  <span class="tc"
                    ><a
                      class="green-link noDecoration"
                      [routerLink]="['/terms-and-conditions']"
                      >Terms and Conditions</a
                    ></span
                  >
                  and
                  <span class="tc"
                    ><a
                      class="green-link noDecoration"
                      [routerLink]="['/privacy-policies']"
                      >Privacy Policy</a
                    ></span
                  >
                  governing the use of Jobejee.</span
                ></label
              >

              <span> </span>
            </div>

            <div class="row" style="text-align: center">
              <div class="col-md-12">
                <button
                  class="btn reg_btn"
                  (click)="openModalSmall(captchaOpen)"
                  [disabled]="candidateRegister.invalid"
                  *ngIf="!processing"
                >
                  Register Now
                </button>
                <button
                  class="btn reg_btn"
                  [disabled]="true"
                  *ngIf="processing"
                >
                  Registering
                  <span class="s-spinner">
                    <span class="bounce1"></span>
                    <span class="bounce2"></span>
                    <span class="bounce3"></span>
                  </span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="footer col-md-12">
          <div class="col-md-8 content">
            <p class="description">
              Are you facing a problem?
              <span class="focus">We are here to help.</span>
            </p>
            <label class="phone"
              ><i
                class="fa fa-volume-control-phone btn-icon"
                aria-hidden="true"
              ></i>
              <a href="tel:01-4390596" class="green-link noDecoration">
                : 01-4390596</a
              ></label
            >
            <p>or email us at:</p>
            <label class="phone"
              ><i class="fa fa-envelope-o btn-icon" aria-hidden="true"></i>
              <a
                href="mailto:support@jobejee.com"
                class="green-link noDecoration"
              >
                support@jobejee.com</a
              ></label
            >
          </div>
          <div class="col-md-4 img_container">
            <img src="../../assets/images/contact-us.jpg" class="image" />
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="side_bar">
          <div class="header_img">
            <a
              href="https://play.google.com/store/apps/details?id=com.suvidhatech.application"
              target="_blank"
            >
              <img src="../../assets/images/js-register.jpg" class="des_img" />
            </a>
          </div>
          <div class="description">
            <label class="heading"
              ><i class="fa fa-search btn-icon" aria-hidden="true"></i> Search
              and Apply for Jobs with Ease!</label
            >
            <p class="top-margin">
              Are you tired of scouring through thousands of jobs in all
              newspapers and websites? Jobejee lets you search for the most
              suitable job for you with its Advanced Search Algorithm. Now, you
              can search jobs based on your skills, desired industry,
              designation and even location! What’s more? You can apply for your
              jobs with just a single click!
            </p>

            <!-- <label class="heading"><i class="fa fa-money btn-icon" aria-hidden="true"></i> Estimated Salary
              Benchmark</label>
            <p>Don’t know how much salary you should ask for? Jobejee can give you your estimated earning potential for
              your
              designation in your industry.</p> -->

            <label class="heading"
              ><i class="fa fa-briefcase btn-icon" aria-hidden="true"></i> Job
              Recommendations</label
            >
            <p class="top-margin">
              No need to look through numerous newspapers and job portals and
              search among thousands of jobs. Let us send you jobs that you are
              qualified for and interested in!
            </p>

            <label class="heading"
              ><i class="fa fa-bell-o btn-icon" aria-hidden="true"></i>
              Customized Job Alerts</label
            >
            <p class="top-margin">
              Tell us your criteria for your perfect job with our customizable
              Job Alerts and we will notify you when they are available.
            </p>

            <label class="heading"
              ><i class="fa fa-level-up btn-icon" aria-hidden="true"></i>
              Applicant Ranking</label
            >
            <p class="top-margin">
              With Jobejee, you will have an advantage of knowing where you rank
              among other applicants. With this knowledge, you know where you
              need to improve and how high your chances of getting the job is!
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<ng-template #captchaOpen>
  <div class="modal-body">
    <app-captcha (captchaResponse)="getCaptcha($event)"></app-captcha>
  </div>
</ng-template>

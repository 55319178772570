<div class="container-fluid container-fixed-top-nav">
    <div class="col-lg-6 col-lg-offset-3 col-md-6 col-md-offset-3 col-sm-12 col-xs-12 wt-box">
        <p class="header">Confirm Password</p>
        <form [formGroup]="confirmForm" (ngSubmit)="onSubmit()">
            <div class="co-lmd-12 nopadding form-group">
                <label for="newPwd">New Password <span class="red-text">*</span></label>
                <input type="password" id="newPwd" formControlName="newPassword" placeholder="Enter new password"
                    class="form-control">
                <div class="invalid-feedback"
                    *ngIf="confirmForm.controls.newPassword.touched && confirmForm.controls.newPassword.invalid">
                    <span *ngIf="confirmForm.controls.newPassword.errors.required">Password is required.
                    </span>
                    <span *ngIf="confirmForm.controls.newPassword.errors.minlength">Password should be
                        minimum 8
                        characters long. </span>
                </div>
            </div>

            <div class="col-md-12 nopadding form-group">
                <label for="confirmPwd">confirm Password <span class="red-text">*</span></label>
                <input type="password" id="confirmPwd" placeholder="Confirm new password" formControlName="confirmPassword" class="form-control">


                <div class="invalid-feedback"
                    *ngIf="confirmForm.controls.confirmPassword.touched && confirmForm.controls.confirmPassword.invalid">
                    <span *ngIf="confirmForm.controls.confirmPassword.errors.required">Password is
                        required.
                    </span>
                    <span *ngIf="confirmForm.controls.confirmPassword.errors.minlength">Password should be
                        minimum 8
                        characters long. </span>
                  
                </div>
                <!-- <span *ngIf="confirmForm.controls.confirmPassword.errors.match">Password doesn't match.</span> -->

                <div *ngIf="confirmForm.hasError('mismatchedPasswords') && confirmForm.controls.newPassword.valid && confirmForm.controls.confirmPassword.valid "
                    class="invalid-feedback">
                    <span>Password doesn't match.</span>
                </div> 


            </div>
            <button class="btn green-btn" [disabled]="confirmForm.invalid || processing" type="submit">Confirm</button>
        </form>
        <p class="info-txt"><span class="orange-text">Note : </span>Enter the email address you have used to
            register your account with
            Jobejee. You will receive an email with which you can change your password.</p>
    </div>
</div>
<div class="container container-fixed-top">
  <div class="col-md-12 box">

    <div class="row">


    <!--Main heading-->
    <p class="main_heading">Privacy policies</p>

    <!--Description-->
    <div class="col-md-12 des_box">
      <p class="justify">
        The Privacy Policy (hereinafter "the policy") provided below is with respect to our use and protection of any
        personal information/data you provide to us through our website www.jobejee.com (hereinafter "website"). The
        Policy is applicable to the website jobejee.com. You may be required to provide personally identifiable
        information at several different points on our website. By accepting the policy at the time of registration, you
        expressly approve and consent to our collection, storage, use and disclosure of your personal information as
        described in this Policy and terms and conditions.
      </p>
    </div>


    <!--Information Collection-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Information Collection:</label>
      <p class="justify">
        When you visit/surf our website, certain personal information about you such as your IP Address, etc. may be
        automatically stored with us. However, if you choose to avail of certain services on our website, you shall be
        required to provide certain personal information for the registration and/or access to such services/web pages.
        Such information may include, without limitation, your name, image, email address, contact address, mobile/telephone
        number(s), sex, age, occupation, interests, credit card information, billing information, financial information,
        content, IP address, standard web log information, information about your computer hardware and software and
        such other information as may be required for your interaction with the services and from which your identity is
        discernible. We may also collect demographic information that is not unique to you such as code, preferences,
        favorites, etc. Further, sometimes you may be asked to provide descriptive, cultural, preferential and social &
        life style information. We may also hold information related to our customer’s utilization of our services the use of our mobile / Web app which may include the following:
        User Camera / Gallery Photo in profile picture. This feature is used to for updating profile pictures within the app. All the data are saved throught https://api.v1.jobejee.com Api 
      </p>
      <p class="justify">In addition to the above we may indirectly also collect information about you when you use
        certain third party
        services available on our website. We may also collect certain information about the use of our website by you,
        such as the services you access/use or areas you visit.</p>
    </div>


    <!--Cookies and Other Tracking Technologies-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Cookies and Other Tracking Technologies:</label>
      <p class="justify">
        Some of our Web pages utilize "cookies" and other tracking technologies. A "cookie" is a small text file that
        may be used, for example, to collect information about Web site activity. Some cookies and other technologies
        may serve to recall Personal Information previously indicated by a Web user. Most browsers allow you to control
        cookies, including whether or not to accept them and how to remove them.
      </p>
      <p class="justify">
        You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your
        browser, but please note that if you choose to erase or block your cookies, you will need to re-enter your
        original user ID and password to gain access to certain parts of the Web site and App.
      </p>
      <p class="justify">
        Tracking technologies may record information such as Internet domain and host names; Internet protocol (IP)
        addresses; browser software and operating system types; clickstream patterns; and dates and times that our site
        is accessed. Our use of cookies and other tracking technologies allows us to improve our Web site and your Web
        experience. We may also analyze information that does not contain Personal Information for trends and
        statistics.
      </p>
    </div>


    <!--Use-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Use:</label>
      <p class="justify">
        If you choose to provide us with the above mentioned information, you consent to the use, transfer, processing
        and storage of the information so provided by you on our servers. The information provided by you shall not be
        given to third parties (third parties for this purpose do not include our group / holding / subsidiary companies
        and or our service partners / associates) for marketing purposes and other related activities without your prior
        consent. Though if you choose to make your profile visible, all information pertaining to your profile will be
        visible to third parties.
      </p>
      <ol class="list">
        <li>Improve our website and enable us to provide you the most user-friendly experience which is safe, smooth and
          customized;</li>
        <li>Improve and customize our services, content and other commercial / non-commercial features on the website;
        </li>
        <li>Contact you to get your opinion on our current products and services or possible new products and/or
          services that may be offered by us;</li>
        <li>Send you information on our products, services, special deals, promotions;</li>
        <li>Ask you to send feedback on any or all of our services;</li>
        <li>Send you marketing/promotional communications;</li>
        <li>Create various surveys and analysis in form of reports;</li>
        <li>Send newsletter(s) to you;</li>
        <li>Send you service-related announcements on rare occasions when it is necessary to do so;</li>
        <li>Provide you the opportunity to participate in contests or surveys on our website (If you participate, we may
          request certain additional personally identifiable information from you. Moreover, participation in these
          surveys or contests is shall be completely voluntary and you therefore shall have a choice whether or not to
          disclose such additional information);</li>
        <li>Contact you or send you e-mail or other communications regarding updates on our website, new job
          opportunities, services and additional/new job postings/openings which may be of your interest;</li>
        <li>Provide customer support and the services you request;</li>
        <li>Resolve disputes, if any and troubleshooting;</li>
        <li>Avoid/check illegal and/or potentially prohibited activities and to enforce Agreements;</li>
        <li>Provide service updates and promotional offers related to our services/products;</li>
        <li>Comply with any court judgment / decree / order / directive / legal &amp; government authority /applicable
          law;</li>
        <li>Investigate potential violations or applicable national &amp; international laws;</li>
        <li>Investigate deliberate damage to the website/services or its legitimate operation;</li>
        <li>Detect, prevent, or otherwise address security and/or technical issues;</li>
        <li>Protect the rights, property or safety of HTML and/or its Directors, employees andthe general public at
          large;</li>
        <li>Respond to Claims of third parties;</li>
        <li>The members of our corporate family and group, affiliates, service providers and third parties under a
          contract to provide joint services, contents and marketing communications;
        </li>
        <li>Other third parties to whom you explicitly require us to send the information;</li>
      </ol>
      <p class="justify">
        If you consent to receive information about third party (such as employers, recruiters, data aggregators,
        marketers or others) opportunities, products or services/job opportunities or you make your profile visible we
        may disclose your information to such third parties for sending you communications via e-mail or otherwise to
        inform about new opportunities/services/products and new job postings/openings which may be of your interest.
        Also we might use your personal information to communicate with you and ascertain if you are interested in third
        party services.
      </p>
      <p class="justify">Your information shall be disclosed to our employees/service providers who provide services to
        us vis-a-vis our website and the services/products therein. These service providers/employees will have access
        to the information provided by you in order to perform their functions/services efficiently.</p>
      <p class="justify">If you choose to use our referral service to tell a friend about our website or refer a job
        posting/service, we will ask you for your friend's name and email address. We will automatically send your
        friend an email inviting him or her to visit the website. However, your friend may contact us to request that he
        does not require such services/content/email or we remove the information related to him from our database.</p>
      <p class="justify">Based upon the personally identifiable information you provide us, we will send you a welcoming
        email to verify your username and password. We will also communicate with you in response to your inquiries with
        respect to our services/content, to provide the services you request, and to manage your account. We will
        communicate with you by email or telephone, in accordance with your wishes. We will also send you SMS alerts
        from time to time to update you on new matched jobs and other updates.</p>
      <p class="justify">In order to provide certain services to you, we may on occasion supplement the personal
        information you submitted to us with information from third party sources (third parties for this purpose may
        include our group / holding / subsidiary companies and or our service partners /associates). We reserve the
        right to disclose your personally identifiable information as required by law and when we believe that
        disclosure is necessary to protect our rights and/or to comply with a judicial proceeding, court order, or legal
        process served on our website.</p>
    </div>



    <!--Resume Information-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Resume Information:</label>
      <p class="justify">
        Based on the profile status selected by you, your resume shall be accessible to potential employers. You can
        store your resume on our servers and make it searchable by third parties/employers/recruiters. You may only use
        it for applying online though our website and denying access to others.
      </p>
      <p class="justify">
        If your profile status is visible, your resume is searchable and you make it non-confidential and visible to all
        and in such a case anyone can have access to your resume. If you keep your profile status anonymous, you opt to
        keep the resume confidential and then only those will have access to it to which you have applied for a
        job/opening.
      </p>
      <p class="justify">
        We cannot guarantee that third parties shall gain access to your resume neither we are responsible for the use
        made of the resume by third parties to who you apply or who have access to our database, while your profile
        status has been made visible by you. In case of making your profile status visible, we are not responsible for
        the privacy, secrecy or retention of your resume.
      </p>
      <p class="justify">
        You may edit, update and remove your resume at any time but parties who have access to our database (while your
        resume or profile was visible) may have retained a copy of your resume in their own files. We cannot be held
        responsible for such retention/storage. You should always remember that once you post your resume on our website
        with your profile visible, anybody will have access to the same and we do not have any control over such access
        and use of your resume/information by third parties. In case your profile status is anonymous and you apply for
        a job on our site, the prospective employer will also be able to retain a copy of your Resume and Profile.
      </p>
    </div>



    <!--Changing Your Personal Information-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Changing Your Personal Information:</label>
      <p class="justify">
        You can access and modify your personal information by signing on to the website. We will not modify the
        information provided by you. However, we recommend that you update your personal information as soon as such
        changes are necessitated.
      </p>
      <p class="justify">
        Where you make a public posting, you may not be able to change or remove it nor shall you be able to close your
        account. Upon your request, we will close your account and remove your personal information from view as soon as
        reasonably possible, based on your account activity and in accordance with applicable law(s). However, we will
        retain your personal information from closed accounts to comply with law, Avoid/check illegal and/or potentially
        prohibited activities and to enforce User Agreements; Comply with any court judgment / decree / order /
        directive / legal & government authority /applicable law; Investigate potential violations or applicable
        national & international laws; Investigate deliberate damage to the website/services or its legitimate
        operation; Detect, prevent, or otherwise address security and/or technical issues; Protect the rights, property
        or safety of HTML and/or its Directors, employees and the general public at large; Respond to Claims of third
        parties; and take such other actions as may be permitted by law.
      </p>
    </div>



    <!--Account Protection-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Account Protection:</label>
      <p class="justify">
        Your password is the key to your account. You shall be solely responsible for all the activities happening under
        your username and you shall be solely responsible for keeping your password secure. Do not disclose your
        password to anyone. If you share your password or your personal information with others, you shall be solely
        responsible for all actions taken under your username and you may lose substantial control over your personal
        information and may be subject to legally binding actions taken on your behalf. Therefore, if your password has
        been compromised for any reason, you should immediately change your password.
      </p>
    </div>


    <!--Business Transaction-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Business Transaction:</label>
      <p class="justify">
        In the event HTML goes through a business transition, such as a merger, acquisition by another company, or sale
        of all or a portion of its assets, your personally identifiable information will likely be among the assets
        transferred. Where your information is transferred you will be notified via email/prominent notice on our
        website for 30 days of any such change in ownership or control of your personal information.
      </p>
    </div>


    <!--Security-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Security:</label>
      <p class="justify">
        The security of your personal information is important to us. When you enter your personal information we treat
        the data as an asset that must be protected and use tools (encryption, passwords, physical security, etc.) to
        protect your personal information against unauthorized access and disclosure. However, no method of transmission
        over the Internet, or method of electronic storage, is 100% secure, therefore, while we strive to use
        commercially acceptable means to protect your personal information, we cannot guarantee its absolute security
        nor can we guarantee that third parties shall not unlawfully intercept or access transmissions or private
        communications, and that other users may abuse or misuse your personal information that you provide. Therefore,
        although we work hard to protect your information, we do not promise, and you should not expect, that your
        personal information or private communications will always remain private.
      </p>
    </div>


    <!--Links to Other Sites-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Links to Other Sites:</label>
      <p class="justify">
        The website contains links to other sites that are not owned or controlled by us. Please be aware that we and/or
        the website, are not responsible for the privacy practices of such other sites.
      </p>
      <p class="justify">
        We encourage you to be aware when you leave our site and to read the privacy statements of each and every Web
        site that collects personally identifiable information.
      </p>
      <p class="justify">
        This privacy statement applies only to information collected by the website or to our other related websites
        provided it appears at the footer of the page therein. It does not apply to third party advertisements which
        appear on our websites and we suggest you read the privacy statements of such advertisers.
      </p>
    </div>


    <!--Changes in this Privacy Statement-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Changes in this Privacy Statement</label>
      <p class="justify">
        If we decide to change our privacy policy, we will post those changes to this privacy statement and such other
        places we deem appropriate so that you are updated about the manner we collect information, what information we
        collect, how we use it and under what circumstances, if any, we disclose it.
      </p>
      <p class="justify">
        We reserve the right to modify this privacy statement at any time, so please review it frequently our website
      </p>
    </div>


    <!--DISCLAIMER-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">DISCLAIMER</label>
      <p class="justify">
        HTML does not store or keep credit card data in a location that is accessible via the Internet. Once a credit
        card transaction has been completed, all credit card data is moved off-line only to ensure that the data/credit
        card information received is not accessible to anyone after completion of the on-line transaction and to ensure
        the maximum security. HTML uses the maximum care as is possible to ensure that all or any data / information in
        respect of electronic transfer of money does not fall in the wrong hands.
      </p>
      <p class="justify">
        HTML shall not be liable for any loss or damage sustained by reason of any disclosure (inadvertent or otherwise)
        of any information concerning the user's account and / or information relating to or regarding online
        transactions using credit cards / debit cards and / or their verification process and particulars nor for any
        error, omission or inaccuracy with respect to any information so disclosed and used whether or not in pursuance
        of a legal process or otherwise.
      </p>
    </div>

    <!--Contact Us-->
    <div class="col-md-12 des_box">
      <label class="sub_heading">Contact Us</label>
      <p class="justify">
        If you have any questions or suggestions regarding our privacy policy, please contact us.
      </p>
    </div>
  </div>
</div>
</div>

<app-footer></app-footer>

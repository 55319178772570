import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    public meta: Meta, public title: Title,
  ) { }

  ngOnInit() {
    this.title.setTitle("Privacy Policies | Jobejee");
    this.meta.updateTag({ name: 'keywords', content: "jobs in Nepal, jobsite in Nepal, job vacancy in Nepal, employment, recent job in nepal, IT jobs in Nepal, job opportunities in Nepal" });
    this.meta.updateTag({ name: 'description', content: "Jobejee is the fastest growing job portal in Nepal. Find your career opportunities here! Search among thousands of jobs from top companies, industries and locations of your choice!" });
    this.meta.updateTag({ name: 'url', content: "https://www.jobejee.com/privacy-policies" });
  }

}

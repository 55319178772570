<div class="col-sm-12 col-md-12">
  <div class="container-fluid container-fixed-top">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <h1 style="color: #000; text-align: center; margin: 50px;">Verifying Email...
        </h1>
      </div>
    </div>
  </div>
</div>

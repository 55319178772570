<div class="container container-fixed-top">
  <div class="row">

    <div class="col-md-6 wt-box">
      <!-- Title -->
      <p class="loginText">Forgot Password</p>

      <!-- Form -->
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="row">
          <!--Email Feild-->
          <div class="col-md-12 form-group">
            <input type="email" class="form-control" placeholder="Email Address" formControlName="email">
          </div>

          <div class="errors" *ngIf="form.controls.email.touched && form.controls.email.invalid">
                <span *ngIf="form.controls.email.errors.required"> Email is required
                </span>

                <span *ngIf="form.controls.email.errors.pattern">Invalid Email. Email format should be
                  <b> alex10@gmail.com</b>
                </span>

                <div *ngIf="form.controls.email.errors && form.controls.email.errors.emailTaken">
                  😢 Oh noes, this email is already taken!
                </div>
              </div>

          <!-- Button -->
          <div class="col-md-12 form-group">
            <button class="btn btn-green" type="submit" [disabled]="!this.form.valid">Send Email</button>
          </div>
        </div>
      </form>

      <!-- Note text -->
      <p><span class="bold-org-txt">Note : </span> Enter the email address you have used to register your account with
        Jobejee. You will receive an email with which you can change your password.</p>
    </div>
  </div>
</div>

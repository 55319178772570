import { Component, OnInit, Output, EventEmitter } from "@angular/core";
@Component({
  selector: "app-captcha",
  templateUrl: "./captcha.component.html",
  styleUrls: ["./captcha.component.scss"],
})
export class CaptchaComponent implements OnInit {
  constructor() {}
  @Output() captchaResponse = new EventEmitter<string>();
  ngOnInit(): void {}

  resolved(res: any) {
    this.captchaResponse.emit(res);
  }
}

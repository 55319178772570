<ng-template #template>
    <div class="modal-header top-poper-heading">
        <h4 class="modal-title pull-left">Mobile Verification</h4>
        <span type="button" class="cursor pull-right" (click)=" closeModal()">
            <span aria-hidden="true">&times;</span>
        </span>
    </div>

    <div class="modal-body ">

        <div [ngClass]="{'visibilityClass': mobileStep=='second'}">
            <div class="row">
                <div class="col-md-12">
                    <p>Do you want to proceed to Mobile Verification ?</p>
                </div>

                <div class="col-md-12 mt-15" >

                    <div class="col-md-6 nopadding">

                        <button class="btn-link" [routerLink]="[ '/jobseeker/change-mobileNumber' ]" (click)="closeModal()">
                            Change Mobile Number </button>
                    </div>

                    <div class="col-md-6 nopadding text-right">

                        <button class="btn green-btn" style="margin-right: 15px;" (click)="proceedForVerification()">
                            Confirm </button>

                        <button class="btn cancel" (click)="closeModal()">
                            Cancel </button>

                    </div>




                </div>
            </div>
        </div>

        <div [ngClass]="{'visibilityClass': mobileStep=='first'}">
            <div class="row otp">
                <div class="col-md-12">
                    <alert type="danger" *ngIf="errorMsg">
                        <span>{{errorMsg}}</span>
                    </alert>
                    <alert type="success" *ngIf="successMsg">
                        <span>{{successMsg}}</span>
                    </alert>
                </div>

                <div class="col-md-12">
                    <!-- <p class="title">Verifying your Mobile Number </p> -->
                    <p style="line-height: 10px;">Enter 6 digit code we sent to</p>
                    <p style="color:#0090e7; line-height: 10px;">+977- {{mobileNo}}</p>



                </div>


                <!-- <div class="row "> -->
                <div class="col-md-12 text-center ">

                    <p style="line-height: 10px; text-align: left;">6-Digit code</p>

                    <input type="text" class="form-control input-class" [(ngModel)]="otpCode" maxlength="6"
                        onkeyup="if(this.value.length == 6) this.blur()">
                </div>

                <div class="col-md-12  text-right mt-15 button-section">
                    <span class="btn-cancel" (click)="closeModal()">CANCEL</span>
                    <button [disabled]="!otpCode" class="btn btn-green " (click)="verifyCode(mobileNo)"
                        style="margin-left: 15px" *ngIf="enableVerify">VERIFY</button>
                    <button [disabled]="true" class="btn btn-green " style="margin-left: 15px"
                        *ngIf="!enableVerify">VERIFYING...</button>
                    <!-- <ng-template #next>
                <span class="secondary-btn form-disabled">VERIFY</span>
              </ng-template>  -->
                </div>

                <div class="col-md-12 footer-section mt-25">
                    <p class="note"><i>Note: It may take few minutes to get verification code</i></p>
                    <div>
                        <span id="timer"></span>
                    </div>

                    <span class="resend" (click)="getVerificationCode(mobileNo); initializeVerification();timer()"
                        *ngIf="!disableResend">RESEND
                        CODE</span>
                    <span class="resendDisable" *ngIf="disableResend" style="color:#808080">RESEND
                        CODE</span>

                    <!-- 
              <span class="resend"
                (click)="getVerificationCode(registerForm.get('mobileNo').value); initializeVerification();timer()">RESEND
                CODE</span> -->
                </div>

            </div>
        </div>

    </div>
</ng-template>
import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { Meta, Title } from '@angular/platform-browser';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-new-company-profile',
  templateUrl: './new-company-profile.component.html',
  styleUrls: ['./new-company-profile.component.scss']
})
export class NewCompanyProfileComponent implements OnInit {

  loader: boolean = true;
  clientId: string;
  clientName: string


  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };

  config1 = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "modal-lg"
  };

  modalRef: BsModalRef;


  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  openModalFixed(templateFixed: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateFixed, this.config);
  }

  openModalBank(templateBank: TemplateRef<any>) {
    this.modalRef = this.modalService.show(templateBank, this.config1);
  }

  constructor(
    private apiServiceService: ApiServiceService,
    private route: ActivatedRoute,
    private router: Router,
    public rest: RestApiService,
    public meta: Meta, public title: Title,
    private modalService: BsModalService,
    private cookie: CookieService,


  ) {
    this.route.params.subscribe(params => {
      this.clientId = params['clientId'];
      this.clientName = params['clientName'];
      this.title.setTitle(this.clientName + " - Job vacancy in Nepal" + " | " + "Jobejee");

      this.meta.updateTag({ property: 'og:title', content: this.clientName + " - Job vacancy in Nepal" + " | " + "Jobejee" });
      this.meta.updateTag({ property: 'og:site_name', content: "jobejee" });
      this.meta.updateTag({ property: 'og:type', content: "website" });
      this.meta.updateTag({ property: 'fb:app_id', content: "2458134394205611" });
      this.meta.updateTag({ property: 'og:url', content: "https://www.jobejee.com" + this.router.url });

      this.getCompanyDetail();
      this.getSimilarCompany();
    })
  }


  replaceSpace(value) {
    let str = value.replace(/\s+/g, '-');
    return str;
  }

  userId: any;
  ngOnInit() {
    if (this.cookie.get('userId'))
      this.userId = this.cookie.get('userId');
  }


  clientData: any;
  jobSearchList = [];

  job: any = [];
  getCompanyDetail() {
    let Obj = {
      employerDetailId: this.clientId
    }

    this.apiServiceService.newClientDetails(Obj).subscribe(
      (response: any) => {
        this.clientData = response.empDet;

        // this.jobSearchList = this.clientObj.employersJobViewsModel.jobList;
        // this.loader = false;
        this.job = response.jobs;
        this.loader = false;
      }
    )
  }

  similarJobs: any = [];
  getSimilarCompany() {
    this.apiServiceService.similarCompany(this.clientId).subscribe(
      (res: any) => {
        this.similarJobs = res.data;
      }
    );
  }

  getSkills(skill) {
    var skills = skill;
    return skills.split(',');
  }


  // Bookmark section

  bookmarkJobId: any;
  jobIndex: any;
  bookmarkJob(data, index) {

    //find the index of object from array that you want to update
    const objIndex = index

    const updatedObj = { ...this.job[objIndex], jobBookmark: data.jobBookmark == 'Y' ? 'N' : 'Y' };
    const updatedProjects = [
      ...this.job.slice(0, objIndex),
      updatedObj,
      ...this.job.slice(objIndex + 1),
    ];
    this.job = updatedProjects;
    // if (data.bookmark == 'N')
    //   this.toastr.success('Successful', 'Added to Bookmark.');
    // else
    //   this.toastr.success('Successful', 'Removed from Bookmark.');

    this.apiServiceService.saveBookmark(data.jobCreateId).subscribe(
      (res: any) => {
      }
    );
  }

  setBookmarkId(id) {
    this.bookmarkJobId = id;
    this.rest.bookmarkJobId = id;
    this.rest.hideBookmarkModal = 0;
  }



  closeModal() {
    this.modalRef.hide();
  }

  showBookmark(data, i) {
    if (this.rest.hideBookmarkModal == 1) {
      this.modalRef.hide();
      this.userId = this.cookie.get('userId');
      // Write codes here
    }
    if (this.rest.hideBookmarkModal == 2) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['jobseeker/register'])
    }
    if (this.rest.hideBookmarkModal == 3) {
      this.modalRef.hide();
      this.rest.hideBookmarkModal = 0;
      this.router.navigate(['forget-password'])
    }

    if (this.rest.savedJobId == data.jobCreateId) {

      const objIndex = i

      const updatedObj = { ...this.job[objIndex], jobBookmark: data.jobBookmark == 'Y' ? 'N' : 'Y' };
      const updatedProjects = [
        ...this.job.slice(0, objIndex),
        updatedObj,
        ...this.job.slice(objIndex + 1),
      ];
      this.job = updatedProjects;
      this.rest.savedJobId = null;
      this.getCompanyDetail();

    }




  }

  checkUrl(url: any) {
    if (url.includes('https')) {
      return url;
    }
    else {
      let newurl = 'https://' + url;
      return newurl;
    }
  }

  
  // splitcompany(value, i) {
  //   let splitData = value.split(" (");
  //   if (i == 0) {
  //     let str = splitData[0].replace(/\s+/g, "-");
  //     return str;
  //   } else if (i == 1) {
  //     return splitData[1];
  //   }
  // }

  checkLoginStatus() {
    if (this.cookie.get('at'))
      return true;
    else
      return false;
  }

  

}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { UniversalService } from 'src/app/common/services/universal.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';


@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.scss']
})
export class BillingListComponent implements OnInit {

  loader: boolean = true;

  config1 = {
    displayKey: "companyName", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Company Name', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { },// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search',
    searchOnKey: 'companyName',

  }
  config2 = {
    displayKey: "bank_name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Bank Name', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { },// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search',
    searchOnKey: 'bank_name',

  }
  config3 = {
    displayKey: "plan_name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Package Type', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { },// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search',
    searchOnKey: 'plan_name',

  }

  config4 = {
    displayKey: "planName", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Package Type', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { },// a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: 1, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search',
    searchOnKey: 'planName ',

  }

  modalRef: BsModalRef;
  config = {
    backdrop: false, ignoreBackdropClick: true
  }
  filterForm: FormGroup;
  paymentForm: FormGroup;
  numberOfItems = [15, 50, 100];
  constructor(
    private modalService: BsModalService,
    private apiService: ApiServiceService,
    private universalService: UniversalService,
    private toastr: ToastrService,
    private router: Router,
    private cookie: CookieService,
    private activatedRoute: ActivatedRoute

  ) { }

  userRole: any;
  ngOnInit(): void {
    this.userRole = this.cookie.get('uTy');
    this.activatedRoute.queryParams.subscribe(params => {
      params['page'] ? this.page = params['page'] : this.page = 1;
      this.getList();
    })
    // this.getList();
    this.initializeForm();
    this.getdropdownValues();
    this.getUserList();

  }

  userList: any = []
  getUserList() {
    this.apiService.getUserListBilling().subscribe(
      (res: any) => {
        this.userList = res;
      });
  }

  paymentApproveID: number = null;
  startDate = null;
  endDate = null;
  status = null;
  searchKey = null;
  viewedItem;
  billingList = [];
  billingData;
  totalItems;
  page: number = 0;
  size: number = 15;
  getList() {
    let obj = {
      from: this.startDate,
      to: this.endDate,
      status: this.status,
      searchKey: this.searchKey
    };
    this.apiService.getBillingList(obj, this.page - 1, this.size).subscribe(
      (res: any) => {
        this.billingList = res.data;
        this.billingData = res;
        this.totalItems = +this.billingData.stat[0].paid + +this.billingData.stat[0].unpaid;
        this.getPagination();
        // this.router.navigate([], { queryParams: { page: this.page + 1, size: this.size } });
        this.loader = false;
      }
    );
  }


  countRes: any = '';
  currentPage;
  totalPagination;
  totalPages;
  getPagination() {
    this.totalPages = this.billingData.totalPages;
    this.currentPage = this.billingData.pageNumber + 1;
    this.totalPagination = this.universalService.getPage(this.totalPages, this.currentPage);
  }


  companyList;
  bankList;
  jobPlansList;
  getdropdownValues() {
    this.apiService.getCompanyName().subscribe(
      (res: any) => {
        this.companyList = res;
      }
    );

    this.apiService.getBankDetails().subscribe(
      (res: any) => {
        this.bankList = res;
      }
    );

    this.getJobPlans();
    this.getResumePlans();


  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }


  initializeForm() {
    this.filterForm = new FormGroup({
      date: new FormControl(null),
      status: new FormControl(null)
    });


    this.paymentForm = new FormGroup({
      "empId": new FormControl(null, Validators.required),
      "bankDetailId": new FormControl(null, Validators.required),
      "voucher": new FormControl(null),
      "jobOfferedPlanId": new FormControl(null),
      "resumeAccessPlanId": new FormControl(null),
      "packageType": new FormControl(null, Validators.required),
      "soldBy": new FormControl(null, Validators.required),
      "remarks": new FormControl(null),
      "paidDate": new FormControl(null, Validators.required)
    });
  }

  getDate(dateValue: any) {
    let date = moment(dateValue).format("YYYY-MM-DD");
    return date;
  }

  getJobPlans() {
    this.apiService.getjobPlans().subscribe(
      (res: any) => {
        this.jobPlansList = res;
      }
    );
  }

  resumePlanList:any;
  getResumePlans() {
    this.apiService.getResumePlans().subscribe(
      (res: any) => {
        this.resumePlanList = res;
      }
    );
  }

  controlValidators() {
    if (this.paymentForm.value.packageType == 'jobPlan') {
      this.paymentForm.patchValue({ resumeAccessPlanId: null });
      this.paymentForm.controls['jobOfferedPlanId'].setValidators([Validators.required]);
      this.paymentForm.controls['resumeAccessPlanId'].clearValidators()
      this.paymentForm.controls['jobOfferedPlanId'].updateValueAndValidity();
      this.paymentForm.controls['resumeAccessPlanId'].updateValueAndValidity();

    }
    else if (this.paymentForm.value.packageType == 'resumeAccessPlan') {
      this.paymentForm.patchValue({ jobOfferedPlanId: null });
      this.paymentForm.controls['resumeAccessPlanId'].setValidators([Validators.required]);
      this.paymentForm.controls['jobOfferedPlanId'].clearValidators()
      this.paymentForm.controls['resumeAccessPlanId'].updateValueAndValidity();
      this.paymentForm.controls['jobOfferedPlanId'].updateValueAndValidity();

    }
  }


  filter() {
    let data = this.filterForm.value;
    if (data.date) {
      this.startDate = this.getDate(data.date[0]);
      this.endDate = this.getDate(data.date[1]);
    }
    else {
      this.startDate = null;
      this.endDate = null;
    }
    this.status = data.status;
    this.page = 0;
    this.changePage();
    this.getList();
    // this.resetForm('filter');
  }

  search(keyword: any) {
    this.searchKey = keyword.target.value;
    this.page = 0;
    this.changePage();
    this.getList();
  }

  processing: boolean = false;
  paymentSubmit() {
    this.processing = true;
    let data = this.paymentForm.value
    let obj;
    obj = {
      "empId": data.empId.empId,
      "bankDetailId": data.bankDetailId.bank_detail_id,
      "voucher": data.voucher,
      "jobOfferedPlanId": data.jobOfferedPlanId ? data.jobOfferedPlanId.job_offered_plan_id : null,
      "soldBy": data.soldBy,
      "remarks": data.remarks,
      "paidDate": this.getDate(data.paidDate),
      "packageType": data.packageType,
      "resumeAccessPlanId": data.resumeAccessPlanId ? data.resumeAccessPlanId.resumeAccessPlanId:null

    }

    this.apiService.addPayment(obj).subscribe(
      (res: any) => {
        this.toastr.success('Successful', 'Payment added successfully.');
        this.modalRef.hide();
        this.resetForm('payment');
        this.getList();
        this.processing = false;
      },

      (err: any) => {
        this.toastr.error('Unsuccessful', 'Payment could not be added.', {
          timeOut: 3000
        });
        this.processing = false;
      }
    );
  }


  resetForm(formName: string) {
    if (formName === 'payment') {
      this.paymentForm.reset();
    }

    if (formName === 'filter') {
      this.filterForm.reset();
      this.filter();
      // this.getList();
    }
  }

  fileStatus: boolean = false;
  filesContainer = [];
  imgURL1;
  fileObj1 = {
    fileStatus: null,
    sendFile: null
  }
  errorMsg;
  successMsg;
  uploadFile(files: any) {
    this.successMsg = null;
    this.filesContainer = [];
    if (files.length) {
      let extensionType = ['doc', 'docx', 'rtf', 'pdf', 'DOC', 'DOCX', 'RTF', 'PDF', 'png', 'PNG', 'JPG', 'JPEG', 'jpg', 'jpeg'];
      this.fileObj1 = this.universalService.fileUpload(files, extensionType, 3097152);
      if (this.fileObj1.fileStatus == 1) {
        let Obj = {
          file: this.fileObj1,
        }
        this.filesContainer.push(Obj);
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {

          this.imgURL1 = reader.result;
          this.successMsg = "Click save to upload file.";
          this.fileStatus = true;
          this.uploadImage();
        }
      }
      else if (this.fileObj1.fileStatus == 3) {
        this.errorMsg = "This file type is not supported.";
        setTimeout(() => {
          this.errorMsg = null;

        }, 3500);
      }
      else {
        this.errorMsg = "File size should be less than 3 Mb.";
        setTimeout(() => {
          this.errorMsg = null;

        }, 3500);
      }

    }
    else {
      this.imgURL1 = null;
      this.fileObj1 = {
        fileStatus: 0,
        sendFile: null
      };
    }
  }

  fileName: string;
  uploadImage() {
    this.fileName = null;
    if (this.filesContainer) {
      for (let files of this.filesContainer) {
        this.apiService.uploadVoucher(files.file.sendFile).subscribe(
          (response: any) => {
            this.paymentForm.patchValue(
              { voucher: response.databaseFileName }
            );
            // this.paymentSubmit();
          },
          (err: any) => {
            this.toastr.error('Unsuccessful', 'File upload unsucessful', {
              timeOut: 3000
            });
          }
        )
      }
    }
  }



  voucherValid: boolean = true;
  changeValidators() {

    console.log(this.paymentForm)
    if (this.paymentForm.value.bankDetailId.bank_name != 'Free') {
      this.voucherValid = true;
      this.paymentForm.controls["voucher"].setValidators(Validators.required);
    }
    else {
      this.voucherValid = false;
      this.paymentForm.controls["voucher"].clearValidators();
    }
    this.paymentForm.controls["voucher"].updateValueAndValidity();
  }

  pyamentApprove() {
    if (this.paymentApproveID != null) {
      this.apiService.changePaymentStatus(this.paymentApproveID).subscribe(
        (res: any) => {
          this.toastr.success('Successful', 'Payment status changed.');
          this.getList();
          this.paymentApproveID = null;
        },
        (error: any) => {
          this.toastr.error('Unsuccessful', 'Payment status could not be changed', {
            timeOut: 3000
          });
          this.paymentApproveID = null;

        }
      );
    }
  }

  remarksId: number = null;
  remarks = null;
  addRemarks() {
    let Obj;
    if (this.remarksId != null) {
      Obj = {
        "cartLogId": this.remarksId,
        "remarks": this.remarks
      }

      this.apiService.addRemarks(Obj).subscribe(
        (res: any) => {
          this.toastr.success('Successful', 'Remarks added.');
          this.getList();
          this.remarksId = null;
          this.remarks = null;
        },
        (error: any) => {
          this.toastr.error('Unsuccessful', 'Remarks could not be added', {
            timeOut: 3000
          });
          this.remarksId = null;
          this.remarks = null;

        }
      );
    }
  }



  changePage() {
    this.page = this.page + 1;
    this.router.navigate([], { queryParams: { page: this.page, size: this.size }, queryParamsHandling: 'merge' });
  }


  onSizeChanged(event: any) {
    this.size = +event;
    this.page = 0;
    this.changePage();
    this.getList();
  }

}

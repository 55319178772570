import { Component, TemplateRef, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RestApiService } from 'src/app/common/services/rest-api.service';
import { ApiServiceService } from 'src/app/common/services/api-service.service';
import { ToastrService } from 'ngx-toastr';
import { RouterExtService } from 'src/app/common/services/router-ext.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-mobile-verification',
  templateUrl: './mobile-verification.component.html',
  styleUrls: ['./mobile-verification.component.scss']
})
export class MobileVerificationComponent implements AfterViewInit {

  @Input() mobileNo: any;
  modalRef: BsModalRef;
  @ViewChild('template') modalTemplate: TemplateRef<any>;
  mobileStep: any = 'first';

  constructor(private modalService: BsModalService,
    public toastr: ToastrService,
    public rest: RestApiService,
    private apiServiceService: ApiServiceService,
    private routerExtService: RouterExtService,
    private router: Router,
    private cookie: CookieService
  ) { }

  ngAfterViewInit() {

    this.openModal(this.modalTemplate);
    var previous = this.routerExtService.getPreviousUrl();
    let url = this.router.url;

  }

  config = {
    backdrop: true,
    ignoreBackdropClick: true
  };
  
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.config);
  }

  proceedForVerification() {
    this.mobileStep = "second";
    this.changeMobileNo();
    this.timer();
  }


  changeMobileNo() {

    this.apiServiceService.changeMobileNumber(this.mobileNo).subscribe(
      (res: any) => {
        this.getVerificationCode(this.mobileNo);

        // this.toastr.success('Successful', 'Number changed successfully.');
      },
      (error: any) => {
        this.toastr.error('Unsuccessful', error.error.errorMessage, {
          timeOut: 3000
        });
        // this.mobileNumberForm.reset();
      }
    )
  }

  closeModal() {
    this.modalRef.hide();
    this.rest.mobileVerification = false;
    this.mobileStep = 'first';
  }

  initializeVerification() {

    this.disableVerify = true;
    this.errorMsg = null;
    this.otpCode = null;
  }


  getVerificationCode(mobileNo) {
    this.enableVerify = true;
    this.apiServiceService.getVerificationCode(this.mobileNo).subscribe(
      (res) => { },
      (error: any) => {
        this.closeModal();
        this.toastr.error('Unsuccessful', error.error.errorMessage, {
          timeOut: 3000
        });
      }

    )
  }

  response;
  errorMsg: any;
  disableVerify: boolean = true;
  otpCode: any;
  successMsg: any;
  enableVerify: boolean = true;
  verifyCode(mobileNo) {
    this.disableVerify = false;
    this.enableVerify = false;
    let Obj = {
      number: mobileNo,
      code: this.otpCode
    }
    this.apiServiceService.verifyCode(this.otpCode).subscribe(
      (res) => {

        this.response = res;
        this.toastr.success('Successful', 'Mobile number verified Successfully.');
        this.cookie.set('mobileVerified', "Y",365,'/');

        this.rest.mobileVerified = 1;
        this.closeModal();



      },
      (err) => {
        this.disableVerify = true;
        this.errorMsg = "Incorrect verification code";
        setTimeout(() => {
          this.errorMsg = null;
          this.initializeVerification();
          this.enableVerify = true;

        }, 2000)
      }
    )


  }


  // TIMER
  min: number;
  sec: number;
  disableResend: boolean = false;
  timer() {

    this.min = 1;
    this.sec = 0;
    this.disableResend = true;
    document.getElementById('timer').style.visibility = 'visible';

    document.getElementById('timer').innerHTML = this.min + ":" + this.sec;
    this.startTimer();
  }

  m: any;
  s: any;
  timeArray: any = [];
  startTimer() {

    if (document.getElementById('timer').innerHTML) {
      var presentTime = document.getElementById('timer').innerHTML;
      this.timeArray = presentTime.split(/[:]+/);
      this.m = this.timeArray[0];
      this.s = this.checkSecond((this.timeArray[1] - 1));
      if (this.s == 59) { this.m = this.m - 1 }
      document.getElementById('timer').innerHTML =
        this.m + ":" + this.s;

      if (this.m == 0 && this.s == 0) {
        this.disableResend = false;
        document.getElementById('timer').style.visibility = 'hidden';
      }
      else {
        setTimeout(() => {
          this.startTimer();
        }, 1000)
      }
    }
  }

  checkSecond(sec) {
    if (sec < 10 && sec >= 0) { sec = "0" + sec };
    if (sec < 0) { sec = "59" };
    return sec;
  }


}

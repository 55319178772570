<nav class="navbar navbar-default custom-navbar-default navbar-fixed-top">
  <div class="container">
    <div class="row">
      <div class="navbar-header" style="height: 82px">
        <button
          type="button"
          class="navbar-toggle collapsed"
          data-toggle="collapse"
          data-target="#bs-example-navbar-collapse-1"
          aria-expanded="false"
          (click)="collapsed = !this.collapsed"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </button>
        <a class="cursor" [routerLink]="['/']">
          <img
            src="../../../assets/images/logo_146x70.png"
            alt="logo"
            class="logo-responsive"
          />
        </a>
      </div>

      <div
        class="collapse navbar-collapse"
        id="bs-example-navbar-collapse-1"
        [ngClass]="{ collapse: collapsed, 'navbar-collapse': true }"
      >
        <!--Not Login-->
        <ul
          class="nav navbar-nav navbar-right sm-hidethis web-view"
          style="padding-top: 15px"
          *ngIf="!checkLoginStatus()"
        >
          <!-- Web view -->
          <!-- Job Seeker -->
          <li class="job-seeker-sec" dropdown>
            <a
              class="header-dropdown"
              dropdownToggle
              aria-controls="dropdown-basic"
            >
              <div class="flex">
                <div class="menu-icon">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div class="icon-texts">
                  <p class="p1">
                    JOB SEEKER
                    <span class="caret"></span>
                  </p>
                  <p class="p2">Upload Resume</p>
                </div>
              </div>
            </a>

            <ul
              class="dropdown-menu"
              id="dropdown-basic"
              *dropdownMenu
              aria-labelledby="dropdownMenu1"
            >
              <li>
                <a class="cursor" [routerLink]="['/jobseeker/login']">Login</a>
              </li>
              <li role="separator" class="divider"></li>
              <li>
                <a class="cursor" [routerLink]="['/jobseeker/register']"
                  >Register</a
                >
              </li>
            </ul>
          </li>

          <!-- Employer Zone -->
          <li class="employer-zone" dropdown>
            <a
              class="header-dropdown"
              dropdownToggle
              aria-controls="dropdown-basic"
            >
              <div class="flex">
                <div class="menu-icon">
                  <i class="fa fa-building-o" aria-hidden="true"></i>
                </div>

                <div class="icon-texts">
                  <p class="nomargin p1">
                    EMPLOYER ZONE
                    <span class="caret"></span>
                  </p>
                  <p class="nomargin p2">Post Job & Hire the Best</p>
                </div>
              </div>
            </a>

            <ul
              class="dropdown-menu"
              id="dropdown-basic"
              *dropdownMenu
              aria-labelledby="dropdownMenu1"
            >
              <li *ngIf="!checkLoginStatus()">
                <a [routerLink]="['/employer/login']">Login</a>
              </li>

              <!-- <li role="separator" class="divider" *ngIf="!checkLoginStatus()"></li> -->

              <li role="separator" class="divider"></li>

              <li *ngIf="checkLoginStatus()">
                <a (click)="doLogout()" class="cursor">logout</a>
              </li>
              <!-- <li role="separator" class="divider" *ngIf="checkLoginStatus()"></li> -->

              <li *ngIf="!checkLoginStatus()">
                <a [routerLink]="['/employer/register']">Register</a>
              </li>
            </ul>
          </li>

          <!-- Contact -->
          <li class="contact-sec">
            <p>
              <b>Contact Us</b>
            </p>
            <p>
              <a href="tel:{{ phoneNo }}" style="color: #555555">{{
                phoneNo
              }}</a>
            </p>
            <p>
              <a href="mailto:{{ email }}" style="color: #555555">{{
                email
              }}</a>
            </p>
          </li>
        </ul>

        <ul class="nav navbar-nav navbar-right sm-hidethis mob-view">
          <div class="row">
            <div class="col-md-12" style="padding: 0px 30px">
              <li class="contact-sec">
                <p style="font-weight: 500">Contact Us</p>
                <p>
                  <a href="tel:{{ phoneNo }}" style="color: #555555">{{
                    phoneNo
                  }}</a>
                </p>
                <p>
                  <a href="mailto:{{ email }}" style="color: #555555">{{
                    email
                  }}</a>
                </p>
              </li>
            </div>
          </div>
        </ul>

        <ul
          class="nav navbar-nav navbar-right sm-hidethis"
          style="padding-top: 15px"
          *ngIf="checkLoginStatus()"
        >
          <li class="" dropdown>
            <a class="cursor" dropdownToggle aria-controls="dropdown-basic">
              {{ cookie.get("username") }} {{ cookie.get("companyName") }}
              <i
                class="fa fa-caret-down"
                style="font-size: 20px"
                aria-hidden="true"
                *ngIf="cookie.get('username') || cookie.get('companyName')"
              ></i>
            </a>
            <ng-container
              *ngIf="
                cookie.get('userRole') == 'JS' || cookie.get('userRole') == 'A'
              "
            >
              <ul
                class="dropdown-menu"
                id="dropdown-basic"
                *dropdownMenu
                aria-labelledby="dropdownMenu1"
                (click)="collapsed = !this.collapsed"
              >
                <li>
                  <a class="cursor" [routerLink]="['/jobseeker/dashboard']"
                    ><i
                      class="fa fa-tachometer"
                      style="margin: 0 5px 0 0"
                      aria-hidden="true"
                    >
                    </i>
                    Dashboard</a
                  >
                </li>

                <li
                  role="separator"
                  class="divider"
                  *ngIf="
                    cookie.get('uTy') == 'an112' || cookie.get('uTy') == 'mr112'
                  "
                ></li>
                <!-- <li *ngIf="userRole=='an112' || userRole=='mr112'">

                  <a class="cursor" [routerLink]="['/admin/user-list']"><i class="fa fa-users"
                      style="margin: 0 5px 0 0;" aria-hidden="true"></i>

                    User List</a>
                </li> -->

                <li role="separator" class="divider"></li>
                <li>
                  <a class="cursor" [routerLink]="['/jobseeker/profile']">
                    <i class="fa fa-user" style="margin: 0 5px 0 0"> </i>
                    Profile</a
                  >
                </li>

                <li role="separator" class="divider"></li>
                <li>
                  <a
                    class="cursor"
                    [routerLink]="['jobseeker/change-password']"
                  >
                    <i
                      class="fa fa-lock"
                      aria-hidden="true"
                      style="margin: 0 5px 0 0"
                    >
                    </i>
                    Change Password</a
                  >
                </li>

                <li role="separator" class="divider"></li>
                <li>
                  <a class="cursor" (click)="doLogout()">
                    <!-- <i style="margin: 0 5px 0 0;" class="glyphicon glyphicon-edit"></i> -->
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    Log Out
                  </a>
                </li>
              </ul>
            </ng-container>

            <ng-container *ngIf="cookie.get('userRole') == 'E'">
              <ul
                class="dropdown-menu"
                id="dropdown-basic"
                *dropdownMenu
                aria-labelledby="dropdownMenu1"
                (click)="collapsed = !this.collapsed"
              >
                <ng-container *ngIf="cookie.get('addInfoType') == 'Y'">
                  <li>
                    <a class="cursor" [routerLink]="['/employer/dashboard']"
                      ><i
                        class="fa fa-tachometer"
                        style="margin: 0 5px 0 0"
                        aria-hidden="true"
                      >
                      </i>
                      Dashboard</a
                    >
                  </li>

                  <li role="separator" class="divider"></li>
                  <li>
                    <a class="cursor" [routerLink]="['/employer/detail']">
                      <i class="fa fa-user" style="margin: 0 5px 0 0"> </i>
                      Profile</a
                    >
                  </li>

                  <ng-container *ngIf="cookie.get('rap') == 'Y'">
                    <li role="separator" class="divider"></li>
                    <li>
                      <a
                        class="cursor"
                        [routerLink]="['/employer/resume-access-plan']"
                      >
                        <i class="fa fa-file" style="margin: 0 5px 0 0"> </i>
                        Resume Access Plan</a
                      >
                    </li>
                  </ng-container>

                  <li role="separator" class="divider"></li>
                  <li>
                    <a
                      class="cursor"
                      [routerLink]="['employer/change-password']"
                    >
                      <i
                        class="fa fa-lock"
                        aria-hidden="true"
                        style="margin: 0 5px 0 0"
                      >
                      </i>
                      Change Password</a
                    >
                  </li>
                  <li role="separator" class="divider"></li>
                </ng-container>

                <li>
                  <a class="cursor" (click)="doLogout()">
                    <!-- <i style="margin: 0 5px 0 0;" class="glyphicon glyphicon-edit"></i> -->
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    Log Out
                  </a>
                </li>
              </ul>
            </ng-container>

            <ng-container *ngIf="cookie.get('userRole') == 'SE'">
              <ul
                class="dropdown-menu"
                id="dropdown-basic"
                *dropdownMenu
                aria-labelledby="dropdownMenu1"
                (click)="collapsed = !this.collapsed"
              >
                <li>
                  <a class="cursor" [routerLink]="['/admin/dashboard']"
                    ><i
                      class="fa fa-tachometer"
                      style="margin: 0 5px 0 0"
                      aria-hidden="true"
                    >
                    </i>
                    Dashboard</a
                  >
                </li>

                <li role="separator" class="divider"></li>
                <li>
                  <a class="cursor" [routerLink]="['/admin/billing-list']">
                    <i class="fa fa-list" style="margin: 0 5px 0 0"> </i>
                    Billing List</a
                  >
                </li>
                <li role="separator" class="divider"></li>

                <li>
                  <a class="cursor" [routerLink]="['/admin/query-list']">
                    <i class="fa fa-ticket" aria-hidden="true"></i>
                    Jobejee Query</a
                  >
                </li>
                <ng-container *ngIf="cookie.get('uTy') == 'an112'">
                  <li role="separator" class="divider"></li>
                  <li>
                    <a class="cursor" [routerLink]="['/admin/job-plan']">
                      <i class="fa fa-file-o" style="margin: 0 5px 0 0"> </i>
                      Job Plan</a
                    >
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a class="cursor" [routerLink]="['/admin/resume-pricing']">
                      <i class="fa fa-file-text-o" style="margin: 0 5px 0 0">
                      </i>
                      Resume Plan</a
                    >
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a class="cursor" [routerLink]="['/admin/deleted-account']">
                      <i class="fa fa-user-o" style="margin: 0 5px 0 0"> </i>
                      Deleted Account</a
                    >
                  </li>
                  <li role="separator" class="divider"></li>
                  <li>
                    <a
                      class="cursor"
                      [routerLink]="['/admin/employment-index']"
                    >
                      <i class="fa fa-list" style="margin: 0 5px 0 0"> </i>
                      Employment Index</a
                    >
                  </li>
                </ng-container>

                <li
                  role="separator"
                  class="divider"
                  *ngIf="cookie.get('uTy') == 'an112'"
                ></li>

                <li *ngIf="cookie.get('uTy') == 'an112'">
                  <a class="cursor" [routerLink]="['/admin/user-list']">
                    <i class="fa fa-user-o" style="margin: 0 5px 0 0"> </i>
                    User List</a
                  >
                </li>
                <li role="separator" class="divider"></li>
                <li *ngIf="cookie.get('uTy') == 'an112'">
                  <a class="cursor" [routerLink]="['admin/hipco-list']">
                    <i class="fa fa-user-o" style="margin: 0 5px 0 0"> </i>
                    Hipco List</a
                  >
                </li>
                <!-- <li *ngIf="cookie.get('uTy') == 'an112'">
                  <a class="cursor" [routerLink]="['admin/football']">
                    <i class="fa fa-user-o" style="margin: 0 5px 0 0"> </i>
                   World Cup</a
                  >
                </li> -->
                <li role="separator" class="divider"></li>

                <li>
                  <a class="cursor" [routerLink]="['/admin/password-change']">
                    <i class="fa fa-unlock-alt" style="margin: 0 5px 0 0"> </i>
                    Change Password</a
                  >
                </li>
                <li role="separator" class="divider"></li>

                <li>
                  <a class="cursor" (click)="doLogout()">
                    <i class="fa fa-sign-out" aria-hidden="true"></i>
                    Log Out</a
                  >
                </li>
              </ul>
            </ng-container>
          </li>

          <!-- <li class="contact-sec">
          <p>
            <b>Contact Us</b>
          </p>
          <p>01-4390596</p>
          <p>support@jobejee.com</p>
        </li> -->
        </ul>
      </div>
    </div>
    <!-- /.container-fluid -->
  </div>
</nav>

<div
  class="container"
  style="margin-top: 77px"
  *ngIf="!checkLoginStatus()"
  [ngClass]="{ 'mob-view': checkLoginStatus() === false }"
>
  <div class="row">
    <div class="col-md-12 nopadding">
      <div class="col-md-6 col-sm-6 col-xs-6 new-nav nopadding">
        <div dropdown>
          <span
            class="header-dropdown"
            dropdownToggle
            aria-controls="dropdown-basic"
          >
            <div class="flex bg">
              <div class="menu-icon">
                <i class="fa fa-user fa-lg" aria-hidden="true"></i>
              </div>

              <div class="icon-texts">
                <p style="margin-bottom: 2px; cursor: pointer">
                  JOB SEEKER
                  <span class="caret"></span>
                </p>
                <p style="font-size: 12px; font-weight: normal">
                  Upload Resume
                </p>
              </div>
            </div>
          </span>

          <ul
            class="dropdown-menu"
            id="dropdown-basic"
            *dropdownMenu
            aria-labelledby="dropdownMenu1"
          >
            <li *ngIf="!checkLoginStatus()">
              <a [routerLink]="['/jobseeker/login']">Login</a>
            </li>

            <li role="separator" class="divider"></li>

            <!-- <li *ngIf="checkLoginStatus()">
              <a (click)="doLogout()" class="cursor">logout</a>
            </li> -->
            <!-- <li role="separator" class="divider" *ngIf="checkLoginStatus()"></li> -->

            <li *ngIf="!checkLoginStatus()">
              <a [routerLink]="['/jobseeker/register']">Register</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 new-nav nopadding">
        <div dropdown>
          <span
            class="header-dropdown"
            dropdownToggle
            aria-controls="dropdown-basic"
          >
            <div class="flex bg">
              <div class="menu-icon" style="margin-right: -10px">
                <i class="fa fa-building-o" aria-hidden="true"></i>
              </div>

              <div class="icon-texts">
                <p style="margin-bottom: 2px; cursor: pointer">
                  EMPLOYER ZONE
                  <span class="caret"></span>
                </p>
                <p style="font-size: 12px; font-weight: normal">
                  Post Job & Hire the Best
                </p>
              </div>
            </div>
          </span>

          <ul
            class="dropdown-menu"
            id="dropdown-basic"
            *dropdownMenu
            aria-labelledby="dropdownMenu1"
          >
            <li *ngIf="!checkLoginStatus()">
              <a [routerLink]="['/employer/login']">Login</a>
            </li>

            <li role="separator" class="divider"></li>

            <!-- <li *ngIf="checkLoginStatus()">
              <a (click)="doLogout()" class="cursor">logout</a>
            </li> -->
            <!-- <li role="separator" class="divider" *ngIf="checkLoginStatus()"></li> -->

            <li *ngIf="!checkLoginStatus()">
              <a [routerLink]="['/employer/register']">Register</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

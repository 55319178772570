import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { HttpInterceptor, HttpRequest, HttpClient, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { RestApiService } from './rest-api.service';
import { CookieService } from 'ngx-cookie-service';
import { catchError } from 'rxjs/internal/operators/catchError';


@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private httpClient: HttpClient,
    private cookie: CookieService,
    private restApiService: RestApiService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  token;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    let cloneRequest: any = request.clone();
    let urlList = cloneRequest.url.split('/');

    if (urlList.find(e => e == 'v2')) {
      if (urlList[urlList.length - 1] == 'token') {
        cloneRequest = request.clone({ 'headers': this.getHeaders() });
        return next.handle(cloneRequest)   
      } else {
        cloneRequest = request.clone({ 'headers': this.getToken() });
        return next.handle(cloneRequest);
      }
    }
    else if (cloneRequest.urlWithParams == environment.token) {
      cloneRequest = request.clone({ 'headers': this.getHeaders() });

      return next.handle(cloneRequest);
    }
    else {
      if (isPlatformBrowser(this.platformId)) {
        let token = localStorage.getItem("at");
        cloneRequest.url = cloneRequest.url + "?access_token=" + token;
        cloneRequest.urlWithParams = cloneRequest.url;
        return next.handle(cloneRequest);
      } else if (isPlatformServer(this.platformId)) {
        let token = this.restApiService.altoken;
        cloneRequest.url = cloneRequest.url + "?access_token=" + token;
        cloneRequest.urlWithParams = cloneRequest.url;
        return next.handle(cloneRequest);
      }
    }
  }


  //for Headers
  private getHeaders(): HttpHeaders {
    let basicAuth = 'Basic ' + 'c2V3YWpvYjI6dGlsYWs=';
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', basicAuth);
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return headers
  }

  private getHeadersEmp(): HttpHeaders {
    // let str = "sewajob2:tilak";
    // let enc = window.btoa(str);
    let basicAuth = 'Basic ' + 'c2V3YWpvYjI6dGlsYWs=';

    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Authorization', basicAuth);
    return headers
  }

  // for accesstoken
  private getToken(): HttpHeaders {

    let token: string
    // token = localStorage.getItem('at');
    token = this.cookie.get('at');
    if (token) {
      let headers: HttpHeaders = new HttpHeaders();
      headers = headers.append('Authorization', 'Bearer' + token);
      return headers;
    }
    return null
  }




  // getheaderV2():HttpHeaders{
  //   let headers: HttpHeaders = new HttpHeaders();
  //   if (isPlatformBrowser(this.platformId)) {
  //     let token = localStorage.getItem("at");
  //     headers = headers.append('Authorization', token);
  //     // cloneRequest.url = cloneRequest.url + "?access_token=" + token;
  //     // cloneRequest.urlWithParams = cloneRequest.url;
  //     // return next.handle(cloneRequest);
  //   } else if (isPlatformServer(this.platformId)) {
  //      let token = this.restApiService.altoken;
  //     // cloneRequest.url = cloneRequest.url + "?access_token=" + token;
  //     // cloneRequest.urlWithParams = cloneRequest.url;
  //     // return next.handle(cloneRequest);
  //   }
  // }

  // // For Token
  // getToken() {
  //   debugger
  //   let url = "https://api.v1.jobejee.com/oauth/token";
  //   let Obj = "grant_type=password&username=sewajob2&password=tilak";
  //   return this.httpClient.post(url, Obj, { 'headers': this.getHeaders() })
  //     .pipe(
  //       map((response) => {
  //         console.log(response);
  //         return response;
  //       }
  //       )
  //     )
  // }


  // if (isPlatformBrowser(this.platformId)) {
  //   localStorage.setItem('at', response.access_token);
  //   localStorage.setItem('rt', response.refresh_token);
  //   localStorage.setItem('time', response.expires_in.toString());
  // }
  // Server only code.
  // else if (isPlatformServer(this.platformId)) {
  // document.cookie='at=[response.access_token]';
  // document.cookie='rt=[response.refresh_token]';
  // document.cookie='time=[response.expires_in.toString()]';
  // }
  // return response;




}

import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { BehaviorSubject } from "rxjs";
import { environment } from "../../../environments/environment";
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: "root"
})

export class RestApiService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private cookie: CookieService) {
    this.getStorage();
  }

  allUrl = environment.allUrl;
  tokenUrl = environment.token;
  emptokenUrl;
  profile:any;
  profileDetail:any;
  bookmarkJobId:any;
  mobileVerification:boolean=false;
  mobileVerified=0;
  emailVerification:boolean=false;
  hideBookmarkModal:any=0;
  //Bookmarked saved without login
  savedJobId:any;
  jobAppliedList:any;

  empProfile:any;

  adsFlag:boolean=true;
  pageShown='list';
  resumePurchaseDetail:any='';

  // 1 implies close
  //0 implies open
  //2 implies navigate


  employerFilter:Date;
  newspaperFilter:Date;
  jobFilter:Date;
  jobseekerFilter:Date;
  applicantFilter:Date;

  jsBasicInfo:any;


  getEmpurl() {
    let empUrl = localStorage.getItem('api');
    if (empUrl) {
      this.emptokenUrl = empUrl;
      return this.emptokenUrl;
    } else {
      this.emptokenUrl = environment.allUrl;
      return this.emptokenUrl;
    }
  }


  modifyRestUrl(url: string) {
    const appendUrl: string = this.getEmpurl() + url;
    const reqObj = { url: appendUrl };
    return reqObj;
  }

  modifyTokenUrl() {
    const appendUrl: string = this.tokenUrl;
    const reqObj = { url: appendUrl };
    return reqObj;
  }

  modifyEmpTokenUrl(url: string) {
    const appendUrl: string = this.getEmpurl() + url;
    const reqObj = { url: appendUrl };
    return reqObj;
  }

  dynamicUrl = {
    cms: "/cms/catergories",
    cmsAdd: "/cms/addCatergories",
    home: "/"
  };

  userObj = {
    uid: null,
    jsInfoId: null,
    name: null,
    email: null
  };

  storagelogic(Obj) {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem("uid", Obj.userDetailId);
      localStorage.setItem("jsInfoId", Obj.jsInfoId);
      localStorage.setItem("name", Obj.firstName + " " + Obj.lastName);
      localStorage.setItem("email", Obj.email);

      this.userObj.uid = Obj.userDetailId;
      this.userObj.jsInfoId = Obj.jsInfoId;
      this.userObj.email = Obj.email;
      this.userObj.name = Obj.firstName + " " + Obj.lastName;
      this.changeMessage(this.userObj);
    }
  }

  private messageSource = new BehaviorSubject(this.userObj);
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: any) {
    this.messageSource.next(message);
  }

  storageLogout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("uid");
      localStorage.removeItem("jsInfoId");
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      this.userObj = {
        uid: null,
        jsInfoId: null,
        name: null,
        email: null
      };
      this.changeMessage(this.userObj);
    }
  }
  // {"status":"SUCCESS",
  // "userDetailId":"5a3e8fd08a954ad6363e61af7aa06c233f79113f",
  // "email":"admin@jobejee.com",
  // "middleName":"",
  // "mobVerified":"N",
  // "emailVerified":"N",
  // "verifyEmail":"N","mobNo":"9849514267",
  // "jsInfoId":"2f630992da70adaa0f7c1d8f51aaa1bf4d15b3ac",
  // "firstName":"Alex","lastName":"Thapa",
  // "gender":"M","verifyMobileNo":"N"}

  getStorage() {
    if (this.userObj.uid && this.userObj.jsInfoId) {
      this.changeMessage(this.userObj);
      return this.userObj;
    } else {
      if (isPlatformBrowser(this.platformId)) {
        this.userObj.uid = localStorage.getItem("uid");
        this.userObj.jsInfoId = localStorage.getItem("jsInfoId");
        this.userObj.name = localStorage.getItem("name");
        this.userObj.email = localStorage.getItem("email");

        if (this.userObj.uid && this.userObj.jsInfoId) {
          this.changeMessage(this.userObj);

          return this.userObj;
        } else {
          return null;
        }
      }
    }
  }



  userCookie = {
    userId: null,
    userRole: null,
    tmpE: null,
    emailVerified: null
  };
  getCookieStorage() {
    this.userCookie.userId = this.cookie.get('userId');
    this.userCookie.userRole = this.cookie.get('userRole');
    this.userCookie.tmpE = this.cookie.get('tmpE');
    this.userCookie.emailVerified = this.cookie.get('emailVerified');

    return this.userCookie;
  }

  altoken;
  storageToken(token) {
    this.altoken = token;
  }
}

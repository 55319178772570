import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError, debounceTime } from "rxjs/operators";
import { RestApiService } from "./rest-api.service";
import { of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  constructor(
    private restApiService: RestApiService,
    private httpClient: HttpClient
  ) {}

  getYearList() {
    let url = "analytics/regJs/allYears";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  selectedYearList(year) {
    let url = "analytics/regJs/" + year;
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  selectedYearMonthList(year, month) {
    let url = "analytics/regJs/" + year + "/" + month;
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  getAds(obj) {
    let url = "ad/getAds";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  impression(obj) {
    let url = "ad/adCtr";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  featureCompany() {
    // let url = "mob/jsInfo/homePage";
    // let url = "jobSortForHomepage/featureCompany";
    let url = "homepage/featureCompany";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((ads) => {
        return ads;
      })
    );
  }

  //home page
  JobsByCompany() {
    // old jobejee url
    // let url = "jobSortForHomepage/company";
    let url = "homepage/company";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }
  JobsByIndustry() {
    // old jobejee url
    // let url = "jobSortForHomepage/industry";
    let url = "homepage/industry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  JobsByLocation() {
    // old jobejee url
    // let url = "jobSortForHomepage/location";
    let url = "homepage/location";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  NewsJobsByCompany() {
    // homepage/newsJobByCompany
    let url = "homepage/newsJobByCompany";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }
  getTestimonials() {
    let url = "homepage/testimonials";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }
  NewsJobsByIndustry() {
    let url = "homepage/newsJobByIndustry";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  NewsJobsByLocation() {
    let url = "homepage/newsJobByLocation";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  jobsearch(Obj, page, size) {
    let url = "jobSearch?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((job) => {
        return job;
      })
    );
  }

  getCategory(Obj) {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((Obj) => {
        return Obj;
      })
    );
  }

  getSidebar(Obj) {
    let url = "jobSearch/nav";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((job) => {
        return job;
      })
    );
  }

  getFilterData(Obj) {
    let url = "jobSearch";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((job) => {
        return job;
      })
    );
  }

  getQuickFilter(Obj) {
    let url = "quickFilter/search";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((job) => {
        return job;
      })
    );
  }

  footerJob() {
    let url = "quickLinks/getAll";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  JobDetails(Obj) {
    let url = "jobView/jobDetail";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  similarJobs(Obj) {
    let url = "jobSearch/selectedJobSimilar";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  SimilarJobDetails(Obj) {
    let url = "jobSearch/selectedJobSimilar";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  NewspaperJobDetails(Obj) {
    // let url = "jobView/otherJob";
    let url = "job/newspaper/view";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  SimilarNewspaperJobDetails(Obj) {
    let url = "newspaperJob/similarJobs";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  clientDetails(Obj) {
    let url = "employerDetail/simpleSite";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  newClientDetails(Obj) {
    // let url = "/employerDetail/profile";
    // let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    // return this.httpClient.get(getUrl.url, Obj).pipe(
    //   map(companyJob => {
    //     return companyJob;
    //   })
    // );
    let url = "employerDetail/simpleSite";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  similarCompany(employerID) {
    let Obj = null;
    let url = "employerDetail/similarEmployer/" + employerID;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  clientMoto(Obj) {
    let url = "empSimpleSite/getCompanyInfo";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((companyJob) => {
        return companyJob;
      })
    );
  }

  jobseekerLogin(Obj) {
    let url = "userDetail/loginCheck";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  resumeCheck(Obj) {
    let url = "jsInfo/checkResume";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  appyjob(Obj) {
    let url = "jobApply/apply";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailValidation(value) {
    let data = {
      email: value,
      userType: "JS",
    };
    let url = "userDetail/emailVerify";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, data).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  phoneValidation(value, cc) {
    let data = {
      mobNo: value,
      countryCode: cc,
    };
    let url = "jsInfo/mobNoValidation";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, data).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  //js get api

  getCandidateInfo(id) {
    let url = "jsInfo/dashboard/userDetailId/" + id;
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  getCountry(obj) {
    let url = "location/search";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  registerCandidate(obj) {
    let url = "jsInfo/register";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  applyWithoutRegister(obj) {
    let url = "jobApply/apply";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadCV(obj) {
    let url = "jsInfo/uploadResume";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadJsDocs(obj) {
    // let url = "jsDocs/uploadDocs";
    let url = "jsEdu/uploadCert";

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadCoverLetters(obj) {
    // let url = "jsDocs/uploadDocs";
    let url = "jsInfo/uploadCoverLetter";

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadDocumentDocs(obj) {
    let url = "jsDocs/uploadDocs";
    // let url = "jsEdu/uploadCert";

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadPhto(obj, type) {
    let url;
    if (type == "photo") {
      url = "jsInfo/uploadPic";
    } else if (type == "citizen") {
      url = "jsInfo/uploadCtz";
    } else if (type == "marksheetI") {
      url = "jsInfo/uploadPlusTwo";
    } else if (type == "marksheetII") {
      url = "jsInfo/uploadBachelors";
    } else if (type == "marksheetIII") {
      url = "jsInfo/uploadMasters";
    } else if (type == "Training") {
      url = "jsInfo/uploadTraining";
    } else if (type == "bankingExp") {
      url = "jsInfo/uploadExp1";
    } else if (type == "bankingExpII") {
      url = "jsInfo/uploadExp2";
    } else if (type == "cv") {
      url = "jsInfo/uploadCv";
    } else if (type == "exam") {
      url = "jsInfo/addJobApplyForExam";
    }

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  jobBank(id) {
    let url = "jsInfo/getJobApplyForExam/" + id;
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadProfileFile(obj) {
    let url = "jsInfo/uploadProfilePic";

    // let url = "adContent/uploadAdImage";

    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadProfileEmp(obj) {
    let url = "user/employer/uploadLogo";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadBannerEmp(obj) {
    let url = "employer/simpleSite/uploadBanner";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  uploadProfilePic(obj) {
    let url = "jsInfo/profile";

    // let url = "adContent/uploadAdImage";

    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  getAllCity(obj) {
    let url = "location/getAllCity";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAllIndustry(obj) {
    let url = "category";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  gettagsInput(obj) {
    let url = "tag/skillSearch";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  updateCandidateProfile(value, type) {
    let url;
    if (type == "summary") {
      url = "jsInfo/updateSummary";
    } else if (type == "location") {
      url = "jsInfo/updateLocation";
    } else if (type == "personal") {
      url = "jsInfo/normalUpdate";
    } else if (type == "education") {
      url = "jsEdu/collection/reg";
    } else if (type == "jobCategory") {
      url = "jsInfo/jobCategory";
    } else if (type == "jobResume") {
      url = "jsInfo/updateResumeTitle-Skill";
    } else if (type == "itSkill") {
      url = "jsItSkill/collection/reg";
    } else if (type == "other Details") {
      url = "jsInfo/otherDetails-lang";
    } else if (type == "certification") {
      url = "jsCertificate/collection/reg";
    } else if (type == "employment") {
      url = "jsEmployment/collection/reg";
    } else if (type == "jobPreference") {
      url = "jsInfo/preferrence";
    } else if (type == "cvUpload") {
      url = "jsInfo/uploadResume";
    }

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, value).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updatenewCandidateProfile(value, type) {
    let url;
    if (type == "jsDetail") {
      url = "candidateProfile/jsDetail";
    } else if (type == "jsProfileCalc") {
      url = "candidateProfile/jsProfileCalc";
    } else if (type == "jsKeySkill") {
      url = "candidateProfile/jsKeySkill";
    } else if (type == "jsPrefLoc") {
      url = "candidateProfile/jsPrefLoc";
    } else if (type == "jsDetail") {
      url = "candidateProfile/jsDetail";
    } else if (type == "jsEmployment") {
      url = "candidateProfile/jsEmployment";
    } else if (type == "jsJobCategory") {
      url = "candidateProfile/jsJobCategory";
    } else if (type == "jsJobPreference") {
      url = "candidateProfile/jsJobPreference";
    } else if (type == "jsItSkills") {
      url = "candidateProfile/jsItSkills";
    } else if (type == "jsOtherDetails") {
      url = "candidateProfile/jsOtherDetails";
    } else if (type == "jsResume") {
      url = "candidateProfile/jsResume";
    } else if (type == "jsEducation") {
      url = "candidateProfile/jsEducation";
    } else if (type == "jsCertificates") {
      url = "candidateProfile/jsCertificates";
    } else if (type == "jsOnlineProfile") {
      url = "candidateProfile/jsOnlineProfile";
    }

    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, value).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteCandidateProfile(value, type) {
    let url;
    if (type == "itSkill") {
      url = "jsItSkill/delete";
    } else if (type == "employment") {
      url = "jsEmployment/delete";
    } else if (type == "jsLang") {
      url = "jsLang/delete";
    } else if (type == "edu") {
      url = "jsEdu/delete";
    } else if (type == "certification") {
      url = "jsCertificate/delete";
    } else if (type == "onlineProfile") {
      url = "jsOnlineProfile/delete";
    } else if (type === "language") {
      url = "jsLang/delete";
    }

    // https://api.v1.jobejee.com/rest/jsLang/delete?access_token=bedde022-3e58-4eb5-80df-1fbb74e3b9cd
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, value).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobRecommendation(Obj) {
    let url = "jobSearch/recommend";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteResumePlan(id) {
    let url = "resumeAccessPlan/delete/" + id;
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  ProfileStatus(Obj) {
    let url = "jsProfileAppStatus/view";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  JsAnalytic(Obj) {
    let url = "jobJsAnalytic/view";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  JsJobAnalytic(Obj) {
    let url = "jobApply/scheduledJobList";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changePass(Obj) {
    let url = "userDetail/changepassword";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailContent(Obj) {
    let url = "emailContent/user";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  applicationContent(Obj) {
    let url = "jobApply/applicationState";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailView(Obj) {
    let url = "emailContent/makeViewed";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getLocationList(Obj) {
    let url = "location/search";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAllIndustryFunctional(Obj) {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAllRewardsInfo(Obj) {
    let url = "referFriend/getInfo";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAllgifts(Obj) {
    let url = "gift/getAllGifts";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  subscriptionDetailCall(Obj) {
    let url = "emailSubscription/getSubscription";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateSubscription(Obj) {
    let url = "emailSubscription/updateSubscription";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailVerification(Obj) {
    let url = "userDetail/validateEmail";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  examList(obj) {
    let url = "jsInfo/jobApplyForExam/filter";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  examListChange(obj) {
    let url = "jsInfo/jobApplyForExam/updateStatus";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getJobDetails(id) {
    let url = "createJob/refillJobDetail/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getEmployerList(page, size, obj) {
    let url = "admin/employer/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
    this.salesList;
  }
  getHipcoList(page, size, obj) {
    let url = "ukaid/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getEmailLogsList(page, size, obj) {
    let url = "admin/employer/emailLogs?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  changeEmail(obj) {
    let url = "admin/employer/updateEmail";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  resetPassword(obj) {
    let url = "admin/employer/resetPassword";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  updatePassword(obj) {
    let url = "admin/employer/updatePassword";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  sendTemporaryPassword(obj) {
    let url = "admin/jobseeker/sendTemporaryPassword";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  exportExcel() {
    let url = "ukaid/downloadExcel";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getEmployerListWithoutPagination(obj) {
    let url = "admin/employer/list";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  companyList() {
    let url = "company/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  newEmployerList() {
    let url = "user/employer/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  jobTitleList(id) {
    let url = "admin/job/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getJobList(page, size, obj) {
    let url = "admin/job?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getQueryList(page, size, obj) {
    let url = "contactUs/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  //updatefolloup on query list
  updateStatus(obj) {
    let url = "contactUs/addFollowup";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  //query list view detail
  viewDetail(queryId) {
    let url = "contactUs/followup/" + queryId;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  //For Advertisement
  getAdsList(addType: string, page?, size?) {
    let url;
    if (addType === "runningAds") {
      url = "adContent/runningAds?page=" + page + "&size=" + size;
    } else if (addType === "scheduledAds") {
      url = "adContent/scheduledAds?page=" + page + "&size=" + size;
    } else if (addType === "expiredAds") {
      url = "adContent/expiredAds?page=" + page + "&size=" + size;
    } else if (addType === "deletedAds") {
      url = "adContent/deletedAds?page=" + page + "&size=" + size;
    }
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getNewAds() {
    let url = "adContent/getAds";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCtrForAds(id) {
    let url = "adContent/adCtr/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  createNewspaperJob(obj) {
    let url = "newspaperJob";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  editNewspaperJob(obj) {
    let url = "newspaperJob";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getNewspaperJob(obj) {
    let url = "job/newspaper/view";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  verifyEmai(code) {
    let url = "user/verify/email/" + code;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, null).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  employerCount() {
    let url = "admin/employer/stat";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  changeEmployeeStatus(obj) {
    let url = "admin/employer/changeStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }
  changeHipcoStatus(obj) {
    let url = "ukaid/updateStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  changeJobStatus(obj) {
    let url = "jobSchedule/changeJobStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  changeNewspaperStatus(obj) {
    let url = "newspaperJob/updateStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((examList) => {
        return examList;
      })
    );
  }

  getNewspaperJoblist(obj, page, size) {
    let url = "newspaperJob/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  adList() {
    let url = "adContent/getAllAdTypes";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPublicationList() {
    let url = "newsPublication/getAll";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadAdImage(Obj) {
    let url = "adContent/uploadAdImage";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadNewspaperImage(Obj) {
    let url = "newspaperJob/uploadAdImage";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadJobApplyImage(Obj) {
    let url = "jsInfo/uploadResume";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveAdvertisement(obj) {
    let url = "adContent";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAdvertisementById(id) {
    let url = "adContent/getById/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  //Billing List
  getBillingList(obj, page, size) {
    let url = "cartLog/cartPayDetail?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCompanyName() {
    let url = "user/employer/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getBankDetails() {
    let url = "bankDetails";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getjobPlans() {
    let url = "jobPlans/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getjobPlansList() {
    let url = "jobPlans/plans/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getResumePlans() {
    let url = "resumeAccessPlan/active";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadVoucher(Obj) {
    let url = "bankDetails/uploadVoucher";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addPayment(obj) {
    let url = "cartLog/bankTransfer";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changePaymentStatus(id: number) {
    let url = "cartLog/payApprove/" + id;
    let obj = null;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addRemarks(obj) {
    let url = "cartLog/soldByRemarks";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  //  Dashboard

  getAnalytics() {
    let url = "analytics";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  geRevenue() {
    let url = "analytics/revenue";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getSmsCredit() {
    let url = "sms/credit";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  unhassJsInfoId(id) {
    let url = "unHash/js/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobApply(obj) {
    let url = "job/apply";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  functionalAreasList() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["func"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getLanguage() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["lang"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  educationDetails() {
    let url = "jsEdu";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getLanguageProf() {
    let url = "jsLang";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  industriesList() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["ind"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  designationList() {
    let url = "category/getCategory";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["desig"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // Employer billing list

  getEmployerBillingList(obj) {
    let url = "cartLog/cartPayDetailUser";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCompanyList(obj, page, size) {
    // let url = "company?page="+page+"&size="+size;
    let url = "company/searchList?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeCompanyStatus(id) {
    let url = "company/activate/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  //jobseeker dashboard
  getRecommendedJobs(page, size) {
    let url = "jsDashboard/recommended?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  profileData() {
    let url = "jsDashboard/profileComplete";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadCompanyLogo(obj) {
    let url = "company/uploadLogo";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  companyDuplicate(company) {
    let url = "company/check";
    let Obj = {
      name: company,
    };
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveCompany(obj) {
    let url = "company";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return true;
      })
    );
  }

  doLogin(obj) {
    let url = "oauth/token";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAppliedJobs(page, size) {
    let url = "jsDashboard/applied?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAppliedJob(page, size) {
    let url = "jsDashboard/applied?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  userInfo() {
    let url = "userInfo";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getSavedJobs() {
    let url = "jsDashboard/bookmarked";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getSavedJob(page, size) {
    let url = "jsDashboard/bookmarked?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCandidateProfile() {
    let url = "jsInfo/profile";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getOverview() {
    let url = "jsDashboard/overview";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobCompleteness() {
    let url = "jsInfo/profile/completeness";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getStats() {
    let url = "jsDashboard/stat";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  empDetails() {
    let url = "jsEmployment";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMail() {
    let url = "js/email";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getCertificate() {
    let url = "jsCertificate";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getProfileReference() {
    let url = "jsReference";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getDocumentList() {
    let url = "jsDocs";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUnreadMail() {
    let url = "js/email/unreadCount";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // To get user list in admin/user-list page

  getUserList(page, size) {
    let url = "systemUser?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserLists() {
    let url = "systemUser?page=" + 0 + "&size=" + 2000;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserListBilling() {
    let url = "systemUser/userName";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateCertificate(Obj) {
    let url = "jsCertificate";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateReference(Obj) {
    let url = "jsReference";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateLanguage(obj) {
    let url = "jsLang";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateEmpDetails(Obj) {
    let url = "jsEmployment";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateEducation(Obj) {
    let url = "jsEdu";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteEmployment(id) {
    let url = "jsEmployment/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.delete(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getUserRole() {
    let url = "systemUser/roles";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteEdu(id) {
    let url = "jsEdu/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.delete(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addUser(obj) {
    let url = "systemUser";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  deleteCertificate(id) {
    let url = "jsCertificate/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.delete(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeUserStatus(userID) {
    let obj = null;
    let url = "systemUser/changeStatus/" + userID;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  deleteLanguage(id) {
    let url = "jsLang/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.delete(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteReference(id) {
    let url = "jsReference/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.delete(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteDocs(id) {
    let url = "jsDocs/delete/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  editUser(obj) {
    let url = "systemUser";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateProfile(obj) {
    let url = "jsInfo/profile";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  countryList() {
    let url = "location/getAllCityCountry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["ctry"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  cityList() {
    let url = "location/getAllCityCountry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    let obj = ["city"];
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  locationSearch(obj) {
    let url = "location/new/search";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadCv(obj) {
    let url = "jsInfo/uploadResume";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  emailDuplicate(email) {
    let url = "user/emailExist?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveBookmark(id) {
    let url = "jobReview/bookMark/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  //to check email
  checkEmail(email: string) {
    let url = "user/emailCheck?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  //to check email
  checkEmailAdmin(email: string) {
    let url = "user/emailExist?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // To change password

  changePassword(obj) {
    let url = "user/changePassword";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  forgetPassword(email: string) {
    let url = "user/forgotPassword?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getApplicantList(page, size, obj: any) {
    let url = "admin/job/applicants?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getVerificationCode(mobNo) {
    let url = "otp/send/" + mobNo;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  verifyCode(code) {
    let url = "otp/verify/" + code;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // sales list
  salesList(obj, page, size) {
    let url = "jobSellerAnalytics?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobAnalytics(email) {
    let url = "jobSellerAnalytics/?email=" + email;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // Jobseeeker
  jobseekerList(obj, page, size) {
    let url = "admin/jobseeker?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  jobseekerListDetail(id) {
    let url = "admin/jobseeker/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateJsCallStatus(id, status) {
    let url = "admin/jobseeker/" + id + "?callStatus=" + status;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  updateJsRemarks(obj) {
    let url = "admin/jobseeker/comment";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  savePlan(obj) {
    let url = "jobPlans";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveResumePlan(obj) {
    let url = "resumeAccessPlan";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeJobPlanStatus(id) {
    let url = "jobPlans/changeStatus/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.put(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }

  planList() {
    let url = "jobPlans/plans";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  changeMobileNumber(number) {
    let url = "jsInfo/changeNumber/" + number;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  prefLocation() {
    let url = "jsInfo/profile/prefLoc";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getFooterIndustry() {
    let url = "homepage/footerByIndustry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getFooterFunctionalArea() {
    let url = "homepage/footerByFuncArea";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAnalyticsJsGender() {
    let url = "analytics/totalJs/gender";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAnalyticsJsEducation() {
    let url = "analytics/totalJs/edu";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getAnalyticsJsLocation(number) {
    let url = "analytics/totalJs/loc/" + number;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  locationList() {
    let url = "analytics/totalJs/loc";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteAdds(id) {
    let url = "adContent/delete/" + id;
    let obj = null;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  reCreateJob(obj) {
    let url = "createJob/copy";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getApplicant(page, size, obj) {
    let url = "admin/job/applicantList?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getEmploymentIndex(page, size) {
    let url = "analytics/empIndex?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  generateExcelEmploymentIndex(obj) {
    let url = "analytics/empIndexExcel";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  unRegisteredJS(page, size, obj) {
    let url = "admin/jobseeker/unRegistered?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  callLogListEmployee(obj, page, size) {
    let url = "jsCallLog/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  callLogListEmployer(obj, page, size) {
    let url = "employerCallLog/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  callLogEmployerList(obj, page, size) {
    let url = "employerCallLog/list?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  callLogEmployerDetail(id) {
    let url = "employerCallLog/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }
  saveCallLogEmployer(obj) {
    let url = "employerCallLog";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveFollowupData(obj) {
    let url = "jsCallLog";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  fullUserDetail(id: number) {
    let url = "admin/jobseeker/unRegistered/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  appStatus() {
    let url = "jsDashboard/appStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  saveDoc(Obj) {
    let url = "jsDocs";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPricingList() {
    let url = "resumeAccessPlan/list?page=0&size=1000";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, null).pipe(
      map((response) => {
        return response;
      })
    );
  }
  getDeletedAccount(obj) {
    let url = "admin/jobseeker/getJsDelete?page=0&size=1000";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
  cancelDeletionAccount(obj) {
    let url = "admin/jobseeker/jsDelete/updateStatus";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  applicationDetails(jobApplicationId) {
    let url = "applicants/interview/" + jobApplicationId;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getEverstBankApplicantList() {
    let url = "testCv/list";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  logHistory() {
    let url = "analytics/applications/allYears";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  empIndex() {
    let url = "analytics/empIndex";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  logHistoryByYear(year) {
    let url = "analytics/applications/" + year;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  employmentIndexByMonth(year, month) {
    let url = "analytics/applications/" + year + "/" + month;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadFlag(obj) {
    let url = "worldCup/uploadFlag";
    let getUrl = this.restApiService.modifyRestUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      }),
      catchError((err) => of(null))
    );
  }

  saveCountry(obj) {
    let url = "worldCup/addCountry";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteFootballCountry(id) {
    let url = "worldCup/deleteCountry/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getSchedule() {
    let url = "worldCup/matchToday";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteFootballSchedule(id) {
    let url = "worldCup/deleteMatch/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getFootballCountryList() {
    let url = "worldCup/getAllCountries?page=" + 0 + "&size=" + 50;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getFootballCountryListOnly() {
    let url = "football/country/all";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addMatchSchedule(obj, type) {
    let url;
    if (type == "result") url = "worldCup/updateMatchStatus";
    else {
      url = "worldCup/addMatchSchedule";
    }

    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMatchSchedule(data, page, size) {
    let url = "worldCup/getMatches?page=" + page + "&size=" + size;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, data).pipe(
      map((response) => {
        return response;
      })
    );
  }

  deleteMatchSchedule(id) {
    let url = "football/match/delete/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getPredictorList(page, size, obj) {
    let url = "worldCup/getAllPredictions?page=" + page + "&size=" + size;

    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getMatchScheuleList(data) {
    let url = "worldCup/getMatches?page=" + 0 + "&size=" + 200;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, data).pipe();
  }

  submitScore(Obj) {
    let url = "worldCup/addPrediction";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, Obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  // downloadPredictorReport(obj) {
  //   let url = "football/predictor/excel";
  //   let getUrl = this.restApiService.modifyEmpTokenUrl(url);
  //   return this.httpClient.post(getUrl.url, obj).pipe(
  //     map((response) => {
  //       return response;
  //     })
  //   );
  // }

  //For Unique Applicant List
  unqApplicationsAllYears() {
    let url = "analytics/UnqApplications/allYears";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  unqApplicationsByYear(year) {
    let url = "analytics/UnqApplications/" + year;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  unqApplicationsByMonth(year, month) {
    let url = "analytics/UnqApplications/" + year + "/" + month;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  checkMatch(id) {
    let url = "worldCup/checkPrediction/" + id;
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.get(getUrl.url).pipe(
      map((response) => {
        return response;
      })
    );
  }

  addDataHulas(obj) {
    let url = "ukaid";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }

  uploadPhoto(obj) {
    let url = "ukaid/uploadCitizenship";
    let getUrl = this.restApiService.modifyEmpTokenUrl(url);
    return this.httpClient.post(getUrl.url, obj).pipe(
      map((response) => {
        return response;
      })
    );
  }
}

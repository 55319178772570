<div class="container-fluid navbar-top" [ngClass]="{'container-fixed-top':checkLoginStatus()===true}">
  <div class="row">
    <div class="col-md-12 banner-box">

      <div class="container nopadding">
        <div class="row">
          <div class="col-md-7"></div>
          <div class="col-md-5 col-sm-8 col-sm-offset-2 col-md-offset-0 login-left-padd">
            <div class="login-box">
              <p><i class="fa fa-user" aria-hidden="true"></i> Employer Login</p>

              <div class="alert alert-danger" role="alert" *ngIf="errorMsg">
                {{errorMsg}}
              </div>

              <form [formGroup]="loginForm">
                <div class="form-group">
                  <label for="email">Email</label>
                  <input type="text" class="form-control" id="email" formControlName="username" placeholder="Email">
                </div>

                <div class="form-group" style="position: relative;">
                  <label for="password">Password</label>
                  <input type={{type}} class="form-control" id="password" formControlName="password"
                    placeholder="Password">
                  <span class="show-password" *ngIf="type=='text'" (click)="changeType()"><i class="fa fa-eye"
                      aria-hidden="true" style="font-size: 14px;"></i></span>
                  <span class="show-password" *ngIf="type=='password'" (click)="changeType()"><i class="fa fa-eye-slash"
                      aria-hidden="true" style="font-size: 14px;"></i></span>



                </div>

                <div class="form-group text-right">
                  <span for="password"> <a class="red-link" [routerLink]="['/forget-password']"> Forget password ?
                    </a></span>
                </div>

                <div class="form-group">
                  <div class="row">
                    <div class="col-md-6">
                      <button class="btn green-btn" (click)="login()" [disabled]="loginForm.invalid"
                        *ngIf="!logging">Log In</button>
                      <button class="btn green-btn" [disabled]="true" *ngIf="logging">Logging in
                        <span class="s-spinner">
                          <span class="bounce1"></span>
                          <span class="bounce2"></span>
                          <span class="bounce3"></span>
                        </span>
                      </button>

                    </div>
                    <div class="col-md-6 text-center">
                      <span class="txt-light create-link" [routerLink]="['/employer/register']">Register New
                        Employer</span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="col-md-2"></div>
        </div>
      </div>



    </div>

    <div class="col-md-12 wt-box">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img src="../../../assets/images/jobejeeScreen.png" alt="" style="width: 100%;">
          </div>
          <div class="col-md-8">
            <p class="header">About Us</p>

            <p class="justify">Jobejee is the fastest-growing job portal in Nepal. With the Jobejee website and application,
              you can search and apply for jobs with optimal ease and also have a stress-free application period.
            </p>

            <p class="justify">
              Find your career opportunities at Jobejee! Search among thousands of jobs from top companies, industries, 
              and locations of your choice!</p>

            <p class="justify">
              Jobejee’s advanced features allow you to find your perfect job within minutes and apply for it with a
              single click. You are also able to easily assess your resume performance and track your applications.
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 text-center">
            <P class="header">Why Choose Us</P>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="col-md-12 grey-box">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img src="../../../assets/images/jobs.png" alt="">
                </div>
                <div class="col-md-12 text-box">
                  <p class="header">Easy Job Post</p>
                  <p class="text-light">Reach out to thousands of job seekers with easy hiring process through our fast
                    and easy job
                    posting services.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-md-12 grey-box">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img src="../../../assets/images/applicant.png" alt="">
                </div>
                <div class="col-md-12 text-box">
                  <p class="header">Applicant Tracking System</p>
                  <p class="text-light">Employers can easily access thousands of resume through our Applicant Tracking System.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="col-md-12 grey-box">
              <div class="row">
                <div class="col-md-12 text-center">
                  <img src="../../../assets/images/speaker.png" alt="">
                </div>
                <div class="col-md-12 text-box">
                  <p class="header"> Advertise With Us</p>
                  <p class="text-light">We offer an easy & cost effective online advertisement.</p>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>